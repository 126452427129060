import { HORSE_RACE_EVENTS , HORSE_RACE_EVENTS_FAILURE , HORSE_RACE_EVENTS_SUCCESS } from "../types";

export const getHorseRaceEvents = (payload) => ({
  type: HORSE_RACE_EVENTS,
  payload,
});

export const getHorseRaceEventsSuccess = (payload) => ({
  type: HORSE_RACE_EVENTS_SUCCESS,
  payload,
});

export const getHorseRaceEventsFailure = () => ({
  type: HORSE_RACE_EVENTS_FAILURE,
});
