import {
  GET_BALANCE_DATA,
  GET_BALANCE_DATA_FAILURE,
  GET_BALANCE_DATA_SUCCESS,
  LOGOUT,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  balanceData: null,
};

const getUserBalanceData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BALANCE_DATA:
      return { ...state, loading: true };
    case GET_BALANCE_DATA_SUCCESS:
      return { ...state, balanceData: action.payload, loading: false };
    case GET_BALANCE_DATA_FAILURE:
      return { ...state, loading: false };
    case LOGOUT:
      return { ...state, userData: null, loading: false };
    default:
      return state;
  }
};

export default getUserBalanceData;
