import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_SPORT } from "../../actions/types";
import { getSportSuccess, getSportFailure } from "./../../actions";
import API from "../../../utils/api";
import { logOut } from "../../actions/auth/logoutAction";

function* getSportRequest(action) {
  try {
    let response;
    if (action?.payload?.auth) {
      const { data } = yield API.get("api/v1/get-sports-data-authenticate");
      response = data;
    } else {
      const { data } = yield API.get("api/v1/get-sports-data");
      response = data;
    }

    if (response.meta.code === 200) {
      yield put(getSportSuccess(response));
      yield call(action.payload.callback, response.data);
    } else if (response.meta.code === 401) {
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
      yield put(getSportFailure());
      yield call(action?.payload?.errorCallback);
      yield put(logOut()); // clearing all sensitive datas of user if any
    } else if (response.meta.code !== 200) {
      yield put(getSportFailure());
      yield call(action?.payload?.errorCallback);
    }
  } catch (error) {
    yield put(getSportFailure());
    yield call(action?.payload?.errorCallback);
  }
}

export function* watchGetSportAPI() {
  yield takeEvery(GET_SPORT, getSportRequest);
}

export default function* rootSaga() {
  yield all([watchGetSportAPI()]);
}
