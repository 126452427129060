import {
  GET_INPLAY_BY_SPORT,
  GET_INPLAY_BY_SPORT_SUCCESS,
  GET_INPLAY_BY_SPORT_FAILURE,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  inPlayBySportData: null,
};

const getInPlayBySportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INPLAY_BY_SPORT:
      return { ...state, loading: true };
    case GET_INPLAY_BY_SPORT_SUCCESS:
      return { ...state, inPlayBySportData: action.payload, loading: false };
    case GET_INPLAY_BY_SPORT_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default getInPlayBySportReducer;