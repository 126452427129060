import { GET_DEBIT, GET_DEBIT_SUCCESS, GET_DEBIT_FAILURE } from "../types";

export const getDebit = (payload) => ({
  type: GET_DEBIT,
  payload,
});

export const getDebitSuccess = (payload) => ({
  type: GET_DEBIT_SUCCESS,
  payload,
});

export const getDebitFailure = () => ({
  type: GET_DEBIT_FAILURE,
});
