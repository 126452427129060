//this is for fetching Events from external Api

import {
  GET_EVENT_EXCH,
  GET_EVENT_EXCH_SUCCESS,
  GET_EVENT_EXCH_FAILURE,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  eventExchData: null,
};

const getEventExchReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EVENT_EXCH:
      return { ...state, loading: true };
    case GET_EVENT_EXCH_SUCCESS:
      return { ...state, eventExchData: action.payload, loading: false };
    case GET_EVENT_EXCH_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default getEventExchReducer;
