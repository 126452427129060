import { SET_SELECTED_MARKET } from "../../actions/types";

const INIT_STATE = {
  loading: false,
  selectedMarket: null,
};

const SelectedMarketReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_MARKET:
      return { ...state, selectedMarket: action.payload };
    default:
      return state;
  }
};

export default SelectedMarketReducer;
