import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_DEBIT } from "../../actions/types";
import { getDebitSuccess, getDebitFailure } from "../../actions";
import API from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";

function* getDebitRequest(action) {
  try {
    const { data } = yield API.post("/api/v1/debit", action?.payload);
    if (data.meta.code === 200) {
      yield put(getDebitSuccess(data));
      yield call(action.payload.callback, data);
      notifySuccess(data.meta.message);
    } else if (data.meta.code === 401) {
      yield put(getDebitFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(getDebitFailure());
      notifyWarning(data.meta.message);
    }
  } catch (error) {
    yield put(getDebitFailure());
    notifyWarning(error?.response?.data?.message);
  }
}

export function* watchGetCDebitAPI() {
  yield takeEvery(GET_DEBIT, getDebitRequest);
}

export default function* rootSaga() {
  yield all([watchGetCDebitAPI()]);
}
