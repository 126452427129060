import { STX_PLACE_BET, STX_PLACE_BET_FAILURE, STX_PLACE_BET_SUCCESS } from "../types";

export const StxPlaceBet = (payload) => ({
  type: STX_PLACE_BET,
  payload,
});

export const StxPlaceBetSuccess = (payload) => ({
  type: STX_PLACE_BET_SUCCESS,
  payload,
});

export const StxPlaceBetFailure = () => ({
  type: STX_PLACE_BET_FAILURE,
});
