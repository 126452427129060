import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_CASINO_BETS } from "../../actions/types";
import { getCasinoBetsSuccess, getCasinoBetsFailure } from "../../actions";
import API from "../../../utils/api";

function* getCasinoBetsRequest(action) {
  try {
    const { data } = yield API.get(
      `api/v1/casino-bet-by-date?createdAt=${action.payload.createdAt}&marketType=${action.payload.marketType}&description=${action.payload.description}`
    );
    if (data.meta.code === 200) {
      yield put(getCasinoBetsSuccess(data?.data));
      yield call(action.payload.callback(data?.data));
    } else if (data.meta.code === 401) {
      yield put(getCasinoBetsFailure());
    } else if (data.meta.code !== 200) {
      yield put(getCasinoBetsFailure());
    }
  } catch (error) {
    yield put(getCasinoBetsFailure());
  }
}

export function* watchGetCasinoBetsAPI() {
  yield takeEvery(GET_CASINO_BETS, getCasinoBetsRequest);
}

export default function* rootSaga() {
  yield all([watchGetCasinoBetsAPI()]);
}
