import {
  GET_CASINO_BETS,
  GET_CASINO_BETS_SUCCESS,
  GET_CASINO_BETS_FAILURE,
  } from "../../actions/types";
  
  const INIT_STATE = {
    loading: false,
    data: null,
  };
  
  const getCasinoBetsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_CASINO_BETS:
        return { ...state, loading: true };
      case GET_CASINO_BETS_SUCCESS:
        return { ...state, data: action.payload, loading: false };
      case GET_CASINO_BETS_FAILURE:
        return { ...state, loading: false };
      default:
        return state;
    }
  };
  
  export default getCasinoBetsReducer;
  