/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlinePolicy } from "react-icons/md";
import { GoLog, GoAlert } from "react-icons/go";
import { TfiAndroid } from "react-icons/tfi";
import { FiAlertOctagon } from "react-icons/fi";
import { LuClipboardSignature } from "react-icons/lu";
import { useSelector } from "react-redux";
import { ThemeContext } from "../context/ThemeContext";

import logoFooter from "../../src/assets/images/adminLogo.png";

const Footer = () => {
  const Navdata = useSelector((data) => data?.FetchWhiteLabel?.data);
  const { theme } = useContext(ThemeContext);
  // const [logoFooter,setLogoFooter] = useState({})

  // useEffect(() => {
  //   if(Navdata) {
  //     if (Object.keys(Navdata).length === 0) {
  //       console.log("Navdata")
  //       setLogoFooter(Navdata)
  //     }
  //   }else{
  //       Navdata.logo_dark = LogoDark;
  //       Navdata.logo_light = LogoLight;
  //   }
  // }, [Navdata]);
  return (
    <>
      <div className="footer justify-center text-skin-textSideBar pt-16 pb-16 ">
        <hr className="border-b border-gray-300 my-4"></hr>
        <div className="flex justify-between flex-wrap footer-menu gap-8 p-2 ">
          <div className="footer-column mt-4 flex-grow ">
            <div className="font-semibold text-lg footer-title mb-4">Info</div>
            <ul className="footer-menu-list">
              <li className="flex items-center py-3 text-sm">
                <span className="mr-2">
                  <MdOutlinePolicy />
                </span>
                <Link className="" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </li>
              <li className="flex items-center py-3 text-sm">
                <span className="mr-2">
                  <GoLog />
                </span>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </li>
              <li className="flex items-center py-3 text-sm">
                <span className="mr-2">
                  <GoAlert />
                </span>
                <Link to="/responsible-gambling">
                  Gambling can be addictive
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-column mt-4 flex-grow">
            <div className="footer-title text-lg font-semibold mb-4">
              Get started
            </div>
            <ul className="footer-menu-list">
              <li className="flex items-center py-3 text-sm">
                <span className="mr-2">
                  <FiAlertOctagon />
                </span>
                <Link to="/about-us" >
                  About Us
                </Link>
              </li>
              <li className="flex items-center py-3 text-sm">
                <span className="mr-2">
                  <LuClipboardSignature />
                </span>
                <Link to="/general-policy" >
                  KYC and Privacy Policy
                </Link>
              </li>
            </ul>
          </div>{" "}
          <div className="footer-column mt-4 flex-grow">
            <div className="flex footer-title text-lg font-semibold mb-2">
              {/* {!theme ? (
                <img
                  className="w-[8rem] text-center "
                  src={Navdata?.logo_dark ?  Navdata?.logo_dark : logoFooter}
                  alt='Logo'
                />
              ) : (
                <img
                  className="w-[8rem] text-center "
                  src={Navdata?.logo_light ?  Navdata?.logo_light : logoFooter}
                  alt='Logo'
                />
              )} */}
              <img
                className="w-[8rem] text-center"
                // src={theme ? Navdata?.logo_dark : Navdata?.logo_light}
                src={theme ? Navdata?.logo_dark : logoFooter}
                alt="BETxFAIR"
              />
            </div>
            <ul className="flex footer-menu-list">
              <li className=" py-3 flex text-sm">
                <div className="flex footer-apk-download items-center">
                  <a href="/get-apk" className="footer-download-apk mr-2">
                    <TfiAndroid />
                  </a>
                  <span className="animate-btn">Download our mobile app</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/*         <div className="flex justify-center mt-3 mb-3">
          <img
            src="https://sportsexch.com/images/bg/betfair.png"
            className="bg-skin-imgbg mb-3 betfair"
            alt=""
          />
        </div> */}
      </div>
    </>
  );
};

export default Footer;
