import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_BALANCE } from "../../actions/types";
import { getBalanceSuccess, getBalanceFailure } from "../../actions";
import API from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";

function* getBalanceRequest(action) {
  try {
    const { data } = yield API.post("/api/v1/balance", action?.payload);
    if (data.meta.code === 200) {
      yield put(getBalanceSuccess(data));
      yield call(action.payload.callback, data);
      notifySuccess(data.meta.message);
    } else if (data.meta.code === 401) {
      yield put(getBalanceFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(getBalanceFailure());
      notifyWarning(data.meta.message);
    }
  } catch (error) {
    yield put(getBalanceFailure());
    notifyWarning(error?.response?.data?.message);
  }
}

export function* watchGetBalanceAPI() {
  yield takeEvery(GET_BALANCE, getBalanceRequest);
}

export default function* rootSaga() {
  yield all([watchGetBalanceAPI()]);
}
