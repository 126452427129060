import { GET_CREDIT, GET_CREDIT_SUCCESS, GET_CREDIT_FAILURE } from "../types";

export const getCredit = (payload) => ({
  type: GET_CREDIT,
  payload,
});

export const getCreditSuccess = (payload) => ({
  type: GET_CREDIT_SUCCESS,
  payload,
});

export const getCreditFailure = () => ({
  type: GET_CREDIT_FAILURE,
});
