import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_EVENT_EXCH } from "../../actions/types";
import { getEventExchSuccess, getEventExchFailure } from "../../actions";
import API from "../../../utils/api";

function* getEventExchRequest(action) {
  try {
    const { data } = yield API.post(
      "/odds/event-exch",
      action?.payload?.marketCodes
    );
    if (data.meta.code === 200) {
      yield put(getEventExchSuccess(data));
      yield call(action.payload.callback, data?.data);
    } else if (data.meta.code === 401) {
      yield put(getEventExchFailure());
    } else if (data.meta.code !== 200) {
      yield put(getEventExchFailure());
    }
  } catch (error) {
    console.log(error);
    yield put(getEventExchFailure());
  }
}

export function* watchEventExchAPI() {
  yield takeEvery(GET_EVENT_EXCH, getEventExchRequest);
}

export default function* rootSaga() {
  yield all([watchEventExchAPI()]);
}
