import {
    GET_ACCOUNT_STATEMENT,
    GET_ACCOUNT_STATEMENT_SUCCESS,
    GET_ACCOUNT_STATEMENT_FAILURE,
  } from "../types";
  
  export const getAccountStatement = (payload) => ({
    type: GET_ACCOUNT_STATEMENT,
    payload
  });
  
  export const getAccountStatementSuccess = (payload) => ({
    type: GET_ACCOUNT_STATEMENT_SUCCESS,
    payload,
  });
  
  export const getAccountStatementFailure = () => ({
    type: GET_ACCOUNT_STATEMENT_FAILURE,
  });
  