import {
  GET_BALANCE_DATA,
  GET_BALANCE_DATA_SUCCESS,
  GET_BALANCE_DATA_FAILURE,
} from "../types";

export const getUserBalanceData = (payload) => ({
  type: GET_BALANCE_DATA,
  payload,
});

export const getUserBalanceDataSuccess = (payload) => ({
  type: GET_BALANCE_DATA_SUCCESS,
  payload,
});

export const getUserBalanceDataFailure = () => ({
  type: GET_BALANCE_DATA_FAILURE,
});
