import {
  MARKET_ANALYTICS,
  MARKET_ANALYTICS_SUCCESS,
  MARKET_ANALYTICS_FAILURE,
} from "../types";

export const getMarketAnalytics = (payload) => ({
  type: MARKET_ANALYTICS,
  payload,
});

export const getMarketAnalyticsSuccess = (payload) => ({
  type: MARKET_ANALYTICS_SUCCESS,
  payload,
});

export const getMarketAnalyticsFailure = () => ({
  type: MARKET_ANALYTICS_FAILURE,
});
