import {
  CURRENCY_BETFAIR,
  CURRENCY_BETFAIR_FAILURE,
  CURRENCY_BETFAIR_SUCCESS,
} from "../types";

export const getCurrency = (payload) => ({
  type: CURRENCY_BETFAIR,
  payload,
});

export const getCurrencySuccess = (payload) => ({
  type: CURRENCY_BETFAIR_SUCCESS,
  payload,
});

export const getCurrencyFailure = () => ({
  type: CURRENCY_BETFAIR_FAILURE,
});
