import { CREATE_SESSION, CREATE_SESSION_SUCCESS, CREATE_SESSION_FAILURE } from "../types";

export const createSession = (payload) => ({
  type: CREATE_SESSION,
  payload,
});

export const createSessionSuccess = (payload) => ({
  type: CREATE_SESSION_SUCCESS,
  payload,
});

export const createSessionFailure = () => ({
  type: CREATE_SESSION_FAILURE,
});
