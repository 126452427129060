import { GET_INPLAY, GET_INPLAY_SUCCESS, GET_INPLAY_FAILURE } from "../types";

export const getInPlay = (payload) => ({
  type: GET_INPLAY,
  payload,
});

export const getInPlaySuccess = (payload) => ({
  type: GET_INPLAY_SUCCESS,
  payload,
});

export const getInPlayFailure = () => ({
  type: GET_INPLAY_FAILURE,
});
