import {
    GET_EVENT_DATA_HORSERACE,
    GET_EVENT_DATA_HORSERACE_SUCCESS,
    GET_EVENT_DATA_HORSERACE_FAILURE,
    GET_EVENT_DATA_HORSERACE_RESET,
  } from "../../actions/types";
  
  const INIT_STATE = {
    loading: false,
    data: null,
  };
  
  
  const getEventDataHorseRaceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_EVENT_DATA_HORSERACE:
        return { ...state, loading: true };
      case GET_EVENT_DATA_HORSERACE_SUCCESS:
        return { ...state, data: action.payload, loading: false };
      case GET_EVENT_DATA_HORSERACE_FAILURE:
        return { ...state, loading: false };
      case GET_EVENT_DATA_HORSERACE_RESET:
        return { ...state,data: null, loading: false };
        default:
        return state;
    }
  };
  
  export default getEventDataHorseRaceReducer;
  