import { all, put, takeEvery } from "redux-saga/effects";
import { HORSERACE_DATA_MANAGE } from "../../actions/types";
import { horseDataManageSuccess } from "../../actions/horseRaceDataMannage/horseRaceDataManage";

function* horseDataManage(action) {
  try {
    yield put(horseDataManageSuccess(action.payload.data));
  } catch (error) {
    console.log(error);
  }
}

export function* watchHorseManageData() {
  yield takeEvery(HORSERACE_DATA_MANAGE, horseDataManage);
}

export default function* rootSaga() {
  yield all([watchHorseManageData()]);
}
