import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_RESULT_TRANSACTION } from "../../actions/types";
import {
  getResultTransactionSuccess,
  getResultTransactionFailure,
} from "../../actions";
import API from "../../../utils/api";

function* getResultTransactionRequest(action) {
  try {
    const { data } = yield API.get(
      `api/v1/result-transaction?start_date=${action?.payload?.startDate}&end_date=${action?.payload?.endDate}&isChecked=${action?.payload?.isChecked}`
    );
    if (data.meta.code === 200) {
      yield put(getResultTransactionSuccess(data?.data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getResultTransactionFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(getResultTransactionFailure());
      yield call(action?.payload?.errorCallback);
    }
  } catch (error) {
    yield put(getResultTransactionFailure());
    yield call(action?.payload?.errorCallback);
  }
}

export function* watchgetResultTransactionAPI() {
  yield takeEvery(GET_RESULT_TRANSACTION, getResultTransactionRequest);
}

export default function* rootSaga() {
  yield all([watchgetResultTransactionAPI()]);
}
