import { all, takeEvery, put, call } from "redux-saga/effects";
import { PLACE_BET } from "../../actions/types";
import { placeBetSuccess, placeBetFailure } from "../../actions";
import API from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";
import { MARKET_TYPES } from "../../../utils/constants";

function* placeBetRequest(action) {
  try {
    let responseData;
    if (
      action?.payload?.data?.marketType === MARKET_TYPES.FANCY.toLowerCase()
    ) {
      const { data } = yield API.post(
        "api/v1/fancy-bet-sport",
        action?.payload?.data
      ); // change with backend URL
      responseData = data;
    } else if (
      action?.payload?.data?.marketType === MARKET_TYPES.BKMKR.toLowerCase()
    ) {
      const { data } = yield API.post(
        "api/v1/bookmaker-bet-sport",
        action?.payload?.data
      ); // change with backend URL
      responseData = data;
    } else {
      const { data } = yield API.post(
        "api/v1/bet-sport",
        action?.payload?.data
      ); // change with backend URL
      responseData = data;
    }

    if (responseData?.meta?.code === 200) {
      yield put(placeBetSuccess(responseData));
      yield call(action?.payload?.callback, responseData);
      notifySuccess(responseData.meta.message);
    } else if (responseData.meta.code === 401) {
      yield put(placeBetFailure());
      notifyWarning(responseData.meta.message);
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
      window.location.reload();
    } else if (responseData?.meta?.code !== 200) {
      yield put(placeBetFailure());

      notifyWarning(responseData.meta.message);
      yield call(action?.payload?.callback, responseData);
    }
  } catch (error) {
    yield put(placeBetFailure());
  }
}

export function* watchPlaceBetAPI() {
  yield takeEvery(PLACE_BET, placeBetRequest);
}

export default function* rootSaga() {
  yield all([watchPlaceBetAPI()]);
}
