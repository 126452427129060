import { getDefaultState } from "../../../utils/helper";
import {
  GET_DEBIT,
  GET_DEBIT_SUCCESS,
  GET_DEBIT_FAILURE,
  LOGOUT,
} from "../../actions/types";

const token = getDefaultState("token");
const INIT_STATE = {
  loading: false,
  // createSession: null,
  // sessionCreated: token ? true : false,
};

const getDebitReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEBIT:
      return { ...state, loading: true };
    case GET_DEBIT_SUCCESS:
      return {
        ...state,
        // createSession: action.payload,
        loading: false,
        // sessionCreated: true,
      };
    case GET_DEBIT_FAILURE:
      return { ...state, loading: false };
    // case LOGOUT:
    //   return { ...state, createSession: null, loading: false, sessionCreated: false };
    default:
      return state;
  }
};

export default getDebitReducer;
