import { all, call, put, takeEvery } from "redux-saga/effects";
import { BET_SPORT_HORSERACING } from "../../actions/types";
import { getBetHorseRaceSuccess , getBetHorseRaceFailure } from "../../actions";
import horseRaceAPI from "../../../utils/horseRaceApi";

function* getHorseRaceBetRequest(action) {
  try {
    const { data } = yield horseRaceAPI.get(`api/v1/bet-sport-horse`);
    if (data?.meta?.code === 200) {
      yield put(getBetHorseRaceSuccess(data));
      yield call(action?.payload?.callback, data?.data);
    } else if (data?.meta?.code === 401) {
      yield put(getBetHorseRaceFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data?.meta?.code !== 200) {
      yield put(getBetHorseRaceFailure());
    }
  } catch (error) {
    console.log("eror in saga us ", error)
    yield put(getBetHorseRaceFailure());
  }
}

export function* watchGetHorseBetsDataAPI() {
  yield takeEvery(BET_SPORT_HORSERACING, getHorseRaceBetRequest);
}

export default function* rootSaga() {
  yield all([watchGetHorseBetsDataAPI()]);
}