import {
  HORSERACE_DATA_MANAGE,
  HORSERACE_DATA_MANAGE_RESET,
  HORSERACE_DATA_MANAGE_SUCCESS,
} from "../types";

export const horseDataManage = (payload) => ({
  type: HORSERACE_DATA_MANAGE,
  payload,
});

export const horseDataManageSuccess = (payload) => ({
  type: HORSERACE_DATA_MANAGE_SUCCESS,
  payload,
});
export const horseDataManageReset = (payload) => ({
  type: HORSERACE_DATA_MANAGE_RESET,
  payload,
});
