import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_SPORT_DATAS } from "../../actions/types";
import { getSportDatasSuccess, getSportDatasFailure } from "../../actions";
import API from "../../../utils/api";

function* getSportDatasRequest(action) {
  try {
    const { selectedSport } = action.payload;
    const { data } = yield API.get(`api/v1/sport-datas?id=${selectedSport}`);

    if (data.meta.code === 200) {
      yield put(getSportDatasSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getSportDatasSuccess());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(getSportDatasFailure());
      yield call(action.payload.callback, data.data);
    }
  } catch (error) {
    yield put(getSportDatasFailure());
    yield call(action?.payload?.errorCallback);
  }
}

export function* watchgetSportDatasAPI() {
  yield takeEvery(GET_SPORT_DATAS, getSportDatasRequest);
}

export default function* rootSaga() {
  yield all([watchgetSportDatasAPI()]);
}
