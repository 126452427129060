import {
  DELETE_UNMATCHED_HORSERACE_BETS_SUCCESS,
  DELETE_UNMATCHED_HORSERACE_BETS,
  DELETE_UNMATCHED_HORSERACE_BETS_FAILURE,
} from "../types";


export const deleteUnMatchedHorseRace = (payload) => ({
  type: DELETE_UNMATCHED_HORSERACE_BETS,
  payload,
});

export const deleteUnMatchedHorseRaceSuccess= (payload) => ({
  type: DELETE_UNMATCHED_HORSERACE_BETS_SUCCESS,
  payload,
});

export const deleteUnMatchedHorseRaceFailure = () => ({
  type: DELETE_UNMATCHED_HORSERACE_BETS_FAILURE,
});
