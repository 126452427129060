import { all, call, put, takeEvery } from "redux-saga/effects";
import { META_CHECK_WALLET } from "../../actions/types";
import {
  metaCheckWalletSuccess,
  metaCheckWalletFailure,
} from "../../actions/metaWallet/metaCheckAction";
import API from "../../../utils/api";
import { notifyWarning } from "../../../utils/helper";

function* CheckMetaWallet(action) {
  try {
    const { data } = yield API.post(
      "/api/v1/moneyplant-wallet-balance",
      action.payload.userDetails
    );
    if (data.code!==400) {
      yield put(metaCheckWalletSuccess(data.data));
      yield call(action.payload.callback, data );

    }
    else{
      
      yield put(metaCheckWalletFailure());
      yield call(action.payload.callback, data );
      
      
    }
  } catch (error) {
    console.log(error);
    notifyWarning("Something went wrong");
    yield put(metaCheckWalletFailure());
  }
}

export function* watchCheckWalletAPI() {
  yield takeEvery(META_CHECK_WALLET, CheckMetaWallet);
}

export default function* rootSaga() {
  yield all([watchCheckWalletAPI()]);
}
