import {
  DELETE_UNMATCH_STX_BET, DELETE_UNMATCH_STX_BET_SUCCESS, DELETE_UNMATCH_STX_BET_FAILURE 
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
};

const deleteUnmatchStxBetReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DELETE_UNMATCH_STX_BET:
      return { ...state, loading: true };
    case DELETE_UNMATCH_STX_BET_SUCCESS:
      return { ...state, bets: action.payload.data, loading: false };
    case DELETE_UNMATCH_STX_BET_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default deleteUnmatchStxBetReducer;
