import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_HOME_DATA } from "../../actions/types";
import { getHomeDataSuccess, getHomeDataFailure } from "../../actions";
import API from "../../../utils/api";

function* getHomeDataRequest(action) {
  try {
    const { data } = yield API.get(`api/v1/home-data`);
    if (data.meta.code === 200) {
      yield put(getHomeDataSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getHomeDataFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data.meta.code !== 200) {
      yield put(getHomeDataFailure());
    }
  } catch (error) {
    yield put(getHomeDataFailure());
  }
}

export function* watchGetHomeDataAPI() {
  yield takeEvery(GET_HOME_DATA, getHomeDataRequest);
}

export default function* rootSaga() {
  yield all([watchGetHomeDataAPI()]);
}
