import {
  STX_PLACE_BET,
  STX_PLACE_BET_SUCCESS,
  STX_PLACE_BET_FAILURE,
  LOGOUT,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  bets: 0,
};

const stxPlaceBetReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STX_PLACE_BET:
      return { ...state, loading: true };
    case STX_PLACE_BET_SUCCESS:
      return { ...state, bets: action.payload.data, loading: false };
    case STX_PLACE_BET_FAILURE:
      return { ...state, loading: false };
    case LOGOUT:
      return { ...state, allBets: null, loading: false };
    default:
      return state;
  }
};

export default stxPlaceBetReducer;
