import { all, takeEvery, put, call } from "redux-saga/effects";
import { DELETE_UNMATCHED_HORSERACE_BETS } from "../../actions/types";
import {
    deleteUnMatchedHorseRaceSuccess,
    deleteUnMatchedHorseRaceFailure,
} from "../../actions";
import Api from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";

function* deleteUnmatchBetHorseRaceRequest(action) {
  try {
    console.log("action from unmacthed bets are -------------", action)
    const { data } = yield Api.post(
      "api/v1/delete-unmatch-bet-horse-race",
      action?.payload?.form
    );
    if (data?.meta?.code === 200) {
      yield put(deleteUnMatchedHorseRaceSuccess(data));
      yield call(action?.payload?.callback, data);
      notifySuccess(data.meta.message);
    } else if (data.meta.code === 401) {
      yield put(deleteUnMatchedHorseRaceFailure());
      notifyWarning(data.meta.message);
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
      window.location.reload();
    } else if (data?.meta?.code !== 200) {
      yield put(deleteUnMatchedHorseRaceFailure());
      yield call(action?.payload?.callback, data);
      notifyWarning(data.meta.message);
    }
  } catch (error) {
    console.log("error from deleting unmatched bets are -------------", error)
    yield call(action?.payload?.callback, error);
    yield put(deleteUnMatchedHorseRaceFailure());
  }
}

export function* watchDeleteUnmatchHorseRaceBetAPI() {
  yield takeEvery(DELETE_UNMATCHED_HORSERACE_BETS, deleteUnmatchBetHorseRaceRequest);
}

export default function* rootSaga() {
  yield all([watchDeleteUnmatchHorseRaceBetAPI()]);
}
