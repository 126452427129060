import {
  GET_GAP_CASINO_GAMES,
  GET_GAP_CASINO_GAMES_SUCCESS,
  GET_GAP_CASINO_GAMES_FAILURE,
} from "../types";

export const getGapCasino = (payload) => ({
  type: GET_GAP_CASINO_GAMES,
  payload,
});

export const getGapCasinoSuccess = (payload) => ({
  type: GET_GAP_CASINO_GAMES_SUCCESS,
  payload,
});

export const getGapCasinoFailure = () => ({
  type: GET_GAP_CASINO_GAMES_FAILURE,
});
