import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_INPLAY_BY_SPORT } from "../../actions/types";
import {
  getInPlayBySportSuccess,
  getInPlayBySportFailure,
} from "../../actions";
import API from "../../../utils/oddApi";

function* getInPlayBySportRequest(action) {
  try {
    const { data } = yield API.get(
      `/api/v2/inplay-by-sport?id=${action?.payload?.params}`
    );
    if (data.meta.code === 200) {
      yield put(getInPlayBySportSuccess(data?.data));
      yield call(action.payload.callback, data?.data);
    } else if (data.meta.code !== 200) {
      yield put(getInPlayBySportFailure());
    }
  } catch (error) {
    yield put(getInPlayBySportFailure());
  }
}

export function* watchGetInPlayBySportAPI() {
  yield takeEvery(GET_INPLAY_BY_SPORT, getInPlayBySportRequest);
}

export default function* rootSaga() {
  yield all([watchGetInPlayBySportAPI()]);
}
