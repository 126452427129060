import {
    GET_BOOKMAKER_MARKETS,
    GET_BOOKMAKER_MARKETS_FAILURE,
    GET_BOOKMAKER_MARKETS_SUCCESS,
  } from "../../actions/types";
  
  const INIT_STATE = {
    loading: false,
    data: null,
  };
  
  const getBookmakerMarketsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_BOOKMAKER_MARKETS:
        return { ...state, loading: true };
      case GET_BOOKMAKER_MARKETS_SUCCESS:
        return { ...state, data: action.payload, loading: false };
      case GET_BOOKMAKER_MARKETS_FAILURE:
        return { ...state, loading: false };
      default:
        return state;
    }
  };
  
  export default getBookmakerMarketsReducer;
  