/** @format */

import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { formatDate, convertToShortForm , convertCurrencyBetFair} from "../utils/helper";
import EmptyRunners from "./EmptyRunners";

const MarketData = ({ league, sport, marketOdds, prevMarketOdds }) => {
  //console.log(league, "league");
  const Login = useSelector((state) => state?.Login?.login);
  const location = useLocation();

  // ===============================================================================================
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);
  const [mobileScreen, setMobileScreen] = useState(false);
  const [isPathHome, setIsPathHome] = useState(false);
  const betFairCurrency = useSelector(
    (state) => state?.currencyBetFairReducer?.currency
  );
  const betfairShare = useSelector((state) => state?.GetUserData?.userData?.data);
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    const isHome = location.pathname === "/home";
    setIsPathHome(isHome);
  }, [location]);

  useEffect(() => {
    // console.log("login render");
    // Check if the login status is stored in localStorage
    const storedLoggedIn = localStorage.getItem("token");
    if (storedLoggedIn) {
      setLoggedIn(true);
    }
  }, [dispatch, Login]);

  function formatDateTime(dateString,mobile) {
    let date;
    if (!dateString) {
      return "No date <br> Specified";
    } else if (dateString.startsWith("Today")) {
      // let dateModified = dateString.split(" ");
      // return dateModified[1] + " " + dateModified[0];
      return dateString.split(" ")[1] + "<br> Today";
    } else if (dateString.startsWith("Tomorrow")) {
      let dateModified = dateString.split(" ");
      return dateModified[1] + "<br> " + dateModified[0];
    } else {
      // const [day, month, time] = dateString.split(" ");
      // const year = new Date().getFullYear(); // Assuming current year
      // date = new Date(`${month} ${day}, ${year}`);
      let dateModified = dateString.split(" ");
      return (
        dateModified[2] + "<br> " + dateModified[1] + " " + dateModified[0]
      );
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setMobileScreen(window.innerWidth <= 450);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {league?.events?.length !== 0 && (
        <div className="rounded-md w-full bg-skin-nav drop-shadow-sm">
          <div
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex w-full items-center bg-skin-containerBg rounded-t-md justify-between h-[40px] px-4 cursor-pointer "
          >
            <div className="flex items-center ">
              <div className="flex items-center text-sm text-skin-containerText  font-medium ">
                {league?.name}
              </div>
            </div>
            <div className="flex items-center space-x-8 px-1">
              <div className="flex text-sm font-bold text-skin-containerText">
                {league?.events?.length}
                {isDropdownOpen ? (
                  <IoIosArrowUp className="ml-2 text-lg  m-auto" />
                ) : (
                  <IoIosArrowDown className="ml-2 text-lg   m-auto" />
                )}
              </div>
            </div>
          </div>
          {isDropdownOpen && (
            <>
              {league?.events?.map((event, index) => {
                // console.log(event, "event");
                var exist;
                var prevExist;
                let matchOdds;
                let start;
                if (event?.markets?.length > 0) {
                  start = event?.markets[0];
                  matchOdds = event?.markets[0];
                }

                const date = formatDate(start?.marketStartTime);
                if (matchOdds) {
                  exist = marketOdds?.find(
                    (obj) => obj?.marketId === matchOdds?.marketId
                  );
                  prevExist = prevMarketOdds?.find(
                    (obj) => obj?.marketId === matchOdds?.marketId
                  );
                }
                let containsObject;
                if (exist) {
                  containsObject = Object.values(exist);
                }
                //console.log(containsObject, "containsObject");

                let encodedDynamicPart = encodeURIComponent(
                  event?.name?.toLowerCase()?.replace(/\s+/g, "-")
                );
                //  console.log(exist?.status, "exist");
                return (
                    <Link
                      key={index}
                      to={`/${sport}/${event?.id}/${encodedDynamicPart}`}
                    >
                      <div
                        className={`flex ${
                          mobileScreen ? "flex-col" : "flex-row"
                        } justify-between gap-1 px-1 lg:mr-2 2xl:mr-4 mb-[2px] border-b-2 border-mainbg`}
                      >
                        {/* <div className="flex-wrap md:flex lg:block gap-3 md:px-2 lg:px-1 xl:px-0 xl:flex border-b-2 border-mainbg pb-1"> */}

                        {/* {console.log("mobileScreen", mobileScreen)} */}

                        <div className="flex items-center flex-1 cursor-pointer select-none">
                         {mobileScreen ? (
                         <div className="rounded-sm flex flex-col bg-skin-main  p-1 min-w-16 max-w-16">
                           <p
                            className="p-0 text-skin-primary"
                            style={{ textAlign: "center",fontSize:"12px" }}
                          >
                           {date?.split(' ')[0]}
                            </p>
                           <p
                            className="p-0 text-skin-primary"
                            style={{ textAlign: "center",fontSize:"12px" }}
                          >
                           {date?.split(' ')[1]}
                            </p>
                       </div>
                         ):(
                          <div
                          className={`${
                            mobileScreen
                              ? "h-12 min-w-[20%] max-w-[20%]"
                              : "h-12"
                          } mt-1 ml-1 p-1 bg-skin-navtop rounded-md flex justify-center items-center 
                          sm:min-h-[25px] sm:max-h-[25px] sm:min-w-[60px] sm:max-w-[60px] 
                          md:min-h-[50px] md:max-h-[50px] md:min-w-[70px] md:max-w-[60px]
                          lg:min-h-[40px] lg:max-h-[40px] lg:min-w-[60px] lg:max-w-[60px]
                          xl:min-h-[50px] xl:max-h-[50px] xl:min-w-[70px] xl:max-w-[70px]
                          2xl:min-h-[50px] 2xl:max-h-[50px] 2xl:min-w-[70px] 2xl:max-w-[70px]
                          `}
                        >
                          <p
                            className="font-medium sm:font-sm text-skin-primary text-sm flex-grow-1 flex justify-center items-center sm:text-xs sm:mt-1"
                            style={{ textAlign: "center" }}
                            dangerouslySetInnerHTML={{
                              __html: formatDateTime(date),
                            }}
                          />
                        </div>          
                         )} 

                            <div className="pl-3 pt-2 w-full leftcontainer text-sm text-skin-primary">
                                <>
                                  <span className="text-skin-primary text-md pt-2 font-medium m-1 mb-0">
                                    {event?.name?.includes("@")
                                      ? event?.name?.split(" @ ")[0]?.trim()
                                      : event?.name?.split(" v ")[0]?.trim()}
                                  </span>
                                  <span className="font-medium text-sm rounded-sm ">
                                    vs
                                  </span>
                                  <span className="text-skin-primary text-md pt-2 font-medium m-1 mb-0">
                                    {event?.name?.includes("@")
                                      ? event?.name?.split(" @ ")[1]?.trim()
                                      : event?.name?.split(" v ")[1]?.trim()}
                                  </span>
                                </>
                          
                              <span
                                className={`items-center ${
                                  mobileScreen ? "gap-0.5" : "gap-1"
                                }`}
                              >
                                {containsObject &&
                                  exist?.inplay &&
                                  exist?.status !== undefined &&
                                  exist?.status === "OPEN" && (
                                    <span className="text-skin-liveIconText bg-skin-liveContainerBg p-0.5 rounded-sm text-xs font-semibold">
                                      Live
                                    </span>
                                  )}
                              </span>
                            </div>
                        </div>

                        {/* <div className="flex md:w-[45%] lg:w-[50%] xl:w-[30%] 2xl:w-[16%]  mt-2 md:mt-0 ">  */}

                        <div
                          className={`flex ${
                            location.pathname === "/home"
                              ? "xl:w-[33%] 2xl:w-[25%]"
                              : "2xl:w-[45%]"
                          } 2xl:w-[25%]`}
                        >
                          <div className="hidden lg:flex flex-col items-center justify-center text-xs font-bold mr-1 gap-1">
                            <p className="text-skin-marketCardTextBlue">Matched</p>
                            <p className="text-sm brightness-50 text-skin-oddsSizeText">{marketOdds[index]?.totalMatched}</p>
                          </div>
                          {containsObject ? (
                            <>
                            {/* {console.log(exist,"existexist")} */}
                              {exist &&
                              Object.values(exist).length !== 0 &&
                              Object.keys(Object.values(exist)[0]).length !==
                                0 &&
                              exist?.runners &&
                              exist?.runners[0] &&
                              exist?.runners[0]?.status !== undefined &&
                              exist?.runners[0]?.status === "ACTIVE" ? (
                                <>
                                  {/* First Runner */}
                                  <div className="inline flex-1">
                                    <div className="flex drop-shadow-sm rounded-sm 2xl:justify-start">
                                      <div
                                        className={`${
                                          mobileScreen
                                            ? "min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                            : "w-[50px] 2xl:w-[50%] py-1.5 h-12  rounded-md "
                                        }  m-1 flex flex-col justify-center text-md 
                                      font-bold text-skin-blue  bg-skin-oddsCardBlue cursor-pointer h-10`}
                                      >
                                        <p
                                          className={`m-auto text-skin-marketCardTextBlue
                                       ${
                                         prevExist?.runners[0]?.ex
                                           ?.availableToBack[0]?.price !==
                                         exist?.runners[0]?.ex
                                           ?.availableToBack[0]?.price
                                           ? "animate-odds"
                                           : ""
                                       }
                                        `}
                                        >
                                          {exist?.runners[0]?.ex
                                            ?.availableToBack[0]?.price
                                            ? exist?.runners[0]?.ex
                                                ?.availableToBack[0]?.price
                                            : "-"}
                                        </p>
                                        <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText"> 
                                        {exist?.runners[0]?.ex
                                            ?.availableToBack[0]?.size
                                            ?
                                            convertToShortForm(convertCurrencyBetFair(exist?.runners[0]?.ex
                                                    ?.availableToBack[0]?.size,  parseFloat(
                                                    betFairCurrency?.value) ,  parseFloat(
                                                      userData?.currency.value
                                                    ),
                                                    parseFloat(betfairShare?.betFairShare)
                                                  ))
                                            : "-"}

                                        </p>
                                      </div>
                                      <div
                                        className={` ${
                                          mobileScreen
                                            ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                            : "w-[50px] 2xl:w-[50%] h-12 py-1.5"
                                        } rounded-md m-1 flex justify-center flex-col text-md font-bold text-skin-pink  bg-skin-oddsCardPink   cursor-pointer
                                        ${
                                          prevExist?.runners[0]?.ex
                                            ?.availableToLay[0]?.price !==
                                          exist?.runners[0]?.ex
                                            ?.availableToLay[0]?.price
                                            ? "animate-lay"
                                            : ""
                                        }
                                        `}
                                      >
                                        <p className="m-auto text-skin-marketCardTextPink">
                                          {exist?.runners[0]?.ex
                                            ?.availableToLay[0]?.price ? (
                                            exist?.runners[0]?.ex
                                              ?.availableToLay[0]?.price
                                          ) : (
                                            <span>&nbsp;</span>
                                          )}
                                        </p>
                                        <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText"> 
                                        {exist?.runners[0]?.ex
                                            ?.availableToLay[0]?.size
                                            ?
                                            convertToShortForm( convertCurrencyBetFair( exist?.runners[0]?.ex
                                              ?.availableToLay[0]?.size,  parseFloat(
                                              betFairCurrency?.value) ,  parseFloat(
                                                userData?.currency.value
                                              ),
                                              parseFloat(betfairShare?.betFairShare)
                                            ))
                                            : "-"}

                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Middle Runner */}
                                  <div className="inline flex-1">
                                    <div className="flex drop-shadow-sm rounded-sm 2xl:justify-start">
                                      <div
                                        className={` ${
                                          mobileScreen
                                            ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                            : "w-[50px] 2xl:w-[50%] h-12  py-1.5 "
                                        } rounded-md m-1 flex justify-center flex-col text-md font-bold text-skin-blue  bg-skin-oddsCardBlue   cursor-pointer
                                         
                                        ${
                                          prevExist?.runners[2]?.ex
                                            ?.availableToBack[0]?.price !==
                                          exist?.runners[2]?.ex
                                            ?.availableToBack[0]?.price
                                            ? "animate-odds"
                                            : ""
                                        }
                                        `}
                                      >
                                        <p className="m-auto text-skin-marketCardTextBlue">
                                          {exist?.runners[2]?.ex
                                            ?.availableToBack[0]?.price
                                            ? exist?.runners[2]?.ex
                                                ?.availableToBack[0]?.price
                                            : "-"}
                                        </p>
                                        <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText"> 
                                        {exist?.runners[2]?.ex
                                            ?.availableToBack[0]?.size
                                            ? 
                                            convertToShortForm( convertCurrencyBetFair( exist?.runners[2]?.ex
                                              ?.availableToBack[0]?.size,  parseFloat(
                                              betFairCurrency?.value) ,  parseFloat(
                                                userData?.currency.value
                                              ),
                                              parseFloat(betfairShare?.betFairShare)
                                            )
                                            )
                                            : "-"}

                                        </p>
                                      </div>
                                      <div
                                        className={` ${
                                          mobileScreen
                                            ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                            : "w-[50px] 2xl:w-[50%] h-12  py-1.5 "
                                        } rounded-md m-1 flex justify-center flex-col text-md font-bold text-skin-pink  bg-skin-oddsCardPink   cursor-pointer
                                         
                                        ${
                                          prevExist?.runners[2]?.ex
                                            ?.availableToLay[0]?.price !==
                                          exist?.runners[2]?.ex
                                            ?.availableToLay[0]?.price
                                            ? "animate-lay"
                                            : ""
                                        }
                                        
                                        `}
                                      >
                                        <p className="m-auto text-skin-marketCardTextPink">
                                          {exist?.runners[2]?.ex
                                            ?.availableToLay[0]?.price
                                            ? exist?.runners[2]?.ex
                                                ?.availableToLay[0]?.price
                                            : "-"}
                                        </p>
                                        <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText"> 
                                        {exist?.runners[2]?.ex
                                            ?.availableToLay[0]?.size
                                            ? 
                                            convertToShortForm(
                                                convertCurrencyBetFair(exist?.runners[2]?.ex
                                                  ?.availableToLay[0]?.size,  parseFloat(
                                                  betFairCurrency?.value) ,  parseFloat(
                                                    userData?.currency.value
                                                  ),
                                                  parseFloat(betfairShare?.betFairShare)
                                                )
                                              )
                                            : "-"}

                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Last Runner */}
                                  <div className="inline flex-1 ">
                                    <div className="flex  drop-shadow-sm rounded-sm 2xl:justify-start">
                                      <div
                                        className={` ${
                                          mobileScreen
                                            ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                            : "w-[50px] 2xl:w-[50%] h-12  py-1.5 "
                                        } rounded-md m-1 flex justify-center flex-col text-md font-bold text-skin-blue  bg-skin-oddsCardBlue   cursor-pointer
                                       ${
                                         prevExist?.runners[1]?.ex
                                           ?.availableToLay[0]?.price !==
                                         exist?.runners[1]?.ex
                                           ?.availableToLay[0]?.price
                                           ? "animate-odds"
                                           : ""
                                       }
                                        `}
                                      >
                                        <p className="m-auto text-skin-marketCardTextBlue">
                                          {exist?.runners[1]?.ex
                                            ?.availableToBack[0]?.price
                                            ? exist?.runners[1]?.ex
                                                ?.availableToBack[0]?.price
                                            : "-"}
                                        </p>
                                        <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText"> 
                                        {exist?.runners[1]?.ex
                                            ?.availableToBack[0]?.size
                                            ? convertToShortForm(convertCurrencyBetFair(exist?.runners[1]?.ex
                                              ?.availableToBack[0]?.size,  parseFloat(
                                              betFairCurrency?.value) ,  parseFloat(
                                                userData?.currency.value
                                              ),
                                              parseFloat(betfairShare?.betFairShare)
                                            ) )
                                            : "-"}

                                        </p>
                                      </div>

                                      <div
                                        className={` ${
                                          mobileScreen
                                            ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                            : "w-[50px] 2xl:w-[50%] h-12  py-1.5 "
                                        } rounded-md m-1 flex justify-center flex-col text-md font-bold text-skin-pink  bg-skin-oddsCardPink   cursor-pointer
                                        `}
                                      >
                                        <p className="m-auto text-skin-marketCardTextPink">
                                          {exist?.runners[1]?.ex
                                            ?.availableToLay[0]?.price
                                            ? exist?.runners[1]?.ex
                                                ?.availableToLay[0]?.price
                                            : "-"}
                                        </p>
                                        <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText"> 
                                        {exist?.runners[1]?.ex
                                            ?.availableToBack[0]?.size
                                            ? convertToShortForm(
                                              convertCurrencyBetFair(exist?.runners[1]?.ex
                                                ?.availableToBack[0]?.size,  parseFloat(
                                                betFairCurrency?.value) ,  parseFloat(
                                                  userData?.currency.value
                                                ),
                                                parseFloat(betfairShare?.betFairShare)
                                              )
                                              )
                                            : "-"}

                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                <EmptyRunners isMobile={mobileScreen} />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <EmptyRunners isMobile={mobileScreen} />
                            </>
                          )}
                        </div>
                      </div>
                    </Link>
                );
              })}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MarketData;
