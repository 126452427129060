import { all, call, put, takeEvery } from "redux-saga/effects";
import { HORSERACE_DATA } from "../../actions/types";
import {
  getHorseRaceDataSuccess,
  getHorseRaceDataFailure,
} from "../../actions";
import horseRaceAPI from "../../../utils/horseRaceApi";

function* getHorseRaceDataRequest(action) {
  try {
    const { data } = yield horseRaceAPI.get(
      `api/v1/horse-events/${action?.payload?.eventId}?venue=${action?.payload?.venueId}&time=${action?.payload?.time}`
    );
    if (data?.meta?.code === 200) {
      yield put(getHorseRaceDataSuccess(data));
      yield call(action?.payload?.callback, data?.data);
    } else if (data?.meta?.code === 401) {
      yield put(getHorseRaceDataFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data?.meta?.code !== 200) {
      yield put(getHorseRaceDataFailure());
    }
  } catch (error) {
    console.log("eror in saga us ", error);
    yield put(getHorseRaceDataFailure());
  }
}

export function* watchGetHorseDataDataAPI() {
  yield takeEvery(HORSERACE_DATA, getHorseRaceDataRequest);
}

export default function* rootSaga() {
  yield all([watchGetHorseDataDataAPI()]);
}
