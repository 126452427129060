import {
    GET_MY_MARKETS,
    GET_MY_MARKETS_SUCCESS,
    GET_MY_MARKETS_FAILURE,
  } from "../types";
  
  export const getMyMarkets = (payload) => ({
    type: GET_MY_MARKETS,
    payload
  });
  
  export const getMyMarketsSuccess  = (payload) => ({
    type: GET_MY_MARKETS_SUCCESS,
    payload,
  });
  
  export const getMyMarketsFailure = () => ({
    type: GET_MY_MARKETS_FAILURE,
  });
  

  