export const defaultChipValues = [
  { name: "2000", value: 2000 },
  { name: "5000", value: 5000 },
  { name: "10,000", value: 10000 },
  { name: "50,000", value: 50000 },
  { name: "100,000", value: 100000 },
  { name: "500,000", value: 500000 },
  { name: "10,00,000", value: 1000000 },
  { name: "25,00,000", value: 2500000 },
];


export const TWO_FACTOR_OTP_TIMER = 60


export const MARKET_TYPES = {
  BKMKR: "BOOKMAKER",
  EXCH: "EXCHANGE",
  FANCY: "FANCY",
  LINE: "LINE",
  STX:"betxfair_odds_slug"
};

export const BETTING_TYPES = {
  ODDS: "ODDS",
  LINE: "LINE",
  FANCY: "FANCY",
  BKMKR: "bookmaker",
};

export const MARKET_TYPES_CRICKET_LEAGUE = {
  BKMKR: "bookmaker",
  EXCH: "exchange",
  FANCY: "fancy",
};

export const TAB_ITEMS = ["All", "Match Odd","Bookmaker", "Line", "Fancy"];
export const TAB_ITEMS_SCOCCER = ["All", "Match Odd","Goals"];
export const TAB_ITEMS_TENNIS = ["All", "Match Odd","Set"];

export const RUNNER_ODDS_LENGTH = 3;

export const availableCasinos = {
  ALL: 0,
  NO_CASINO: null,
  LIVE_CASINO: 1,
  INDIAN_CASINO: 2,
};

export const MARKET_SLUGS = {
  MATCH_ODDS: "match_odds",
  BOOKMAKER: "bookmaker",
};

export const LIVE_CASINO_DISABLE = false;

export const maxOddLength = 10;

export const LOGO = "Logo";

export const currencySymbols = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "A$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "КМ",
  BBD: "Bds$",
  BDT: "৳",
  BGN: "лв",
  BHD: "ب.د",
  BIF: "FBu",
  BMD: "BD$",
  BND: "B$",
  BOB: "Bs.",
  BRL: "R$",
  BSD: "B$",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BZD: "BZ$",
  CAD: "CA$",
  CDF: "FC",
  CHF: "CHF",
  CLP: "$",
  CNY: "¥",
  COP: "COL$",
  CRC: "₡",
  CUP: "₱",
  CVE: "$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD$",
  DZD: "دج",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  EUR: "€",
  FJD: "FJ$",
  FKP: "£",
  FOK: "FOK",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "GY$",
  HKD: "HK$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JOD: "د.ا",
  JPY: "¥",
  KES: "KSh",
  KGS: "сом",
  KHR: "៛",
  KID: "$",
  KMF: "CF",
  KRW: "₩",
  KWD: "د.ك",
  KYD: "KY$",
  KZT: "₸",
  LAK: "₭",
  LBP: "ل.ل",
  LKR: "රු",
  LRD: "L$",
  LSL: "M",
  LYD: "ل.د",
  MAD: "د.م.",
  MDL: "L",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRU: "UM",
  MUR: "₨",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MYR: "RM",
  MZN: "MT",
  NAD: "N$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "रू",
  NZD: "NZ$",
  OMR: "ر.ع.",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "₲",
  QAR: "ر.ق",
  RON: "lei",
  RSD: "дин.",
  RUB: "₽",
  RWF: "RF",
  SAR: "ر.س",
  SBD: "SI$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: "kr",
  SGD: "S$",
  SHP: "£",
  SLL: "Le",
  SOS: "Sh",
  SRD: "$",
  SSP: "£",
  STN: "Db",
  SYP: "£",
  SZL: "L",
  THB: "฿",
  TJS: "SM",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRY: "₺",
  TTD: "TT$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYU: "$U",
  UZS: "so'm",
  VES: "Bs.",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XCD: "EC$",
  XOF: "CFA",
  XPF: "CFPF",
  YER: "﷼",
  ZAR: "R",
  ZMW: "ZK",
};

export const appName = "Betxfair";
export const mobileThreshold = "768";

