import { all, takeEvery, put, call } from "redux-saga/effects";
// import { PLACE_BET } from "../../actions/types";
import { placeBetSuccess } from "../../actions";
import API from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";
import { StxPlaceBetFailure, StxPlaceBetSuccess } from "../../actions";
import { STX_PLACE_BET } from "../../actions/types";
// import { MARKET_TYPES } from "../../../utils/constants";

function* stxPlaceBetRequest(action) {
  try {
    let responseData;
    if (action?.payload?.data) {
      const { data } = yield API.post(
        "api/v1/stx-place-bet",
        action?.payload?.data
      );
      responseData = data;
    } 

    if (responseData?.meta?.code === 200) {
      yield put(StxPlaceBetSuccess(responseData));
      yield put(placeBetSuccess(responseData));
      yield call(action?.payload?.callback, responseData);
      notifySuccess(responseData.meta.message);
    } else if (responseData.meta.code === 401) {
      yield put(StxPlaceBetFailure());
      notifyWarning(responseData.meta.message);
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
      window.location.reload();
    } else if (responseData?.meta?.code !== 200) {
      yield put(StxPlaceBetFailure());

      notifyWarning(responseData.meta.message);
      yield call(action?.payload?.callback, responseData);
    }
  } catch (error) {
    yield put(StxPlaceBetFailure());
  }
}

export function* watchStxPlaceBetAPI() {
  yield takeEvery(STX_PLACE_BET, stxPlaceBetRequest);
}

export default function* rootSaga() {
  yield all([watchStxPlaceBetAPI()]);
}
