import React, { useContext, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  VerifyTwoFactorCodeAction,
  googleAuthenticatorOTPValidation,
  login,
} from "../redux/actions";
import { notifyWarning } from "../utils/helper";
import { useForm } from "react-hook-form";
import { SocketContext } from "../context/SocketContext";
import TwoAuth from "./2FAComponents/TwoAuthComponents/TwoAuth";
import Googlelogin from "./2FAComponents/GoogleLogin/Googlelogin";
import GoogleAuthenticator from "../assets/images/GoogleAuthenticator.jpg";
import Loginwithgoogle from "../assets/images/Authentication/loginwithgoogle.png";
import logoFooter from "../../src/assets/images/adminLogo.png"
import { appName } from "../utils/constants";

const LoginModal = ({ closeModal, onLogin }) => {
  const dispatch = useDispatch();
  const whiteLabelData = useSelector((data) => data?.FetchWhiteLabel?.data);
  const themeMode = document.querySelector(".light-theme");
  const socket = useContext(SocketContext);
  const [isLoading, setIsLoading] = useState(false);
  const [userWithTokenData, setUserWithTokenData] = useState();
  const [twoFactorAuth, settwoFactorAuth] = useState(false);
  const [twoFactorAuthGoogle, setTwoFactorAuthGoogle] = useState(false);
  const [
    twoFactorAuthGoogleAuthenticator,
    setTwoFactorAuthGoogleAuthenticator,
  ] = useState(false);
  const [phoneNumberValue, setPhoneNumber] = useState("");
  const [passwordValue, setPassword] = useState("");
  const { logins, handleSubmit } = useForm();
  const [otp, setOTP] = useState("");
  const [error, seterror] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Only allow digits
    setOTP(value);
  };

  const handleSubmitGoogleOTP = (e) => {
    e.preventDefault();
    if (otp.length === null || otp === undefined || otp.length < 5) {
      seterror("This Field is required Max length is 6");
      return;
    } else if (otp.length === 6) {
      seterror("");
      const responseObject = {
        userId: userWithTokenData?.data.id,
        withoutAuth: true,
        OTPValue: otp,
        validateOTP: true,
      };
      dispatch(
        googleAuthenticatorOTPValidation({
          data: responseObject,
          callback: (data) => {
            if (data?.data !== null) {
              localStorage?.setItem("token", userWithTokenData?.meta?.token);
              localStorage?.setItem(
                "userData",
                JSON.stringify(userWithTokenData?.data)
              );
              localStorage?.setItem(
                "googleToken",
                userWithTokenData?.data?.TFA_Token?.token
              );
              socket.emit("initialize-session", userWithTokenData?.data?.id);
              setTwoFactorAuthGoogleAuthenticator(false);
              closeModal();
              onLogin(true);
              setIsLoading(false);
              return;
            } else {
              seterror("Invalid OTP");
              setOTP("");
              return;
            }
          },
        })
      );
    }
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const onSubmit = () => {
    if (phoneNumberValue.trim() !== "" && passwordValue.trim() !== "") {
      let inputValues = {
        username: phoneNumberValue,
        password: passwordValue,
      };
      // console.log(whiteLabelData, "whiteLabelData");
      if (whiteLabelData?.userId !== undefined) {
        inputValues.white_label_id = whiteLabelData?.userId;
      }
      setIsLoading(true);
      setUserWithTokenData();
      dispatch(
        login({
          inputValues,
          callback: (data) => {
            if (data?.meta?.code === 200) {
              if (
                data?.data?.twoFactorEnabled === true &&
                data?.data?.twoFactorMethod === "app"
              ) {
                setUserWithTokenData(data);
                settwoFactorAuth(true);
                setIsLoading(false);
                // closeModal();
              } else if (
                data?.data?.twoFactorEnabled === true &&
                data?.data?.twoFactorMethod === "google" &&
                data?.data?.TFA_Token?.expired
              ) {
                // console.log("google token");
                setTwoFactorAuthGoogle(true);
                setUserWithTokenData(data);
                setIsLoading(false);
                // closeModal();
              } else if (
                data?.data?.twoFactorEnabled === true &&
                data?.data?.twoFactorMethod === "googleAuthenticator" &&
                data?.data?.TFA_Token?.expired
              ) {
                setTwoFactorAuthGoogleAuthenticator(true);
                setUserWithTokenData(data);
                setIsLoading(false);
              } else {
                localStorage?.setItem("token", data?.meta?.token); // add userdata also to localstorage
                localStorage?.setItem(
                  "googleToken",
                  data?.data?.TFA_Token?.token
                );
                localStorage?.setItem("userData", JSON.stringify(data?.data));
                socket.emit("initialize-session", data?.data?.id);
                closeModal();
                onLogin(true);
                setIsLoading(false);
              }
            } else {
              setIsLoading(false);
            }
          },
        })
      );
    } else {
      notifyWarning("please fill all Fields");
    }
  };

  //FOR CLOSE MODAL OF TWO FACTOR AUTHENTICATION
  const closeTwoFactorAuth = () => {
    settwoFactorAuth(!twoFactorAuth);
    setTwoFactorAuthGoogleAuthenticator(!twoFactorAuthGoogleAuthenticator);
  };

  const handleAuth = (sanditizedInputValue) => {
    if (sanditizedInputValue && sanditizedInputValue.length === 6) {
      const data = {
        secret: sanditizedInputValue,
        userId: userWithTokenData.data.id,
      };
      dispatch(
        VerifyTwoFactorCodeAction({
          data: data,
          callback: (data) => {
            if (data) {
              localStorage?.setItem("token", userWithTokenData?.meta?.token); // add userdata also to localstorage
              localStorage?.setItem(
                "userData",
                JSON.stringify(userWithTokenData?.data)
              );
              localStorage?.setItem(
                "googleToken",
                userWithTokenData?.data?.TFA_Token?.token
              );

              onLogin(true);
              socket.emit("initialize-session", userWithTokenData?.data?.id);
              setIsLoading(false);
              closeTwoFactorAuth();
              closeModal();
              return;
            }
          },
        })
      );
    }
  };

  //THIS FUNCTION IS FOR TIMER
  const handleTimeout = () => {
    window.location.reload();
    return;
  };

  const handleGoogleSignIn = (data) => {
    if (data.email_verified && data.email !== null) {
      if (userWithTokenData.data.TFA_email === data?.email) {
        closeModal();
        localStorage?.setItem("token", userWithTokenData?.meta?.token); // add userdata also to localstorage
        localStorage?.setItem(
          "userData",
          JSON.stringify(userWithTokenData?.data)
        );
        localStorage?.setItem(
          "googleToken",
          userWithTokenData?.data?.TFA_Token?.token
        );
        socket.emit("initialize-session", userWithTokenData?.data?.id);
        onLogin(true);
        setIsLoading(false);
      } else {
        notifyWarning("Email Was mismatch with your 2FA email");
      }
    }
  };

  return (
    <>
      {twoFactorAuth ? (
        <TwoAuth
          callbackValue={handleAuth}
          onTimeout={handleTimeout}
          closeTwoFactorAuth={closeTwoFactorAuth}
        />
      ) : (
        <>
          <div className="relative inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-2">
            <div className="fixed z-[100] inset-0 bg-skin-modalBackgroundBlur bg-opacity-25 backdrop-blur-sm flex items-center justify-center ">
              <div className="relative bg-skin-modalBackground p-5 rounded-xl w-96">
                <button
                  className="absolute top-4 right-3"
                  type="button"
                  onClick={closeModal}
                >
                  <MdOutlineClose className="text-skin-primary text-3xl font-bold" />
                </button>

                <div className="logo w-full flex justify-center">
                  {themeMode ? (
                    <img
                      className="w-[17rem] text-center mt-5 mb-4"
                      // src={whiteLabelData?.logo_light}
                      src={logoFooter}
                      alt=""
                    />
                  ) : (
                    <img
                      className="w-[17rem] text-center mt-5 mb-4"
                      //src={whiteLabelData?.logo_dark}
                      src={logoFooter}
                      alt=""
                    />
                  )}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col">
                    <div className={`phone-number`}>
                      <label
                        className="text-skin-primary text-xs font-semibold mb-2"
                        htmlFor="phoneNumberInput"
                      >
                        Username
                      </label>
                      <input
                        type="text"
                        id="phoneNumberInput"
                        className="w-full p-2 rounded focus:outline-none text-skin-white bg-skin-inputBackground"
                        placeholder="username"
                        value={phoneNumberValue}
                        onChange={handlePhoneNumberChange}
                        required
                      />
                    </div>

                    <div className={`password `}>
                      <label
                        className="text-skin-primary text-xs font-semibold mb-2"
                        htmlFor="passwordInput"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        id="passwordInput"
                        className="w-full p-2 rounded focus:outline-none text-skin-white bg-skin-inputBackground mb-4"
                        placeholder="password"
                        value={passwordValue}
                        onChange={handlePasswordChange}
                        required
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="w-full p-2 px-4 uppercase font-semibold rounded-md  text-skin-liveIconText bg-skin-liveContainerBg text-lg focus:outline-none mb-3"
                  >
                    {isLoading ? "Please wait..." : "Log In"}
                  </button>
                </form>

                {/* <Googlelogin callbackData={handleGoogleSignIn}/> */}
              </div>
            </div>
          </div>
        </>
      )}

      {/* NAMING IS DIFFERENT BACAUSE OF INITIAL TIME ONLY GOOGLE AUTH IS THERE */}
      {/* THIS COMPONENT IS ONLY FOR GOOGLE LOGIN AND IT WILL RETURN A RESPONSE FROM GOOGLE LOGIN */}
      {twoFactorAuthGoogle && (
        <div className="relative inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-2">
          <div className="fixed z-[100] inset-0 bg-skin-modalBackgroundBlur bg-opacity-25 backdrop-blur-sm flex items-center justify-center ">
            {/* <div className="relative bg-skin-modalBackground p-5 rounded-xl w-96"> */}
            {/* <div className="fixed top-0 left-0 w-full h-full bg-opacity-50 z-50 flex flex-col items-center justify-center min-h-screen py-2"> */}

            <div className="bg-[#ffffffd7] shadow-md rounded-lg px-12 pb-8 w-full max-w-lg">
              <div className="flex justify-center my-6">
                <img
                  className="w-auto h-30 rounded"
                  src={Loginwithgoogle}
                  alt="Google logo"
                />
              </div>
              <h1 className="text-2xl font-medium text-center mb-4">
                Complete 2FA with Google Login
              </h1>
              <p className="text-center text-gray-600">to continue to {appName}</p>
              <Googlelogin callbackData={handleGoogleSignIn} />
              <div className="flex justify-between items-center mt-6 text-gray-600">
                <p>English (United States)</p>
                <div className="flex items-center">
                  <p className="text-sm mr-2">Help</p>
                  <p className="text-sm">Privacy</p>
                  <p className="text-sm">Terms</p>
                </div>
              </div>
            </div>

            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      )}

      {/* THIS COMPONENT IS USED TO HANDLE GOOGLE AUTHENTICATOR OTP LOGIN */}
      {twoFactorAuthGoogleAuthenticator && (
        <div className="relative inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-2">
          <div className="fixed z-[100] inset-0 bg-skin-modalBackgroundBlur bg-opacity-25 backdrop-blur-sm flex items-center justify-center ">
            <div className="relative bg-skin-modalBackground p-5 rounded-xl w-96">
              <div className="fixed top-0 left-0 w-full h-full bg-opacity-50 z-50 flex flex-col items-center justify-center min-h-screen py-2">
                <div className="bg-[#ffffffd7] shadow-md rounded-lg px-8 pb-8 w-full max-w-md">
                  <div className="flex justify-center mb-6">
                    <img
                      className="w-auto h-30 rounded mt-2"
                      src={GoogleAuthenticator}
                      alt="Google logo"
                    />
                  </div>
                  <h1 className="text-2xl font-medium text-center mb-4">
                    Complete with Google Authenticator Extra Layer Security
                  </h1>

                  <div className="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
                    <header className="mb-8">
                      <h1 className="text-2xl font-bold mb-1">
                        Google Authenticator
                      </h1>
                      <p className="text-[15px] text-slate-500">
                        Enter the 6-digit verification code in your Google
                        Authenticator app.
                      </p>
                    </header>
                    <form onSubmit={handleSubmitGoogleOTP} id="otp-form">
                      <div className="flex items-center justify-center gap-3">
                        <input
                          type="text"
                          className="w-auto h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                          pattern="\d*"
                          maxLength="6"
                          value={otp}
                          onChange={handleInputChange}
                        />
                      </div>
                      {error && <p className="text-red-500">{error}</p>}
                      <div className="max-w-[260px] mx-auto mt-4">
                        <button
                          type="submit"
                          className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
                        >
                          Verify PassCode
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginModal;
