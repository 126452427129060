import { all, call, put, takeEvery } from "redux-saga/effects";
import { MARKET_ANALYTICS } from "../../actions/types";
import {
  getMarketAnalyticsSuccess,
  getMarketAnalyticsFailure,
} from "../../actions";
import API from "../../../utils/oddApi";
function* getMarketAnalyticsDataRequest(action) {
  try {
    console.log("getEventDataRequest", action);
    const marketId = action.payload?.data;
    const { data } = yield API.get(`api/v2/analytics?marketId=${marketId}`);

    if (data.meta.code === 200) {
      yield put(getMarketAnalyticsSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getMarketAnalyticsFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data.meta.code !== 200) {
      yield put(getMarketAnalyticsFailure());
    }
  } catch (error) {
    yield put(getMarketAnalyticsFailure());
  }
}

export function* watchGetMarketAnalyticsDataAPI() {
  yield takeEvery(MARKET_ANALYTICS, getMarketAnalyticsDataRequest);
}

export default function* rootSaga() {
  yield all([watchGetMarketAnalyticsDataAPI()]);
}
