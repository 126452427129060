import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_EVENT_DATA } from "../../actions/types";
import { getEventDataSuccess, getEventDataFailure } from "../../actions";
import API from "../../../utils/api";

function* getEventDataRequest(action) {
  try {
    const { data } = yield API.get(
      `api/v1/event-data`,
      {
        params: {
          sport: action?.payload?.eventObj?.sport,
          eventCode: action?.payload?.eventObj?.eventCode
        }
      }
    );
    
    if (data.meta.code === 200) {
      yield put(getEventDataSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getEventDataFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data.meta.code !== 200) {
      yield put(getEventDataFailure());
    }
  } catch (error) {
    yield put(getEventDataFailure());
  }
}

export function* watchGetEventDataAPI() {
  yield takeEvery(GET_EVENT_DATA, getEventDataRequest);
}

export default function* rootSaga() {
  yield all([watchGetEventDataAPI()]);
}
