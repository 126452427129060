import { all, call, put, takeEvery } from "redux-saga/effects";
import {  GET_EVENT_DATA_HORSERACE } from "../../actions/types";
import { getEventDataHorseRaceSuccess , getEventDataHorseRaceFailure } from "../../actions";
import horseRaceAPI from "../../../utils/horseRaceApi";

function* getEventDataHorseRaceRequest(action) {
  try {
    const { data } = yield horseRaceAPI.get(
      `api/v1/horse-race-event`,
      {
        params: {
          sport: action?.payload?.eventObj?.sport,
          eventCode: action?.payload?.eventObj?.eventCode,
          time : action?.payload?.eventObj?.time
        }
      }
    );
    
    if (data.meta.code === 200) {
      yield put(getEventDataHorseRaceSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getEventDataHorseRaceFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data.meta.code !== 200) {
      yield put(getEventDataHorseRaceFailure());
    }
  } catch (error) {
    yield put(getEventDataHorseRaceFailure());
  }
}

export function* watchGetEventDataHorseRaceAPI() {
  yield takeEvery(GET_EVENT_DATA_HORSERACE, getEventDataHorseRaceRequest);
}

export default function* rootSaga() {
  yield all([watchGetEventDataHorseRaceAPI()]);
}
