import { all, call, put, takeEvery } from "redux-saga/effects";
import { HORSE_RACING_SETTINGS } from "../../actions/types";
import { getHorseRaceSettingsSuccess , getHorseRaceSettingsFailure } from "../../actions";
import horseRaceAPI from "../../../utils/horseRaceApi";

function* getHorseRacingSettingsRequest(action) {
  try {
    const { data } = yield horseRaceAPI.get(`api/v1/setting-horse-racing?currencyId=${action.payload?.currencyId}&sportsId=${action.payload?.sportsId}`);
    if (data.meta.code === 200) {
      yield put(getHorseRaceSettingsSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getHorseRaceSettingsFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data.meta.code !== 200) {
      yield put(getHorseRaceSettingsFailure());
    }
  } catch (error) {
    yield put(getHorseRaceSettingsFailure());
  }
}

export function* watchGetHorseSettingsAPI() {
  yield takeEvery(HORSE_RACING_SETTINGS, getHorseRacingSettingsRequest);
}

export default function* rootSaga() {
  yield all([watchGetHorseSettingsAPI()]);
}
