import {
  GET_RESULT_TRANSACTION,
  GET_RESULT_TRANSACTION_SUCCESS,
  GET_RESULT_TRANSACTION_FAILURE,
} from "../types";

export const getResultTransaction = (payload) => ({
  type: GET_RESULT_TRANSACTION,
  payload,
});

export const getResultTransactionSuccess = (payload) => ({
  type: GET_RESULT_TRANSACTION_SUCCESS,
  payload,
});

export const getResultTransactionFailure = () => ({
  type: GET_RESULT_TRANSACTION_FAILURE,
});
