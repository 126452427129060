import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_POPULAR_EVENTS } from "../../actions/types";
import {
  getPopularEventsSuccess,
  getPopularEventsFailure,
} from "../../actions";
import API from "../../../utils/api";

function* getPopularEventsRequest(action) {
  try {
    const { data } = yield API.get(`api/v1/popular-events`);
    if (data.meta.code === 200) {
      yield put(getPopularEventsSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getPopularEventsFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data.meta.code !== 200) {
      yield put(getPopularEventsFailure());
    }
  } catch (error) {
    yield put(getPopularEventsFailure());
  }
}

export function* watchGetPopularEventsAPI() {
  yield takeEvery(GET_POPULAR_EVENTS, getPopularEventsRequest);
}

export default function* rootSaga() {
  yield all([watchGetPopularEventsAPI()]);
}
