import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_ACCOUNT_STATEMENT } from "../../actions/types";
import {
  getAccountStatementSuccess,
  getAccountStatementFailure,
} from "../../actions";
import API from "../../../utils/api";

function* getAccountStatementRequest(action) {
  try {
    const { data } = yield API.get(
      `api/v1/transaction?start_date=${action?.payload?.startDate}&end_date=${action?.payload?.endDate}&isChecked=${action?.payload?.isChecked}`
    );
    if (data.meta.code === 200) {
      yield put(getAccountStatementSuccess(data?.data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getAccountStatementSuccess());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(getAccountStatementFailure());
    }
  } catch (error) {
    yield put(getAccountStatementFailure());
  }
}

export function* watchgetAccountStatementAPI() {
  yield takeEvery(GET_ACCOUNT_STATEMENT, getAccountStatementRequest);
}

export default function* rootSaga() {
  yield all([watchgetAccountStatementAPI()]);
}
