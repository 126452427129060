import { all, call, put, takeEvery } from "redux-saga/effects";
import { CURRENCY_BETFAIR } from "../../actions/types";
import { getCurrencySuccess, getCurrencyFailure } from "./../../actions";
import API from "../../../utils/api";

function* getCurrencyRequest(action) {
  try {
    const { data } = yield API.get("api/v1/currency"); // Add the URL from Backend
    if (data.meta.code === 200) {
      yield put(getCurrencySuccess(data?.data));
      yield call(action.payload.callback, data?.data);
    } else if (data.meta.code === 401) {
      yield put(getCurrencyFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
      window.location.reload();
    } else if (data.meta.code !== 200) {
      yield put(getCurrencyFailure());
    }
  } catch (error) {
    yield put(getCurrencyFailure());
  }
}

export function* watchCurrencyAPI() {
  yield takeEvery(CURRENCY_BETFAIR, getCurrencyRequest);
}

export default function* rootSaga() {
  yield all([watchCurrencyAPI()]);
}
