import {
  GET_INPLAY,
  GET_INPLAY_SUCCESS,
  GET_INPLAY_FAILURE,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  inPlayData: null,
};

const getInPlayReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INPLAY:
      return { ...state, loading: true };
    case GET_INPLAY_SUCCESS:
      return { ...state, inPlayData: action.payload, loading: false };
    case GET_INPLAY_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default getInPlayReducer;
