import {
  GET_ST8CASINO_URL,
  GET_ST8CASINO_URL_FAILURE,
  GET_ST8CASINO_URL_SUCCESS,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  data: null,
};

const getSt8CasinoUrlReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ST8CASINO_URL:
      return { ...state, loading: true };
    case GET_ST8CASINO_URL_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case GET_ST8CASINO_URL_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default getSt8CasinoUrlReducer;
