const Input = ({
  isIncrementDecrement,
  handleDecrement,
  value,
  placeholder,
  handleIncrement,
  handleChange,
  handleFocusChange,
  handleFocusCursor,
  handleKeyDown,
}) => {
  const handleBlur = (e) => {
    if (handleFocusChange) {
      handleFocusChange(e);
    }
  };

  const handleFocusChangeCursor = (e) => {
    if (handleFocusCursor) {
      handleFocusCursor(e);
    }
  };
  return (
    <div className="flex rounded-sm mt-1">
      {isIncrementDecrement  ? (
        <button
          onClick={() => handleDecrement(placeholder)}
          data-action="decrement"
          className="bg-skin-nav text-skin-primary  h-full px-2 rounded-l cursor-pointer outline-none"
          style={{ opacity: value <= 0 ? 0.5 : 1 }}
          disabled={value <= 0}
        >
          <span className="text-[24px] font-thin">−</span>
        </button>
      ) : (
        ""
      )}

      <input
        type="number"
        className="text-center w-full bg-skin-nav font-semibold text-md md:text-base flex items-center text-skin-primary outline-none"
        name="custom-input-number"
        value={value !== null && value !== 0 ? value : ""}
        placeholder={placeholder}
        onChange={(e) => handleChange(e, placeholder)}
        onBlur={(e) => handleBlur(e)}
        onFocus={(e) => handleFocusChangeCursor(e)}
        onKeyDown={(e) => handleKeyDown(e, placeholder)}
        inputMode="numeric"
        style={{ appearance: "textfield" }}
      />
      {isIncrementDecrement ? (
        <button
          onClick={(e) => handleIncrement(placeholder, e)}
          data-action="increment"
          className="bg-skin-nav text-skin-primary  h-full px-2 rounded-r cursor-pointer"
          style={{ opacity: placeholder == "Odds" && value >= 1000 ? 0.5 : 1 }}
          disabled={placeholder == "Odds" && value >= 1000}
        >
          <span className="text-[24px] font-thin">+</span>
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
