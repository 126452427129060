import {
  META_CREATE,
  META_CREATE_SUCCESS,
  META_CREATE_FAILURE,
  META_CHECK_WALLET,
  META_CHECK_WALLET_SUCCESS,
  META_CHECK_WALLET_FAILURE,
  META_DEPOSIT,
  META_DEPOSIT_SUCCESS,
  META_DEPOSIT_FAILURE,
  META_WITHDRAW,
  META_WITHDRAW_SUCCESS,
  META_WITHDRAW_FAILURE,
} from "../../actions/types/index";

const INIT_STATE = {
  loading: false,
  wallet: null,
  walletDetail: {},
};

const userHasWallet = (state = INIT_STATE, action) => {
  switch (action.type) {
    case META_CREATE:
      return { ...state, loading: true };
    case META_CHECK_WALLET:
      return { ...state, loading: true };
    case META_DEPOSIT:
      return { ...state, loading: true };
    case META_WITHDRAW:
      return { ...state, loading: true };

    case META_CREATE_SUCCESS:
      return { ...state, wallet: true, loading: false };
    case META_CHECK_WALLET_SUCCESS:
      return {
        ...state,
        wallet: true,
        walletDetail: action.payload,
        loading: false,
      };
    case META_DEPOSIT_SUCCESS:
      return { ...state, loading: false };
    case META_WITHDRAW_SUCCESS:
      return { ...state, loading: false };
      
    case META_CREATE_FAILURE:
      return { ...state, loading: false };
    case META_CHECK_WALLET_FAILURE:
      return { ...state, loading: false };
    case META_DEPOSIT_FAILURE:
      return { ...state, loading: false };
    case META_WITHDRAW_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default userHasWallet;
