import React, { useState } from 'react';

export const LoadingContext = React.createContext();

const Loading= ({ children }) => {


  const [loading, setloading] = useState(true)
  return (
    <LoadingContext.Provider value={{ loading, setloading }}>
      {children}
    </LoadingContext.Provider>
  )
}

export default Loading