import {
  GET_HORSE_RACE_LIVE,
  GET_HORSE_RACE_LIVE_SUCCESS,
  GET_HORSE_RACE_LIVE_FAILURE,
} from "../types";

export const getHorseRaceLive = (payload) => ({
  type: GET_HORSE_RACE_LIVE,
  payload,
});

export const getHorseRaceLiveSuccess = (payload) => ({
  type: GET_HORSE_RACE_LIVE_SUCCESS,
  payload,
});

export const getHorseRaceLiveFailure = () => ({
  type: GET_HORSE_RACE_LIVE_FAILURE,
});
