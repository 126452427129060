import {
  GET_INPLAY_BY_SPORT,
  GET_INPLAY_BY_SPORT_SUCCESS,
  GET_INPLAY_BY_SPORT_FAILURE,
} from "../types";

export const getInPlayBySport = (payload) => ({
  type: GET_INPLAY_BY_SPORT,
  payload,
});

export const getInPlayBySportSuccess = (payload) => ({
  type: GET_INPLAY_BY_SPORT_SUCCESS,
  payload,
});

export const getInPlayBySportFailure = () => ({
  type: GET_INPLAY_BY_SPORT_FAILURE,
});
