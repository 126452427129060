import {
  GET_HORSE_RACE_COUNTRIES_VENUES,
  GET_HORSE_RACE_COUNTRIES_VENUES_SUCCESS,
  GET_HORSE_RACE_COUNTRIES_VENUES_FAILURE,
} from "../types";

export const getCountriesVenuesHorseRace = (payload) => ({
  type: GET_HORSE_RACE_COUNTRIES_VENUES,
  payload,
});

export const getCountriesVenuesHorseRaceSuccess = (payload) => ({
  type: GET_HORSE_RACE_COUNTRIES_VENUES_SUCCESS,
  payload,
});

export const getCountriesVenuesHorseRaceFailure = () => ({
  type: GET_HORSE_RACE_COUNTRIES_VENUES_FAILURE,
});
