import { getDefaultState } from "../../../utils/helper";
import {
  CREATE_SESSION,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAILURE,
  LOGOUT,
} from "../../actions/types";

const token = getDefaultState("token");
const INIT_STATE = {
  loading: false,
  createSession: null,
  sessionCreated: token ? true : false,
};

const createSessionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_SESSION:
      return { ...state, loading: true };
    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        createSession: action.payload,
        loading: false,
        sessionCreated: true,
      };
    case CREATE_SESSION_FAILURE:
      return { ...state, loading: false };
    case LOGOUT:
      return { ...state, createSession: null, loading: false, sessionCreated: false };
    default:
      return state;
  }
};

export default createSessionReducer;
