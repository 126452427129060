//All types for Actions need to be created here and export, this will be used as action types

// =====================Get Sports Name===========================

export const GET_SPORT = "GET_SPORT";
export const GET_SPORT_SUCCESS = "GET_SPORT_SUCCESS";
export const GET_SPORT_FAILURE = "GET_SPORT_FAILURE";

// =====================Login Action==============================

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// =====================Change Password Action==============================

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

// =====================Get Sport Data (leages and events)==============================

export const GET_ALL_SPORT_DATA = "GET_ALL_SPORT_DATA";
export const GET_ALL_SPORT_DATA_SUCCESS = "GET_ALL_SPORT_DATA_SUCCESS";
export const GET_ALL_SPORT_DATA_FAILURE = "GET_ALL_SPORT_DATA_FAILURE";

// =====================Get Sport Data (leages and events)==============================

export const GLOBAL_SPORT_DATA = "GLOBAL_SPORT_DATA";
export const GLOBAL_SPORT_DATA_SUCCESS = "GLOBAL_SPORT_DATA_SUCCESS";

// ===================== Market Odds Data(Global)==============================

export const GLOBAL_MARKET_ODDS = "GLOBAL_MARKET_ODDS";
export const GLOBAL_MARKET_ODDS_SUCCESS = "GLOBAL_MARKET_ODDS_SUCCESS";

// =====================Get Runners Data From DB==============================

export const GET_RUNNER_DATA = "GET_RUNNER_DATA";
export const GET_RUNNER_DATA_SUCCESS = "GET_RUNNER_DATA_SUCCESS";
export const GET_RUNNER_DATA_FAILURE = "GET_RUNNER_DATA_FAILURE";

// =====================PLACE BET (ADD)===========================
export const PLACE_BET = "PLACE_BET";
export const PLACE_BET_SUCCESS = "PLACE_BET_SUCCESS";
export const PLACE_BET_FAILURE = "PLACE_BET_FAILURE";

// =====================GET USERDATA (GET)===========================
export const GET_USERDATA = "GET_USERDATA";
export const GET_USERDATA_SUCCESS = "GET_USERDATA_SUCCESS";
export const GET_USERDATA_FAILURE = "GET_USERDATA_FAILURE";

// =====================GET  BET(GET)===========================
export const GET_BET = "GET_BET";
export const GET_BET_SUCCESS = "GET_BET_SUCCESS";
export const GET_BET_FAILURE = "GET_BET_FAILURE";

//=====================USER LOGOUT===========================

export const LOGOUT = "LOGOUT";
export const LOGOUTSUCCESS = "LOGOUTSUCCESS";

// =====================CHIP SETTINGS(POST)===========================

export const CHIP_SETTING = "CHIP_SETTING";
export const CHIP_SETTING_SUCCESS = "CHIP_SETTING_SUCCESS";
export const CHIP_SETTING_FAILURE = "CHIP_SETTING_FAILURE";

// =====================CHIP SETTINGS(GET)===========================

export const GET_CHIP_SETTING = "GET_CHIP_SETTING";
export const GET_CHIP_SETTING_SUCCESS = "GET_CHIP_SETTING_SUCCESS";
export const GET_CHIP_SETTING_FAILURE = "GET_CHIP_SETTING_FAILURE";

// =====================SPORT SETTINGS(GET)===========================

export const GET_SPORT_SETTING = "GET_SPORT_SETTING";
export const GET_SPORT_SETTING_SUCCESS = "GET_SPORT_SETTING_SUCCESS";
export const GET_SPORT_SETTING_FAILURE = "GET_SPORT_SETTING_FAILURE";

// =====================ACCOUNT STATEMENT (GET)===========================

export const GET_ACCOUNT_STATEMENT = "GET_ACCOUNT_STATEMENT";
export const GET_ACCOUNT_STATEMENT_SUCCESS = "GET_ACCOUNT_STATEMENT_SUCCESS";
export const GET_ACCOUNT_STATEMENT_FAILURE = "GET_ACCOUNT_STATEMENT_FAILURE";

// =====================GET MY MARKETS(GET)===========================

export const GET_MY_MARKETS = "GET_MY_MARKETS";
export const GET_MY_MARKETS_SUCCESS = "GET_MY_MARKETS_SUCCESS";
export const GET_MY_MARKETS_FAILURE = "GET_MY_MARKETS_FAILURE";

// =====================GET RESULT TRANSACTION (GET)===========================

export const GET_RESULT_TRANSACTION = "GET_RESULT_TRANSACTION";
export const GET_RESULT_TRANSACTION_SUCCESS = "GET_RESULT_TRANSACTION_SUCCESS";
export const GET_RESULT_TRANSACTION_FAILURE = "GET_RESULT_TRANSACTION_FAILURE";

// =====================GET EVENT BETS (GET)===========================

export const GET_EVENT_BETS = "GET_EVENT_BETS";
export const GET_EVENT_BETS_SUCCESS = "GET_EVENT_BETS_SUCCESS";
export const GET_EVENT_BETS_FAILURE = "GET_EVENT_BETS_FAILURE";

// =====================GET BOOKMAKER MARKETS (GET)===========================

export const GET_BOOKMAKER_MARKETS = "GET_BOOKMAKER_MARKETS";
export const GET_BOOKMAKER_MARKETS_SUCCESS = "GET_BOOKMAKER_MARKETS_SUCCESS";
export const GET_BOOKMAKER_MARKETS_FAILURE = "GET_BOOKMAKER_MARKETS_FAILURE";

// =====================GET ACTIVE FANCIES (GET)===========================

export const GET_ACTIVE_FANCIES = "GET_ACTIVE_FANCIES";
export const GET_ACTIVE_FANCIES_SUCCESS = "GET_ACTIVE_FANCIES_SUCCESS";
export const GET_ACTIVE_FANCIES_FAILURE = "GET_ACTIVE_FANCIES_FAILURE";

// ===================== CASINO CREATE SESSION (POST)===========================

export const CREATE_SESSION = "CREATE_SESSION";
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS";
export const CREATE_SESSION_FAILURE = "CREATE_SESSION_FAILURE";

// ===================== CASINO GET BALANCE (POST)===========================

export const GET_BALANCE = "GET_BALANCE";
export const GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS";
export const GET_BALANCE_FAILURE = "GET_BALANCE_FAILURE";

// ===================== CASINO GET CREDIT (POST)===========================

export const GET_CREDIT = "GET_CREDIT";
export const GET_CREDIT_SUCCESS = "GET_CREDIT_SUCCESS";
export const GET_CREDIT_FAILURE = "GET_CREDIT_FAILURE";

// ===================== TV URL (POST)===========================

export const GET_TV_URL = "GET_TV_URL";
export const GET_TV_URL_SUCCESS = "GET_TV_URL_SUCCESS";
export const GET_TV_URL_FAILURE = "GET_TV_URL_FAILURE";

// ===================== TV URL (POST)===========================

export const GET_DEBIT = "GET_DEBIT";
export const GET_DEBIT_SUCCESS = "GET_DEBIT_SUCCESS";
export const GET_DEBIT_FAILURE = "GET_DEBIT_FAILURE";

// ===================== GET CASINO BETS===========================
export const GET_CASINO_BETS = "GET_CASINO_BETS";
export const GET_CASINO_BETS_SUCCESS = "GET_CASINO_BETS_SUCCESS";
export const GET_CASINO_BETS_FAILURE = "GET_CASINO_BETS_FAILURE";

// ===================== INDIAN CASINO===========================
export const INDIAN_CASINO = "INDIAN_CASINO";
export const INDIAN_CASINO_SUCCESS = "INDIAN_CASINO_SUCCESS";
export const INDIAN_CASINO_FAILURE = "INDIAN_CASINO_FAILURE";

// =====================Get Home Sliders==============================

export const GET_HOME_SLIDER = "GET_HOME_SLIDER";
export const GET_HOME_SLIDER_SUCCESS = "GET_HOME_SLIDER_SUCCESS";
export const GET_HOME_SLIDER_FAILURE = "GET_HOME_SLIDER_FAILURE";

// =====================GET DELETED EVENT BETS (GET)===========================

export const GET_DELETED_EVENT_BETS = "GET_DELETED_EVENT_BETS";
export const GET_DELETED_EVENT_BETS_SUCCESS = "GET_DELETED_EVENT_BETS_SUCCESS";
export const GET_DELETED_EVENT_BETS_FAILURE = "GET_DELETED_EVENT_BETS_FAILURE";

// =====================ST8CASINO (GET)===========================

export const GET_ST8CASINO = "GET_ST8CASINO";
export const GET_ST8CASINO_SUCCESS = "GET_ST8CASINO_SUCCESS";
export const GET_ST8CASINO_FAILURE = "GET_ST8CASINO_FAILURE";

// =====================ST8CASINO PLAY (GET)===========================

export const GET_ST8CASINO_URL = "GET_ST8CASINO_URL";
export const GET_ST8CASINO_URL_SUCCESS = "GET_ST8CASINO_URL_SUCCESS";
export const GET_ST8CASINO_URL_FAILURE = "GET_ST8CASINO_URL_FAILURE";

// =====================FETCH WHITELABEL (GET)===========================

export const FETCH_WHITELABEL = "FETCH_WHITELABEL";
export const FETCH_WHITELABEL_SUCCESS = "FETCH_WHITELABEL_SUCCESS";
export const FETCH_WHITELABEL_FAILURE = "FETCH_WHITELABEL_FAILURE";

// =====================FETCH UNMATCHBET (GET)===========================

export const DELETE_UNMATCH_BET = "DELETE_UNMATCH_BET";
export const DELETE_UNMATCH_BET_SUCCESS = "DELETE_UNMATCH_BET_SUCCESS";
export const DELETE_UNMATCH_BET_FAILURE = "DELETE_UNMATCH_BET_FAILURE";
// =====================FETCH POPULAR EVENTS (GET)===========================

export const GET_POPULAR_EVENTS = "GET_POPULAR_EVENTS";
export const GET_POPULAR_EVENTS_SUCCESS = "GET_POPULAR_EVENTS_SUCCESS";
export const GET_POPULAR_EVENTS_FAILURE = "GET_POPULAR_EVENTS_FAILURE";

// =====================FETCH EVENTS DATA (GET)===========================

export const GET_EVENT_DATA = "GET_EVENT_DATA";
export const GET_EVENT_DATA_SUCCESS = "GET_EVENT_DATA_SUCCESS";
export const GET_EVENT_DATA_FAILURE = "GET_EVENT_DATA_FAILURE";
export const GET_EVENT_DATA_HORSE_RACE = "GET_EVENT_DATA_HORSE_RACE";

// =====================FETCH HOME DATA (GET)===========================

export const GET_HOME_DATA = "GET_HOME_DATA";
export const GET_HOME_DATA_SUCCESS = "GET_HOME_DATA_SUCCESS";
export const GET_HOME_DATA_FAILURE = "GET_HOME_DATA_FAILURE";

// =====================FETCH INPLAY DATA (GET)===========================

export const GET_INPLAY = "GET_INPLAY";
export const GET_INPLAY_SUCCESS = "GET_INPLAY_SUCCESS";
export const GET_INPLAY_FAILURE = "GET_INPLAY_FAILURE";

// =====================FETCH EVENT EXCH DATA (GET)===========================

export const GET_EVENT_EXCH = "GET_EVENT_EXCH";
export const GET_EVENT_EXCH_SUCCESS = "GET_EVENT_EXCH_SUCCESS";
export const GET_EVENT_EXCH_FAILURE = "GET_EVENT_EXCH_FAILURE";

// =====================UPDATE 2F AUTH (UPDATE)===========================

export const UPDATE_TWO_FACTOR_STATUS = "UPDATE_TWO_FACTOR_STATUS";
export const UPDATE_TWO_FACTOR_STATUS_SUCCESS =
  "UPDATE_TWO_FACTOR_STATUS_SUCCESS";
export const UPDATE_TWO_FACTOR_STATUS_FAILURE =
  "UPDATE_TWO_FACTOR_STATUS_FAILURE";

// =====================TWO FACTOR CODE VERIFICATION (VERIFY)===========================

export const VERIFY_TWO_FACTOR_CODE = "VERIFY_TWO_FACTOR_CODE";
export const VERIFY_TWO_FACTOR_CODE_SUCCESS = "VERIFY_TWO_FACTOR_CODE_SUCCESS";
export const VERIFY_TWO_FACTOR_CODE_FAILURE = "VERIFY_TWO_FACTOR_CODE_FAILURE";

// =====================GOOGLE LOGIN VALIDATION (VERIFY)===========================

export const GOOGLE_LOGIN_VALIDATION = "GOOGLE_LOGIN_VALIDATION";
export const GOOGLE_LOGIN_VALIDATION_SUCCESS =
  "GOOGLE_LOGIN_VALIDATION_SUCCESS";
export const GOOGLE_LOGIN_VALIDATION_FAILURE = "VERIFY_TWO_FACTOR_CODE_FAILURE";

// =====================GOOGLE LOGIN VALIDATION (VERIFY)===========================

export const GENERATE_QR_CODE = "GENERATE_QR_CODE";
export const GENERATE_QR_CODE_SUCCESS = "GENERATE_QR_CODE_SUCCESS";
export const GENERATE_QR_CODE_FAILURE = "GENERATE_QR_CODE_FAILURE";

// =====================GOOGLE LOGIN VALIDATION (VERIFY)===========================

export const GOOGLE_AUTHENTICATOR_OTP_VALIDATION =
  "GOOGLE_AUTHENTICATOR_OTP_VALIDATION";
export const GOOGLE_AUTHENTICATOR_OTP_VALIDATION_SUCCESS =
  "GOOGLE_AUTHENTICATOR_OTP_VALIDATION_SUCCESS";
export const GOOGLE_AUTHENTICATOR_OTP_VALIDATION_FAILURE =
  "GOOGLE_AUTHENTICATOR_OTP_VALIDATION_FAILURE";

// ================================= TYPE CODE FANCIES DATA ===========================

export const TYPECODEFANCIESDATA = "TYPECODEFANCIESDATA";
export const TYPECODEFANCIESDATASUCCESS = "TYPECODEFANCIESDATASUCCESS";
export const TYPECODEFANCIESDATAFAILURE = "TYPECODEFANCIESDATAFAILURE";

// =====================Get Sport Leagues (leages )==============================

export const GET_SPORT_LEAGUES = "GET_SPORT_LEAGUES";
export const GET_SPORT_LEAGUES_SUCCESS = "GET_SPORT_LEAGUES_SUCCESS";
export const GET_SPORT_LEAGUES_FAILURE = "GET_SPORT_LEAGUES_FAILURE";

// =====================Get Sport Data ( events)==============================

export const GET_LEAGUE_EVENTS = "GET_LEAGUE_EVENTS";
export const GET_LEAGUE_EVENTS_SUCCESS = "GET_LEAGUE_EVENTS_SUCCESS";
export const GET_LEAGUE_EVENTS_FAILURE = "GET_LEAGUE_EVENTS_FAILURE";

// =====================Get InPlay By Sport Data ( events)==============================

export const GET_INPLAY_BY_SPORT = "GET_INPLAY_BY_SPORT";
export const GET_INPLAY_BY_SPORT_SUCCESS = "GET_INPLAY_BY_SPORT_SUCCESS";
export const GET_INPLAY_BY_SPORT_FAILURE = "GET_INPLAY_BY_SPORT_FAILURE";

// ================================= SEARCH IN MARKET,LEAGUE,EVENTS ===========================

export const SEARCH_KEYWORD_REQUEST = "SEARCH_KEYWORD_REQUEST";
export const SEARCH_KEYWORD_SUCCESS = "SEARCH_KEYWORD_SUCCESS";
export const SEARCH_KEYWORD_FAILURE = "SEARCH_KEYWORD_FAILURE";

// ================================= NOTIFICATION ===========================

export const NOTIFICATION = "NOTIFICATION";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAILURE = "NOTIFICATION_FAILURE";

// =====================SPORT DATS(GET)===========================

export const GET_SPORT_DATAS = "GET_SPORT_DATAS";
export const GET_SPORT_DATAS_SUCCESS = "GET_SPORT_DATAS_SUCCESS";
export const GET_SPORT_DATAS_FAILURE = "GET_SPORT_DATAS_FAILURE";

// =====================CURRENCY - BETFAIR===========================

export const CURRENCY_BETFAIR = "CURRENCY_BETFAIR";
export const CURRENCY_BETFAIR_SUCCESS = "CURRENCY_BETFAIR_SUCCESS";
export const CURRENCY_BETFAIR_FAILURE = "CURRENCY_BETFAIR_FAILURE";
// =====================GET_USER_BALANCE_DATA===========================

export const GET_BALANCE_DATA = "GET_BALANCE_DATA";
export const GET_BALANCE_DATA_SUCCESS = "GET_BALANCE_DATA_SUCCESS";
export const GET_BALANCE_DATA_FAILURE = "GET_BALANCE_DATA_FAILURE";

// =====================Toggle sidebar===========================

export const SIDEBAR_DATA_UPDATE = "SIDEBAR_DATA_UPDATE";
export const SIDEBAR_DATA_UPDATE_SUCCESS = "SIDEBAR_DATA_UPDATE_SUCCESS";
export const SIDEBAR_DATA_UPDATE_FAILURE = "SIDEBAR_DATA_UPDATE_FAILURE";

// =====================Toggle sidebar===========================

export const GET_SINGLE_LEAGUE = "GET_SINGLE_LEAGUE";
export const GET_SINGLE_LEAGUE_SUCCESS = "GET_SINGLE_LEAGUE_SUCCESS";
export const GET_SINGLE_LEAGUE_FAILURE = "GET_SINGLE_LEAGUE_FAILURE";

// =====================GAP CASINO===========================

export const GET_GAP_CASINO_GAMES = "GET_GAP_CASINO_GAMES";
export const GET_GAP_CASINO_GAMES_SUCCESS = "GET_GAP_CASINO_GAMES_SUCCESS";
export const GET_GAP_CASINO_GAMES_FAILURE = "GET_GAP_CASINO_GAMES_FAILURE";

// =====================GAP CASINO URL===========================

export const GAP_CASINO_GAMES_URL = "GAP_CASINO_GAMES_URL";
export const GAP_CASINO_GAMES_URL_SUCCESS = "GAP_CASINO_GAMES_URL_SUCCESS";
export const GAP_CASINO_GAMES_URL_FAILURE = "GAP_CASINO_GAMES_URL_FAILURE";

// =====================Toggle sidebar===========================
export const RESET_EVENT_DATA = "RESET_EVENT_DATA";

export const SET_SELECTED_MARKET = "SET_SELECTED_MARKET";

// =====================Horse Race events===========================

export const HORSE_RACE_EVENTS = "HORSE_RACE_EVENTS";
export const HORSE_RACE_EVENTS_SUCCESS = "HORSE_RACE_EVENTS_SUCCESS";
export const HORSE_RACE_EVENTS_FAILURE = "HORSE_RACE_EVENTS_FAILURE";

//==========================HorseRacePlaceBet =========================

export const PLACE_BET_HORSERACE = "PLACE_BET_HORSERACE";
export const PLACE_BET_HORSERACE_SUCCESS = "PLACE_BET_HORSERACE_SUCCESS";
export const PLACE_BET_HORSERACE_FAILURE = "PLACE_BET_HORSERACE_FAILURE";

//========================= Horse Race settings =========================

export const HORSE_RACING_SETTINGS = "HORSE_RACING_SETTINGS";
export const HORSE_RACING_SETTINGS_SUCCESS = "HORSE_RACING_SETTINGS_SUCCESS";
export const HORSE_RACING_SETTINGS_FAILURE = "HORSE_RACING_SETTINGS_FAILURE";

//======================= Get horse race bets ==============================

export const BET_SPORT_HORSERACING = "BET_SPORT_HORSERACING";
export const BET_SPORT_HORSERACING_SUCCESS = "BET_SPORT_HORSERACING_SUCCESS";
export const BET_SPORT_HORSERACING_FAILURE = "BET_SPORT_HORSERACING_FAILURE";

//=========================Get horse Race data ==============================

export const HORSERACE_DATA = "HORSERACE_DATA";
export const HORSERACE_DATA_SUCCESS = "HORSERACE_DATA_SUCCESS";
export const HORSERACE_DATA_FAILURE = "HORSERACE_DATA_FAILURE";
//=========================Get horse Race Data Manage ==============================

export const HORSERACE_DATA_MANAGE = "HORSERACE_DATA_MANAGE";
export const HORSERACE_DATA_MANAGE_SUCCESS = "HORSERACE_DATA_MANAGE_SUCCESS";
export const HORSERACE_DATA_MANAGE_RESET = "HORSERACE_DATA_MANAGE_RESET";

//=========================Get horse Race Data Live ==============================

export const GET_HORSE_RACE_LIVE = "GET_HORSE_RACE_LIVE";
export const GET_HORSE_RACE_LIVE_SUCCESS = "GET_HORSE_RACE_LIVE_SUCCESS";
export const GET_HORSE_RACE_LIVE_FAILURE = "GET_HORSE_RACE_LIVE_FAILURE";

//========================Cancel unmatched horse race bets ======================

export const DELETE_UNMATCHED_HORSERACE_BETS =
  "DELETE_UNMATCHED_HORSERACE_BETS";
export const DELETE_UNMATCHED_HORSERACE_BETS_SUCCESS =
  "DELETE_UNMATCHED_HORSERACE_BETS_SUCCESS";
export const DELETE_UNMATCHED_HORSERACE_BETS_FAILURE =
  "DELETE_UNMATCHED_HORSERACE_BETS_FAILURE";

//===================== Horse -- countries and venue's ==============================

export const GET_HORSE_RACE_COUNTRIES_VENUES =
  "GET_HORSE_RACE_COUNTRIES_VENUES";
export const GET_HORSE_RACE_COUNTRIES_VENUES_SUCCESS =
  "GET_HORSE_RACE_COUNTRIES_VENUES_SUCCESS";
export const GET_HORSE_RACE_COUNTRIES_VENUES_FAILURE =
  "GET_HORSE_RACE_COUNTRIES_VENUES_FAILURE";

//===================== Horse --Get events -side bar ==============================

export const GET_EVENT_DATA_HORSERACE = "GET_EVENT_DATA_HORSERACE";
export const GET_EVENT_DATA_HORSERACE_SUCCESS =
  "GET_EVENT_DATA_HORSERACE_SUCCESS";
export const GET_EVENT_DATA_HORSERACE_FAILURE =
  "GET_EVENT_DATA_HORSERACE_FAILURE";
export const GET_EVENT_DATA_HORSERACE_RESET = "GET_EVENT_DATA_HORSERACE_RESET";

// =====================Market Analytics===========================
export const MARKET_ANALYTICS = "MARKET_ANALYTICS";
export const MARKET_ANALYTICS_SUCCESS = "MARKET_ANALYTICS_SUCCESS";
export const MARKET_ANALYTICS_FAILURE = "MARKET_ANALYTICS_FAILURE";



// =====================Meta Check Wallet ===========================
export const META_CHECK_WALLET = "META_CHECK_WALLET";
export const META_CHECK_WALLET_SUCCESS = "META_CHECK_WALLET_SUCCESS";
export const META_CHECK_WALLET_FAILURE = "META_CHECK_WALLET_FAILURE";


// =====================Meta Wallet create===========================
export const META_CREATE = "META_CREATE";
export const META_CREATE_SUCCESS = "META_CREATE_SUCCESS";
export const META_CREATE_FAILURE = "META_CREATE_FAILURE";


// =====================Meta Wallet Deposit===========================
export const META_DEPOSIT = "META_DEPOSIT";
export const META_DEPOSIT_SUCCESS = "META_DEPOSIT_SUCCESS";
export const META_DEPOSIT_FAILURE = "META_DEPOSIT_FAILURE";

// =====================Meta Wallet withdraw===========================
export const META_WITHDRAW = "META_WITHDRAW";
export const META_WITHDRAW_SUCCESS = "META_WITHDRAW_SUCCESS";
export const META_WITHDRAW_FAILURE = "META_WITHDRAW_FAILURE";

// =====================STX place bet===========================
export const STX_PLACE_BET = "STX_PLACE_BET";
export const STX_PLACE_BET_SUCCESS = "STX_PLACE_BET_SUCCESS";
export const STX_PLACE_BET_FAILURE = "STX_PLACE_BET_FAILURE";

// =====================STX UNMATCH DELETE BETS ===========================
export const DELETE_UNMATCH_STX_BET = "DELETE_UNMATCH_STX_BET";
export const DELETE_UNMATCH_STX_BET_SUCCESS = "DELETE_UNMATCH_STX_BET_SUCCESS";
export const DELETE_UNMATCH_STX_BET_FAILURE = "DELETE_UNMATCH_STX_BET_FAILURE";