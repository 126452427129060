import {
    GET_RESULT_TRANSACTION,
    GET_RESULT_TRANSACTION_FAILURE,
    GET_RESULT_TRANSACTION_SUCCESS,
  } from "../../actions/types";
  
  const INIT_STATE = {
    loading: false,
    data: null,
  };
  
  const getResultTransactionReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_RESULT_TRANSACTION:
        return { ...state, loading: true };
      case GET_RESULT_TRANSACTION_SUCCESS:
        return { ...state, data: action.payload, loading: false };
      case GET_RESULT_TRANSACTION_FAILURE:
        return { ...state, loading: false };
      default:
        return state;
    }
  };
  
  export default getResultTransactionReducer;
  