import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_EVENT_BETS } from "../../actions/types";
import { getEventBetsSuccess, getEventBetsFailure } from "../../actions";
import API from "../../../utils/api";

function* getEventBetsRequest(action) {
  try {
    const { data } = yield API.get(
      `api/v1/bet-sport-byMarket?marketId=${action.payload.marketId}`
    );
    if (data.meta.code === 200) {
      yield put(getEventBetsSuccess(data?.data));
      yield call(action.payload.callback(data?.data));
    } else if (data.meta.code === 401) {
      yield put(getEventBetsFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(getEventBetsFailure());
    }
  } catch (error) {
    yield put(getEventBetsFailure());
  }
}

export function* watchgetEventBetsAPI() {
  yield takeEvery(GET_EVENT_BETS, getEventBetsRequest);
}

export default function* rootSaga() {
  yield all([watchgetEventBetsAPI()]);
}
