import {
    GET_CASINO_BETS,
    GET_CASINO_BETS_SUCCESS,
    GET_CASINO_BETS_FAILURE,
  } from "../types";
  
  export const getCasinoBets = (payload) => ({
    type: GET_CASINO_BETS,
    payload
  });
  
  export const getCasinoBetsSuccess = (payload) => ({
    type: GET_CASINO_BETS_SUCCESS,
    payload,
  });
  
  export const getCasinoBetsFailure = () => ({
    type: GET_CASINO_BETS_FAILURE,
  });
  