import { all, call, put, takeEvery } from "redux-saga/effects";
import { GAP_CASINO_GAMES_URL } from "../../actions/types";
import { getGapCasinoUrlSuccess, getGapCasinoUrlFailure } from "../../actions";
import API from "../../../utils/api";

function* getGapCasinoUrlDataRequest(action) {
  try {
    console.log('acton',action)
    const { data } = yield API.post(
      `api/v1/get-games-url?gameId=${action?.payload?.gameId}&providerName=${action?.payload?.providerName}`
    );
    if (data.meta.code === 200) {
      yield put(getGapCasinoUrlSuccess(data?.data));
      yield call(action.payload.callback, data.data);
    } else {
      yield put(getGapCasinoUrlFailure());
    }
  } catch (error) {
    yield put(getGapCasinoUrlFailure());
  }
}

export function* watchgetGapCasinoUrlAPI() {
  yield takeEvery(GAP_CASINO_GAMES_URL, getGapCasinoUrlDataRequest);
}

export default function* rootSaga() {
  yield all([watchgetGapCasinoUrlAPI()]);
}
