import React from "react";

const Notification = (props) => {
  return (
    <div className=" h-10 bg-black text-white flex items-center">
      <marquee scrollamount="5" behavior="scroll">
        {props?.msg}
      </marquee>
    </div>
  );
};

export default Notification;
