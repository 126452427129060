/** @format */

import React, { useEffect, useState } from "react";
import EmptyRunners from "./EmptyRunners";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  convertCurrencyBetFair,
  formatDate,
  notifyWarning,
  convertToShortForm,
} from "../utils/helper";
import { Link, useLocation } from "react-router-dom";
import { BETTING_TYPES, MARKET_TYPES } from "../utils/constants";
import { RiArrowUpSLine } from "react-icons/ri";
import BetSlip from "./BetSlip";
import { placeBet } from "../redux/actions";
import { socket } from "../context/SocketContext";
import { toast } from "react-toastify";
import { playNotificationSound } from "../utils/helper";

const InplayCard = ({
  leagues,
  sport,
  sportId,
  sportCode,
  home,
  marketOdds,
  prevMarketOdds,
}) => {
    //console.log("market odds ", marketOdds)
  const sportSetting = useSelector(
    (state) => state?.GetSportSettingReducer?.sportSettingData
  );

  const Login = useSelector((state) => state?.Login?.login);
  const location = useLocation();
  const betFairCurrency = useSelector(
    (state) => state?.currencyBetFairReducer?.currency
  );
  const betfairShare = useSelector((state) => state?.GetUserData?.userData?.data);
  const userData = JSON.parse(localStorage.getItem("userData"));

  // console.log(prevMarketOdds, "prevMarketOdds");

  //console.log(leagues, "leagues");

  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isSubDropdownOpen, setIsSubDropdownOpen] = useState([]);
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);
  const [events, setEvents] = useState([]);

  const [isBetSlipOpen, setIsBetSlipOpen] = useState(false);
  const [slipData, setSlipData] = useState({ price: 0, type: "" });
  const [stakeAmount, setStakeAmount] = useState(null);
  const [stake, setStake] = useState(null);
  const [mobileScreen, setMobileScreen] = useState(true);

  useEffect(() => {
    // Check if the login status is stored in localStorage
    const storedLoggedIn = localStorage.getItem("token");
    if (storedLoggedIn) {
      setLoggedIn(true);
    } else if (Login?.data?.twoFactorEnabled) {
      setLoggedIn(true);
    }
    // else if(Login.)
  }, [dispatch, Login]);

  useEffect(() => {
    setEvents(leagues);
  }, [leagues]);

  const handleSubDropDown = (index) => {
    setIsSubDropdownOpen((prev) => {
      if (prev.includes(index)) {
        // If it is, remove it
        return prev.filter((item) => item !== index);
      } else {
        // If it is not, add it
        return [...prev, index];
      }
    });
  };

  const handleOddsClick = (
    e,
    liveRunnerData,
    type,
    market,
    leagueId,
    eventId
  ) => {
    e.preventDefault();
    //console.log(market, "market");
    // console.log(liveRunnerData, "liveRunnerData");

    // console.log("loggedIn",loggedIn)
    if (!loggedIn) {
      notifyWarning("Please Login Before Bet");
    } else {
      const selectionId = liveRunnerData?.selectionId;
      const runner = market?.runners?.find(
        (runner) => runner?.selectionId == selectionId
      );
      console.log(liveRunnerData, "liveRunnerData");
      let price;
      if (type === "back") {
        price = liveRunnerData?.ex?.availableToBack[0]
          ? liveRunnerData?.ex?.availableToBack[0]?.price
          : 0;
      } else {
        price = liveRunnerData?.ex?.availableToLay[0]
          ? liveRunnerData?.ex?.availableToLay[0]?.price
          : 0;
      }
      handleClearClick();
      if (price > 0) {
        setIsBetSlipOpen(true);
        setSlipData({
          price: price,
          type: type,
          selectionId: selectionId,
          marketId: market?._id,
          selectionName: runner?.runnerName,
          marketType: market?.type,
          marketCode: market?.marketId,
          leagueId: leagueId,
          eventId: eventId,
          marketSlugName: market?.slugName,
        });
      }
    }
  };

  // To Clear BetSlip
  const handleClearClick = () => {
    setStake(0);
    setStakeAmount(null);
  };

  //To close or cancel betSlip
  const handleBetSlipClose = () => {
    handleClearClick();
    setSlipData({ price: 0, type: "" });
    setIsBetSlipOpen(false);
  };

  //To place the Bet
  const handlePlaceBet = () => {
    if (localStorage?.getItem("token")) {
      if (
        sportSetting?.stakeSize === undefined &&
        sportSetting?.stakeSize?.length === 0
      ) {
        toast.warn("bet place not allowed at this moment");
        return;
      }

      const data = {
        eventId: slipData?.eventId,
        sportId: sportId,
        stake: Math.abs(stake),
        selectionType: slipData?.type,
        odds: slipData?.price,
        marketId: slipData?.marketId,
        selection: slipData?.selectionName,
        marketType: slipData?.marketType,
        leagueId: slipData?.leagueId,
        selectionId: slipData?.selectionId,
        marketName: slipData?.marketSlugName,
        bettingType: BETTING_TYPES.ODDS,
      };
      //selection, leagueId
      //  console.log(data, "data...9");

      dispatch(
        placeBet({
          data,
          callback: (data) => {
            //console.log(data, "data-res");
            if (data?.meta?.code === 200) {
              handleBetSlipClose();
              socket.emit("place-bet", data);
              playNotificationSound();
            }
          },
        })
      );
    } else {
      notifyWarning("Please Login Before Bet");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setMobileScreen(window.innerWidth <= 450);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // console.log(prevMarketOdds, "prevMarketOdds");

  return (
    <>
      {home && events?.length !== 0 && (
        <div className="flex justify-between mt-1">
          <p
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="text-skin-white text-lg font-semibold cursor-pointer flex"
          >
            Live {sport}
            {isDropdownOpen ? (
              <RiArrowUpSLine className="ml-2 text-xl m-auto" />
            ) : (
              <IoIosArrowDown className="ml-2 text-lg  m-auto" />
            )}
          </p>
        </div>
      )}
      {isDropdownOpen && events?.length !== 0 ? (
        events?.map((league, index) => {
          //console.log(league, "league");
          return (
            <div
              className="rounded-md w-full bg-skin-nav drop-shadow-sm"
              key={index}
            >
              <div
                onClick={() => handleSubDropDown(league?._id)}
                className="flex w-full items-center bg-skin-containerBg rounded-t-md justify-between h-[40px] px-4 cursor-pointer "
              >
                <div className="flex items-center ">
                  <div className="flex items-center text-sm text-skin-containerText  font-medium ">
                    {league?.name}
                  </div>
                </div>
                <div className="flex items-center space-x-8 px-1">
                  <div className="flex text-sm font-bold text-skin-containerText">
                    {league?.events?.length}
                    {!isSubDropdownOpen.includes(league?._id) ||
                    isSubDropdownOpen?.length === 0 ? (
                      <IoIosArrowUp className="ml-2 text-lg  m-auto" />
                    ) : (
                      <IoIosArrowDown className="ml-2 text-lg   m-auto" />
                    )}
                  </div>
                </div>
              </div>
              {(!isSubDropdownOpen.includes(league?._id) ||
                isSubDropdownOpen?.length === 0) && (
                <>
                  {league?.events?.length !== 0 ? (
                    league?.events?.map((event, index) => {
                      //console.log(event, "event");
                      const date = formatDate(
                        event?.markets[0]?.marketStartTime
                      );

                      var exist;
                      var prevExist;
                      const matchOddsId = event?.markets[0]?.marketId;

                      //setting current Market
                      let currentMarket = event?.markets[0];
                      //console.log(matchOddsId, "matchOdds");
                      if (matchOddsId) {
                        exist = marketOdds?.find(
                          (obj) => obj?.marketId === matchOddsId
                        );
                        prevExist = prevMarketOdds?.find(
                          (obj) => obj?.marketId === matchOddsId
                        );
                        // console.log(exist, "exist");
                        // console.log(prevExist,"prevExist")
                      }
                      let encodedDynamicPart = encodeURIComponent(
                        event?.name?.toLowerCase()?.replace(/\s+/g, "-")
                      );
                      return (
                        <>
                          <Link
                            key={index}
                            to={`/${sport}/${event?.id}/${encodedDynamicPart}`}
                          >
                            <div
                              className={`flex ${
                                mobileScreen ? "flex-col" : "flex-row"
                              } justify-between gap-1 px-1 mb-[2px] border-b-2 border-mainbg 2xl:justify-center`}
                            >
                              {mobileScreen ? (
                                <>
                                  <div className="pl-3 pt-2 w-full flex justify-between text-sm text-skin-primary">
                                    <div>
                                      <span className="text-skin-primary text-md pt-2 font-semi-bold m-1 mb-0">
                                        {event?.name?.includes("@")
                                          ? event?.name?.split(" @ ")[0]?.trim()
                                          : event?.name
                                              ?.split(" v ")[0]
                                              ?.trim()}
                                      </span>
                                      &nbsp;
                                      <span className="font-bold text-sm p-0.5 rounded-lg ">
                                        vs
                                      </span>
                                      &nbsp;
                                      <span className="text-skin-primary text-md pt-2 font-semi-bold m-1 mb-0">
                                        {event?.name?.includes("@")
                                          ? event?.name?.split(" @ ")[1]?.trim()
                                          : event?.name
                                              ?.split(" v ")[1]
                                              ?.trim()}
                                      </span>
                                    </div>

                                    <div className="items-center inline justify-end">
                                      <span className="text-skin-liveIconText bg-skin-liveContainerBg p-0.5 mr-2 rounded-sm text-xs font-semibold">
                                        Live
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="flex items-center flex-1  cursor-pointer select-none">
                                  <div className="flex ml-1 flex-col items-center justify-center w-10 h-10 ">
                                    <div
                                      className="rounded-full relative block "
                                    >
                                      <img
                                        alt="profil"
                                        src={require(`../assets/images/sidemenu/${sport}.png`)}
                                        className="mx-auto object-contain h-8 w-8   p-1.5"
                                      />
                                    </div>
                                  </div>

                                  <div className="flex pl-3 justify-between gap-3 w-full">
                                    <div className="min-w-6">
                                      <div className=" font-medium  text-skin-primary  text-sm min-w-10">
                                        {event?.name?.includes("@")
                                          ? event?.name?.split(" @ ")[0]?.trim()
                                          : event?.name
                                              ?.split(" v ")[0]
                                              ?.trim()}
                                        &nbsp;
                                        <span className="font-bold text-sm p-1 rounded-lg ">
                                          vs
                                        </span>
                                        &nbsp;
                                        {event?.name?.includes("@")
                                          ? event?.name?.split(" @ ")[1]?.trim()
                                          : event?.name
                                              ?.split(" v ")[1]
                                              ?.trim()}
                                      </div>
                                    </div>

                                    <div className="flex justify-center items-center gap-1">
                                      <div className="text-skin-liveIconText bg-skin-liveContainerBg px-2 py-1 rounded-sm text-xs font-semibold">
                                        Live
                                      </div>
                                      {/* <div className="text-[12px] mr-2 font-bold  text-skin-primary ">
                                        <div>
                                          {mobileScreen === false ? (
                                            <div className="flex justify-end mt-1">
                                              <div className="mx-2 ml-4 px-2 h-[14px] text-center rounded-sm text-[10px] bg-skin-cardsmall text-skin-dark">
                                                F 4
                                              </div>
                                              <div className="px-1 h-[14px] text-center rounded-sm text-[10px] bg-skin-cardsmall text-skin-dark">
                                                B
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* <div className="flex  mt-2 md:mt-0  md:w-[40%] lg:w-[100%] xl:w-[32%]"> */}
                              <div className="flex 2xl:w-[25%]">
                                {exist &&
                                Object.keys(Object.values(exist)[0]).length !==
                                  0 ? (
                                  <>
                                    {/* first runner */}
                                    <div className="inline-block flex-1">
                                      <div className="flex drop-shadow-sm rounded-sm">
                                        {/* FIRST runner */}
                                        <div
                                          className={`
                                          ${
                                            prevExist?.runners[0]?.ex
                                              ?.availableToBack[0]?.price !==
                                            exist?.runners[0]?.ex
                                              ?.availableToBack[0]?.price
                                              ? "animate-odds"
                                              : ""
                                          }
                                           ${
                                             mobileScreen
                                               ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                               : "w-[50px] py-1.5 h-12 rounded-md 2xl:w-[50%]"
                                           } m-1 flex flex-col justify-center text-md 
                                          font-bold text-skin-blue  bg-skin-oddsCardBlue  cursor-pointer h-10`}
                                          onClick={(e) =>
                                            handleOddsClick(
                                              e,
                                              exist?.runners[0],
                                              "back",
                                              currentMarket,
                                              league?._id,
                                              event?._id
                                            )
                                          }
                                        >
                                          <p className="m-auto  text-skin-marketCardTextBlue">
                                            {exist?.runners[0]?.ex
                                              ?.availableToBack[0]?.price
                                              ? exist?.runners[0]?.ex
                                                  ?.availableToBack[0]?.price
                                              : "-"}
                                          </p>
                                          <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText">
                                            {exist?.runners[0]?.ex
                                              ?.availableToBack[0]?.size
                                              ? convertToShortForm( convertCurrencyBetFair(exist?.runners[0]?.ex
                                                ?.availableToBack[0]?.size,  parseFloat(
                                                  betFairCurrency?.value) ,  parseFloat(
                                                    userData?.currency.value
                                                  ),
                                                  parseFloat(betfairShare?.betFairShare)
                                                )
                                              )
                                              : "-"}
                                          </p>
                                        </div>
                                        <div
                                          className={` ${
                                            mobileScreen
                                              ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                              : "w-[50px] h-12 py-1.5 2xl:w-[50%] "
                                          } rounded-md m-1 flex flex-col justify-center text-md font-bold text-skin-pink  bg-skin-oddsCardPink   cursor-pointer
                                          ${
                                            prevExist?.runners[0]?.ex
                                              ?.availableToLay[0]?.price !==
                                            exist?.runners[0]?.ex
                                              ?.availableToLay[0]?.price
                                              ? "animate-lay"
                                              : ""
                                          }`}
                                          onClick={(e) =>
                                            handleOddsClick(
                                              e,
                                              exist?.runners[0],
                                              "lay",
                                              currentMarket,
                                              league?._id,
                                              event?._id
                                            )
                                          }
                                        >
                                          <p className="m-auto  text-skin-marketCardTextPink">
                                            {exist?.runners[0]?.ex
                                              ?.availableToLay[0]?.price
                                              ? exist?.runners[0]?.ex
                                                  ?.availableToLay[0]?.price
                                              : "-"}
                                          </p>
                                          <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText">
                                            {exist?.runners[0]?.ex
                                              ?.availableToLay[0]?.size
                                              ?  convertToShortForm( convertCurrencyBetFair(exist?.runners[0]?.ex
                                                ?.availableToLay[0]?.size,  parseFloat(
                                                  betFairCurrency?.value) ,  parseFloat(
                                                    userData?.currency.value
                                                  ),
                                                  parseFloat(betfairShare?.betFairShare)
                                                )
                                              )
                                              : "-"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {/* middle runner */}
                                    <div className="inline-block flex-1  ">
                                      <div className="flex drop-shadow-sm rounded-sm">
                                        <div
                                          className={` ${
                                            mobileScreen
                                              ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                              : "w-[50px] h-12  py-1.5 2xl:w-[50%]"
                                          } rounded-md m-1 flex flex-col justify-center text-md font-bold text-skin-blue  bg-skin-oddsCardBlue   cursor-pointer
                                          ${
                                            prevExist?.runners[2]?.ex
                                              ?.availableToBack[0]?.price !==
                                            exist?.runners[2]?.ex
                                              ?.availableToBack[0]?.price
                                              ? "animate-odds"
                                              : ""
                                          }
                                          `}
                                          onClick={(e) =>
                                            handleOddsClick(
                                              e,
                                              exist?.runners[2],
                                              "back",
                                              currentMarket,
                                              league?._id,
                                              event?._id
                                            )
                                          }
                                        >
                                          <p className="m-auto  text-skin-marketCardTextBlue">
                                            {exist?.runners[2]?.ex
                                              ?.availableToBack[0]?.price
                                              ? exist?.runners[2]?.ex
                                                  ?.availableToBack[0]?.price
                                              : "-"}
                                          </p>
                                          <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText">
                                            {exist?.runners[2]?.ex
                                              ?.availableToBack[0]?.size
                                              ? convertToShortForm( convertCurrencyBetFair( exist?.runners[2]?.ex
                                                ?.availableToBack[0]?.size,  parseFloat(
                                                  betFairCurrency?.value) ,  parseFloat(
                                                    userData?.currency.value
                                                  ),
                                                  parseFloat(betfairShare?.betFairShare)
                                                ))
                                              : "-"}
                                          </p>
                                        </div>
                                        <div
                                          className={` ${
                                            mobileScreen
                                              ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                              : "w-[50px] h-12  py-1.5 2xl:w-[50%]"
                                          } rounded-md m-1 flex flex-col justify-center text-md font-bold text-skin-pink  bg-skin-oddsCardPink   cursor-pointer
                                          ${
                                            prevExist?.runners[2]?.ex
                                              ?.availableToLay[0]?.price !==
                                            exist?.runners[2]?.ex
                                              ?.availableToLay[0]?.price
                                              ? "animate-lay"
                                              : ""
                                          }
                                          `}
                                          onClick={(e) =>
                                            handleOddsClick(
                                              e,
                                              exist?.runners[2],
                                              "lay",
                                              currentMarket,
                                              league?._id,
                                              event?._id
                                            )
                                          }
                                        >
                                          <p className="m-auto  text-skin-marketCardTextPink">
                                            {exist?.runners[2]?.ex
                                              ?.availableToLay[0]?.price
                                              ? exist?.runners[2]?.ex
                                                  ?.availableToLay[0]?.price
                                              : "-"}
                                          </p>
                                          <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText">
                                            {exist?.runners[2]?.ex
                                              ?.availableToLay[0]?.size
                                              ?
                                                convertToShortForm( convertCurrencyBetFair( exist?.runners[2]?.ex
                                                  ?.availableToLay[0]?.size,  parseFloat(
                                                    betFairCurrency?.value) ,  parseFloat(
                                                      userData?.currency.value
                                                    ),
                                                    parseFloat(betfairShare?.betFairShare)
                                                  ))
                                                
                                              : "-"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* last runner */}
                                    <div className="inline-block flex-1  ">
                                      <div className="flex drop-shadow-sm rounded-sm ">
                                        <div
                                          className={` ${
                                            mobileScreen
                                              ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                              : "w-[50px] h-12  py-1.5 2xl:w-[50%]"
                                          } rounded-md m-1 flex flex-col justify-center text-md font-bold text-skin-blue  bg-skin-oddsCardBlue   cursor-pointer
                                         ${
                                           prevExist?.runners[1]?.ex
                                             ?.availableToBack[0]?.price !==
                                           exist?.runners[1]?.ex
                                             ?.availableToBack[0]?.price
                                             ? "animate-odds"
                                             : ""
                                         }
                                         `}
                                          onClick={(e) =>
                                            handleOddsClick(
                                              e,
                                              exist?.runners[1],
                                              "back",
                                              currentMarket,
                                              league?._id,
                                              event?._id
                                            )
                                          }
                                        >
                                          <p className="m-auto  text-skin-marketCardTextBlue">
                                            {exist?.runners[1]?.ex
                                              ?.availableToBack[0]?.price
                                              ? exist?.runners[1]?.ex
                                                  ?.availableToBack[0]?.price
                                              : "-"}
                                          </p>
                                          <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText">
                                            {exist?.runners[1]?.ex
                                              ?.availableToBack[0]?.size
                                              ? 
                                                convertToShortForm( convertCurrencyBetFair( exist?.runners[1]?.ex
                                                  ?.availableToBack[0]?.size,  parseFloat(
                                                    betFairCurrency?.value) ,  parseFloat(
                                                      userData?.currency.value
                                                    ),
                                                    parseFloat(betfairShare?.betFairShare)
                                                  ))
                                              : "-"}
                                          </p>
                                        </div>
                                        <div
                                          className={` ${
                                            mobileScreen
                                              ? " min-w-[45%] h-10 py-1 rounded-md my-1 mx-0.5"
                                              : "w-[50px] h-12  py-1.5 2xl:w-[50%]"
                                          } rounded-md m-1 flex flex-col justify-center text-md font-bold text-skin-pink  bg-skin-oddsCardPink   cursor-pointer
                                         ${
                                           prevExist?.runners[1]?.ex
                                             ?.availableToLay[0]?.price !==
                                           exist?.runners[1]?.ex
                                             ?.availableToLay[0]?.price
                                             ? "animate-lay"
                                             : ""
                                         }
                                         `}
                                          onClick={(e) =>
                                            handleOddsClick(
                                              e,
                                              exist?.runners[1],
                                              "lay",
                                              currentMarket,
                                              league?._id,
                                              event?._id
                                            )
                                          }
                                        >
                                          <p className="m-auto  text-skin-marketCardTextPink">
                                            {exist?.runners[1]?.ex
                                              ?.availableToLay[0]?.price
                                              ? exist?.runners[1]?.ex
                                                  ?.availableToLay[0]?.price
                                              : "-"}
                                          </p>
                                          <p className="text-center text-[10px] brightness-50 text-skin-oddsSizeText">
                                            {exist?.runners[1]?.ex
                                              ?.availableToLay[0]?.size
                                              ?
                                                convertToShortForm( convertCurrencyBetFair( exist?.runners[1]?.ex
                                                  ?.availableToLay[0]?.size,  parseFloat(
                                                    betFairCurrency?.value) ,  parseFloat(
                                                      userData?.currency.value
                                                    ),
                                                    parseFloat(betfairShare?.betFairShare)
                                                  ))
                                              : "-"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <EmptyRunners isMobile={mobileScreen} />
                                )}
                              </div>
                            </div>
                            {/* )} */}
                          </Link>
                          {currentMarket?._id === slipData?.marketId &&
                          isBetSlipOpen ? (
                            <BetSlip
                              stakeLimit={
                                sportSetting?.stakeSize !== undefined &&
                                sportSetting?.stakeSize?.length > 0
                                  ? sportSetting?.stakeSize[0]?.maxExch
                                  : 0
                              }
                              closeBetslip={handleBetSlipClose}
                              slipData={slipData}
                              setSlipData={setSlipData}
                              setStakeAmount={setStakeAmount}
                              stake={stake}
                              setStake={setStake}
                              clearBetSlip={handleClearClick}
                              marketType={MARKET_TYPES.EXCH}
                              handlePlaceBet={handlePlaceBet}
                              uncheck={true}
                              isIncrementDecrement={true} // to avoid sport setting as of now
                              bettingType={BETTING_TYPES.ODDS}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })
                  ) : (
                    <>
                      {!home && (
                        <div className="mt-2 p-2 text-sm rounded-md w-full bg-skin-nav text-skin-FontSecondaryColor font-semibold ">
                          There is a no match available at this time in
                          <span className="text-skin-textPrimaryColor ml-2">
                            InPlay.
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          );
        })
      ) : (
        <>
          {!home && (
            <div className="mt-2 p-2 text-sm rounded-md w-full bg-skin-nav text-skin-FontSecondaryColor font-semibold ">
              There is a no match available at this time in
              <span className="text-skin-textPrimaryColor ml-2">InPlay.</span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InplayCard;
