import {
  GET_LEAGUE_EVENTS,
  GET_LEAGUE_EVENTS_SUCCESS,
  GET_LEAGUE_EVENTS_FAILURE,
} from "../types";

export const getLeagueEvent = (payload) => ({
  type: GET_LEAGUE_EVENTS,
  payload,
});

export const getLeagueEventSuccess = (payload) => ({
  type: GET_LEAGUE_EVENTS_SUCCESS,
  payload,
});

export const getLeagueEventFailure = () => ({
  type: GET_LEAGUE_EVENTS_FAILURE,
});
