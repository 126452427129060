import React from "react";
import liveCasino from "../assets/liveCasino.json";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { createSession, getGapCasinoUrl } from "../redux/actions";
import { notifyWarning } from "../utils/helper";
import { Link } from "react-router-dom";

const LiveCasinoSlider = () => {
  let getGapCasino = useSelector((state) => state?.GetGapCasinoDataReducer.data);
  // console.log("getGapCasino",getGapCasino);

  // const filteredCasino = liveCasino.filter((item) => item.producer === "ezugi");
  const otherCarousel = {
    infinite: true,
    speed: 400,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const dispatch = useDispatch();

  // const handleCasinoPlay = (data) => {
  //   let payload = {
  //     game_id: data,
  //     game_code: data,
  //   };
  //   if (localStorage?.getItem("token")) {
  //     dispatch(
  //       createSession({
  //         payload,
  //         callback: (data) => {
  //           if (data) {
  //             window.open(data?.data?.url, "_blank");
  //           }
  //         },
  //       })
  //     );
  //   } else {
  //     notifyWarning("Please login before bet");
  //   }
  // };

  const handleCasinoPlay = (gameId, providerName) => {
    if (localStorage?.getItem("token")) {
      dispatch(
        getGapCasinoUrl({
          gameId, providerName,
          callback: (data) => {
            if (data) {
              if (data?.reason === "Incorrect signature") {
                notifyWarning("Something went wrong");
              } else {
                // setGameData(data)
                window.open(data?.url, "_blank");
              }
            }
          },
        })
      );
    } else {
      notifyWarning("Please Login to PLay");
    }
  };

  return (
    <div>
      <div className="flex justify-between mt-1">
        <p className="text-skin-white pb-2 px-2 text-lg font-semibold">
          Live Casino
        </p>
        <Link to={"/casino"}>
          <button className=" text-skin-primary hover:bg-skin-hovercolor text-sm bg-skin-nav px-2 p-2 mb-2  rounded-md font-semibold">
            All Live Casino
          </button>
        </Link>
      </div>

      <Slider {...otherCarousel}>
        {getGapCasino && getGapCasino?.royalgames?.slice(0, 50)?.map((element, index) => (
          <div
            className="relative group px-1 text-white text-sm font-semibold flex flex-col items-center justify-center h-full"
            key={index}
          >
            <div className="absolute rounded-md w-full h-[16rem] opacity-0 top-0 right-0 bottom-0 left-0 bg-[#000] bg-opacity-75 group-hover:opacity-100 content-overlay transition-all duration-300 ease-in-out"></div>
            <img
              className="block w-full object-fill rounded-lg opacity-100 h-[16rem]"  // Uniform height
              src={element.urlThumb}
              alt="gallery"
            />
            <span className="flex justify-center py-3 text-skin-white">
              {element.name}
            </span>

            <div className="group-hover:opacity-100 transition-opacity duration-500 ease-in-out opacity-0">
              <div
                onClick={() => handleCasinoPlay(element?.gameId, element?.providerName)}
                className="content-details absolute text-center top-14 md:top-[39%] left-7 md:left-[34%] -translate-y-[-100%] group-hover:-translate-y-0 transition-all duration-500 ease-in-out"
              >
                <span className="content-title bg-skin-SidebarPrimaryColor  text-skin-textPrimaryColor px-4 py-1 font-semibold rounded-full cursor-pointer uppercase">
                  Play
                </span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>

  );
};

export default LiveCasinoSlider;
