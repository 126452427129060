import {
    HORSE_RACING_SETTINGS,
    HORSE_RACING_SETTINGS_SUCCESS,
    HORSE_RACING_SETTINGS_FAILURE
} from "../../actions/types";

const INIT_STATE = {
    loading: false,
    data: null,
};

const getHorseRacingSettingsDataReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case HORSE_RACING_SETTINGS:
            return { ...state, loading: true };
        case HORSE_RACING_SETTINGS_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case HORSE_RACING_SETTINGS_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default getHorseRacingSettingsDataReducer;
