//this is for fetching Events from external Api
import {
  GET_EVENT_EXCH,
  GET_EVENT_EXCH_SUCCESS,
  GET_EVENT_EXCH_FAILURE,
} from "../types";
export const getEventExch = (payload) => ({
  type: GET_EVENT_EXCH,
  payload,
});

export const getEventExchSuccess = (payload) => ({
  type: GET_EVENT_EXCH_SUCCESS,
  payload,
});

export const getEventExchFailure = () => ({
  type: GET_EVENT_EXCH_FAILURE,
});
