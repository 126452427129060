import {
    HORSE_RACE_EVENTS,
    HORSE_RACE_EVENTS_FAILURE,
    HORSE_RACE_EVENTS_SUCCESS
} from "../../actions/types";

const INIT_STATE = {
    loading: false,
    data: null,
};

const getHorseRacingDataReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case HORSE_RACE_EVENTS:
            return { ...state, loading: true };
        case HORSE_RACE_EVENTS_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case HORSE_RACE_EVENTS_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default getHorseRacingDataReducer;
