import {
  GET_SPORT_LEAGUES,
  GET_SPORT_LEAGUES_SUCCESS,
  GET_SPORT_LEAGUES_FAILURE,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  leagues: null,
};

const getSportLeaguesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SPORT_LEAGUES:
      return { ...state, loading: true };
    case GET_SPORT_LEAGUES_SUCCESS:
      return { ...state, leagues: action.payload, loading: false };
    case GET_SPORT_LEAGUES_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default getSportLeaguesReducer;
