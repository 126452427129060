import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_SPORT_LEAGUES } from "../../actions/types";
import {
  getSportLeaguesSuccess,
  getSportLeaguesFailure,
} from "./../../actions";
import API from "../../../utils/api";

function* getSportLeaguesRequest(action) {
  try {
    let response;
    const { data } = yield API.get(
      `/api/v1/sport-leagues?sportId=${action?.payload?.sportId}`
    );
    response = data;

    if (response.meta.code === 200) {
      yield put(getSportLeaguesSuccess(response));
      yield call(action.payload.callback, response.data);
    } else if (response.meta.code === 401) {
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
      yield put(getSportLeaguesFailure());
      yield call(action?.payload?.errorCallback);
    } else if (response.meta.code !== 200) {
      yield put(getSportLeaguesFailure());
      yield call(action?.payload?.errorCallback);
    }
  } catch (error) {
    yield put(getSportLeaguesFailure());
    yield call(action?.payload?.errorCallback);
  }
}

export function* watchGetSportLeaguesAPI() {
  yield takeEvery(GET_SPORT_LEAGUES, getSportLeaguesRequest);
}

export default function* rootSaga() {
  yield all([watchGetSportLeaguesAPI()]);
}
