import { all, call, put, takeEvery } from "redux-saga/effects";
import {  META_WITHDRAW } from "../../actions/types";
import { metaWithdrawSuccess, metaWithdrawFailure } from "../../actions/metaWallet/metaWithdrawAction";
import API from "../../../utils/api";
import {notifyWarning } from "../../../utils/helper";


function* withdrawMetaWallet(action) {
  try {
      const { data } = yield API.post(
        "/api/v1/moneyplant-wallet-transfer",
        action.payload
      );
    if (data.code!==400) {
      yield put(metaWithdrawSuccess(true));
      yield call(action.payload.callback, data);
      
    } 
    else{ 
      yield put(metaWithdrawFailure());
      yield call(action.payload.callback, data);
    }
  } catch (error) {
    console.log(error);
    notifyWarning("Couldn't initiate Wallet Withdrawal");
    yield put(metaWithdrawFailure());
  }
}

export function* watchWithdrawWalletAPI() {
  yield takeEvery(META_WITHDRAW, withdrawMetaWallet);
}

export default function* rootSaga() {
  yield all([watchWithdrawWalletAPI()]);
}