import React, { startTransition, useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  marketPriority,
  formatDate,
} from "../utils/helper";
import Search from "./Search";
import {
  getLeagueEvent,
  getSportLeagues,
  searchKeyword,
  setSelectedMarket,
  getCountriesVenuesHorseRace,
  getHorseRaceEvents,
} from "../redux/actions";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { FaAngleDoubleRight } from "react-icons/fa";
import api from "../utils/oddApi";

const SideNavbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let allSportsData = useSelector(
    (state) => state?.GlobalSportData?.globalSportData
  );
  let eventObj = useSelector(
    (state) => state?.GetEventData?.data?.data?.eventData
  );
  const horseRaceObj = useSelector((state) => state)
    ?.getEventDataHorseRaceReducer?.data?.data?.eventData;
  let eventObjCodes = useSelector(
    (state) => state?.GetEventData?.data?.data?.codes
  );
  const horseRaceEvent = useSelector(
    (state) => state?.GetEventData?.data?.data
  );
  const prevEventObjRef = useRef();
  // console.log(allSportsData, "allSportsData");

  const [loggedIn, setLoggedIn] = useState(false);

  const [activeIndex, setActiveIndex] = useState(null);
  const [dropdownIndex, setdropdownIndex] = useState(null);
  const [SearchOn, setSearchOn] = useState(false);
  const [searchTerm, setSearchTerm] = useState({ search: "" });
  const [sortedSportsArray, setsortedSportsArray] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [events, setEvents] = useState([]);

  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searchResult, setSearchResult] = useState([]);
  const [searchData, setSearchData] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLeftSidebarVisible, setIsLeftSidebarVisible] = useState(true);

  const [sidebarEventData, setSidebarEventData] = useState([]);
  const [marketOdds, setMarketOdds] = useState([]);

  const prevSearchLength = useRef(0);
  const [countriesVenues, setCountriesVenues] = useState([]);
  const [venueSelected, setVeneueSelected] = useState([]);
  const [selectTime, setSelectTime] = useState(null);
  const [horseRaceEventData, setHorseRaceEventData] = useState([]);
  const [dropDownHorseMarket, setDropDownHorseMarket] = useState(false);
  const [indexMarketHorseRace, setIndexMarketHorseRace] = useState(null);
  const [selectMarket, setSelectMarket] = useState(null);

  useEffect(() => {
    setSearchData(allSportsData);
  }, [allSportsData]);

  useEffect(() => {
    // Check if the login status is stored in localStorage
    const storedLoggedIn = localStorage.getItem("token");
    if (storedLoggedIn) {
      setLoggedIn(true);
    }
  }, [dispatch, loggedIn]);

  const handleSportClick = (sportId) => {
    setSearchTerm({ search: "" });
    setSearchResult([]);
    if (activeIndex !== sportId) {
      //console.log("called--=");
      dispatch(
        getSportLeagues({
          sportId,
          callback: (data) => {
            //console.log(data);
            setLeagues(data?.leagues);
          },
        })
      );
    }

    setActiveIndex((prevIndex) => (prevIndex === sportId ? null : sportId));
  };

  // horse racing changes
  const handleSportClickHorseRace = (sportId) => {

    setActiveIndex((prevIndex) => (prevIndex === sportId ? null : sportId));
  };
  const handleLeagueClick = (leagueId) => {
    if (dropdownIndex !== leagueId) {
      dispatch(
        getLeagueEvent({
          leagueId,
          callback: (data) => {
            setEvents(data?.events);
          },
        })
      );
    }
    setdropdownIndex(dropdownIndex === leagueId ? null : leagueId);
  };

  function getCountriesAndVenue() {
    dispatch(
      getHorseRaceEvents({
        callback: (data) => {
          setCountriesVenues(data);
        },
      })
    );
  }
  useEffect(() => {
    setsortedSportsArray(allSportsData);
  }, [allSportsData]);
  

  useEffect(() => {
    if (searchTerm.search === "") {
      setsortedSportsArray(allSportsData);
      setSearchResult([]);
    } else {
      setdropdownIndex(null);
      setActiveIndex(null);
      const currentLength = searchTerm.search.length;
      const isBackspace = currentLength < prevSearchLength.current;

      if (!isBackspace && currentLength >= 3) {
        clearTimeout(typingTimeout);
        const timeout = setTimeout(() => {
          dispatch(
            searchKeyword({
              data: searchTerm.search,
              callback: (data) => {
                if (data.length > 0) {
                  setSearchResult(data);
                } else {
                  const notFound = [
                    {
                      name: "No Result Found",
                      slugName: "No Result Found",
                      id: "No Result Found",
                    },
                  ];
                  setSearchResult(notFound);
                }
              },
            })
          );
        }, 1000);
        setTypingTimeout(timeout);
      } else if (currentLength < 3) {
        setSearchResult([]);
      }

      // Update previous search term length
      prevSearchLength.current = currentLength;
    }
  }, [searchTerm]);

  const handleClose = () => {
    try {
      console.log("handleClose");
      setSearchOn(false);
      setSearchTerm({ search: "" });
      // const data = sortSports();
      // setsortedSportsArray(data);
      setSearchResult(false);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsLeftSidebarVisible(!isLeftSidebarVisible);
  };

  // useEffect(() => {
  //   if (selectedSport !== null) {
  //     startTransition(() => {
  //       dispatch(
  //         getSportDatas({
  //           selectedSport,
  //           callback: (data) => {
  //             //console.log(data, "res dtaa");
  //             if (data !== null) {
  //               setIsloading(false);
  //               setSportData(data?.sportData);
  //               const marketCodes = data?.marketCodes;
  //               setMarketCodes(marketCodes);
  //             }
  //           },
  //         })
  //       );
  //     });
  //   }
  // }, [selectedSport]);

  const navigate = useNavigate();

  const onSportClick = (index, sportCode) => {
    navigate("/all-sports", { state: { sportCode, index } });
  };

  function hasAnyKeyAndNonNullValue(obj) {
    if (typeof obj !== "object" || obj === null) return false;
    return Object.values(obj).some(
      (value) => value !== null && value !== undefined
    );
  }
  function findSportByName(sportsArray, sportName) {
    let sportNameToFind = sportName?.toLowerCase();
    return sportsArray.find(
      (sport) => sport?.name?.toLowerCase() == sportNameToFind
    );
  }

  useEffect(() => {
    if (eventObj === undefined) {
      setSidebarEventData(null);
    } else {
      const marketsToAvoid = ["MINI BOOKMAKER","BetXFair odds"];
      const filteredMarkets = eventObj?.markets?.filter(
        (market) => !marketsToAvoid.includes(market.marketName)
      );
      let updatedEventObj = { ...eventObj, markets: filteredMarkets };

      // Sort the filtered markets by priority
      updatedEventObj?.markets?.sort((a, b) => {
        const priorityA = marketPriority[a.marketName] || 9999;
        const priorityB = marketPriority[b.marketName] || 9999;
        return priorityA - priorityB;
      });

      setSidebarEventData(updatedEventObj);
      setMarketOdds(eventObjCodes);
      setActiveIndex(eventObj?.sportCode);
    }
  }, [eventObj]);
  useEffect(() => {
    if (horseRaceObj) {
      setSidebarEventData(horseRaceObj);
    }
  }, [horseRaceObj]);
  useEffect(() => {
    const updatedEventObj = { ...eventObj, markets: horseRaceEvent };
    setHorseRaceEventData(updatedEventObj);
    setActiveIndex(updatedEventObj?.sportCode);
  }, [horseRaceEvent]);

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const handleSelectMarket = (market, index) => {
    let data = {
      market,
      index,
    };
    dispatch(setSelectedMarket(data));
  };
  function handleCountryClick(country_id) {
    try {
      const veneues = countriesVenues?.countries?.filter(
        (country) => country?._id === country_id
      );
      setVeneueSelected(veneues);
      setdropdownIndex(dropdownIndex === country_id ? null : country_id);
    } catch (err) {
      console.log(err)
      console.log("Error in finding veneues");
    }
  }
  //horse racing changes
  const handleEventClick = (index, country_id) => {
    setdropdownIndex(country_id);
    setSelectTime(selectTime === index ? null : index);
  };
  //for market horse racing
  const handleMarketClick = (index, market_id) => {
    setDropDownHorseMarket(!dropDownHorseMarket);
    setIndexMarketHorseRace(indexMarketHorseRace === index ? null : index);
  };
  useEffect(() => {
    if (horseRaceEvent === undefined || horseRaceEvent === null) {
      setdropdownIndex(null);
      setSelectTime(null);
    }
  }, [horseRaceEvent]);
  // useEffect(() => {
  //   const fetchOdds = async () => {
  //     api.post("/odds/event-exch", eventObjCodes)
  //       .then((response) => {
  //         if (response?.data?.data?.length > 0) {
  //           setMarketOdds(response?.data?.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //       });
  //   };

  //   let interval;
  //   if (eventObjCodes?.length > 0) {
  //     fetchOdds();
  //     interval = setInterval(fetchOdds, 700);
  //   }
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [eventObjCodes]);

  useEffect(() => {
    getCountriesAndVenue();
  }, []);
  
  

  return (
    <>
      {isSidebarOpen ? (
        <div className="h-[94vh] overflow-y-scroll scrollbar-hide absolute md:relative z-40 w-2/4 max-w-[252px] lg:mx-4 md:mx-4">
          {/* <div> */}

          <div
            className={`transition-transform duration-900 ${
              isSidebarOpen ? "translate-x-0" : "-translate-x-full"
            } inset-y-0 left-0 w-64  bg-skin-main  p-1 rounded-lg z-40`}
          >
            <div>
              <div className="w-full px-2 pl-2 pt-2 z-50 ">
                <div className="flex mb-1">
                  <div
                    className={`flex ${
                      !SearchOn ? "justify-between" : ""
                    } items-center text-skin-primary bg-skin-nav p-3 rounded-md w-full`}
                  >
                    <button className="flex text-xs font-semibold">
                      SPORTS
                    </button>

                    <div className="flex items-center ml-auto">
                      <button
                        onClick={toggleSidebar}
                        className="ml-2 z-50 p-2 bg-gray-800 text-white rounded-full focus:outline-none"
                      >
                        <FaAngleDoubleLeft size={15} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full px-2 pl-2 z-50 ">
                <div className="flex mb-2">
                  <div
                    className={`flex ${
                      !SearchOn ? "justify-between" : ""
                    } items-center text-skin-primary bg-skin-nav p-3 rounded-md w-full`}
                  >
                    <Search
                      setSearchOn={setSearchOn}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      handleClose={handleClose}
                      setActiveIndex={setActiveIndex}
                      setSearchResult={setSearchResult}
                    />
                  </div>
                </div>
              </div>

              <ul className="bg-skin-sidebarContainerBg rounded-md">
                {searchResult.length > 0 &&
                  searchResult?.map((item, index) => {
                    let encodedDynamicPart = encodeURIComponent(
                      item?.name?.toLowerCase()?.replace(/\s+/g, "-")
                    );
                    return (
                      <li
                        key={index}
                        className={`cursor-pointer flex flex-col justify-between transition hover:scale-x-105 mb-1 px-1 w-[95%] bg-skin-sidebarContainerBg hover:duration-75 rounded-md ${
                          activeIndex === item?.sportCode ? "scale-x-105" : ""
                        }`}
                      >
                        <div className="flex text-white justify-between items-center px-2 rounded-md">
                          <div className="flex items-center gap-3">
                            <Link
                              key={index}
                              to={`/${item?.slugName}/${item?.id}/${encodedDynamicPart}`}
                              onClick={() => {
                                setSearchResult([]);
                              }}
                            >
                              <div className="justify-between pl-2 rounded-md p-1 hover:duration-200 flex border border-slate-300">
                                <div className="text-skin-sidebarContainerText block text-xs font-semibold">
                                  {item?.name}
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>

                        {/* <div className="flex flex-col items-start pt-2 mt-2">
                          {events.map((event, index) => {
                            let encodedDynamicPart = encodeURIComponent(
                              event?.name?.toLowerCase()?.replace(/\s+/g, "-")
                            );
                            return (
                              <Link
                                key={index}
                                to={`/${item?.slugName}/${event?.id}/${encodedDynamicPart}`}
                              >
                                <div className="justify-between pl-2 pr-4 rounded-md py-3 hover:duration-200 hover:bg-skin-sideBarHover flex w-full">
                                  <div className="text-skin-textPrimaryColor block text-xs w-full font-semibold">
                                    {event?.name}
                                  </div>
                                </div>
                              </Link>
                            );
                          })}
                        </div> */}
                      </li>
                    );
                  })}
              </ul>
              {sidebarEventData &&
              sidebarEventData !== undefined &&
              sidebarEventData !== null &&
              location.pathname !== "/home" &&
              location.pathname !== "/all-sports" &&
              location.pathname !== "horse_racing" ? (
                <>
                  <ul className="w-full px-3 pl-3">
                    <li
                      className={`cursor-pointer flex flex-col justify-between transition hover:scale-x-105 mb-2 py-3 w-full hover:bg-skin-nav hover:duration-75 rounded-md ${
                        activeIndex === sidebarEventData?.sportCode
                          ? "bg-skin-nav scale-x-105"
                          : ""
                      }`}
                    >
                      <Link to="/home">
                        <div className="flex justify-between items-center px-2">
                          <div className="flex items-center gap-3 pl-2">
                            <h4 className="text-skin-navtext text-[14px] font-semibold">
                              {sidebarEventData?.sportName
                                ? toTitleCase(sidebarEventData?.sportName)
                                : ""}
                            </h4>
                          </div>
                        </div>
                      </Link>

                      <div className="flex flex-col items-start pl-5 pt-2 m-1 sm:p-0 lg:pl-3 xl:pl-3">
                        <>
                          <div
                            className={`justify-between rounded-md hover:duration-200 flex w-full`}
                          >
                            <div className="w-full">
                              <Link
                                to={
                                  location.pathname.includes("/horse_racing/")
                                    ? "/horse_racing"
                                    : "/all-sports"
                                }
                              >
                                <div
                                  className={`pl-2 py-3 block w-full text-[12px] font-semibold text-skin-FontSecondaryColor`}
                                >
                                  {sidebarEventData?.league?.name}
                                </div>
                              </Link>

                              <div className="flex flex-col items-start border rounded-md ">
                                <div className="justify-between pl-3 pr-4 rounded-md py-3  hover:duration-200 flex w-full">
                                  <div className="block text-xs w-full font-semibold text-skin-FontSecondaryColor">
                                    {sidebarEventData?.name}
                                  </div>
                                </div>
                                <div className="flex flex-col items-start md:min-w-[150px] lg:min-w-[150px] xl:min-w-[150px] 2xl:min-w-[150px]">
                                  {sidebarEventData?.markets?.map(
                                    (league, index) => {
                                      if (
                                        location.pathname?.slice(1, 13) ===
                                        "horse_racing"
                                      ) {
                                        return (
                                          <>
                                            <div
                                              key={index}
                                              onClick={() =>
                                                handleSelectMarket(
                                                  league,
                                                  index
                                                )
                                              }
                                              className={`justify-between border rounded-md hover:duration-200 hover:bg-skin-sidenavhover w-full`}
                                            >
                                              <div
                                                className={`pl-5 py-3 text-skin-FontSecondaryColor px-3 block w-full text-[12px] font-semibold hover:text-skin-textPrimaryColor`}
                                              >
                                                {league?.type}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                      let odds;
                                      if (
                                        marketOdds !== undefined &&
                                        marketOdds?.length > 0
                                      ) {
                                        odds = marketOdds?.find(
                                          (odd) =>
                                            league?.marketCode === odd?.marketId
                                        );
                                      }
                                      if (odds) {
                                        return (
                                          <>
                                            <div
                                              key={index}
                                              onClick={() =>
                                                handleSelectMarket(
                                                  league,
                                                  index
                                                )
                                              }
                                              className={`justify-between border rounded-md hover:duration-200 hover:bg-skin-sidenavhover w-full`}
                                            >
                                              <div
                                                className={`pl-5 py-3 text-skin-FontSecondaryColor px-3 block w-full text-[12px] font-semibold hover:text-skin-textPrimaryColor`}
                                              >
                                                {league?.marketName}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    </li>
                  </ul>
                </>
              ) : (
                <ul className="w-full px-3 pl-3">
                  {sortedSportsArray?.map((item, index) => {
                    if (item?.slugName === "greyhound_racing") {
                      return (
                        <li
                          key={item?.sportCode}
                          className={`cursor-pointer flex flex-col justify-between transition hover:scale-x-105 mb-2 py-3 w-full hover:bg-skin-nav hover:duration-75 rounded-md ${
                            activeIndex === item?.sportCode
                              ? "bg-skin-nav scale-x-105"
                              : ""
                          }`}
                        >
                          <div
                            onClick={() =>
                              handleSportClickHorseRace(item?.sportCode)
                            }
                            className="flex justify-between items-center px-2"
                          >
                            <div className="flex items-center gap-3">
                              <img
                                src={require(`../assets/images/sidemenu/${item?.slugName}.png`)}
                                alt=""
                                className="w-4 h-4 sidebar"
                              />
                              <h4 className="text-skin-navtext text-[14px] font-semibold">
                                {item?.name}
                              </h4>
                            </div>
                            {item?.name && (
                              <div className="flex items-center gap-3">
                                <span
                                  className={`flex ${
                                    item?.eventsCount === 0 && "hidden"
                                  } justify-center bg-skin-imgbg rounded-sm w-6 h-4 rounded-xs font-semibold text-xs`}
                                >
                                  {item?.eventsCount}
                                </span>
                                <h5 className="text-sm justify-center text-skin-navtext cursor-pointer">
                                  {activeIndex === item?.sportCode &&
                                  countriesVenues?.length > 0 ? (
                                    <IoIosArrowUp />
                                  ) : (
                                    <IoIosArrowDown />
                                  )}
                                </h5>
                              </div>
                            )}
                          </div>
                           {activeIndex === item?.sportCode &&
                            countriesVenues &&
                            countriesVenues?.countries?.length > 0 && (
                              <div className="flex flex-col items-start pt-2 m-1">
                                {countriesVenues?.countries?.map((league, leagueIndex) => (
                                  <div
                                    key={league?._id}
                                    onClick={() =>
                                      handleCountryClick(league?._id)
                                    }
                                    className={`justify-between ${
                                      dropdownIndex === league?._id
                                        ? "bg-skin-sidenavhover"
                                        : ""
                                    } rounded-md hover:duration-200 hover:bg-skin-sidenavhover flex w-full`}
                                  >
                                    <div className="w-full">
                                      <div
                                        className={`pl-3 py-3 ${
                                          dropdownIndex === league?._id
                                            ? "text-skin-textPrimaryColor hover:text-skin-textPrimaryColor"
                                            : "text-skin-FontSecondaryColor"
                                        } block w-full text-[12px] font-semibold hover:text-skin-textPrimaryColor`}
                                      >
                                        {league?.name}
                                      </div>
                                      
                                      {dropdownIndex === league?._id &&
                                        league?.Venues && (
                                          <div className="flex flex-col items-start pt-2">
                                            {league.Venues.map(
                                              (event, eventIndex) => (
                                                <div
                                                  key={event?._id}
                                                  className="justify-between pl-2 pr-4 rounded-md py-3 hover:duration-200 hover:bg-skin-sideBarHover flex w-full"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEventClick(
                                                      eventIndex,
                                                      league?._id
                                                    );
                                                  }}
                                                >
                                                  <div className="text-skin-textPrimaryColor block text-xs w-full font-semibold">
                                                    {event?.name}
                                                  </div>
                                                  {selectTime ===
                                                    eventIndex && (
                                                    <div className="flex flex-col items-start">
                                                      {event?.events?.map(
                                                        (time, timeIndex) => (
                                                          <>
                                                            <div
                                                              className="justify-start pr-4 rounded-md py-3 hover:duration-200 hover:bg-skin-sideBarHover flex w-full"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleMarketClick(
                                                                  timeIndex,
                                                                  time?._id
                                                                );
                                                              }}
                                                              key={time?._id}
                                                            >
                                                              <div className="text-skin-textPrimaryColor block text-xs w-full font-semibold">
                                                                {time?.name}
                                                              </div>
                                                            </div>
                                                            {/* start markets here */}
                                                            {console.log(
                                                              "slect marjet us ",
                                                              indexMarketHorseRace
                                                            )}
                                                            {indexMarketHorseRace ===
                                                              timeIndex &&
                                                              dropDownHorseMarket && (
                                                                <div className="grid grid-rows-1 gap-2 mt-2">
                                                                  {time?.markets?.map(
                                                                    (
                                                                      market
                                                                    ) => (
                                                                      <Link
                                                                        key={
                                                                          time?._id
                                                                        }
                                                                        to={`/${item?.slugName}/${time?._id}`}
                                                                        state={{
                                                                          venueId:
                                                                            league
                                                                              ?.Venues[0]
                                                                              ?._id,
                                                                          time: market?.marketStartTime,
                                                                        }}
                                                                      >
                                                                        <p className="text-white text-xs">
                                                                          {formatDate(
                                                                            market?.marketStartTime
                                                                          )}
                                                                        </p>
                                                                      </Link>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                    </div>
                                    <div
                                      className={`${
                                        dropdownIndex === leagueIndex
                                          ? "text-skin-textPrimaryColor hover:text-skin-textPrimaryColor"
                                          : "text-skin-secondary hover:text-skin-secondary"
                                      } py-3 text-sm cursor-pointer`}
                                    >
                                      {dropdownIndex === leagueIndex ? (
                                        <IoIosArrowUp />
                                      ) : (
                                        <IoIosArrowDown />
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </li>
                      );
                    } else if (item?.slugName === "horse_racing") {
                      return (
                        <li
                          key={item?.sportCode}
                          className={`cursor-pointer flex flex-col justify-between transition hover:scale-x-105 mb-2 py-3 w-full hover:bg-skin-nav hover:duration-75 rounded-md ${
                            activeIndex === item?.sportCode
                              ? "bg-skin-nav scale-x-105"
                              : ""
                          }`}
                        >
                          <div
                            onClick={() =>
                              handleSportClickHorseRace(item?.sportCode)
                            }
                            className="flex justify-between items-center px-2"
                          >
                            <div className="flex items-center gap-3">
                              <img
                                src={require(`../assets/images/sidemenu/${item?.slugName}.png`)}
                                alt=""
                                className="w-4 h-4 sidebar"
                              />
                              <h4 className="text-skin-navtext text-[14px] font-semibold">
                                {item?.name}
                              </h4>
                            </div>
                            {item?.name && (
                              <div className="flex items-center gap-3">
                                <span
                                  className={`flex ${
                                    item?.eventsCount === 0 && "hidden"
                                  } justify-center bg-skin-imgbg rounded-sm w-6 h-4 rounded-xs font-semibold text-xs`}
                                >
                                  {item?.eventsCount}
                                </span>
                                <h5 className="text-sm justify-center text-skin-navtext cursor-pointer">
                                  {activeIndex === item?.sportCode &&
                                  countriesVenues?.length > 0 ? (
                                    <IoIosArrowUp />
                                  ) : (
                                    <IoIosArrowDown />
                                  )}
                                </h5>
                              </div>
                            )}
                          </div>
                           {activeIndex === item?.sportCode &&
                            countriesVenues &&
                            countriesVenues?.countries?.length > 0 && (
                              <div className="flex flex-col items-start pt-2 m-1">
                                {countriesVenues?.countries?.map((league, leagueIndex) => (
                                  <div
                                    key={league?._id}
                                    onClick={() =>
                                      handleCountryClick(league?._id)
                                    }
                                    className={`justify-between ${
                                      dropdownIndex === league?._id
                                        ? "bg-skin-sidenavhover"
                                        : ""
                                    } rounded-md hover:duration-200 hover:bg-skin-sidenavhover flex w-full`}
                                  >
                                    <div className="w-full">
                                      <div
                                        className={`pl-3 py-3 ${
                                          dropdownIndex === league?._id
                                            ? "text-skin-textPrimaryColor hover:text-skin-textPrimaryColor"
                                            : "text-skin-FontSecondaryColor"
                                        } block w-full text-[12px] font-semibold hover:text-skin-textPrimaryColor`}
                                      >
                                        {league?.name}
                                      </div>
                                      
                                      {dropdownIndex === league?._id &&
                                        league?.Venues && (
                                          <div className="flex flex-col items-start pt-2">
                                            {league.Venues.map(
                                              (event, eventIndex) => (
                                                <div
                                                  key={event?._id}
                                                  className="justify-between pl-2 pr-4 rounded-md py-3 hover:duration-200 hover:bg-skin-sideBarHover flex w-full"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEventClick(
                                                      eventIndex,
                                                      league?._id
                                                    );
                                                  }}
                                                >
                                                  <div className="text-skin-textPrimaryColor block text-xs w-full font-semibold">
                                                    {event?.name}
                                                  </div>
                                                  {selectTime ===
                                                    eventIndex && (
                                                    <div className="flex flex-col items-start">
                                                      {event?.events?.map(
                                                        (time, timeIndex) => (
                                                          <>
                                                            <div
                                                              className="justify-start pr-4 rounded-md py-3 hover:duration-200 hover:bg-skin-sideBarHover flex w-full"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleMarketClick(
                                                                  timeIndex,
                                                                  time?._id
                                                                );
                                                              }}
                                                              key={time?._id}
                                                            >
                                                              <div className="text-skin-textPrimaryColor block text-xs w-full font-semibold">
                                                                {time?.name}
                                                              </div>
                                                            </div>
                                                            {/* start markets here */}
                                                            {/* {console.log(
                                                              "slect marjet us ",
                                                              indexMarketHorseRace
                                                            )} */}
                                                            {indexMarketHorseRace ===
                                                              timeIndex &&
                                                              dropDownHorseMarket && (
                                                                <div className="grid grid-rows-1 gap-2 mt-2">
                                                                  {time?.markets?.map(
                                                                    (
                                                                      market
                                                                    ) => (
                                                                      <Link
                                                                        key={
                                                                          time?._id
                                                                        }
                                                                        to={`/${item?.slugName}/${time?._id}`}
                                                                        state={{
                                                                          venueId:
                                                                            league
                                                                              ?.Venues[0]
                                                                              ?._id,
                                                                          time: market?.marketStartTime,
                                                                        }}
                                                                      >
                                                                        <p className="text-white text-xs">
                                                                          {formatDate(
                                                                            market?.marketStartTime
                                                                          )}
                                                                        </p>
                                                                      </Link>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                    </div>
                                    <div
                                      className={`${
                                        dropdownIndex === leagueIndex
                                          ? "text-skin-textPrimaryColor hover:text-skin-textPrimaryColor"
                                          : "text-skin-secondary hover:text-skin-secondary"
                                      } py-3 text-sm cursor-pointer`}
                                    >
                                      {dropdownIndex === leagueIndex ? (
                                        <IoIosArrowUp />
                                      ) : (
                                        <IoIosArrowDown />
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </li>
                      );
                    } else
                      return (
                        <li
                          key={index}
                          className={`cursor-pointer flex flex-col justify-between transition hover:scale-x-105 mb-2 py-3 w-full hover:bg-skin-nav hover:duration-75 rounded-md ${
                            activeIndex === item?.sportCode
                              ? "bg-skin-nav scale-x-105"
                              : ""
                          }`}
                        >
                          <div
                            onClick={() => handleSportClick(item?.sportCode)}
                            className="flex justify-between items-center px-2"
                          >
                            <div className="flex items-center gap-3">
                              <img
                                src={require(`../assets/images/sidemenu/${item?.slugName}.png`)}
                                alt=""
                                className="w-4 h-4 sidebar"
                              />
                              <h4 className="text-skin-navtext text-[14px] font-semibold">
                                {item?.name}
                              </h4>
                            </div>
                            {item?.name ? (
                              <div className="flex items-center gap-3">
                                <span
                                  className={`flex ${
                                    item?.eventsCount === 0 && "hidden"
                                  } justify-center bg-skin-imgbg rounded-sm w-6 h-4 rounded-xs font-semibold text-xs`}
                                >
                                  {item?.eventsCount}
                                </span>
                                <h5 className="text-sm justify-center text-skin-navtext cursor-pointer">
                                  {activeIndex === item?.sportCode &&
                                  leagues?.length > 0 ? (
                                    <IoIosArrowUp />
                                  ) : (
                                    <IoIosArrowDown />
                                  )}
                                </h5>
                              </div>
                            ) : null}
                          </div>

                          {activeIndex === item?.sportCode && leagues && (
                            <div className="flex flex-col items-start pt-2 m-1">
                              {leagues?.map((league, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      onClick={() =>
                                        handleLeagueClick(league?._id)
                                      }
                                      className={`justify-between ${
                                        dropdownIndex === league?._id
                                          ? "bg-skin-sidebarContainerBg"
                                          : ""
                                      } rounded-md hover:duration-200 hover:bg-skin-sidebarContainerBg flex w-full`}
                                    >
                                      <div className="w-full">
                                        <div
                                          className={`pl-3 py-3 ${
                                            dropdownIndex === league?._id
                                              ? "text-skin-sidebarContainerText"
                                              : "text-skin-FontSecondaryColor"
                                          } block w-full text-[12px] font-semibold hover:text-[12.5px]`}
                                        >
                                          {league?.name}
                                        </div>

                                        {dropdownIndex === league?._id &&
                                          events && (
                                            <div className="flex flex-col items-start bg-skin-sidebarContainerBg">
                                              {events.map((event, index) => {
                                                let encodedDynamicPart =
                                                  encodeURIComponent(
                                                    event?.name
                                                      ?.toLowerCase()
                                                      ?.replace(/\s+/g, "-")
                                                  );
                                                return (
                                                  <Link
                                                    key={index}
                                                    to={`/${item?.slugName}/${event?.id}/${encodedDynamicPart}`}
                                                  >
                                                    <div className="justify-between pl-2 pr-4 rounded-md py-3 hover:duration-200 hover:bg-skin-sidebarContainerBg  flex w-full">
                                                      <div className="text-skin-sidebarContainerText block text-xs transition duration-300 ease-in-out hover:text-[12.5px] w-full font-semibold">
                                                        {event?.name}
                                                      </div>
                                                    </div>
                                                  </Link>
                                                );
                                              })}
                                            </div>
                                          )}
                                      </div>

                                      <div
                                        className={`${
                                          dropdownIndex === index
                                            ? "text-skin-textPrimaryColor hover:text-skin-textPrimaryColor"
                                            : "text-skin-secondary hover:text-skin-secondary"
                                        } py-3 text-sm cursor-pointer`}
                                      >
                                        {dropdownIndex === index ? (
                                          <IoIosArrowUp className="" />
                                        ) : (
                                          <IoIosArrowDown className="" />
                                        )}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          )}
                        </li>
                      );
                  })}
                </ul>
              )}
            </div>
          </div>

          {/* </div> */}
        </div>
      ) : (
        <div className="h-[94vh] min-w-20 overflow-y-scroll scrollbar-hide absolute md:relative z-40 lg:mx-4 md:mx-4">
          <div
            className={`transition-transform duration-300 ${
              !isSidebarOpen ? "translate-x-0" : "-translate-x-full"
            } inset-y-0 left-0 bg-skin-main border border-[#cbd5e1] p-1 rounded-lg z-40`}
          >
            <div className="w-full z-50 border rounded-lg">
              <div className="flex items-center text-skin-primary bg-skin-nav p-3 rounded-md">
                <button
                  onClick={toggleSidebar}
                  className="p-2 bg-gray-800 text-white rounded-full"
                >
                  <FaAngleDoubleRight size={20} />
                </button>
              </div>
            </div>

            <ul>
              {sortedSportsArray?.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => onSportClick(index, item?.sportCode)}
                    className="cursor-pointer p-3 rounded-md relative"
                  >
                    {item?.name && (
                      <span
                        className={`absolute right-0 transform flex ${
                          item?.eventsCount === 0 && "hidden"
                        } justify-center rounded-sm w-6 h-4 font-semibold text-xs`}
                      >
                        {item?.eventsCount}
                      </span>
                    )}
                    <div className="items-center p-2">
                      <div className="flex items-center gap-3">
                        <img
                          src={require(`../assets/images/sidemenu/${item?.slugName}.png`)}
                          alt="SportsIcon"
                          className="w-7 h-6 sidebar rounded-lg"
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default SideNavbar;
