import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../ConfirmModal';
import './style.css'
import { updateTwoFactorStatusAction } from '../../../redux/actions';
import { notifyWarning } from '../../../utils/helper';
import QRCode from '../../QRCode';

const LockButton = () => {
  const dispatch = useDispatch ();

  const userData = useSelector((state) => state?.GetUserData?.userData?.data);
 
  
  const [confirmModalToggle,setConfirmModalToggle]=useState(false);
  const [is2faStatus, setIs2faStatus] = useState(userData?.twoFactorEnabled);
  const [isDisabled,setIsDisabled]=useState(false)

 
  const confirmModalToggleFunc = () => {
    setConfirmModalToggle(!confirmModalToggle)
  }
 
 
  // const handleTwoFactor = (callback) =>{
  //   setIsTwoFactorDisabled(true)
  //   if(callback){
  //     const currectData = {
  //       status:callback.target.value  
  //        };
  //        dispatch(
  //         updateTwoFactorStatusAction({
  //           data:currectData,
  //           callback: (data) => {
  //             setIs2faStatus(data?.data?.status);
  //             setTimeout(()=>{
  //               setIsTwoFactorDisabled(false)
  //             },5000)
  //           }
  //         })
  //        )
  //   }
  // }


  const handleDisable2Factor = (e) =>{
      if(is2faStatus){
        if(e.target.value){
          setIsDisabled(true)
          const currectData = {
            status:false  
             };
             dispatch(
              updateTwoFactorStatusAction({
                data:currectData,
                callback: (data) => {
                  setIs2faStatus(data?.data?.status);
                  setTimeout(()=>{
                    setIsDisabled(false)
                  },5000)
                  confirmModalToggleFunc()
                }
              })
             )
        }
      }else{
        console.log("2FA is already disabled")
        notifyWarning("2FA is already disabled")
        setTimeout(()=>{
          confirmModalToggleFunc()
        },2000)
      }
  }

  useEffect(()=>{
    setIs2faStatus(userData?.twoFactorEnabled)
  },[userData])
 
  return (
    <>
    <input type="checkbox" 
      disabled={isDisabled}
      checked={!is2faStatus}
      value={!is2faStatus}
      onClick={confirmModalToggleFunc}
       id="lock" />
      <label for="lock" class="lock-label">
        <span class="lock-wrapper">
          <span class="shackle"></span>
          <svg
            class="lock-body"
            width=""
            height=""
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 5C0 2.23858 2.23858 0 5 0H23C25.7614 0 28 2.23858 28 5V23C28 25.7614 25.7614 28 23 28H5C2.23858 28 0 25.7614 0 23V5ZM16 13.2361C16.6137 12.6868 17 11.8885 17 11C17 9.34315 15.6569 8 14 8C12.3431 8 11 9.34315 11 11C11 11.8885 11.3863 12.6868 12 13.2361V18C12 19.1046 12.8954 20 14 20C15.1046 20 16 19.1046 16 18V13.2361Z"
              fill="white"
            ></path>
          </svg>
        </span>
      </label>


      {confirmModalToggle && (
            <ConfirmModal  
            isOpen={confirmModalToggle}
            isClose={confirmModalToggleFunc}
            
            isDisable2Factor={handleDisable2Factor}
            />
           
          )}
    </>
  )
}

export default LockButton