import {
    BET_SPORT_HORSERACING_FAILURE,
    BET_SPORT_HORSERACING,
    BET_SPORT_HORSERACING_SUCCESS
} from "../../actions/types";

const INIT_STATE = {
    loading: false,
    data: null,
};

const getHorseRaceBetReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case BET_SPORT_HORSERACING:
            return { ...state, loading: true };
        case BET_SPORT_HORSERACING_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case BET_SPORT_HORSERACING_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default getHorseRaceBetReducer;
