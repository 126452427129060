import {
    SEARCH_KEYWORD_REQUEST,
    SEARCH_KEYWORD_SUCCESS,
    SEARCH_KEYWORD_FAILURE,
  } from "../../actions/types";

  const INIT_STATE = {
    loading: false,
    keyword: null,
  };

  const searchKeyword = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SEARCH_KEYWORD_REQUEST:
        return { ...state, loading: true };
      case SEARCH_KEYWORD_SUCCESS:
        return { ...state, loading: false, newChip: action?.payload };
      case SEARCH_KEYWORD_FAILURE:
        return { ...state, loading: false };
      default:
        return state;
    }
  };

  export default searchKeyword;