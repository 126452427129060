const stxLimit = [
  { value: 100.0 },
  { value: 50.0 },
  { value: 33.33 },
  { value: 25.0 },
  { value: 20.0 },
  { value: 16.67 },
  { value: 14.29 },
  { value: 12.5 },
  { value: 11.11 },
  { value: 10.0 },
  { value: 9.09 },
  { value: 8.33 },
  { value: 7.69 },
  { value: 7.14 },
  { value: 6.67 },
  { value: 6.25 },
  { value: 5.88 },
  { value: 5.56 },
  { value: 5.26 },
  { value: 5.0 },
  { value: 4.76 },
  { value: 4.55 },
  { value: 4.35 },
  { value: 4.17 },
  { value: 4.0 },
  { value: 3.85 },
  { value: 3.7 },
  { value: 3.57 },
  { value: 3.45 },
  { value: 3.33 },
  { value: 3.23 },
  { value: 3.13 },
  { value: 3.03 },
  { value: 2.94 },
  { value: 2.86 },
  { value: 2.78 },
  { value: 2.7 },
  { value: 2.63 },
  { value: 2.56 },
  { value: 2.5 },
  { value: 2.44 },
  { value: 2.38 },
  { value: 2.33 },
  { value: 2.27 },
  { value: 2.22 },
  { value: 2.17 },
  { value: 2.13 },
  { value: 2.08 },
  { value: 2.04 },
  { value: 2.0 },
  { value: 1.96 },
  { value: 1.92 },
  { value: 1.89 },
  { value: 1.85 },
  { value: 1.82 },
  { value: 1.79 },
  { value: 1.75 },
  { value: 1.72 },
  { value: 1.69 },
  { value: 1.67 },
  { value: 1.64 },
  { value: 1.61 },
  { value: 1.59 },
  { value: 1.56 },
  { value: 1.54 },
  { value: 1.52 },
  { value: 1.49 },
  { value: 1.47 },
  { value: 1.45 },
  { value: 1.43 },
  { value: 1.41 },
  { value: 1.39 },
  { value: 1.37 },
  { value: 1.35 },
  { value: 1.33 },
  { value: 1.32 },
  { value: 1.3 },
  { value: 1.28 },
  { value: 1.27 },
  { value: 1.25 },
  { value: 1.23 },
  { value: 1.22 },
  { value: 1.2 },
  { value: 1.19 },
  { value: 1.18 },
  { value: 1.16 },
  { value: 1.15 },
  { value: 1.14 },
  { value: 1.12 },
  { value: 1.11 },
  { value: 1.1 },
  { value: 1.09 },
  { value: 1.08 },
  { value: 1.06 },
  { value: 1.05 },
  { value: 1.04 },
  { value: 1.03 },
  { value: 1.02 },
  { value: 1.01 },
];
const stxBackLimit = [
  { value: 1000.0 },
  { value: 500.0 },
  { value: 333.33 },
  { value: 250.0 },
  { value: 200.0 },
  { value: 166.67 },
  { value: 142.86 },
  { value: 125.0 },
  { value: 111.11 },
  { value: 100.0 },
  { value: 90.91 },
  { value: 83.33 },
  { value: 76.92 },
  { value: 71.43 },
  { value: 66.67 },
  { value: 62.5 },
  { value: 58.82 },
  { value: 55.56 },
  { value: 52.63 },
  { value: 50.0 },
  { value: 47.62 },
  { value: 45.45 },
  { value: 43.48 },
  { value: 41.67 },
  { value: 40.0 },
  { value: 38.46 },
  { value: 37.04 },
  { value: 35.71 },
  { value: 34.48 },
  { value: 33.33 },
  { value: 32.26 },
  { value: 31.25 },
  { value: 30.3 },
  { value: 29.41 },
  { value: 28.57 },
  { value: 27.78 },
  { value: 27.03 },
  { value: 26.32 },
  { value: 25.64 },
  { value: 25.0 },
  { value: 24.39 },
  { value: 23.81 },
  { value: 23.26 },
  { value: 22.73 },
  { value: 22.22 },
  { value: 21.74 },
  { value: 21.28 },
  { value: 20.83 },
  { value: 20.41 },
  { value: 20.0 },
  { value: 19.61 },
  { value: 19.23 },
  { value: 18.87 },
  { value: 18.52 },
  { value: 18.18 },
  { value: 17.86 },
  { value: 17.54 },
  { value: 17.24 },
  { value: 16.95 },
  { value: 16.67 },
  { value: 16.39 },
  { value: 16.13 },
  { value: 15.87 },
  { value: 15.63 },
  { value: 15.38 },
  { value: 15.15 },
  { value: 14.93 },
  { value: 14.71 },
  { value: 14.49 },
  { value: 14.29 },
  { value: 14.08 },
  { value: 13.89 },
  { value: 13.7 },
  { value: 13.51 },
  { value: 13.33 },
  { value: 13.16 },
  { value: 12.99 },
  { value: 12.82 },
  { value: 12.66 },
  { value: 12.5 },
  { value: 12.35 },
  { value: 12.2 },
  { value: 12.05 },
  { value: 11.9 },
  { value: 11.76 },
  { value: 11.63 },
  { value: 11.49 },
  { value: 11.36 },
  { value: 11.24 },
  { value: 11.11 },
  { value: 10.99 },
  { value: 10.87 },
  { value: 10.75 },
  { value: 10.64 },
  { value: 10.53 },
  { value: 10.42 },
  { value: 10.31 },
  { value: 10.2 },
  { value: 10.1 },
  { value: 10.0 },
  { value: 9.9 },
  { value: 9.8 },
  { value: 9.71 },
  { value: 9.62 },
  { value: 9.52 },
  { value: 9.43 },
  { value: 9.35 },
  { value: 9.26 },
  { value: 9.17 },
  { value: 9.09 },
  { value: 9.01 },
  { value: 8.93 },
  { value: 8.85 },
  { value: 8.77 },
  { value: 8.7 },
  { value: 8.62 },
  { value: 8.55 },
  { value: 8.47 },
  { value: 8.4 },
  { value: 8.33 },
  { value: 8.26 },
  { value: 8.2 },
  { value: 8.13 },
  { value: 8.06 },
  { value: 8.0 },
  { value: 7.94 },
  { value: 7.87 },
  { value: 7.81 },
  { value: 7.75 },
  { value: 7.69 },
  { value: 7.63 },
  { value: 7.58 },
  { value: 7.52 },
  { value: 7.46 },
  { value: 7.41 },
  { value: 7.35 },
  { value: 7.3 },
  { value: 7.25 },
  { value: 7.19 },
  { value: 7.14 },
  { value: 7.09 },
  { value: 7.04 },
  { value: 6.99 },
  { value: 6.94 },
  { value: 6.9 },
  { value: 6.85 },
  { value: 6.8 },
  { value: 6.76 },
  { value: 6.71 },
  { value: 6.67 },
  { value: 6.62 },
  { value: 6.58 },
  { value: 6.54 },
  { value: 6.49 },
  { value: 6.45 },
  { value: 6.41 },
  { value: 6.37 },
  { value: 6.33 },
  { value: 6.29 },
  { value: 6.25 },
  { value: 6.21 },
  { value: 6.17 },
  { value: 6.13 },
  { value: 6.1 },
  { value: 6.06 },
  { value: 6.02 },
  { value: 5.99 },
  { value: 5.95 },
  { value: 5.92 },
  { value: 5.88 },
  { value: 5.85 },
  { value: 5.81 },
  { value: 5.78 },
  { value: 5.75 },
  { value: 5.71 },
  { value: 5.68 },
  { value: 5.65 },
  { value: 5.62 },
  { value: 5.59 },
  { value: 5.56 },
  { value: 5.52 },
  { value: 5.49 },
  { value: 5.46 },
  { value: 5.43 },
  { value: 5.41 },
  { value: 5.38 },
  { value: 5.35 },
  { value: 5.32 },
  { value: 5.29 },
  { value: 5.26 },
  { value: 5.24 },
  { value: 5.21 },
  { value: 5.18 },
  { value: 5.15 },
  { value: 5.13 },
  { value: 5.1 },
  { value: 5.08 },
  { value: 5.05 },
  { value: 5.03 },
  { value: 5.0 },
  { value: 4.98 },
  { value: 4.95 },
  { value: 4.93 },
  { value: 4.9 },
  { value: 4.88 },
  { value: 4.85 },
  { value: 4.83 },
  { value: 4.81 },
  { value: 4.78 },
  { value: 4.76 },
  { value: 4.74 },
  { value: 4.72 },
  { value: 4.69 },
  { value: 4.67 },
  { value: 4.65 },
  { value: 4.63 },
  { value: 4.61 },
  { value: 4.59 },
  { value: 4.57 },
  { value: 4.55 },
  { value: 4.52 },
  { value: 4.5 },
  { value: 4.48 },
  { value: 4.46 },
  { value: 4.44 },
  { value: 4.42 },
  { value: 4.41 },
  { value: 4.39 },
  { value: 4.37 },
  { value: 4.35 },
  { value: 4.33 },
  { value: 4.31 },
  { value: 4.29 },
  { value: 4.27 },
  { value: 4.26 },
  { value: 4.24 },
  { value: 4.22 },
  { value: 4.2 },
  { value: 4.18 },
  { value: 4.17 },
  { value: 4.15 },
  { value: 4.13 },
  { value: 4.12 },
  { value: 4.1 },
  { value: 4.08 },
  { value: 4.07 },
  { value: 4.05 },
  { value: 4.03 },
  { value: 4.02 },
  { value: 4.0 },
  { value: 3.98 },
  { value: 3.97 },
  { value: 3.95 },
  { value: 3.94 },
  { value: 3.92 },
  { value: 3.91 },
  { value: 3.89 },
  { value: 3.88 },
  { value: 3.86 },
  { value: 3.85 },
  { value: 3.83 },
  { value: 3.82 },
  { value: 3.8 },
  { value: 3.79 },
  { value: 3.77 },
  { value: 3.76 },
  { value: 3.75 },
  { value: 3.73 },
  { value: 3.72 },
  { value: 3.7 },
  { value: 3.69 },
  { value: 3.68 },
  { value: 3.66 },
  { value: 3.65 },
  { value: 3.64 },
  { value: 3.62 },
  { value: 3.61 },
  { value: 3.6 },
  { value: 3.58 },
  { value: 3.57 },
  { value: 3.56 },
  { value: 3.55 },
  { value: 3.53 },
  { value: 3.52 },
  { value: 3.51 },
  { value: 3.5 },
  { value: 3.48 },
  { value: 3.47 },
  { value: 3.46 },
  { value: 3.45 },
  { value: 3.44 },
  { value: 3.42 },
  { value: 3.41 },
  { value: 3.4 },
  { value: 3.39 },
  { value: 3.38 },
  { value: 3.37 },
  { value: 3.36 },
  { value: 3.34 },
  { value: 3.33 },
  { value: 3.32 },
  { value: 3.31 },
  { value: 3.3 },
  { value: 3.29 },
  { value: 3.28 },
  { value: 3.27 },
  { value: 3.26 },
  { value: 3.25 },
  { value: 3.24 },
  { value: 3.23 },
  { value: 3.22 },
  { value: 3.21 },
  { value: 3.19 },
  { value: 3.18 },
  { value: 3.17 },
  { value: 3.16 },
  { value: 3.15 },
  { value: 3.14 },

  { value: 3.13 },
  { value: 3.12 },
  { value: 3.11 },
  { value: 3.1 },
  { value: 3.09 },
  { value: 3.08 },
  { value: 3.07 },
  { value: 3.06 },
  { value: 3.05 },
  { value: 3.04 },
  { value: 3.03 },
  { value: 3.02 },
  { value: 3.01 },
  { value: 3.0 },

  { value: 2.99 },
  { value: 2.98 },
  { value: 2.97 },
  { value: 2.96 },
  { value: 2.95 },
  { value: 2.94 },
  { value: 2.93 },

  { value: 2.92 },
  { value: 2.91 },
  { value: 2.9 },
  { value: 2.89 },
  { value: 2.88 },

  { value: 2.87 },
  { value: 2.86 },
  { value: 2.85 },
  { value: 2.84 },
  { value: 2.83 },

  { value: 2.82 },
  { value: 2.81 },
  { value: 2.8 },

  { value: 2.79 },
  { value: 2.78 },
  { value: 2.77 },
  { value: 2.76 },

  { value: 2.75 },
  { value: 2.74 },
  { value: 2.73 },

  { value: 2.72 },
  { value: 2.71 },

  { value: 2.7 },
  { value: 2.69 },
  { value: 2.68 },

  { value: 2.67 },
  { value: 2.66 },

  { value: 2.65 },
  { value: 2.64 },
  { value: 2.63 },

  { value: 2.62 },
  { value: 2.61 },

  { value: 2.6 },
  { value: 2.59 },

  { value: 2.58 },
  { value: 2.57 },

  { value: 2.56 },
  { value: 2.55 },

  { value: 2.54 },

  { value: 2.53 },
  { value: 2.52 },

  { value: 2.51 },
  { value: 2.5 },

  { value: 2.49 },

  { value: 2.48 },
  { value: 2.47 },

  { value: 2.46 },
  { value: 2.45 },

  { value: 2.44 },

  { value: 2.43 },

  { value: 2.42 },
  { value: 2.41 },

  { value: 2.4 },

  { value: 2.39 },

  { value: 2.38 },
  { value: 2.37 },

  { value: 2.36 },

  { value: 2.35 },

  { value: 2.34 },

  { value: 2.33 },
  { value: 2.32 },

  { value: 2.31 },

  { value: 2.3 },

  { value: 2.29 },

  { value: 2.28 },

  { value: 2.27 },

  { value: 2.26 },

  { value: 2.25 },

  { value: 2.24 },

  { value: 2.23 },

  { value: 2.22 },

  { value: 2.21 },

  { value: 2.2 },

  { value: 2.19 },

  { value: 2.18 },

  { value: 2.17 },

  { value: 2.16 },

  { value: 2.15 },

  { value: 2.14 },

  { value: 2.13 },

  { value: 2.12 },

  { value: 2.11 },

  { value: 2.1 },

  { value: 2.09 },

  { value: 2.08 },

  { value: 2.07 },

  { value: 2.06 },

  { value: 2.05 },

  { value: 2.04 },

  { value: 2.03 },

  { value: 2.02 },

  { value: 2.01 },

  { value: 2.0 },

  { value: 1.99 },

  { value: 1.98 },

  { value: 1.97 },

  { value: 1.96 },

  { value: 1.95 },

  { value: 1.94 },

  { value: 1.93 },

  { value: 1.92 },

  { value: 1.91 },

  { value: 1.9 },

  { value: 1.89 },

  { value: 1.88 },

  { value: 1.87 },

  { value: 1.86 },

  { value: 1.85 },

  { value: 1.84 },

  { value: 1.83 },

  { value: 1.82 },

  { value: 1.81 },

  { value: 1.8 },

  { value: 1.79 },

  { value: 1.78 },

  { value: 1.77 },

  { value: 1.76 },

  { value: 1.75 },

  { value: 1.74 },

  { value: 1.73 },

  { value: 1.72 },

  { value: 1.71 },

  { value: 1.7 },

  { value: 1.69 },

  { value: 1.68 },

  { value: 1.67 },

  { value: 1.66 },

  { value: 1.65 },

  { value: 1.64 },

  { value: 1.63 },

  { value: 1.62 },

  { value: 1.61 },

  { value: 1.6 },

  { value: 1.59 },

  { value: 1.58 },

  { value: 1.57 },

  { value: 1.56 },

  { value: 1.55 },

  { value: 1.54 },

  { value: 1.53 },

  { value: 1.52 },

  { value: 1.51 },

  { value: 1.5 },

  { value: 1.49 },

  { value: 1.48 },

  { value: 1.47 },

  { value: 1.46 },

  { value: 1.45 },

  { value: 1.44 },

  { value: 1.43 },

  { value: 1.42 },

  { value: 1.41 },

  { value: 1.4 },

  { value: 1.39 },

  { value: 1.38 },

  { value: 1.37 },

  { value: 1.36 },

  { value: 1.35 },

  { value: 1.34 },

  { value: 1.33 },

  { value: 1.32 },

  { value: 1.31 },

  { value: 1.3 },

  { value: 1.29 },

  { value: 1.28 },

  { value: 1.27 },

  { value: 1.26 },

  { value: 1.25 },

  { value: 1.24 },

  { value: 1.23 },

  { value: 1.22 },

  { value: 1.21 },

  { value: 1.2 },

  { value: 1.19 },

  { value: 1.18 },

  { value: 1.17 },

  { value: 1.16 },

  { value: 1.15 },

  { value: 1.14 },

  { value: 1.13 },

  { value: 1.12 },

  { value: 1.11 },

  { value: 1.1 },

  { value: 1.09 },

  { value: 1.08 },

  { value: 1.07 },

  { value: 1.06 },

  { value: 1.05 },

  { value: 1.04 },

  { value: 1.03 },

  { value: 1.02 },

  { value: 1.01 },

  { value: 1.0 },
];
const stxLayLimit = [
  { value: 1000.0 },
  { value: 500.0 },
  { value: 333.33 },
  { value: 250.0 },
  { value: 200.0 },
  { value: 166.67 },
  { value: 142.86 },
  { value: 125.0 },
  { value: 111.11 },
  { value: 100.0 },
  { value: 90.91 },
  { value: 83.33 },
  { value: 76.92 },
  { value: 71.43 },
  { value: 66.67 },
  { value: 62.5 },
  { value: 58.82 },
  { value: 55.56 },
  { value: 52.63 },
  { value: 50.0 },
  { value: 47.62 },
  { value: 45.45 },
  { value: 43.48 },
  { value: 41.67 },
  { value: 40.0 },
  { value: 38.46 },
  { value: 37.04 },
  { value: 35.71 },
  { value: 34.48 },
  { value: 33.33 },
  { value: 32.26 },
  { value: 31.25 },
  { value: 30.3 },
  { value: 29.41 },
  { value: 28.57 },
  { value: 27.78 },
  { value: 27.03 },
  { value: 26.32 },
  { value: 25.64 },
  { value: 25.0 },
  { value: 24.39 },
  { value: 23.81 },
  { value: 23.26 },
  { value: 22.73 },
  { value: 22.22 },
  { value: 21.74 },
  { value: 21.28 },
  { value: 20.83 },
  { value: 20.41 },
  { value: 20.0 },
  { value: 19.61 },
  { value: 19.23 },
  { value: 18.87 },
  { value: 18.52 },
  { value: 18.18 },
  { value: 17.86 },
  { value: 17.54 },
  { value: 17.24 },
  { value: 16.95 },
  { value: 16.67 },
  { value: 16.39 },
  { value: 16.13 },
  { value: 15.87 },
  { value: 15.63 },
  { value: 15.38 },
  { value: 15.15 },
  { value: 14.93 },
  { value: 14.71 },
  { value: 14.49 },
  { value: 14.29 },
  { value: 14.08 },
  { value: 13.89 },
  { value: 13.7 },
  { value: 13.51 },
  { value: 13.33 },
  { value: 13.16 },
  { value: 12.99 },
  { value: 12.82 },
  { value: 12.66 },
  { value: 12.5 },
  { value: 12.35 },
  { value: 12.2 },
  { value: 12.05 },
  { value: 11.9 },
  { value: 11.76 },
  { value: 11.63 },
  { value: 11.49 },
  { value: 11.36 },
  { value: 11.24 },
  { value: 11.11 },
  { value: 10.99 },
  { value: 10.87 },
  { value: 10.75 },
  { value: 10.64 },
  { value: 10.53 },
  { value: 10.42 },
  { value: 10.31 },
  { value: 10.2 },
  { value: 10.1 },
  { value: 10.0 },
  { value: 9.9 },
  { value: 9.8 },
  { value: 9.71 },
  { value: 9.62 },
  { value: 9.52 },
  { value: 9.43 },
  { value: 9.35 },
  { value: 9.26 },
  { value: 9.17 },
  { value: 9.09 },
  { value: 9.01 },
  { value: 8.93 },
  { value: 8.85 },
  { value: 8.77 },
  { value: 8.7 },
  { value: 8.62 },
  { value: 8.55 },
  { value: 8.47 },
  { value: 8.4 },
  { value: 8.33 },
  { value: 8.26 },
  { value: 8.2 },
  { value: 8.13 },
  { value: 8.06 },
  { value: 8.0 },
  { value: 7.94 },
  { value: 7.87 },
  { value: 7.81 },
  { value: 7.75 },
  { value: 7.69 },
  { value: 7.63 },
  { value: 7.58 },
  { value: 7.52 },
  { value: 7.46 },
  { value: 7.41 },
  { value: 7.35 },
  { value: 7.3 },
  { value: 7.25 },
  { value: 7.19 },
  { value: 7.14 },
  { value: 7.09 },
  { value: 7.04 },
  { value: 6.99 },
  { value: 6.94 },
  { value: 6.9 },
  { value: 6.85 },
  { value: 6.8 },
  { value: 6.76 },
  { value: 6.71 },
  { value: 6.67 },
  { value: 6.62 },
  { value: 6.58 },
  { value: 6.54 },
  { value: 6.49 },
  { value: 6.45 },
  { value: 6.41 },
  { value: 6.37 },
  { value: 6.33 },
  { value: 6.29 },
  { value: 6.25 },
  { value: 6.21 },
  { value: 6.17 },
  { value: 6.13 },
  { value: 6.1 },
  { value: 6.06 },
  { value: 6.02 },
  { value: 5.99 },
  { value: 5.95 },
  { value: 5.92 },
  { value: 5.88 },
  { value: 5.85 },
  { value: 5.81 },
  { value: 5.78 },
  { value: 5.75 },
  { value: 5.71 },
  { value: 5.68 },
  { value: 5.65 },
  { value: 5.62 },
  { value: 5.59 },
  { value: 5.56 },
  { value: 5.52 },
  { value: 5.49 },
  { value: 5.46 },
  { value: 5.43 },
  { value: 5.41 },
  { value: 5.38 },
  { value: 5.35 },
  { value: 5.32 },
  { value: 5.29 },
  { value: 5.26 },
  { value: 5.24 },
  { value: 5.21 },
  { value: 5.18 },
  { value: 5.15 },
  { value: 5.13 },
  { value: 5.1 },
  { value: 5.08 },
  { value: 5.05 },
  { value: 5.03 },
  { value: 5.0 },
  { value: 4.98 },
  { value: 4.95 },
  { value: 4.93 },
  { value: 4.9 },
  { value: 4.88 },
  { value: 4.85 },
  { value: 4.83 },
  { value: 4.81 },
  { value: 4.78 },
  { value: 4.76 },
  { value: 4.74 },
  { value: 4.72 },
  { value: 4.69 },
  { value: 4.67 },
  { value: 4.65 },
  { value: 4.63 },
  { value: 4.61 },
  { value: 4.59 },
  { value: 4.57 },
  { value: 4.55 },
  { value: 4.52 },
  { value: 4.5 },
  { value: 4.48 },
  { value: 4.46 },
  { value: 4.44 },
  { value: 4.42 },
  { value: 4.41 },
  { value: 4.39 },
  { value: 4.37 },
  { value: 4.35 },
  { value: 4.33 },
  { value: 4.31 },
  { value: 4.29 },
  { value: 4.27 },
  { value: 4.26 },
  { value: 4.24 },
  { value: 4.22 },
  { value: 4.2 },
  { value: 4.18 },
  { value: 4.17 },
  { value: 4.15 },
  { value: 4.13 },
  { value: 4.12 },
  { value: 4.1 },
  { value: 4.08 },
  { value: 4.07 },
  { value: 4.05 },
  { value: 4.03 },
  { value: 4.02 },
  { value: 4.0 },
  { value: 3.98 },
  { value: 3.97 },
  { value: 3.95 },
  { value: 3.94 },
  { value: 3.92 },
  { value: 3.91 },
  { value: 3.89 },
  { value: 3.88 },
  { value: 3.86 },
  { value: 3.85 },
  { value: 3.83 },
  { value: 3.82 },
  { value: 3.8 },
  { value: 3.79 },
  { value: 3.77 },
  { value: 3.76 },
  { value: 3.75 },
  { value: 3.73 },
  { value: 3.72 },
  { value: 3.7 },
  { value: 3.69 },
  { value: 3.68 },
  { value: 3.66 },
  { value: 3.65 },
  { value: 3.64 },
  { value: 3.62 },
  { value: 3.61 },
  { value: 3.6 },
  { value: 3.58 },
  { value: 3.57 },
  { value: 3.56 },
  { value: 3.55 },
  { value: 3.53 },
  { value: 3.52 },
  { value: 3.51 },
  { value: 3.5 },
  { value: 3.48 },
  { value: 3.47 },
  { value: 3.46 },
  { value: 3.45 },
  { value: 3.44 },
  { value: 3.42 },
  { value: 3.41 },
  { value: 3.4 },
  { value: 3.39 },
  { value: 3.38 },
  { value: 3.37 },
  { value: 3.36 },
  { value: 3.34 },
  { value: 3.33 },
  { value: 3.32 },
  { value: 3.31 },
  { value: 3.3 },
  { value: 3.29 },
  { value: 3.28 },
  { value: 3.27 },
  { value: 3.26 },
  { value: 3.25 },
  { value: 3.24 },
  { value: 3.23 },
  { value: 3.22 },
  { value: 3.21 },
  { value: 3.19 },
  { value: 3.18 },
  { value: 3.17 },
  { value: 3.16 },
  { value: 3.15 },
  { value: 3.14 },
  { value: 3.13 },
  { value: 3.12 },
  { value: 3.11 },
  { value: 3.1 },
  { value: 3.09 },
  { value: 3.08 },
  { value: 3.07 },
  { value: 3.06 },
  { value: 3.05 },
  { value: 3.04 },
  { value: 3.03 },
  { value: 3.02 },
  { value: 3.01 },
  { value: 3.0 },
  { value: 2.99 },
  { value: 2.98 },
  { value: 2.97 },
  { value: 2.96 },
  { value: 2.95 },
  { value: 2.94 },
  { value: 2.93 },
  { value: 2.92 },
  { value: 2.91 },
  { value: 2.9 },
  { value: 2.89 },
  { value: 2.88 },
  { value: 2.87 },

  { value: 2.86 },
  { value: 2.85 },
  { value: 2.84 },
  { value: 2.83 },
  { value: 2.82 },

  { value: 2.81 },
  { value: 2.8 },
  { value: 2.79 },

  { value: 2.78 },
  { value: 2.77 },
  { value: 2.76 },
  { value: 2.75 },

  { value: 2.74 },
  { value: 2.73 },
  { value: 2.72 },

  { value: 2.71 },
  { value: 2.7 },

  { value: 2.69 },
  { value: 2.68 },
  { value: 2.67 },

  { value: 2.66 },
  { value: 2.65 },

  { value: 2.64 },
  { value: 2.63 },
  { value: 2.62 },

  { value: 2.61 },
  { value: 2.6 },

  { value: 2.59 },
  { value: 2.58 },

  { value: 2.57 },
  { value: 2.56 },

  { value: 2.55 },
  { value: 2.54 },

  { value: 2.53 },

  { value: 2.52 },
  { value: 2.51 },

  { value: 2.5 },
  { value: 2.49 },

  { value: 2.48 },

  { value: 2.47 },
  { value: 2.46 },

  { value: 2.45 },
  { value: 2.44 },

  { value: 2.43 },

  { value: 2.42 },

  { value: 2.41 },
  { value: 2.4 },

  { value: 2.39 },

  { value: 2.38 },

  { value: 2.37 },
  { value: 2.36 },

  { value: 2.35 },

  { value: 2.34 },

  { value: 2.33 },

  { value: 2.32 },
  { value: 2.31 },

  { value: 2.3 },

  { value: 2.29 },

  { value: 2.28 },

  { value: 2.27 },

  { value: 2.26 },

  { value: 2.25 },

  { value: 2.24 },

  { value: 2.23 },

  { value: 2.22 },

  { value: 2.21 },

  { value: 2.2 },

  { value: 2.19 },

  { value: 2.18 },

  { value: 2.17 },

  { value: 2.16 },

  { value: 2.15 },

  { value: 2.14 },

  { value: 2.13 },

  { value: 2.12 },

  { value: 2.11 },

  { value: 2.1 },

  { value: 2.09 },

  { value: 2.08 },

  { value: 2.07 },

  { value: 2.06 },

  { value: 2.05 },

  { value: 2.04 },

  { value: 2.03 },

  { value: 2.02 },

  { value: 2.01 },

  { value: 2.0 },

  { value: 1.99 },

  { value: 1.98 },

  { value: 1.97 },

  { value: 1.96 },

  { value: 1.95 },

  { value: 1.94 },

  { value: 1.93 },

  { value: 1.92 },

  { value: 1.91 },

  { value: 1.9 },

  { value: 1.89 },

  { value: 1.88 },

  { value: 1.87 },

  { value: 1.86 },

  { value: 1.85 },

  { value: 1.84 },

  { value: 1.83 },

  { value: 1.82 },

  { value: 1.81 },

  { value: 1.8 },

  { value: 1.79 },

  { value: 1.78 },

  { value: 1.77 },

  { value: 1.76 },

  { value: 1.75 },

  { value: 1.74 },

  { value: 1.73 },

  { value: 1.72 },

  { value: 1.71 },

  { value: 1.7 },

  { value: 1.69 },

  { value: 1.68 },

  { value: 1.67 },

  { value: 1.66 },

  { value: 1.65 },

  { value: 1.64 },

  { value: 1.63 },

  { value: 1.62 },

  { value: 1.61 },

  { value: 1.6 },

  { value: 1.59 },

  { value: 1.58 },

  { value: 1.57 },

  { value: 1.56 },

  { value: 1.55 },

  { value: 1.54 },

  { value: 1.53 },

  { value: 1.52 },

  { value: 1.51 },

  { value: 1.5 },

  { value: 1.49 },

  { value: 1.48 },

  { value: 1.47 },

  { value: 1.46 },

  { value: 1.45 },

  { value: 1.44 },

  { value: 1.43 },

  { value: 1.42 },

  { value: 1.41 },

  { value: 1.4 },

  { value: 1.39 },

  { value: 1.38 },

  { value: 1.37 },

  { value: 1.36 },

  { value: 1.35 },

  { value: 1.34 },

  { value: 1.33 },

  { value: 1.32 },

  { value: 1.31 },

  { value: 1.3 },

  { value: 1.29 },

  { value: 1.28 },

  { value: 1.27 },

  { value: 1.26 },

  { value: 1.25 },

  { value: 1.24 },

  { value: 1.23 },

  { value: 1.22 },

  { value: 1.21 },

  { value: 1.2 },

  { value: 1.19 },

  { value: 1.18 },

  { value: 1.17 },

  { value: 1.16 },

  { value: 1.15 },

  { value: 1.14 },

  { value: 1.13 },

  { value: 1.12 },

  { value: 1.11 },

  { value: 1.1 },

  { value: 1.09 },

  { value: 1.08 },

  { value: 1.07 },

  { value: 1.06 },

  { value: 1.05 },

  { value: 1.04 },

  { value: 1.03 },

  { value: 1.02 },
  { value: 1.01 },
  { value: 1.0 },
];
export function findClosestAndAdjust(value, increment = null, type = null) {
  
  console.log({value, increment, type})

  const oddsMappingLadder = {
    back: stxBackLimit,
    lay: stxLayLimit,
  };

    const sortedOdds = type 
    ? oddsMappingLadder[type]?.map((item) => item.value).sort((a, b) => a - b)
    : stxLimit.map((item) => item.value).sort((a, b) => a - b);

  let low = 0;
  let high = sortedOdds.length - 1;
  let closestIndex = -1;

  // Perform binary search to find the closest index
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);

    if (sortedOdds[mid] === value) {
      closestIndex = mid;
      break;
    } else if (sortedOdds[mid] < value) {
      low = mid + 1;
    } else {
      high = mid - 1;
    }
  }

  // Determine the closest index
  if (closestIndex === -1) {
    if (low >= sortedOdds.length) {
      closestIndex = sortedOdds.length - 1;
    } else if (high < 0) {
      closestIndex = 0;
    } else {
      closestIndex =
        Math.abs(sortedOdds[low] - value) < Math.abs(sortedOdds[high] - value)
          ? low
          : high;
    }
  }

  // If increment is null, return the closest value directly
  if (increment === null) {
    return sortedOdds[closestIndex];
  }

  // Adjust index based on increment or decrement
  const newIndex = increment
    ? Math.min(closestIndex + 1, sortedOdds.length - 1)
    : Math.max(closestIndex - 1, 0);

  return sortedOdds[newIndex];
}
