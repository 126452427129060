import { INDIAN_CASINO, INDIAN_CASINO_SUCCESS, INDIAN_CASINO_FAILURE } from "../types";

export const indianCasino = (payload) => ({
  type: INDIAN_CASINO,
  payload,
});

export const indianCasinoSuccess = (payload) => ({
  type: INDIAN_CASINO_SUCCESS,
  payload,
});

export const indianCasinoFailure = () => ({
  type: INDIAN_CASINO_FAILURE,
});
