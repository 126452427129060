import { put, takeEvery, all, call } from "redux-saga/effects";
import { LOGOUT } from "../../actions/types";
import API from "../../../utils/api";
import { getLocalStorageItem } from "../../../utils/helper";
import { logOutSuccess } from "../../actions/auth/logoutAction";

function* logoutSaga(action) {
  let user_data = JSON.parse(getLocalStorageItem("userData"));
  let payload = {
    user_id: user_data?.id,
  };
  const { data } = yield API.post("/api/v1/logout", payload);
  if (data?.meta?.code === 200) {
    // Remove data from localStorage here
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
    localStorage.removeItem("googleToken");
    yield put(logOutSuccess());
    yield call(action.payload.callback, data);
  }
}

export function* watchlogoutAPI() {
  yield takeEvery(LOGOUT, logoutSaga);
}

export default function* rootSaga() {
  yield all([watchlogoutAPI()]);
}
