import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_SPORT_SETTING } from "../../actions/types";
import { getSportSettingSuccess, getSportSettingFailure } from "../../actions";
import API from "../../../utils/api";

function* getSportSettingRequest(action) {
  try {
    const { auth } = action.payload;
    console.log(action.payload);
    let responseData;
    if (auth) {
      const { data } = yield API.get(
        `/api/v1/setting?sportsId=${action?.payload?.sportsId}&leagueId=${action?.payload?.leagueId}&eventId=${action?.payload?.eventId}&id=${action?.payload?.id}`
      );
      responseData = data;
    } else {
      const { data } = yield API.get(
        `/api/v1/default-setting?sportsId=${action?.payload?.sportsId}&leagueId=${action?.payload?.leagueId}&eventId=${action?.payload?.eventId}&id=${action?.payload?.id}`
      );
      responseData = data;
    }
    if (responseData.meta.code === 200) {
      yield put(getSportSettingSuccess(responseData?.data));
      yield call(action.payload.callback, responseData.data);
    } else if (responseData.meta.code === 401) {
      yield put(getSportSettingSuccess());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (responseData.meta.code !== 200) {
      yield put(getSportSettingFailure());
    }
  } catch (error) {
    yield put(getSportSettingFailure());
  }
}

export function* watchgetSportSettingAPI() {
  yield takeEvery(GET_SPORT_SETTING, getSportSettingRequest);
}

export default function* rootSaga() {
  yield all([watchgetSportSettingAPI()]);
}
