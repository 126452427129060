import React, { lazy } from "react";
import Footer from "../components/Footer";
import Home from "../components/Home";

// const Home = lazy(() => import("../components/Home"));

const HomePage = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="w-full lg:p-2 p-1 overflow-y-scroll scrollbar-hide h-[91vh] ">
        <Home />
        <Footer scrollToTop={scrollToTop} />
      </div>
      <div className="hidden lg:flex lg:flex-col md:basis-6/6"></div>
    </>
  );
};

export default HomePage;
