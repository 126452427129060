import { all, call, put, takeEvery } from "redux-saga/effects";
import { META_CREATE } from "../../actions/types";
import { metaCreateSuccess, metaCreateFailure } from "../../actions/metaWallet/metaCreateAction";
import API from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";


function* CreateMetaWallet(action) {
  try {
      const { data } = yield API.post(
        "/api/v1/moneyplant-wallet-create",
        action.payload
      );
    if (data.code!==400) {
      
      yield put(metaCreateSuccess(true));
      yield call(action.payload.callback, data);
      
    } 
    else{ 
      
      yield put(metaCreateFailure());
      yield call(action.payload.callback, data);
    }
  } catch (error) {
    console.log(error);
    notifyWarning("Couldn't initiate Wallet Creation");
    yield put(metaCreateFailure());
  }
}

export function* watchCreateWalletAPI() {
  yield takeEvery(META_CREATE, CreateMetaWallet);
}

export default function* rootSaga() {
  yield all([watchCreateWalletAPI()]);
}
