import {
    GET_BOOKMAKER_MARKETS,
    GET_BOOKMAKER_MARKETS_SUCCESS,
    GET_BOOKMAKER_MARKETS_FAILURE,
  } from "../types";
  
  export const getBookmakerMarkets = (payload) => ({
    type: GET_BOOKMAKER_MARKETS,
    payload
  });
  
  export const getBookmakerMarketsSuccess  = (payload) => ({
    type: GET_BOOKMAKER_MARKETS_SUCCESS,
    payload,
  });
  
  export const getBookmakerMarketsFailure = () => ({
    type: GET_BOOKMAKER_MARKETS_FAILURE,
  });
  