//import all Saga functions you are creating here...
//Add it inside the array like function eg:mySaga()

import { all } from "redux-saga/effects";
import GetSport from "../saga/getSport/getSportSaga";
import Login from "../saga/auth/loginSaga";
import Logout from "../saga/auth/logoutSaga";
import ChangePassword from "./auth/changePasswordSaga";
import GetAllSportData from "./sportData/getAllSportDataSaga";
import GlobalSportData from "./globalData/globalSportDataSaga";
import GlobalMarketOdds from "./globalData/marketOddsSaga";
import GetRunnerData from "./runnerData/getRunnerDataSaga";
import PlaceBet from "./bet/placeBetSaga";
import GetBet from "./bet/getBetSaga";
import GetUserData from "./userData/getUserDataSaga";
import ChipSetting from "./settings/chipSettingSaga";
import GetChipSetting from "./settings/getChipSettingSaga";
import GetSportSetting from "./settings/getSportSettingSaga";
import getAccountStatement from "./userData/getAccountStatementSaga";
import getMyMarkets from "./userData/getMyMarkets";
import getResultTransaction from "./userData/getResultTransactionSaga";
import getEventBets from "./userData/getEventBetsSaga";
import getBookmakerMarkets from "./markets/getBookmakerMarketsSaga";
import GetActiveFancies from "./fancyData/getActiveFanciesSaga";
import CreateSessionSaga from "./casino/createSessionSaga";
import GetBalanceSaga from "./casino/getBalanceSaga";
import GetCreditSaga from "./casino/getCreditSaga";
import GetDebitSaga from "./casino/getDebitSaga";
import GetTvUrl from "./live/getTvUrlSaga";
import GetCasinoBets from "./userData/getCasinoBetsSaga";
import IndianCasinoUrl from "./indianCasino/generateIndianCasinoUrlSaga";
import GetHomeSlider from "./sliders/getHomeSliderSaga";
import GetDeletedEventBets from "./userData/getDeletedEventBetsSaga";
import GetSt8Casino from "./st8Casino/getCasinoDataSaga";
import GetSt8CasinoUrl from "./st8Casino/getSt8CasinoUrlSaga";
import FetchWhiteLabel from "./whiteLabel/fetchWhiteLabelSaga";
import DeleteUnmatchBet from "./bet/deleteUnmatchBetSaga";
import GetPopularEvents from "./events/getPopularEvents";
import GetEventData from "./events/getEventDataSaga";
import GetHomeData from "./home/getHomeDataSaga";
import GetInPlayData from "./live/getInPlaySaga";
import GetEventExch from "./odds/getEventExchSaga";

import TwoFactorStatusUpdateSaga from "./TwoFA/TwoFactorStatusUpdateSaga";
import VerifyTwoFactorCodeSaga from "./TwoFA/VerifyTwoFactorCodeSaga";
import GoogleLoginValidationSaga from "./TwoFA/googleLoginValidationSaga";
import GenerateQRCodeSaga from "./TwoFA/GenerateQRCodeSaga";
import GoogleAuthenticatorOTPValidationSaga from "./TwoFA/GoogleAuthenticatorOTPValidationSaga";
import TypeCodeFanciesSaga from "./fancyData/getTypeCodeFanciesSaga";
import GetSportLeagues from "./getSport/getSportLeagues";
import GetLeagueEvents from "./events/getLeagueEvents";
import GetInPlayBySport from "./live/getInPlayBySportSaga";
import SearchKeywordSaga from "./Search/searchKeywordSaga";
import NotificationSaga from "./notification/notificationSaga";

import GetSportDatas from "./sportData/getSportDatas";
import CurrencySaga from "./currency/currencySaga";
import GetUserBalanceData from "./userData/getUserBalanceData";
import GetGapCasinoDataSaga from "./gapCasino/getGapCasinoDataSaga";
import GetGapCasinoUrlSaga from "./gapCasino/getGapCasinoUrlSaga";
import HorseRacingEventsSaga from "./horseRacing/horseRacingEventsSaga";
import PlaceBetHorseRaceSaga from "./horseRacing/placeBetHorseRacingSaga";
import HorseRaceSettings from "./horseRacing/horseRacingSettingSaga";
import GetBetHorseRaceSaga from "./horseRacing/getBetHorseRaceSaga";
import HorseRaceDataSaga from "./horseRacing/horseRaceDataSaga";
import unMatchedHorseRaceBets from "./horseRacing/unmatchHorseRacingSaga";
import HorseRaceCountriesVenue from "./horseRacing/getHorseRaceCountriesVenueSaga";
import HorseRaceEvents from "./horseRaceEvents/horseRaceEventsSaga";
import MarketAnalytics from "./analytics/analyticsSaga";
import CreateMetaWallet from "./meta/createWalletSaga";
import CheckMetaWallet from "./meta/checkWalletSaga";
import DepositWallet from "./meta/depositWalletSaga";
import WithdrawWallet from "./meta/withdrawWalletSaga";

import HorseDataManage from "./horseDataManage/horseDataManage";
import HorseRaceLive from "./horseRaceEvents/horseRaceLiveSaga";

// stx placebet
import StxPlaceBetSaga from "./STX/stxPlaceBetSaga"; 
import DeleteUnmatchStxBetSaga from "./STX/deleteUnmatchStxBetSaga"; 

export default function* rootSaga() {
  yield all([
    Login(),
    Logout(),
    ChangePassword(),
    GetSport(),
    GetAllSportData(),
    GlobalSportData(),
    GlobalMarketOdds(),
    GetRunnerData(),
    PlaceBet(),
    GetBet(),
    GetUserData(),
    ChipSetting(),
    GetChipSetting(),
    GetSportSetting(),
    getAccountStatement(),
    getMyMarkets(),
    getResultTransaction(),
    getEventBets(),
    getBookmakerMarkets(),
    GetActiveFancies(),
    CreateSessionSaga(),
    GetBalanceSaga(),
    GetCreditSaga(),
    GetDebitSaga(),
    GetTvUrl(),
    GetCasinoBets(),
    IndianCasinoUrl(),
    GetHomeSlider(),
    GetDeletedEventBets(),
    GetSt8Casino(),
    GetSt8CasinoUrl(),
    FetchWhiteLabel(),
    DeleteUnmatchBet(),
    GetPopularEvents(),
    GetEventData(),
    GetHomeData(),
    GetInPlayData(),
    GetEventExch(),
    TwoFactorStatusUpdateSaga(),
    VerifyTwoFactorCodeSaga(),
    GoogleLoginValidationSaga(),
    GenerateQRCodeSaga(),
    GoogleAuthenticatorOTPValidationSaga(),
    TypeCodeFanciesSaga(),
    GetSportLeagues(),
    GetLeagueEvents(),
    GetInPlayBySport(),
    SearchKeywordSaga(),
    NotificationSaga(),
    GetSportDatas(),
    CurrencySaga(),
    GetUserBalanceData(),
    GetGapCasinoDataSaga(),
    GetGapCasinoUrlSaga(),
    HorseRacingEventsSaga(),
    PlaceBetHorseRaceSaga(),
    HorseRaceSettings(),
    GetBetHorseRaceSaga(),
    HorseRaceDataSaga(),
    unMatchedHorseRaceBets(),
    HorseRaceCountriesVenue(),
    HorseRaceEvents(),
    MarketAnalytics(),
    CreateMetaWallet(),
    CheckMetaWallet(),
    DepositWallet(),
    WithdrawWallet(),
    HorseDataManage(),
    HorseRaceLive(),
    StxPlaceBetSaga(),
    DeleteUnmatchStxBetSaga(),
  ]);
}
