import { all, takeEvery, put, call } from "redux-saga/effects";
import { PLACE_BET_HORSERACE } from "../../actions/types";
import { placeBetHorseRaceSuccess, placeBetHorseRaceFailure } from "../../actions";
import Api from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";

function* placeBetHorseRaceRequest(action) {
  try {
    const { data } = yield Api.post(
        "api/v1/bet-sport-horse-racing",
        action?.payload?.data
      );

    if (data?.meta?.code === 200) {
      yield put(placeBetHorseRaceSuccess(data));
      yield call(action?.payload?.callback, data);
      notifySuccess(data.meta.message);
    } else if (data.meta.code === 401) {
      yield put(placeBetHorseRaceFailure());
      notifyWarning(data.meta.message);
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
      window.location.reload();
    } else if (data?.meta?.code !== 200) {
      yield put(placeBetHorseRaceFailure());

      notifyWarning(data.meta.message);
      yield call(action?.payload?.callback, data);
    }
  } catch (error) {
    yield put(placeBetHorseRaceFailure());
  }
}

export function* watchPlaceBetHorseRaceAPI() {
  yield takeEvery(PLACE_BET_HORSERACE, placeBetHorseRaceRequest);
}

export default function* rootSaga() {
  yield all([watchPlaceBetHorseRaceAPI()]);
}
