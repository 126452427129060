import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_TV_URL } from "../../actions/types";
import { getTvUrlSuccess, getTvUrlFailure } from "../../actions";
import API from "../../../utils/api";

function* getTvUrlRequest(action) {
  try {
    const { data } = yield API.post("/api/v1/get-tv-url", action?.payload);
    if (data.meta.code === 200) {
      yield put(getTvUrlSuccess(data?.data));
      yield call(action.payload.callback, data?.data);  
    } else if (data.meta.code !== 200) {
      yield put(getTvUrlFailure());
    }
  } catch (error) {
    yield put(getTvUrlFailure());
  }
}

export function* watchgetTvUrlAPI() {
  yield takeEvery(GET_TV_URL, getTvUrlRequest);
}

export default function* rootSaga() {
  yield all([watchgetTvUrlAPI()]);
}
