// LazyLoadWithDelay.js
import React, { lazy, useEffect, useState, useTransition } from "react";
import XLoader from "../Loader/XLoader";

const LazyLoadWithDelay = (importFunc, delayMs = 1000) => {
  const LazyComponent = lazy(importFunc);

  const DelayedComponent = (props) => {
    const [isPending, startTransition] = useTransition();
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const loadComponent = async () => {
        await new Promise((resolve) => setTimeout(resolve, delayMs)); // Delay for specified milliseconds
        setIsLoading(false);
        startTransition(() => {
          setIsVisible(true);
        });
      };

      loadComponent();
    }, []);

    return isLoading ? <XLoader /> : <LazyComponent {...props} />;
  };

  return DelayedComponent;
};

export default LazyLoadWithDelay;
