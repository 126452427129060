import {
  GAP_CASINO_GAMES_URL,
  GAP_CASINO_GAMES_URL_FAILURE,
  GAP_CASINO_GAMES_URL_SUCCESS,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  data: null,
};

const getGapCasinoUrlReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GAP_CASINO_GAMES_URL:
      return { ...state, loading: true };
    case GAP_CASINO_GAMES_URL_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case GAP_CASINO_GAMES_URL_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default getGapCasinoUrlReducer;
