import { all, call, put, takeEvery } from "redux-saga/effects";
import { CHIP_SETTING } from "../../actions/types";
import { chipSettingSuccess, chipSettingFailure } from "../../actions";
import API from "../../../utils/api";
import {
  notifySuccess,
  notifyWarning,
} from "../../../utils/helper";

function* chipSettingRequest(action) {
  try {
    const { data } = yield API.post(
      "/api/v1/chip-setting",
      action?.payload?.chip
    );

    if (data.meta.code === 200) {
      yield put(chipSettingSuccess(data?.data));
      yield call(action.payload.callback, data);
      notifySuccess(data.meta.message);
    } else if (data.meta.code === 401) {
      yield put(chipSettingFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(chipSettingFailure());
      yield call(action.payload.errorCallback, data);
      notifyWarning(data.meta.message);
    }
  } catch (error) {
    yield call(action.payload.errorCallback, error?.response?.data);
    yield put(chipSettingFailure());
  }
}

export function* watchchipSettingAPI() {
  yield takeEvery(CHIP_SETTING, chipSettingRequest);
}

export default function* rootSaga() {
  yield all([watchchipSettingAPI()]);
}
