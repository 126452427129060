import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { RiArrowUpSLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import MarketDataCard from "./MarketDataCard";
import { checkCasinoAccess, findEventsLength } from "../utils/helper";
import LiveCasinoSlider from "./LiveCasinoSlider";
import { availableCasinos, mobileThreshold } from "../utils/constants";
import { getHomeSlider, getHomeData, getInPlay, getCurrency } from "../redux/actions";
import InplayCard from "./InplayCard";
import API from "../utils/oddApi";
import { BallTriangle } from "react-loader-spinner";

const Home = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.GetUserData?.userData?.data);
  // ==================================CALLING THE API DATA======================================

  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [filteredSportButton, setFilteredSportButton] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [marketCodes, setMarketCodes] = useState([]);
  const [marketOdds, setMarketOdds] = useState([]);
  const [sportData, setSportData] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [liveLoading, setLiveLoading] = useState(false);

  const [prevMarketOdds, setPrevMarketOdds] = useState([]);

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true, // Enable fade effect
  };

  const sportsButtons = [
    {
      icon: "https://sportsexch.com/images/icons/sports.png",
      name: "Sports",
      route: "/all-sports",
    },
    {
      icon: "https://sportsexch.com/images/icons/live.png",
      name: "In-Play",
      route: "/in-play",
    },
    {
      icon: "https://sportsexch.com/images/icons/live-casino.png",
      name: "Casino",
      route: "/casino",
      value: availableCasinos.LIVE_CASINO,
      type: "casino",
    },
    {
      icon: "https://sportsexch.com/images/icons/trophy.png",
      name: "Slots",
      route: "/slots",
      value: availableCasinos.LIVE_CASINO,
      type: "casino",
    },
  ];

  useEffect(() => {
    dispatch(
      getHomeData({
        callback: (data) => {
          if (data) {
            setSportData(data?.sportData);
            const marketCodes = data?.marketCodes;
            setMarketCodes(marketCodes);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    const fetchData = async (loading) => {
      if (loading) {
        setLiveLoading(true);
      }

      //  console.log(loading, "loading");
      dispatch(
        getInPlay({
          callback: (data) => {
            // console.log(data, "data");
            if (data) {
              setLiveData(data?.liveData);
              const marketCodes = data?.allMarketIds;
              setMarketCodes((prevData) => {
                // Combine previous data and new data into a Set to remove duplicates
                const uniqueMarketCodes = new Set([...prevData,...marketCodes]);
                // Convert the Set back to an array
                return [...uniqueMarketCodes];
              });
            }
            //console.log(liveLoading, "liveLoading");
            if (loading) setLiveLoading(false);
          },
        })
      );
    };
    fetchData(true);
    const homeInterval = setInterval(() => {
      fetchData(false);
    }, 180000); // for check in every 3 minutes

    return () => {
      clearInterval(homeInterval);
    };
  }, []);

  useEffect(() => {
    //console.log(marketCodes, "marketCodes");

    const fetchOdds = async () => {
      API.post("odds/home", marketCodes)
        .then((response) => {
          if (response?.data?.data) {
            setMarketOdds((prevState) => {
              setPrevMarketOdds(prevState);
              return response?.data?.data;
            });
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    let homeInterval;

    if (marketCodes?.length > 0) {
      fetchOdds();
      homeInterval = setInterval(() => {
        fetchOdds();
      }, 700);
    }
    return () => {
      clearInterval(homeInterval);
    };
  }, [marketCodes]);

  useEffect(() => {
    const checkScreenSize = () => {
      const mobileSize = mobileThreshold

      if (window.innerWidth < mobileSize) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkScreenSize();

    const handleResize = () => {
      checkScreenSize();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(
      getHomeSlider({
        data: isMobile,
        callback: (data) => {
          setSliders(data);
        },
      })
    );
  }, [isMobile]);

  useEffect(() => {
    if (userData) {
      const filteredButtons = sportsButtons?.filter((data) => {
        if (checkCasinoAccess(userData?.casino, data?.value)) {
          return data;
        }
      });

      setFilteredSportButton(filteredButtons);
    } else {
      setFilteredSportButton(sportsButtons);
    }
  }, [userData]);
  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  // ============================================================================================

  return (
    <div className="w-full sm:px-0 mb-4">
      <div>
        <Slider {...settings}>
          {sliders?.map((img, index) => {
            return (
              <div key={index}>
                <LazyLoadImage
                  effect="opacity"
                  delayMethod="debounce"
                  delayTime={3000}
                  alt={`Slide ${index + 1}`}
                  className="w-full rounded-xl"
                  src={img?.image}
                />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="flex overflow-x-auto w-full scroll-x ">
        <Swiper
          slidesPerView={
            filteredSportButton?.length === sportsButtons?.length
              ? 3.7
              : filteredSportButton?.length
          }
          spaceBetween={4}
          freeMode={true}
          draggable={true}
          className="flex gap-1 rounded-xl bg-skin-main overflow-x justify-center"
        >
          {filteredSportButton?.map((element, index) => {
            return (
              <SwiperSlide key={index}>
                <Link to={element.route}>
                  <div
                    className={`cursor-pointer  w-full rounded-md py-2 p-2 text-xs font-medium leading-5 ring-opacity-60 ring-offset-2 text-skin-white bg-skin-nav hover:bg-skin-hovercolor hover:text-skin-white`}
                  >
                    <div className="relative block">
                      <img
                        alt="profil"
                        src={element.icon}
                        className="mx-auto object-cover rounded-full h-5 w-5 bg-skin-nav"
                      />
                    </div>
                    <p className="text-center whitespace-nowrap overflow-hidden ">
                      {element.name}
                    </p>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {!liveLoading ? (
        <>
          {liveData?.length > 0 && (
            <div>
              {/* {console.log("prevMarketOdds",prevMarketOdds)} */}
              {liveData?.map((sport, idx) => {
                return (
                  <InplayCard
                    key={idx}
                    leagues={sport?.leagues}
                    sport={sport?.slugName}
                    sportCode={sport?.sportCode}
                    sportId={sport?._id}
                    home={true}
                    marketOdds={marketOdds}
                    prevMarketOdds={prevMarketOdds}
                  />
                );
              })}
            </div>
          )}{" "}
        </>
      ) : (
        <>
          <div className="flex justify-between mt-1">
            <p className="text-skin-white pb-2 px-2 text-lg font-semibold cursor-pointer flex">
              Live Data
            </p>
          </div>
          <div className="flex justify-center">
            <div className="flex flex-col items-center">
              <BallTriangle
                height={100}
                width={100}
                radius={3}
                color="#EDC529"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                ariaLabel="ball-triangle-loading"
                visible={true}
              />
              <p className=" font-semibold m-0 p-0">
                Checking For Live Sports...
              </p>
            </div>
          </div>
        </>
      )}
      {sportData?.map((sport) => {
        if (sport?.leagues?.length > 0) {
          return (
            <div>
              <div className="flex justify-between mt-1">
                <p
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="text-skin-white pb-2 px-2 text-lg font-semibold cursor-pointer flex"
                >
                  {sport?.name}
                  {/* {isDropdownOpen ? (
                    <RiArrowUpSLine className="ml-2 text-xl m-auto" />
                  ) : (
                    <IoIosArrowDown className="ml-2 text-lg  m-auto" />
                  )} */}
                </p>
                <p className="text-skin-white pb-2 px-2 text-lg font-semibold">
                  {sportData !== undefined && sportData?.length !== 0
                    ? `${findEventsLength(sport?.leagues)} Events`
                    : "Events"}
                </p>
              </div>
              {sport?.leagues?.map(
                (league, idx) =>
                  isDropdownOpen && (
                    <MarketDataCard
                      league={league}
                      key={idx}
                      sport={sport?.slugName}
                      sportId={sport?._id}
                      sportCode={sport?.sportsCode}
                      marketOdds={marketOdds}
                      prevMarketOdds={prevMarketOdds}
                    />
                  )
              )}
            </div>
          );
        } else {
          return null;
        }
      })}
      {userData ? (
        <>
          {userData?.casino?.includes(availableCasinos.LIVE_CASINO) ||
          userData?.casino?.includes(availableCasinos.ALL) ||
          userData?.casino?.length === 0 ? (
            <LiveCasinoSlider />
          ) : (
            ""
          )}
        </>
      ) : (
        <LiveCasinoSlider />
      )}
    </div>
  );
};

export default Home;
