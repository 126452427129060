import { all, call, put, takeEvery } from "redux-saga/effects";
import { NOTIFICATION } from "../../actions/types";
import { notificationSuccess, notificationFailure } from "../../actions";
import API from "../../../utils/api";

function* notificationRequest(action) {
  try {
    let response;
    if(action?.payload?.type ==="post"){
      const { data } = yield API.post(
        "/api/v1/notification/",
        action.payload.user
      );
      response=data;
    } else if(action?.payload?.type ==="get"){
      const { data } = yield API.get(
        "/api/v1/notification/",
        action.payload.user
      );
      response = data;
    }
   

    if (response.meta.code === 200) {
      yield put(notificationSuccess(response?.data));
      yield call(action.payload.callback, response);
    } else if (response.meta.code !== 200) {
      yield put(notificationFailure());
    }
  } catch (error) {
    console.log(error);
    yield put(notificationFailure());
  }
}

export function* watchNotificationAPI() {
  yield takeEvery(NOTIFICATION, notificationRequest);
}

export default function* rootSaga() {
  yield all([watchNotificationAPI()]);
}
