import React from 'react'
import { defaultChipValues } from '../utils/constants'
const DefaultChip = ({ handleClick ,currency}) => {
  return (
    <>
      {defaultChipValues?.map((chipVal) => {
        return (
          <span
            className="bg-skin-chipBg text-skin-white rounded-md cursor-pointer mt-2 px-4 py-1 text-center font-medium "
            onClick={() => handleClick(chipVal?.value * parseFloat(currency))}
          >  
            <span>{chipVal?.value * parseFloat(currency)}</span>
          </span>
        )
      })}
    </>
  )
}

export default DefaultChip