import { all, takeEvery, put, call } from "redux-saga/effects";
import { DELETE_UNMATCH_STX_BET } from "../../actions/types";
import {
  deleteUnmatchStxBetSuccess,
  deleteUnmatchStxBetFailure,
} from "../../actions";
import API from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";

function* deleteUnmatchStxBetRequest(action) {
  try {
    const { data } = yield API.post(
      "api/v1/delete-unmatch-stx-bet",
      action?.payload?.form
    );
    if (data?.meta?.code === 200) {
      yield put(deleteUnmatchStxBetSuccess(data));
      yield call(action?.payload?.callback, data);
      notifySuccess(data.meta.message);
    } else if (data.meta.code === 401) {
      yield put(deleteUnmatchStxBetFailure());
      notifyWarning(data.meta.message);
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
      window.location.reload();
    } else if (data?.meta?.code !== 200) {
      yield put(deleteUnmatchStxBetFailure());
      yield call(action?.payload?.callback, data);
      notifyWarning(data.meta.message);
    }
  } catch (error) {
    yield call(action?.payload?.callback, error);
    yield put(deleteUnmatchStxBetFailure());
  }
}

export function* watchDeleteUnmatchStxBetAPI() {
  yield takeEvery(DELETE_UNMATCH_STX_BET, deleteUnmatchStxBetRequest);
}

export default function* rootSaga() {
  yield all([watchDeleteUnmatchStxBetAPI()]);
}
