import { HORSERACE_DATA , HORSERACE_DATA_SUCCESS , HORSERACE_DATA_FAILURE } from "../types";

export const getHorseData = (payload) => ({
  type: HORSERACE_DATA,
  payload,
});

export const getHorseRaceDataSuccess = (payload) => ({
  type: HORSERACE_DATA_SUCCESS,
  payload,
});

export const getHorseRaceDataFailure = () => ({
  type: HORSERACE_DATA_FAILURE,
});
