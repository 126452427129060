import {
  INDIAN_CASINO, INDIAN_CASINO_SUCCESS, INDIAN_CASINO_FAILURE
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  indianCasino: null,
};

const indianCasinoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INDIAN_CASINO:
      return { ...state, loading: true };
    case INDIAN_CASINO_SUCCESS:
      return {
        ...state,
        indianCasino: action.payload,
        loading: false,
      };
    case INDIAN_CASINO_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default indianCasinoReducer;
