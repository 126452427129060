export const Paths = {
  home: "/home",
  sports: "/all-sports",
  inplay: "/in-play",
  indianCasino: "/indian-casino",
  slots: "/slots",
  iplclub: "/indian-premier-league",
  // liveCasino: "/live-casino",
  deposit: "/deposit",
  withdrawal: "/withdrawal-request",
  mymarket: "/my-market",
  stocks: "/stocks",
  pl: "/reports",
  accountstatement: "/account-statement",
  cricketleague: "/:sport/:id/:event",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-and-conditions",
  playResponsible: "/responsible-gambling",
  aboutUs: "/about-us",
  kycAndPrivacy: "/general-policy",
  st8Casino: "/st8-casino",
  gapCasino: "/casino",
  horseRacing :"/horse_racing",
  horseRacingEvents:"/horse_racing/:id",
  analytics: "/market-view/:market/:selection",
};
