import {
  GET_SPORT_LEAGUES,
  GET_SPORT_LEAGUES_SUCCESS,
  GET_SPORT_LEAGUES_FAILURE,
} from "../types";

export const getSportLeagues = (payload) => ({
  type: GET_SPORT_LEAGUES,
  payload,
});

export const getSportLeaguesSuccess = (payload) => ({
  type: GET_SPORT_LEAGUES_SUCCESS,
  payload,
});

export const getSportLeaguesFailure = () => ({
  type: GET_SPORT_LEAGUES_FAILURE,
});
