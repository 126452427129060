import {
  GET_EVENT_DATA,
  GET_EVENT_DATA_SUCCESS,
  GET_EVENT_DATA_FAILURE,
  RESET_EVENT_DATA,
} from "../types";

export const getEventData = (payload) => ({
  type: GET_EVENT_DATA,
  payload,
});

export const getEventDataSuccess = (payload) => ({
  type: GET_EVENT_DATA_SUCCESS,
  payload,
});

export const getEventDataFailure = () => ({
  type: GET_EVENT_DATA_FAILURE,
});

export const resetEventData = () => ({
  type: RESET_EVENT_DATA, 
});

