import {
  GET_SPORT_DATAS,
  GET_SPORT_DATAS_SUCCESS,
  GET_SPORT_DATAS_FAILURE,
} from "../types";

export const getSportDatas = (payload) => ({
  type: GET_SPORT_DATAS,
  payload,
});

export const getSportDatasSuccess = (payload) => ({
  type: GET_SPORT_DATAS_SUCCESS,
  payload,
});

export const getSportDatasFailure = () => ({
  type: GET_SPORT_DATAS_FAILURE,
});
