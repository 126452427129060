import {
  GET_HOME_SLIDER,
  GET_HOME_SLIDER_SUCCESS,
  GET_HOME_SLIDER_FAILURE,
} from "../types";

export const getHomeSlider = (payload) => ({
  type: GET_HOME_SLIDER,
  payload,
});

export const getHomeSliderSuccess = (payload) => ({
  type: GET_HOME_SLIDER_SUCCESS,
  payload,
});

export const getHomeSliderFailure = () => ({
  type: GET_HOME_SLIDER_FAILURE,
});
