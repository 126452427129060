import {
  GET_HOME_DATA,
  GET_HOME_DATA_SUCCESS,
  GET_HOME_DATA_FAILURE,
} from "../types";

export const getHomeData = (payload) => ({
  type: GET_HOME_DATA,
  payload,
});

export const getHomeDataSuccess = (payload) => ({
  type: GET_HOME_DATA_SUCCESS,
  payload,
});

export const getHomeDataFailure = () => ({
  type: GET_HOME_DATA_FAILURE,
});
