import { all, put, takeEvery } from "redux-saga/effects";
import { GET_USERDATA } from "../../actions/types";
import { getUserDataSuccess, getUserDataFailure } from "../../actions";
import API from "../../../utils/api";

function* getUserDataRequest() {
  try {
    const { data } = yield API.get(`api/v1/getUserData`);
    if (data.meta.code === 200) {
      yield put(getUserDataSuccess(data));
    } else if (data.meta.code === 401) {
      yield put(getUserDataFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(getUserDataFailure());
    }
  } catch (error) {
    yield put(getUserDataFailure());
  }
}

export function* watchgetUserDataAPI() {
  yield takeEvery(GET_USERDATA, getUserDataRequest);
}

export default function* rootSaga() {
  yield all([watchgetUserDataAPI()]);
}
