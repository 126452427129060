import {
  GET_HOME_SLIDER,
  GET_HOME_SLIDER_SUCCESS,
  GET_HOME_SLIDER_FAILURE,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  sliders: null,
};

const getHomeSliderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HOME_SLIDER:
      return { ...state, loading: true };
    case GET_HOME_SLIDER_SUCCESS:
      return { ...state, sliders: action.payload, loading: false };
    case GET_HOME_SLIDER_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default getHomeSliderReducer;
