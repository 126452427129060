import {
  GET_DELETED_EVENT_BETS,
  GET_DELETED_EVENT_BETS_SUCCESS,
  GET_DELETED_EVENT_BETS_FAILURE,
} from "../types";

export const getDeletedEventBets = (payload) => ({
  type: GET_DELETED_EVENT_BETS,
  payload,
});

export const getDeletedEventBetsSuccess = (payload) => ({
  type: GET_DELETED_EVENT_BETS_SUCCESS,
  payload,
});

export const getDeletedEventBetsFailure = () => ({
  type: GET_DELETED_EVENT_BETS_FAILURE,
});
