import { HORSE_RACING_SETTINGS, HORSE_RACING_SETTINGS_FAILURE , HORSE_RACING_SETTINGS_SUCCESS } from "../types";

export const getHorseRaceSettings = (payload) => ({
  type: HORSE_RACING_SETTINGS,
  payload,
});

export const getHorseRaceSettingsSuccess = (payload) => ({
  type: HORSE_RACING_SETTINGS_SUCCESS,
  payload,
});

export const getHorseRaceSettingsFailure = () => ({
  type: HORSE_RACING_SETTINGS_FAILURE,
});
