import { all, takeEvery, put, call } from "redux-saga/effects";
import { DELETE_UNMATCH_BET } from "../../actions/types";
import {
  deleteUnmatchBetSuccess,
  deleteUnmatchBetFailure,
} from "../../actions";
import API from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";

function* deleteUnmatchBetRequest(action) {
  try {
    const { data } = yield API.post(
      "api/v1/delete-unmatch-bet",
      action?.payload?.form
    );
    if (data?.meta?.code === 200) {
      yield put(deleteUnmatchBetSuccess(data));
      yield call(action?.payload?.callback, data);
      notifySuccess(data.meta.message);
    } else if (data.meta.code === 401) {
      yield put(deleteUnmatchBetFailure());
      notifyWarning(data.meta.message);
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
      window.location.reload();
    } else if (data?.meta?.code !== 200) {
      yield put(deleteUnmatchBetFailure());
      yield call(action?.payload?.callback, data);
      notifyWarning(data.meta.message);
    }
  } catch (error) {
    yield call(action?.payload?.callback, error);
    yield put(deleteUnmatchBetFailure());
  }
}

export function* watchDeleteUnmatchBetAPI() {
  yield takeEvery(DELETE_UNMATCH_BET, deleteUnmatchBetRequest);
}

export default function* rootSaga() {
  yield all([watchDeleteUnmatchBetAPI()]);
}
