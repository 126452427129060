import { all, call, put, takeEvery } from "redux-saga/effects";
import { TYPECODEFANCIESDATA } from "../../actions/types";
import {
  getTypeCodeFanciesSuccess,
  getTypeCodeFanciesFailure,
} from "../../actions";
import API from "../../../utils/api";

function* getTypeCodeFanciesRequest(action) {
  try {
    let response;
    if(action.payload?.payload?.auth)
      {
        const { data } = yield API.post(`api/v1/gettypecode-currency/`, action.payload);
        response = data;
      }else{
        const { data } = yield API.post(`api/v1/gettypecode/`, action.payload);
        response = data;
      }
    
    if (response?.meta.code === 200) {
      yield put(getTypeCodeFanciesSuccess(response));
      yield call(action.payload.callback, response.data);
    } else if (response.meta.code !== 200) {
      yield put(getTypeCodeFanciesFailure());
    }
  } catch (error) {
    yield put(getTypeCodeFanciesFailure());
  }
}
export function* watchtgetTypeCodeFanciesAPI() {
  yield takeEvery(TYPECODEFANCIESDATA, getTypeCodeFanciesRequest);
}

export default function* rootSaga() {
  yield all([watchtgetTypeCodeFanciesAPI()]);
}
