import {
  GET_POPULAR_EVENTS,
  GET_POPULAR_EVENTS_SUCCESS,
  GET_POPULAR_EVENTS_FAILURE,
} from "../types";

export const getPopularEvents = (payload) => ({
  type: GET_POPULAR_EVENTS,
  payload,
});

export const getPopularEventsSuccess = (payload) => ({
  type: GET_POPULAR_EVENTS_SUCCESS,
  payload,
});

export const getPopularEventsFailure = () => ({
  type: GET_POPULAR_EVENTS_FAILURE,
});
