import { Tab } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { getGapCasino, getGapCasinoUrl, } from "../redux/actions";
import { notifyWarning } from "../utils/helper";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GapCasino = () => {
  const dispatch = useDispatch();
  let getGapCasino = useSelector((state) => state?.GetGapCasinoDataReducer.data);
  const [casinoData, setCasinoData] = useState({});
  const [producers, setProducers] = useState([]);
  const [gameData, setGameData] = useState([]);
  // console.log("getGapCasino", getGapCasino);

  useEffect(() => {
    if (getGapCasino) {
      setCasinoData(getGapCasino);

      if (Object.keys(getGapCasino).length > 0) {
        const keys = Object.keys(getGapCasino)?.reverse();
        setProducers(keys);
      }
    }
  }, []);
  // useEffect(() => {
  //   dispatch(
  //     getGapCasino({
  //       callback: (data) => {
  //         if (data) {
  //           setCasinoData(data);

  //           if (Object.keys(data).length > 0) {
  //             const keys = Object.keys(data)?.reverse();
  //             setProducers(keys);
  //           }
  //         }
  //       },
  //     })
  //   );
  // }, []);

  const handleCasinoPlay = (gameId, providerName) => {
    if (localStorage?.getItem("token")) {
      dispatch(
        getGapCasinoUrl({
          gameId, providerName,
          callback: (data) => {
            if (data) {
              if (data?.reason === "Incorrect signature") {
                notifyWarning("Something went wrong");
              } else {
                setGameData(data)
                window.open(data?.url, "_blank");
              }
            }
          },
        })
      );
    } else {
      notifyWarning("Please Login to PLay");
    }
  };

  return (
    <div className="h-screen overflow-auto">
      <Tab.Group>
        <Tab.List className="flex gap-2 rounded-xl p-1 overflow-x-auto whitespace-nowrap">
          {producers &&
            producers.map((producer, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  classNames(
                    "w-[150px] flex-shrink-0 capitalize rounded-md py-2 px-2 text-sm font-medium leading-5",
                    "ring-opacity-60 ring-offset-2 h-[50px]",
                    selected
                      ? "bg-skin-imgbg font-semibold duration-200"
                      : "text-skin-white bg-skin-nav hover:bg-skin-hovercolor hover:text-skin-white"
                  )
                }
              >
                {producer}
              </Tab>
            ))}
        </Tab.List>


        <div className="image-tab-container overflow-auto">
          <Tab.Panels className="mt-2">
            <>
              {producers?.map((producerName, idx) => {
                // Filter the data for the current producerName
                const producerData = casinoData[producerName];
                return (
                  <Tab.Panel key={idx}>
                    <div className="px-1">
                      <div className="slots-tab flex items-center justify-between mt-2 mb-0.5 text-[#fff]">
                        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 w-full gap-4">
                          {producerData.map((element, index) => {
                            return (
                              <div key={index}>
                                <div className="relative group text-white text-sm font-bold flex flex-col items-center">
                                  <div className="absolute w-full h-[8rem] md:h-[12rem] opacity-0 top-0 right-0 bottom-0 left-0 bg-[#000] bg-opacity-75 group-hover:opacity-100 content-overlay transition-all duration-300 ease-in-out"></div>
                                  <LazyLoadImage
                                    effect="opacity"
                                    delayMethod="debounce"
                                    delayTime={30000}
                                    alt="gallery"
                                    className="block w-[12rem] h-[8rem] md:h-[12rem] object-contain rounded-lg opacity-100"
                                    src={element?.urlThumb}
                                  />
                                  <span className="text-skin-white">
                                    {element?.name}({element?.gameId})
                                  </span>
                                  <div className="group-hover:opacity-100 transition-opacity duration-500 ease-in-out opacity-0">
                                    <div
                                      onClick={() =>
                                        handleCasinoPlay(element?.gameId, element?.providerName)
                                      }
                                      className="content-details absolute text-center top-14 md:top-[40%] left-7 md:left-[34%] -translate-y-[-100%] group-hover:-translate-y-0 transition-all duration-500 ease-in-out"
                                    >
                                      <span className="content-title bg-skin-balanceIcon text-skin-textPrimaryColor px-4 py-1 font-semibold rounded-full cursor-pointer uppercase">
                                        Play
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                );
              })}
            </>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  )
}
export default GapCasino;