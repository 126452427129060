import React, { useEffect } from "react";
import LogoLight from "../assets/images/logo/moneyexch-logo-light.png";
import LogoDark from "../assets/images/logo/moneyexch-logo-light1.png";

const Loader = ({ themeMode,Navdata }) => {
  useEffect(() => {
    if (Object.keys(Navdata).length === 0) {
      Navdata.logo_dark = LogoDark;
      Navdata.logo_light = LogoLight;
    }
  }, [Navdata]);
  return (
    <div className="flex justify-center items-center h-full pt-15 w-full">
      {themeMode ? (
        <img className="w-52"  src={ Navdata?.logo_light} alt="Your Company"  />
      ) : (
        <img className="w-52" src={Navdata?.logo_dark} alt="Your Company" />
      )}
    </div>
  );
};

export default Loader;
