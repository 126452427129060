import React, { startTransition, useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Search from "./Search";
import {
  getHorseRaceEvents,
  getLeagueEvent,
  getSportDatas,
  getSportLeagues,
  searchKeyword,
  setSelectedMarket,
} from "../redux/actions";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { FaAngleDoubleRight } from "react-icons/fa";
import { formatDate, marketPriority } from "../utils/helper";

const MobileSideNavbar = ({
  visible,
  animationClass,
  setMobileSideBar,
  mobileSideBar,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let allSportsData = useSelector(
    (state) => state?.GlobalSportData?.globalSportData
  );
  let eventObj = useSelector(
    (state) => state?.GetEventData?.data?.data?.eventData
  );
  const horseRaceObj = useSelector((state) => state)
    ?.getEventDataHorseRaceReducer?.data?.data?.eventData;

  const [loggedIn, setLoggedIn] = useState(false);

  const prevSearchLength = useRef(0);

  const [activeIndex, setActiveIndex] = useState(null);
  const [dropdownIndex, setdropdownIndex] = useState(null);
  const [SearchOn, setSearchOn] = useState(false);
  const [searchTerm, setSearchTerm] = useState({ search: "" });
  const [sortedSportsArray, setsortedSportsArray] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [events, setEvents] = useState([]);

  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searchResult, setSearchResult] = useState([]);
  const [searchData, setSearchData] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLeftSidebarVisible, setIsLeftSidebarVisible] = useState(true);

  const [sidebarEventData, setSidebarEventData] = useState([]);

  //HORSE RACE HOOKS
  const [countriesVenues, setCountriesVenues] = useState([]);
  const [selectTime, setSelectTime] = useState(null);
  const [dropDownHorseMarket, setDropDownHorseMarket] = useState(false);
  const [indexMarketHorseRace, setIndexMarketHorseRace] = useState(null);
  const [venueSelected, setVeneueSelected] = useState([]);
  const [horseRaceEventData, setHorseRaceEventData] = useState([]);
  const [selectMarket, setSelectMarket] = useState(null);

  useEffect(() => {
    setSearchData(allSportsData);
  }, [allSportsData]);

  useEffect(() => {
    const storedLoggedIn = localStorage.getItem("token");
    if (storedLoggedIn) {
      setLoggedIn(true);
    }
  }, [dispatch, loggedIn]);

  const handleSportClick = (sportId) => {
    if (activeIndex !== sportId) {
      dispatch(
        getSportLeagues({
          sportId,
          callback: (data) => {
            setLeagues(data?.leagues);
          },
        })
      );
    }

    setActiveIndex((prevIndex) => (prevIndex === sportId ? null : sportId));
  };

  const handleLeagueClick = (leagueId) => {
    if (dropdownIndex !== leagueId) {
      dispatch(
        getLeagueEvent({
          leagueId,
          callback: (data) => {
            setEvents(data?.events);
          },
        })
      );
    }
    setdropdownIndex(dropdownIndex === leagueId ? null : leagueId);
  };

  useEffect(() => {
    // const data = sortSports();
    setsortedSportsArray(allSportsData);
  }, [allSportsData]);

  useEffect(() => {
    // const data = sortSports();
    setsortedSportsArray(allSportsData);
  }, [allSportsData]);

  useEffect(() => {
    if (searchTerm.search === "") {
      setsortedSportsArray(allSportsData);
      setSearchResult([]);
    } else {
      const currentLength = searchTerm.search.length;
      const isBackspace = currentLength < prevSearchLength.current;

      if (!isBackspace && currentLength >= 3) {
        clearTimeout(typingTimeout);
        const timeout = setTimeout(() => {
          dispatch(
            searchKeyword({
              data: searchTerm.search,
              callback: (data) => {
                if (data.length > 0) {
                  setSearchResult(data);
                } else {
                  const notFound = [
                    {
                      name: "No Result Found",
                      slugName: "No Result Found",
                      id: "No Result Found",
                    },
                  ];
                  setSearchResult(notFound);
                }
              },
            })
          );
        }, 1000);
        setTypingTimeout(timeout);
      } else if (currentLength < 3) {
        setSearchResult([]);
      }

      // Update previous search term length
      prevSearchLength.current = currentLength;
    }
  }, [searchTerm]);

  const handleClose = () => {
    setSearchOn(false);
    setSearchTerm({ search: "" });
    setSearchResult(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsLeftSidebarVisible(!isLeftSidebarVisible);
    setMobileSideBar(!mobileSideBar);
  };

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  useEffect(() => {
    eventObj?.markets.sort((a, b) => {
      const priorityA = marketPriority[a.marketName] || 9999;
      const priorityB = marketPriority[b.marketName] || 9999;
      return priorityA - priorityB;
    });
    setSidebarEventData(eventObj);
    setActiveIndex(eventObj?.sportCode);
  }, [eventObj]);

  const handleSelectMarket = (market, index) => {
    let data = {
      market,
      index,
    };
    dispatch(setSelectedMarket(data));
    toggleSidebar();
  };

  //HORSE HANDLING
  const handleSportClickHorseRace = (sportId) => {
    setActiveIndex((prevIndex) => (prevIndex === sportId ? null : sportId));
  };

  const handleEventClick = (index, country_id) => {
    setdropdownIndex(country_id);
    setSelectTime(selectTime === index ? null : index);
  };

  //FOR MARKET HORSE RACING
  const handleMarketClick = (index, market_id) => {
    setDropDownHorseMarket(!dropDownHorseMarket);
    setIndexMarketHorseRace(indexMarketHorseRace === index ? null : index);
  };

  useEffect(() => {
    getCountriesAndVenue();
  }, []);

  useEffect(() => {
    if (horseRaceObj) {
      setSidebarEventData(horseRaceObj);
    }
  }, [horseRaceObj]);

  function handleCountryClick(country_id) {
    try {
      const veneues = countriesVenues?.countries?.filter(
        (country) => country?._id === country_id
      );
      setVeneueSelected(veneues);
      setdropdownIndex(dropdownIndex === country_id ? null : country_id);
    } catch (err) {
      console.log(err);
      console.log("Error in finding veneues");
    }
  }

  function getCountriesAndVenue() {
    dispatch(
      getHorseRaceEvents({
        callback: (data) => {
          setCountriesVenues(data);
        },
      })
    );
  }

  return (
    <>
      {isSidebarOpen && (
        <div
          className={`mobile-side-navbar ${animationClass} h-[94vh] overflow-y-scroll scrollbar-hide absolute top-14 z-[100] w-9/12 max-w-[90%] `}
        >
          <div
            className={`transition-transform duration-900 
                ${
                  isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                } inset-y-0 left-0  bg-skin-main  p-1 rounded-lg z-40`}
          >
            <div className="w-full px-2 pl-2 pt-2 z-50 ">
              <div className="flex mb-2">
                <div
                  className={`flex ${
                    !SearchOn ? "justify-between" : ""
                  } items-center text-skin-primary bg-skin-nav p-3 rounded-md w-full`}
                >
                  {!SearchOn && (
                    <button className="flex text-xs font-semibold">
                      SPORTS
                    </button>
                  )}
                </div>
              </div>
              <div className="flex items-center ml-auto p-2 pt-0 w-full">
                <Search
                  setSearchOn={setSearchOn}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  handleClose={handleClose}
                  setActiveIndex={setActiveIndex}
                  setSearchResult={setSearchResult}
                />
                <button
                  onClick={toggleSidebar}
                  className="invisible ml-2 z-50 p-2 bg-gray-800 text-white rounded-full focus:outline-none"
                >
                  <FaAngleDoubleLeft size={15} />
                </button>
              </div>
            </div>

            {/* SEARCH RESULT UI SECTION */}
            {searchResult.length > 0 &&
              searchResult?.map((item, index) => {
                let encodedDynamicPart = encodeURIComponent(
                  item?.name?.toLowerCase()?.replace(/\s+/g, "-")
                );
                return (
                  <li
                    key={index}
                    className={`cursor-pointer flex flex-col justify-between transition hover:scale-x-105 mb-2 py-3 w-full bg-black text-white hover:duration-75 rounded-md ${
                      activeIndex === item?.sportCode ? "scale-x-105" : ""
                    }`}
                  >
                    <div className="flex text-white justify-between items-center px-2 rounded-md">
                      <div className="flex items-center gap-3">
                        <Link
                          key={index}
                          to={`/${item?.slugName}/${item?.id}/${encodedDynamicPart}`}
                          onClick={() => {
                            setSearchResult([]);
                            setMobileSideBar(!mobileSideBar);
                          }}
                        >
                          <div className="justify-between pl-2 pr-4 rounded-md py-1 hover:duration-200 hover:bg-skin-sideBarHover flex w-full">
                            <div className="text-skin-textPrimaryColor block text-xs w-full font-semibold">
                              {item?.name}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>

                    <div className="flex flex-col items-start pt-2 mt-2">
                      {events.map((event, index) => {
                        let encodedDynamicPart = encodeURIComponent(
                          event?.name?.toLowerCase()?.replace(/\s+/g, "-")
                        );
                        return (
                          <Link
                            key={index}
                            to={`/${item?.slugName}/${event?.id}/${encodedDynamicPart}`}
                          >
                            <div className="justify-between pl-2 pr-4 rounded-md py-3 hover:duration-200 hover:bg-skin-sideBarHover flex w-full">
                              <div className="text-skin-textPrimaryColor block text-xs w-full font-semibold">
                                {event?.name}
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </li>
                );
              })}
            {/* SEARCH RESULT UI SECTION ABOVE*/}

            {sidebarEventData &&
            sidebarEventData !== undefined &&
            location.pathname !== "/home" &&
            location.pathname !== "/all-sports" &&
            location.pathname !== "horse_racing" ? (
              <>
                <ul className="w-full px-3 pl-3">
                  <li
                    className={`cursor-pointer flex flex-col justify-between transition hover:scale-x-105 mb-2 py-3 w-full hover:bg-skin-nav hover:duration-75 rounded-md ${
                      activeIndex === sidebarEventData?.sportCode
                        ? "bg-skin-nav scale-x-105"
                        : ""
                    }`}
                  >
                    <div className="flex justify-between items-center px-2">
                      <Link to="/home" onClick={() => toggleSidebar()}>
                        <div className="flex items-center gap-3 pl-2">
                          {/* {sidebarEventData?.sportName !== null ? (
                              <img
                                src={require(`../assets/images/sidemenu/${slugNameCreation(sidebarEventData?.sportName)}.png`)}
                                alt="aljt"
                                className="w-4 h-4 sidebar"
                              />
                            ) : (
                              ""
                            )} */}
                          <h4 className="text-skin-navtext text-[14px] font-semibold">
                            {sidebarEventData?.sportName
                              ? toTitleCase(sidebarEventData?.sportName)
                              : ""}
                          </h4>
                        </div>
                      </Link>
                    </div>

                    <div className="flex flex-col items-start pt-2 m-1 sm:p-0 lg:pl-3 xl:pl-3">
                      <>
                        <div
                          className={`justify-between rounded-md hover:duration-200 flex w-full`}
                        >
                          <div className="w-full">
                            <Link
                              to="/all-sports"
                              onClick={() => toggleSidebar()}
                            >
                              <div
                                className={`pl-2 py-3  block w-full text-[12px] font-semibold text-skin-FontSecondaryColor`}
                              >
                                {sidebarEventData?.league?.name}
                              </div>
                            </Link>

                            <div className="flex flex-col items-start border rounded-md ">
                              <Link
                                to="/all-sports"
                                onClick={() => toggleSidebar()}
                              >
                                <div className="justify-between pl-3 pr-4 rounded-md py-3  hover:duration-200 flex w-full">
                                  <div className="block text-xs w-full font-semibold text-skin-FontSecondaryColor">
                                    {sidebarEventData?.name}
                                  </div>
                                </div>
                              </Link>
                              <div className="flex flex-col items-start">
                                {sidebarEventData?.markets?.map(
                                  (league, index) => {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          onClick={() =>
                                            handleSelectMarket(league, index)
                                          }
                                          className={`justify-between border rounded-md hover:duration-200 hover:bg-skin-sidenavhover flex w-full`}
                                        >
                                          <div className="px-3">
                                            <div
                                              className={`pl-5 py-3 ${
                                                dropdownIndex === league?._id
                                                  ? "text-skin-textPrimaryColor hover:text-skin-textPrimaryColor"
                                                  : "text-skin-FontSecondaryColor"
                                              } block w-full text-[12px] font-semibold hover:text-skin-textPrimaryColor`}
                                            >
                                              {league?.marketName}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </li>
                </ul>
              </>
            ) : (
              <ul className="w-full px-3 pl-3">
                {sortedSportsArray?.map((item, index) => {
                  if (item.slugName === "horse_racing") {
                    return (
                      <li
                        key={item?.sportCode}
                        className={`cursor-pointer flex flex-col justify-between transition hover:scale-x-105 mb-2 py-3 w-full hover:bg-skin-nav hover:duration-75 rounded-md ${
                          activeIndex === item?.sportCode
                            ? "bg-skin-nav scale-x-105"
                            : ""
                        }`}
                      >
                        <div
                          onClick={() =>
                            handleSportClickHorseRace(item?.sportCode)
                          }
                          className="flex justify-between items-center px-2"
                        >
                          <div className="flex items-center gap-3">
                            <img
                              src={require(`../assets/images/sidemenu/${item?.slugName}.png`)}
                              alt=""
                              className="w-4 h-4 sidebar"
                            />
                            <h4 className="text-skin-navtext text-[14px] font-semibold">
                              {item?.name}
                            </h4>
                          </div>
                          {item?.name && (
                            <div className="flex items-center gap-3">
                              <span
                                className={`flex ${
                                  item?.eventsCount === 0 && "hidden"
                                } justify-center bg-skin-imgbg rounded-sm w-6 h-4 rounded-xs font-semibold text-xs`}
                              >
                                {item?.eventsCount}
                              </span>
                              <h5 className="text-sm justify-center text-skin-navtext cursor-pointer">
                                {activeIndex === item?.sportCode &&
                                countriesVenues?.length > 0 ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </h5>
                            </div>
                          )}
                        </div>
                        {activeIndex === item?.sportCode &&
                          countriesVenues &&
                          countriesVenues?.countries?.length > 0 && (
                            <div className="flex flex-col items-start pt-2 m-1">
                              {countriesVenues?.countries?.map(
                                (league, leagueIndex) => (
                                  <div
                                    key={league?._id}
                                    onClick={() =>
                                      handleCountryClick(league?._id)
                                    }
                                    className={`justify-between ${
                                      dropdownIndex === league?._id
                                        ? "bg-skin-sidenavhover"
                                        : ""
                                    } rounded-md hover:duration-200 hover:bg-skin-sidenavhover flex w-full`}
                                  >
                                    <div className="w-full">
                                      <div
                                        className={`pl-3 py-3 ${
                                          dropdownIndex === league?._id
                                            ? "text-skin-textPrimaryColor hover:text-skin-textPrimaryColor"
                                            : "text-skin-FontSecondaryColor"
                                        } block w-full text-[12px] font-semibold hover:text-skin-textPrimaryColor`}
                                      >
                                        {league?.name}
                                      </div>

                                      {dropdownIndex === league?._id &&
                                        league?.Venues && (
                                          <div className="flex flex-col items-start pt-2">
                                            {league.Venues.map(
                                              (event, eventIndex) => (
                                                <div
                                                  key={event?._id}
                                                  className="justify-between pl-2 pr-4 rounded-md py-3 hover:duration-200 hover:bg-skin-sideBarHover flex w-full"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEventClick(
                                                      eventIndex,
                                                      league?._id
                                                    );
                                                  }}
                                                >
                                                  <div className="text-skin-textPrimaryColor block text-xs w-full font-semibold">
                                                    {event?.name}
                                                  </div>
                                                  {selectTime ===
                                                    eventIndex && (
                                                    <div className="flex flex-col items-start">
                                                      {event?.events?.map(
                                                        (time, timeIndex) => (
                                                          <>
                                                            <div
                                                              className="justify-start pr-4 rounded-md py-3 hover:duration-200 hover:bg-skin-sideBarHover flex w-full"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleMarketClick(
                                                                  timeIndex,
                                                                  time?._id
                                                                );
                                                              }}
                                                              key={time?._id}
                                                            >
                                                              <div className="text-skin-textPrimaryColor block text-xs w-full font-semibold">
                                                                {time?.name}
                                                              </div>
                                                            </div>
                                                            {/* start markets here */}
                                                            {indexMarketHorseRace ===
                                                              timeIndex &&
                                                              dropDownHorseMarket && (
                                                                <div className="grid grid-rows-1 gap-2 mt-2">
                                                                  {time?.markets?.map(
                                                                    (
                                                                      market
                                                                    ) => (
                                                                      <Link
                                                                        key={
                                                                          time?._id
                                                                        }
                                                                        to={`/${item?.slugName}/${time?._id}`}
                                                                        state={{
                                                                          venueId:
                                                                            league
                                                                              ?.Venues[0]
                                                                              ?._id,
                                                                          time: market?.marketStartTime,
                                                                        }}
                                                                      >
                                                                        <p className="text-white text-xs">
                                                                          {formatDate(
                                                                            market?.marketStartTime
                                                                          )}
                                                                        </p>
                                                                      </Link>
                                                                    )
                                                                  )}
                                                                </div>
                                                              )}
                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                    </div>
                                    <div
                                      className={`${
                                        dropdownIndex === leagueIndex
                                          ? "text-skin-textPrimaryColor hover:text-skin-textPrimaryColor"
                                          : "text-skin-secondary hover:text-skin-secondary"
                                      } py-3 text-sm cursor-pointer`}
                                    >
                                      {dropdownIndex === leagueIndex ? (
                                        <IoIosArrowUp />
                                      ) : (
                                        <IoIosArrowDown />
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                      </li>
                    );
                  } else {
                    return (
                      <li
                        key={index}
                        className={`cursor-pointer flex flex-col justify-between transition hover:scale-x-105 mb-2 py-3 w-full hover:bg-skin-nav hover:duration-75 rounded-md ${
                          activeIndex === item?.sportCode
                            ? "bg-skin-nav scale-x-105"
                            : ""
                        }`}
                      >
                        <div
                          onClick={() => handleSportClick(item?.sportCode)}
                          className="flex justify-between items-center px-2"
                        >
                          <div className="flex items-center gap-3">
                            <img
                              src={require(`../assets/images/sidemenu/${item?.slugName}.png`)}
                              alt="sportsImage"
                              className="w-4 h-4 sidebar"
                            />
                            <h4 className="text-skin-navtext text-[14px] font-semibold">
                              {item?.name}
                            </h4>
                          </div>
                          {item?.name ? (
                            <div className="flex items-center gap-3">
                              <span
                                className={`flex ${
                                  item?.eventsCount === 0 && "hidden"
                                } justify-center bg-skin-imgbg rounded-sm w-6 h-4 rounded-xs font-semibold text-xs`}
                              >
                                {item?.eventsCount}
                              </span>
                              <h5 className="text-sm justify-center text-skin-navtext cursor-pointer">
                                {activeIndex === item?.sportCode &&
                                leagues?.length > 0 ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </h5>
                            </div>
                          ) : null}
                        </div>
                        {activeIndex === item?.sportCode && leagues && (
                          <div className="flex flex-col items-start pt-2 m-1">
                            {leagues?.map((league, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    onClick={() =>
                                      handleLeagueClick(league?._id)
                                    }
                                    className={`justify-between ${
                                      dropdownIndex === league?._id
                                        ? "bg-skin-sidenavhover"
                                        : ""
                                    } rounded-md hover:duration-200 hover:bg-skin-sidenavhover flex w-full`}
                                  >
                                    <div className="w-full">
                                      <div
                                        className={`pl-3 py-3 ${
                                          dropdownIndex === league?._id
                                            ? "text-skin-textPrimaryColor hover:text-skin-textPrimaryColor"
                                            : "text-skin-FontSecondaryColor"
                                        } block w-full text-[12px] font-semibold hover:text-skin-textPrimaryColor`}
                                      >
                                        {league?.name}
                                      </div>

                                      {dropdownIndex === league?._id &&
                                        events && (
                                          <div className="flex flex-col items-start pt-2">
                                            {events.map((event, index) => {
                                              let encodedDynamicPart =
                                                encodeURIComponent(
                                                  event?.name
                                                    ?.toLowerCase()
                                                    ?.replace(/\s+/g, "-")
                                                );
                                              return (
                                                <Link
                                                  key={index}
                                                  to={`/${item?.slugName}/${event?.id}/${encodedDynamicPart}`}
                                                >
                                                  <div className="justify-between pl-2 pr-4 rounded-md py-3 hover:duration-200 hover:bg-skin-sideBarHover flex w-full">
                                                    <div
                                                      onClick={() => {
                                                        setMobileSideBar(
                                                          !mobileSideBar
                                                        );
                                                      }}
                                                      className="text-skin-textPrimaryColor block text-xs w-full font-semibold"
                                                    >
                                                      {event?.name}
                                                    </div>
                                                  </div>
                                                </Link>
                                              );
                                            })}
                                          </div>
                                        )}
                                    </div>

                                    <div
                                      className={`${
                                        dropdownIndex === index
                                          ? "text-skin-textPrimaryColor hover:text-skin-textPrimaryColor"
                                          : "text-skin-secondary hover:text-skin-secondary"
                                      } py-3 text-sm cursor-pointer`}
                                    >
                                      {dropdownIndex === index ? (
                                        <IoIosArrowUp />
                                      ) : (
                                        <IoIosArrowDown />
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </li>
                    );
                  }
                })}
              </ul>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MobileSideNavbar;
