import { PLACE_BET_HORSERACE , PLACE_BET_HORSERACE_SUCCESS , PLACE_BET_HORSERACE_FAILURE } from "../types";

export const placeBetHorseRace = (payload) => ({
  type: PLACE_BET_HORSERACE,
  payload,
});

export const placeBetHorseRaceSuccess = (payload) => ({
  type: PLACE_BET_HORSERACE_SUCCESS,
  payload,
});

export const placeBetHorseRaceFailure = () => ({
  type: PLACE_BET_HORSERACE_FAILURE,
});
