import {
  FETCH_WHITELABEL,
  FETCH_WHITELABEL_SUCCESS,
  FETCH_WHITELABEL_FAILURE,
} from "../types";

export const fetchWhiteLabel = (payload) => ({
  type: FETCH_WHITELABEL,
  payload,
});

export const fetchWhiteLabelSuccess = (payload) => ({
  type: FETCH_WHITELABEL_SUCCESS,
  payload,
});

export const fetchWhiteLabelFailure = () => ({
  type: FETCH_WHITELABEL_FAILURE,
});
