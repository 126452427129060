import {
   GET_HORSE_RACE_COUNTRIES_VENUES,
   GET_HORSE_RACE_COUNTRIES_VENUES_SUCCESS,
   GET_HORSE_RACE_COUNTRIES_VENUES_FAILURE
  } from "../../actions/types";
  
  const INIT_STATE = {
    loading: false,
    data: null,
  };
  
  const getHorseRaceCountriesVenuesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_HORSE_RACE_COUNTRIES_VENUES:
        return { ...state, loading: true };
      case GET_HORSE_RACE_COUNTRIES_VENUES_SUCCESS:
        return { ...state, data: action.payload, loading: false };
      case GET_HORSE_RACE_COUNTRIES_VENUES_FAILURE:
        return { ...state, loading: false };
      default:
        return state;
    }
  };
  
  export default getHorseRaceCountriesVenuesReducer;
  