import {
  GAP_CASINO_GAMES_URL,
  GAP_CASINO_GAMES_URL_SUCCESS,
  GAP_CASINO_GAMES_URL_FAILURE,
} from "../types";

export const getGapCasinoUrl = (payload) => ({
  type: GAP_CASINO_GAMES_URL,
  payload,
});

export const getGapCasinoUrlSuccess = (payload) => ({
  type: GAP_CASINO_GAMES_URL_SUCCESS,
  payload,
});

export const getGapCasinoUrlFailure = () => ({
  type: GAP_CASINO_GAMES_URL_FAILURE,
});
