import {
    GET_EVENT_DATA_HORSERACE,
    GET_EVENT_DATA_HORSERACE_SUCCESS,
    GET_EVENT_DATA_HORSERACE_FAILURE,
    GET_EVENT_DATA_HORSERACE_RESET,
  } from "../types";
  
  export const getEventDataHorseRace= (payload) => ({
    type: GET_EVENT_DATA_HORSERACE,
    payload,
  });
  
  export const getEventDataHorseRaceSuccess = (payload) => ({
    type: GET_EVENT_DATA_HORSERACE_SUCCESS,
    payload,
  });
  
  export const getEventDataHorseRaceFailure = () => ({
    type: GET_EVENT_DATA_HORSERACE_FAILURE,
  });
  
  export const resetEventDataHorseRace = () => ({
    type: GET_EVENT_DATA_HORSERACE_RESET, 
  });
  
  