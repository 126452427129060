import {
  META_DEPOSIT,
  META_DEPOSIT_SUCCESS,
  META_DEPOSIT_FAILURE,
} from "../types";

export const metaDeposit = (payload) => ({
    type: META_DEPOSIT,
    payload
  });
  export const metaDepositSuccess = (payload) => ({
    type: META_DEPOSIT_SUCCESS,
    payload
  });
  
  export const metaDepositFailure = () => ({
    type: META_DEPOSIT_FAILURE,
   
  });