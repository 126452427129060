import {
  FETCH_WHITELABEL,
  FETCH_WHITELABEL_SUCCESS,
  FETCH_WHITELABEL_FAILURE,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  data: null,
};

const fetchWhiteLabelReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_WHITELABEL:
      return { ...state, loading: true };
    case FETCH_WHITELABEL_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case FETCH_WHITELABEL_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default fetchWhiteLabelReducer;
