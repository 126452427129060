import { all, call, put, takeEvery } from "redux-saga/effects";
import { CREATE_SESSION } from "../../actions/types";
import { createSessionSuccess, createSessionFailure } from "../../actions";
import API from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";

function* createSessionRequest(action) {
  try {
    const { data } = yield API.post(
      "/api/v1/play-game",
      action?.payload?.payload
    );
    if (data.meta.code === 200) {
      yield put(createSessionSuccess(data));
      yield call(action.payload.callback, data);
      notifySuccess(data.meta.message);
    } else if (data.meta.code === 401) {
      yield put(createSessionFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(createSessionFailure());
      notifyWarning(data.meta.message);
    }
  } catch (error) {
    yield put(createSessionFailure());
    notifyWarning(error?.response?.data?.message);
  }
}

export function* watchCreateSessionAPI() {
  yield takeEvery(CREATE_SESSION, createSessionRequest);
}

export default function* rootSaga() {
  yield all([watchCreateSessionAPI()]);
}
