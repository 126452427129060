import { useEffect, useRef, useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import {
  GenerateQRcode,
  getUserData,
  googleAuthenticatorOTPValidation,
} from "../redux/actions";
import { notifyWarning } from "../utils/helper";

const QRCode = (props) => {
  const dispatch = useDispatch();
  const [qrCode, setQRcode] = useState(null); // Initialize as null
//   const [secret, setSecret] = useState(null); // Initialize as null
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(null);
  const [error, setError] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [showGenerateQRcode, setShowGenerateQRcode] = useState(false);

  const [isCopied, setIsCopied] = useState(false);
  const [setUpKey, setSetUpKey] = useState("");




  useEffect(() => {
    setLoading(true);
    dispatch(
      GenerateQRcode({
        callback: (data) => {
          console.log("data", data);
          setQRcode(data.data.url);
          setSetUpKey(data.data.setupKey);
          setLoading(false);
        },
      })
    );
  }, []);

  const handleOTPChange = (e) => {
    const inputValue = e.target.value;
    // ONLY ALLOW NUMERIC INPUT AND LIMIT TO 6 CHARACTERS
    const sanitizedInput = inputValue.replace(/\D/g, "").slice(0, 6);
    setOtp(sanitizedInput);
  };

  const handleSubmit = () => {
    setDataLoading(true)
    if(otp === null){
        setDataLoading(false)
        setError('This Filed is Required')
        return

    }
    if (otp.length === 6) {
      setError("");
      const responseObject = {
        authType: "GoogleAuthenticator",
        OTPValue: otp,
        twoFactorMethod: "GoogleAuthenticator",
        status: true,
        validateOTP:false 
        // secret: secret,
      }; 
      dispatch(googleAuthenticatorOTPValidation({
        data:responseObject,
        callback: (data) => {
            console.log("data", data);
            if(data){
               setOtp("");
               setTimeout(() => {
                setDataLoading(false)
               }, 2000);
               if(data?.data?.data === true){
                dispatch(getUserData());
                props.toggled()
               }
            }
        }
    }))
      return;
    } else {
      return error("Only 6 digit allowed");
    }
  };


  const handleCopy = () => {
    navigator.clipboard.writeText(setUpKey);
    setIsCopied(true);
    setTimeout(() => {
        setIsCopied(false);
    }, 2000);
};


  return (
    <>
    {showGenerateQRcode ? (
        <>
        <div>
    {loading ? (
      <p>Loading...</p>
    ) : qrCode ? ( 
      <>
        <div className="m-3 ml-0 flex">
        <div>
            <img src={qrCode} alt="QR code" className="m-3 ml-0" />
         
         <input
           type="text"
           value={otp}
           onChange={handleOTPChange}
           placeholder="Enter Code from GOOGLE AUTHENTICATOR APP"
           className="w-full border border-gray-300 rounded focus:outline-none focus:border-blue-500 p-3"
         />
         {error !== null && (
           <p className="my-1 text-sm text-red-500">{error}</p>
         )}
         
         



         <div className="w-full max-w-sm">
            <div className="flex items-center mt-1">
                <span className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg dark:bg-gray-600 dark:text-white dark:border-gray-600">Setup Key</span>
                <input
                    id="website-url"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-500 dark:text-gray-400 text-sm border-l-0 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={setUpKey}
                    readOnly
                    disabled
                />
                <button
                    onClick={handleCopy}
                    className="inline-flex items-center py-3 px-4 text-sm font-medium text-center text-white bg-skin-loginBtn rounded-r-lg hover:bg-dark-800"
                    type="button"
                >
                    <span className={isCopied ? 'hidden' : 'inline-block'}>
                        <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </span>
                    <span className={isCopied ? 'inline-block' : 'hidden'}>
                        <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                    </span>
                </button>
            </div>
           </div>




         <button
           onClick={handleSubmit}
           className="mt-2 px-4 py-2 bg-skin-loginBtn text-white rounded hover:outline outline-1 outline-offset-2"
         >
           {dataLoading? "Verifying..." : "Verify"}
         </button>
         </div>
          
        </div>
      </>
    ) : (
      <p>No QR code available</p> 
    )}
  </div>
        </>
    ):(
    <>
  <button onClick={()=>{setShowGenerateQRcode(true)}} class=" mt-3 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
  <span>Show QR Code</span>
</button>
    </>)}
    </>
  );
};

export default QRCode;
