import { all, call, put, takeEvery } from "redux-saga/effects";
import { UPDATE_TWO_FACTOR_STATUS } from "../../actions/types";
import { updateTwoFactorStatusActionSuccess, updateTwoFactorStatusActionFailure } from "../../actions";
import API from "../../../utils/api";
import { notifySuccess, notifyWarning } from "../../../utils/helper";

function* twoFactorStatusUpdateRequest(action) {
  try {
    const { data } = yield API.put(
      "/api/v1/two-factor-auth/update",
      action?.payload?.data
    );
    if (data?.meta?.code === 200) {
      if(data.data.status){
        notifySuccess(data?.meta?.message);
      }else{
        notifyWarning(data?.meta?.message);
      }
      yield put(updateTwoFactorStatusActionSuccess(data));
      yield call(action.payload.callback, data);
    } else if (data?.code === 400) {
      yield put(updateTwoFactorStatusActionFailure());
      notifyWarning(data?.message);
    } else if (data?.meta?.code !== 200) {
      yield put(updateTwoFactorStatusActionFailure());
      notifyWarning(data?.meta?.message);
    }
  } catch (error) {
    yield put(updateTwoFactorStatusActionFailure());
    notifyWarning(error?.response?.data?.message);
  }
}

export function* watchTwoFactorUpdateStatusAPI() {
  yield takeEvery(UPDATE_TWO_FACTOR_STATUS, twoFactorStatusUpdateRequest);
}

export default function* rootSaga() {
  yield all([watchTwoFactorUpdateStatusAPI()]);
}
