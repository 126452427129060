import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/loader/XLoader .json";
import { BallTriangle } from "react-loader-spinner";
const XLoader = () => {
  //Lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="absolute w-full h-full  flex items-center justify-center">
      {/* <BallTriangle
        height={100}
        width={100}
        radius={5}
        color="#EDC529"
        ariaLabel="ball-triangle-loading"
        wrapperStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
        wrapperClass=""
        visible={true}
      /> */}
      <Lottie options={defaultOptions} height={200} width={100} />
    </div>
  );
};

export default XLoader;
