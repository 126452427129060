import {
  GET_ST8CASINO,
  GET_ST8CASINO_SUCCESS,
  GET_ST8CASINO_FAILURE,
} from "../types";

export const getSt8Casino = (payload) => ({
  type: GET_ST8CASINO,
  payload,
});

export const getSt8CasinoSuccess = (payload) => ({
  type: GET_ST8CASINO_SUCCESS,
  payload,
});

export const getSt8CasinoFailure = () => ({
  type: GET_ST8CASINO_FAILURE,
});
