import { useEffect, useState, useContext } from "react";
import MainNavbar from "../components/MainNavbar";
import MobileNavbar from "../components/MobileNavbar";
import SideNavbar from "../components/SideNavbar";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingContext } from "../context/LoadingContext";
import Loader from "./Loader";
import { useDispatch } from "react-redux";
import { fetchWhiteLabel } from "../redux/actions";

export const Layout = () => {
  const themeMode = document.querySelector(".light-theme");
  const dispatch = useDispatch();
  const location = useLocation();
  const [toggle, setToggle] = useState(true);
  const [isAboveXl, setisAbovexl] = useState(false);
  const [whiteLabelData, setWhiteLabelData] = useState({});

  //PATHNAME CHECK
  const isIndianCasinoRoute = location.pathname.includes("indian-casino");
  const isLiveCasinoRoute = location.pathname.includes("live-casino");
  const isSlotsRoute = location.pathname.includes("slots");
  const isSt8Casino = location.pathname.includes("st8-casino");
  const isStocksPage= location.pathname.includes("stocks")

  const footerLinks =
    location.pathname.includes("privacy-policy") ||
    location.pathname.includes("terms-and-conditions") ||
    location.pathname.includes("responsible-gambling") ||
    location.pathname.includes("about-us") ||
    location.pathname.includes("general-policy");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setToggle(true);
      } else if (window.innerWidth < 1024) {
        // Show on mobile and tablet screens (max-width: 768px)
        setToggle(false);
        setisAbovexl(false);
      } else if (window.innerWidth >= 1400) {
        setisAbovexl(true); // Show on screens between 768px and 1400px
      } else {
        setToggle(true);
        setisAbovexl(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(
      fetchWhiteLabel({
        domain: window.location.origin,
        callback: (data) => {
          setWhiteLabelData(data);
        },
      })
    );
  }, []);

  const { loading } = useContext(LoadingContext);

  return (
    <>
      <div className="bg-skin-main h-[100vh] overflow-y-hidden ">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        {/* <Chatbot /> */}
        <div className={` mx-auto w-full `}>
          {
            <MainNavbar
              setToggle={setToggle}
              toggle={toggle}
              screen={isAboveXl}
              Navdata={whiteLabelData}
            />
          }
        </div>
        {!loading ? (
          <>
            <div className={`mx-auto`}>
              <div className="">
                <div className="flex gap-4 lg:gap-1 ">
                  {toggle &&
                  !isIndianCasinoRoute &&
                  !isLiveCasinoRoute &&
                  !isSlotsRoute &&
                  !isSt8Casino &&
                  !footerLinks  && 
                  !isStocksPage ? 
                    (
                    <>
                   
                      <SideNavbar/>
                   
                    </>
                  ) : null}
                  <Outlet />
                </div>
              </div>
            </div>
            <div className=" mx-auto px-6 lg:hidden ">
              <MobileNavbar />
            </div>
          </>
        ) : (
          <Loader themeMode={themeMode} Navdata={whiteLabelData} />
        )}
      </div>
    </>
  );
};
