import React, { useState, useRef, useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";

const Search = ({
  setSearchOn,
  searchTerm,
  setSearchTerm,
  handleClose,
  setActiveIndex,
  setSearchResult,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);


  const handleCloseIconClick = () => {
    setIsOpen(false);
    setSearchOn(false);
    handleClose();
  };

  const handleChange = (e) => {
    setSearchTerm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setSearchResult([]);
  };

  const  ICON_CLASSNAME = "absolute right-3 text-gray-500 hover:text-gray-700 cursor-pointer top-3"

  return (
    <div className={`relative ${isOpen ? "w-full" : ""}`}>
      <div className="flex items-center relative w-full">
        <input
          ref={inputRef}
          type="text"
          placeholder="Search. . . ."
          name="search"
          autoComplete="off"
          onChange={handleChange}
          value={searchTerm?.search}
          className={`w-full p-2 pl-8 pr-4 rounded-md bg-skin-nav text-skin-primary transition-all duration-1000 border focus:outline-none ring-2 ${
            searchTerm?.search?.length >= 3
              ? "focus:ring-blue-500"
              : "focus:ring-red-500"
          }`}
          />


        {searchTerm?.search?.length > 0 ? (
          <button
            onClick={handleCloseIconClick}
            className={ICON_CLASSNAME}
          >
            <FaTimes />
          </button>
        ):(
          <FaSearch className={ICON_CLASSNAME}/>
        )}
      </div>
    </div>
  );
};

export default Search;
