import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_LEAGUE_EVENTS } from "../../actions/types";
import { getLeagueEventSuccess, getLeagueEventFailure } from "../../actions";
import API from "../../../utils/api";

function* getLeagueEventRequest(action) {
  try {
    const { data } = yield API.get(
      `/api/v1/league-events?leagueId=${action?.payload?.leagueId}`
    );
    if (data.meta.code === 200) {
      yield put(getLeagueEventSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getLeagueEventFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data.meta.code !== 200) {
      yield put(getLeagueEventFailure());
    }
  } catch (error) {
    yield put(getLeagueEventFailure());
  }
}

export function* watchGetLeagueEventsAPI() {
  yield takeEvery(GET_LEAGUE_EVENTS, getLeagueEventRequest);
}

export default function* rootSaga() {
  yield all([watchGetLeagueEventsAPI()]);
}
