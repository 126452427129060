import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_ST8CASINO } from "../../actions/types";
import { getSt8CasinoSuccess, getSt8CasinoFailure } from "../../actions";
import API from "../../../utils/api";

function* getCasinoDataRequest(action) {
  try {
    const { data } = yield API.get(`api/v1/st8/db/get-games`);
    if (data.meta.code === 200) {
      yield put(getSt8CasinoSuccess(data?.data));
      yield call(action.payload.callback, data.data);
    } else {
      yield put(getSt8CasinoFailure());
    }
  } catch (error) {
    yield put(getSt8CasinoFailure());
  }
}

export function* watchgetSt8CasinoAPI() {
  yield takeEvery(GET_ST8CASINO, getCasinoDataRequest);
}

export default function* rootSaga() {
  yield all([watchgetSt8CasinoAPI()]);
}
