import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_GAP_CASINO_GAMES } from "../../actions/types";
import { getGapCasinoSuccess, getGapCasinoFailure } from "../../actions";
import API from "../../../utils/api";

function* getGapCasinoDataAPI(action) {
  try {
    const { data } = yield API.get(`api/v1/get-games-list-db`);
    if (data.meta.code === 200) {
      yield put(getGapCasinoSuccess(data?.data));
      yield call(action.payload.callback, data.data);
    } else {
      yield put(getGapCasinoFailure());
    }
  } catch (error) {
    yield put(getGapCasinoFailure());
  }
}

export function* watchgetGapCasinoAPI() {
  yield takeEvery(GET_GAP_CASINO_GAMES, getGapCasinoDataAPI);
}

export default function* rootSaga() {
  yield all([watchgetGapCasinoAPI()]);
}
