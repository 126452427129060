import {
  META_CHECK_WALLET,
  META_CHECK_WALLET_SUCCESS,
  META_CHECK_WALLET_FAILURE,
} from "../types";


export const metaCheckWallet = (payload) => ({
    type: META_CHECK_WALLET,
    payload
  });
  export const metaCheckWalletSuccess = (payload) => ({
    type: META_CHECK_WALLET_SUCCESS,
    payload
  });
  
  export const metaCheckWalletFailure = () => ({
    type: META_CHECK_WALLET_FAILURE,
  });
