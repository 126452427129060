import {
  GET_LEAGUE_EVENTS,
  GET_LEAGUE_EVENTS_SUCCESS,
  GET_LEAGUE_EVENTS_FAILURE,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  events: null,
};

const getLeagueEventReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEAGUE_EVENTS:
      return { ...state, loading: true };
    case GET_LEAGUE_EVENTS_SUCCESS:
      return { ...state, events: action.payload, loading: false };
    case GET_LEAGUE_EVENTS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default getLeagueEventReducer;
