import React, { useEffect, useState } from "react";

export const ThemeContext = React.createContext();

const Theme = ({ children }) => {
  const defaultTheme = localStorage.getItem("theme");
  const [theme, setTheme] = useState(
    defaultTheme === null ? false : JSON.parse(defaultTheme)
  );

  useEffect(() => {
    // Store the context value in localStorage whenever it changes
    localStorage.setItem("theme", JSON.stringify(theme));
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default Theme;
