import { all, call, put, takeEvery } from "redux-saga/effects";
import { SEARCH_KEYWORD_REQUEST } from "../../actions/types";
import { searchKeywordSuccess, searchKeywordFailure } from "../../actions";
import API from "../../../utils/api";

function* searchKeywordSaga(action) {
  try {
    console.log("action",action)
    const { data } = yield API.get(
      `/api/v1/search?keyword=${action?.payload?.data}`
    );
    console.log(data)
    if (data.meta.code === 200) {
      yield put(searchKeywordSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(searchKeywordFailure());
    } else if (data.meta.code !== 200) {
      yield put(searchKeywordFailure());
    }
  } catch (error) {
    yield put(searchKeywordFailure());
  }
}

export function* watchtSearchRequestAPI() {
  yield takeEvery(SEARCH_KEYWORD_REQUEST, searchKeywordSaga);
}

export default function* rootSaga() {
  yield all([watchtSearchRequestAPI()]);
}