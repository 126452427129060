import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { notifyWarning } from "../utils/helper";
import { changePassword } from "../redux/actions";

const ChangePassword = ({ closeModal }) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state?.ChangePassword?.loading);

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setconfirmPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (
      currentPassword.trim() !== "" &&
      password.trim() !== "" &&
      confirmPassword.trim() !== ""
    ) {
      if (password === confirmPassword) {
        const inputValues = {
          old_password: currentPassword,
          password,
          confirm_password: confirmPassword,
        };
        dispatch(
          changePassword({
            inputValues,
            callback: (data) => {
              if (data) {
                closeModal();
              }
            },
          })
        );
      } else {
        notifyWarning("new password and confirm password mismatch");
      }
    } else {
      notifyWarning("please fill all Fields");
    }
  };

  return (
    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-2">
      <div className="z-[100]">
        <div className="fixed inset-0 bg-skin-modalBackgroundBlur bg-opacity-25 backdrop-blur-sm flex items-center justify-center ">
          <div className="relative bg-skin-modalBackground p-5 rounded-xl w-96">
            <button
              className="absolute top-4 right-3"
              type="button"
              onClick={() => closeModal(false)}
            >
              <MdOutlineClose className="text-skin-primary text-3xl font-bold" />
            </button>
            <h1 className="font-semibold capitalize text-center text-xl text-skin-primary mb-5 mt-1">
              Change Password
            </h1>

            <div className="flex flex-col">
              <div className="current-password mt-3">
                <label
                  className="text-skin-primary text-xs font-semibold "
                  htmlFor=""
                >
                  Current Password
                </label>
                <input
                  type="password"
                  className=" bg-skin-inputBackground w-full mt-2 p-3 rounded focus:outline-none text-[#454c53]"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={handleCurrentPasswordChange}
                  required
                />
              </div>
              <div className="new-password mt-3">
                <label
                  className="text-skin-primary text-xs font-semibold "
                  htmlFor=""
                >
                  New Password
                </label>
                <input
                  type="password"
                  className=" bg-skin-inputBackground w-full mt-2 p-3 rounded focus:outline-none text-[#454c53]"
                  placeholder="New Password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <div className="confirm-password mt-3">
                <label
                  className="text-skin-primary text-xs font-semibold"
                  htmlFor=""
                >
                  Confirm New Password
                </label>
                <input
                  type="password"
                  className=" bg-skin-inputBackground w-full p-3 mt-2 rounded focus:outline-none text-[#454c53]"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
              </div>

              <button
                disabled={loading}
                type="button"
                className={`${
                  loading
                    ? "bg-skin-loginBtn cursor-progress hover:bg-skin-loginBtn"
                    : ""
                } p-2 px-4 mt-5 mb-10 capitalize font-semibold rounded-md hover:bg-skin-loginBtn bg-skin-loginBtn text-lg text-white focus:outline-none `}
                onClick={handleSubmit}
              >
                {loading ? "processing ..." : "change password"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
