import {
  TYPECODEFANCIESDATA,
  TYPECODEFANCIESDATASUCCESS,
  TYPECODEFANCIESDATAFAILURE,
} from "../types";

export const getTypeCodeFancies = (payload) => ({
  type: TYPECODEFANCIESDATA,
  payload,
});

export const getTypeCodeFanciesSuccess = (payload) => ({
  type: TYPECODEFANCIESDATASUCCESS,
  payload,
});

export const getTypeCodeFanciesFailure = () => ({
  type: TYPECODEFANCIESDATAFAILURE,
});
