import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_BOOKMAKER_MARKETS } from "../../actions/types";
import {
  getBookmakerMarketsSuccess,
  getBookmakerMarketsFailure,
} from "../../actions";
import API from "../../../utils/api";

function* getBookmakerMarketsRequest(action) {
  try {
    const { data } = yield API.get(
      `admin/get-runners-data?marketId=${action?.payload?.id}`
    );
    if (data.meta.code === 200) {
      yield put(getBookmakerMarketsSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getBookmakerMarketsFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(getBookmakerMarketsFailure());
    }
  } catch (error) {
    yield put(getBookmakerMarketsFailure());
  }
}

export function* watchgetBookmakerMarketsAPI() {
  yield takeEvery(GET_BOOKMAKER_MARKETS, getBookmakerMarketsRequest);
}

export default function* rootSaga() {
  yield all([watchgetBookmakerMarketsAPI()]);
}
