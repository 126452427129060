import {
  HORSERACE_DATA_MANAGE,
  HORSERACE_DATA_MANAGE_RESET,
  HORSERACE_DATA_MANAGE_SUCCESS,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  data: null,
};

const getHorseDataManageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case HORSERACE_DATA_MANAGE:
      return { ...state, loading: true };
    case HORSERACE_DATA_MANAGE_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case HORSERACE_DATA_MANAGE_RESET:
      return { ...state, data: null, loading: false };
    default:
      return state;
  }
};

export default getHorseDataManageReducer;
