import { combineReducers } from "redux";
//import all reducers creating here, and add inside the combine reducers
import GetSport from "./sports/getSportReducer";
import Login from "./auth/loginReducer";
import Logout from "./auth/logoutReducer";
import ChangePassword from "./auth/changePasswordReducer";
import GetAllSportData from "./sportData/getAllSportDataReducer";
import GlobalSportData from "./globalData/globalSportDataReducer";
import GobalMarketOdds from "./globalData/marketOddsReducer";
import GetRunnerData from "./runnerData/getRunnerDataReducer";
import PlaceBet from "./bet/placeBetReducer";
import GetBet from "./bet/getBetReducer";
import GetUserData from "./userData/getUserData";
import ChipSetting from "./settings/chipSettingReducer";
import GetChipSetting from "./settings/getChipSettingReducer";
import GetSportSettingReducer from "./settings/getSportSettingReducer";
import getAccountStatementReducer from "./userData/getAccountStatementReducer";
import getMyMarkets from "./userData/getMyMarketsReducer";
import getResultTransactionReducer from "./userData/getResultTransactionAction";
import getEventBetsReducer from "./userData/getEventBetsReducer";
import getBookmakerMarketsReducer from "./markets/getBookmakerMarketsReducer";
import GetActiveFancies from "./fancyData/getActiveFanciesReducer";
import CreateSessionReducer from "./casino/createSessionReducer";
import GetBalanceReducer from "./casino/getBalanceReducer";
import GetCreditReducer from "./casino/getCreditReducer";
import GetDebitReducer from "./casino/getDebitReducer";
import GetTvUrl from "./live/getTvUrlActionReducer";
import GetCasinoBets from "./userData/getCasinoBetsReducer";
import IndianCasinoUrl from "./indianCasino/generateIndianCasinoUrlReducer";
import GetHomeSlider from "./sliders/getHomeSliderReducer";
import GetDeletedEventBets from "./userData/getDeletedEventBetsReducer";
import GetSt8Casino from "./st8Casino/getCasinoDataReducer";
import GetSt8CasinoUrl from "./st8Casino/getCasinoUrlReducer";
import FetchWhiteLabel from "./whitelabel/fetchWhiteLabelReducer";
import DeleteUnmatchBet from "./bet/deleteUnmatchBetReducer";
import GetPopularEvents from "./events/getPopularEvents";
import GetEventData from "./events/getEventDataReducer";
import GetHomeData from "./home/getHomeDataReducer";
import GetInPlayData from "./live/getInPlayReducer";
import GetEventExch from "./odds/getEventExchReducer";
import TwoFactorStatusReducer from "./TwoFA/TwoFactorStatusReducer";
import VerifyTwoFactorCodeReducer from "./TwoFA/VerifyTwoFactorCodeReducer";
import GoogleLoginValidation from "./TwoFA/googleLoginValidation";
import GenerateQRcodeReducer from "./TwoFA/generateQRcodeReducer";
import GoogleAuthenticatorOTPvalidation from "./TwoFA/googleAuthenticatorOTPvalidation";
import typeCodeFanciesReducer from "./fancyData/typeCodeFanciesReducer";
import GetSportLeagues from "./sports/getSportLeagues";
import GetLeagueEvents from "./events/getLeagueEvents";
import GetInPlayBySport from "./live/getInPlayBySportReducer";
import SearchReducer from "./Search/searchReducer";
import notificationReducer from "./Notification/NotificationReducer";
import GetSportDatas from "./sportData/getSportDatas";
import currencyBetFairReducer from "./currency/currencyReducer";
import GetUserBalanceData from "./userData/getUserBalanceData";
import SelectedMarketReducer from "./reset/selectedMarketReducer";
import GetGapCasinoDataReducer from "./gapCasino/getGapCasinoDataReducer";
import GapCasinoUrlReducer from "./gapCasino/gapCasinoUrlReducer";
import getHorseRacingDataReducer from "./horseRacing/horseRacingReducer";
import placeBetHorseRaceReducer from "./horseRacing/placeBetHorseRaceReducer";
import getHorseRacingSettingsDataReducer from "./horseRacing/horseRacingSettingsReducer";
import getHorseRaceBetReducer from "./horseRacing/getBetHorseRaceReducer";
import getHorseRaceDataReducer from "./horseRacing/getHorseRaceDataReducer";
import unMatchedBetsHorseRaceReducer from "./horseRacing/unMatchedHorseRaceReducer";
import getHorseRaceCountriesVenuesReducer from "./horseRacing/getHorseRaceCountriesVenue";
import getEventDataHorseRaceReducer from "./horseRaceEvents/horseRaceEventsReducer";
import MarketAnalytics from "./analytics/analyticsReducer";
import userHasWallet from "./meta/metaWalletReducer";
import HorseDataManageReducer from "./horseDataManage/horseDataManage";
import HorseRaceLiveReducer from "./horseRaceEvents/horseRaceLiveReducer";

//STX
import StxPlaceBetReducer from "./STX/stxPlaceBetReducer";
import DeleteUnmatchStxBetReducer from "./STX/deleteUnmatchStxBetReducer";
/* ---------------------------- //example// ---------------------------- */
// import exampleReducer from "./path"

const appReducer = combineReducers({
  //exampleReducer
  Login,
  Logout,
  ChangePassword,
  GetSport,
  GetAllSportData,
  GlobalSportData,
  GobalMarketOdds,
  GetRunnerData,
  PlaceBet,
  GetBet,
  GetUserData,
  ChipSetting,
  GetChipSetting,
  GetSportSettingReducer,
  getAccountStatementReducer,
  getMyMarkets,
  getResultTransactionReducer,
  getEventBetsReducer,
  getBookmakerMarketsReducer,
  GetActiveFancies,
  CreateSessionReducer,
  GetBalanceReducer,
  GetCreditReducer,
  GetDebitReducer,
  GetTvUrl,
  GetCasinoBets,
  IndianCasinoUrl,
  GetHomeSlider,
  GetDeletedEventBets,
  GetSt8Casino,
  GetSt8CasinoUrl,
  FetchWhiteLabel,
  DeleteUnmatchBet,
  GetPopularEvents,
  GetEventData,
  GetHomeData,
  GetInPlayData,
  GetEventExch,
  TwoFactorStatusReducer,
  VerifyTwoFactorCodeReducer,
  GoogleLoginValidation,
  GenerateQRcodeReducer,
  GoogleAuthenticatorOTPvalidation,
  typeCodeFanciesReducer,
  GetSportLeagues,
  GetLeagueEvents,
  GetInPlayBySport,
  SearchReducer,
  notificationReducer,
  GetSportDatas,
  currencyBetFairReducer,
  GetUserBalanceData,
  SelectedMarketReducer,
  GetGapCasinoDataReducer,
  GapCasinoUrlReducer,
  MarketAnalytics,
  getHorseRacingDataReducer,
  placeBetHorseRaceReducer,
  getHorseRacingSettingsDataReducer,
  getHorseRaceBetReducer,
  getHorseRaceDataReducer,
  unMatchedBetsHorseRaceReducer,
  getHorseRaceCountriesVenuesReducer,
  getEventDataHorseRaceReducer,
  userHasWallet,
  HorseDataManageReducer,
  HorseRaceLiveReducer,

  StxPlaceBetReducer,
  DeleteUnmatchStxBetReducer,
  
});

const reducers = (state, action) => {
  return appReducer(state, action);
};

export default reducers;
