import {
  NOTIFICATION,
  NOTIFICATION_FAILURE,
  NOTIFICATION_SUCCESS,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  notification: null,
};

const notificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION:
      return { ...state, loading: true };
    case NOTIFICATION_SUCCESS:
      return { ...state, loading: false, notification: action?.payload };
    case NOTIFICATION_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default notificationReducer;
