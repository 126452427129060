import {
    GET_EVENT_BETS,
    GET_EVENT_BETS_SUCCESS,
    GET_EVENT_BETS_FAILURE,
  } from "../types";
  
  export const getEventBets = (payload) => ({
    type: GET_EVENT_BETS,
    payload
  });
  
  export const getEventBetsSuccess = (payload) => ({
    type: GET_EVENT_BETS_SUCCESS,
    payload,
  });
  
  export const getEventBetsFailure = () => ({
    type: GET_EVENT_BETS_FAILURE,
  });
  