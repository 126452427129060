import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_ST8CASINO_URL } from "../../actions/types";
import { getSt8CasinoUrlSuccess, getSt8CasinoUrlFailure } from "../../actions";
import API from "../../../utils/api";

function* getCasinoUrlDataRequest(action) {
  try {
    const { data } = yield API.get(
      `api/v1/st8/get-games/url?gameCode=${action?.payload?.code}`
    );
    if (data.meta.code === 200) {
      yield put(getSt8CasinoUrlSuccess(data?.data));
      yield call(action.payload.callback, data.data);
    } else {
      yield put(getSt8CasinoUrlFailure());
    }
  } catch (error) {
    yield put(getSt8CasinoUrlFailure());
  }
}

export function* watchgetSt8CasinoUrlAPI() {
  yield takeEvery(GET_ST8CASINO_URL, getCasinoUrlDataRequest);
}

export default function* rootSaga() {
  yield all([watchgetSt8CasinoUrlAPI()]);
}
