import {
  TYPECODEFANCIESDATA,
  TYPECODEFANCIESDATASUCCESS,
  TYPECODEFANCIESDATAFAILURE,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  typeCodeData: null,
};

const typeCodeFanciesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPECODEFANCIESDATA:
      return { ...state, loading: true };
    case TYPECODEFANCIESDATASUCCESS:
      return { ...state, typeCodeData: action.payload, loading: false };
    case TYPECODEFANCIESDATAFAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default typeCodeFanciesReducer;
