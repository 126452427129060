import {
  META_CREATE,
  META_CREATE_SUCCESS,
  META_CREATE_FAILURE,
} from "../types";

export const metaCreate = (payload) => ({
  type: META_CREATE,
  payload
});
export const metaCreateSuccess = (payload) => ({
  type: META_CREATE_SUCCESS,
  payload
});

export const metaCreateFailure = () => ({
  type: META_CREATE_FAILURE,
});
