import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { availableCasinos } from "./constants";
import NotificationSound from "../assets/audio/notification-sound.mp3";

export const url = process.env.REACT_APP_API_ENDPOINT || "";

export const oddUrl = process.env.REACT_APP_ODD_API_ENDPOINT || "";

// STX ODDS API
export const stxOddUrl = process.env.REACT_APP_STX_ODD_API_ENDPOINT || "";
//horse race api

export const oddRaceApi =  process.env.REACT_APP_API_ENDPOINT_HORSE_RACE || "";

//GET LOCAL STORAGE ITEM
export const getLocalStorageItem = (key) => localStorage.getItem(key);

//SET VALUE TO LOCAL STORAGE
export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, value);

//REMOVE ITEM FROM LOCALSTORAGE
export const removeLocalStorageItem = (key) => localStorage.removeItem(key);

//GET DEFAULT VALUES USING LOCALSTORAGE

export const getDefaultState = (keyName) => {
  const storedValue = localStorage.getItem(keyName);
  if (storedValue !== null && storedValue !== undefined) {
    try {
      const value = JSON.parse(storedValue);
      return value;
    } catch (error) {
      return storedValue;
    }
  } else {
    console.error("Value is undefined or null in localStorage");
    return null;
  }
};
export const ErrorToast = ({ msg }) => (
  <div>
    <svg
      width="1.0625em"
      height="1em"
      viewBox="0 0 17 16"
      className="bi bi-exclamation-triangle mb-1 mr-1"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
      />
      <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
    </svg>
    &nbsp;&nbsp;
    {msg}
  </div>
);
ErrorToast.propTypes = {
  msg: PropTypes.string,
};

export const SuccessToast = ({ msg }) => (
  <div>
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      className="bi bi-check-circle mb-1 mr-1"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
      />
      <path
        fillRule="evenodd"
        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
      />
    </svg>
    &nbsp;&nbsp;
    {msg}
  </div>
);
SuccessToast.propTypes = {
  msg: PropTypes.string,
};

export const WarningToast = ({ msg }) => (
  <div>
    <svg
      width="1.0625em"
      height="1em"
      viewBox="0 0 17 16"
      className="bi bi-exclamation-triangle mb-1 mr-1"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
      />
      <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
    </svg>
    &nbsp;&nbsp;
    {msg}
  </div>
);
WarningToast.propTypes = {
  msg: PropTypes.string,
};

//To Find odds for the runner in Market From the API data emit by Socket [EXCH]

export const findRunnerOdds = (odds, runnerCode) => {
  if (odds?.length !== 0) {
    var currentodd = odds?.runners?.find((odd) => {
      const data = parseFloat(odd?.selectionId) === parseFloat(runnerCode);
      return data;
    });
  }
  // return { ...currentodd, marketCode: Object.values(odds)[0]?.marketId };
  return currentodd;
};

//To Find odds for the runner in Market From the API data emit by Socket [BOOKMAKER]

export const findBookMakerOdds = (odds, runnerCode) => {
  //console.log(odds, "odds");
  if (Object.keys(Object.values(odds)[0])?.length !== 0) {
    //console.log(Object.values(odds)[0], "Object.values(odds)[0]");
    const runners = JSON.parse(odds?.data?.runners);
    return runners[runnerCode];
  }
};

//Find odds for current market from array of fancies for events
export const findEventFancyOdds = (allOdds, eventCode) => {
  try {
    for (let i = 0; i < allOdds.length; i++) {
      const key = Object.keys(allOdds[i])[0];
      if (key === `fancy-${eventCode}`) {
        return allOdds[i][key];
      }
    }
    return null; // If the code is not found in the array
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const findFancyOdds = (odds, runnerId) => {
  try {
    if (odds[runnerId] !== undefined && odds[runnerId] !== null) {
      const updatedArrayOfObjects = Object.entries(odds[runnerId]).map(
        ([key, value]) => {
          const parsedValue = JSON.parse(value);
          return parsedValue;
        }
      );
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

// Calculate Book for Bet on Back for EXCH -------------

export const betOnBack = {
profit: (odds, stake) => {
    return parseFloat(odds - 1).toFixed(2) * parseFloat(stake);
  },
  lose: (stake) => {
    return -1 * parseFloat(stake);
  },
};

// Calculate Book for Bet on Lay for EXCH ----------
export const betOnLay = {
  lose: (odds, stake) => {
    return -1 * parseFloat(odds - 1).toFixed(2) * parseFloat(stake);
  },
  profit: (stake) => {
    return parseFloat(stake);
  },
};

// Calculate Book for Bet on Lay for BOOKMAKER ----------
export const betOnLayBookmaker = {
  lose: (odds, stake) => {
    return -1 * parseFloat(odds / 100) * stake;
  },
  profit: (stake) => {
    return stake;
  },
};

// Calculate Book for Bet on Lay for BOOKMAKER ----------
export const betOnBackBookmaker = {
  profit: (odds, stake) => {
    return parseFloat(odds / 100) * stake;
  },
  lose: (stake) => {
    return -1 * stake;
  },
};

// Bets Validations for odds
export const checkOdds = (odd, type, oddsObj) => {
  // oddsObj contains lay, and back arrays
  if (type === "back") {
    const limit = findMaxFromArrayOfObjects(oddsObj?.availableToBack);
    if (odd > limit?.price) {
      return false;
    } else {
      return true;
    }
  } else {
    const limit = findMinFromArrayOfObjects(oddsObj?.availableToLay);
    if (odd < limit?.price) {
      return false;
    } else {
      return true;
    }
  }
};

//Date format to show

export const formatDate = (date) => {
  if (date) {
    const currentDate = new Date();
    const parsedEventDate = DateTime.fromISO(date);
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);

    // Format the dates without time component for comparison
    const parsedEventDateFormatted = parsedEventDate.toISODate();
    const currentDateFormatted = currentDate.toISOString().split("T")[0];
    const nextDayFormatted = nextDay.toISOString().split("T")[0];

    if (parsedEventDateFormatted === currentDateFormatted) {
      return "Today " + parsedEventDate.toFormat("HH:mm");
    } else if (parsedEventDateFormatted === nextDayFormatted) {
      return "Tomorrow " + parsedEventDate.toFormat("HH:mm");
    } else {
      const formattedDate = parsedEventDate.toFormat("dd MMM HH:mm");
      return formattedDate;
    }
  }
};

export const findFancyRunnerBooks = (fancyBets, exp) => {
  if (fancyBets !== undefined && fancyBets.length !== 0) {
    let book = [];
    let real_books = [];
    if (fancyBets.length > 0) {
      let min_odd = Math.min(...fancyBets.map((bet) => bet?.odds));
      let max_odd = Math.max(...fancyBets.map((bet) => bet?.odds));
      for (let i = min_odd - 5; i < max_odd + 5; i++) {
        if (i >= 0) {
          let filteredBets = fancyBets.filter((bet) => bet?.odds === i);
          if (filteredBets.length > 0) {
            filteredBets.forEach((bet) => {
              let my_pr = 100; //my_pr means percentage share
              let result = 0;
              let pl =
                bet?.selectionType === "lay"
                  ? (bet?.stake * my_pr) / 100
                  : (bet?.stake * (parseInt(bet?.oddsSize) / 100) * my_pr) /
                    100;
              let liability =
                bet?.liability === 0
                  ? (bet?.stake * my_pr) / 100
                  : (bet?.liability * my_pr) / 100;
              if (bet?.selectionType === "lay") {
                result = pl;
              } else {
                result = -liability;
              }

              book.push({
                odds: i,
                liability: liability,
                pl: pl,
                type: bet?.selectionType,
                result: result,
              });
            });
          } else {
            book.push({
              odds: i,
              liability: 0,
              pl: 0,
              type: null,
              result: 0,
            });
          }
        }
      }
    }
    if (book.length > 0) {
      for (let i = 0; i < book.length; i++) {
        let value = book[i];
        let result = 0;
        let odds = value.odds;
        for (let j = 0; j < book.length; j++) {
          let value2 = book[j];
          //  console.log("value2",value2);
          if (value2.type !== null) {
            if (value2.odds > odds) {
              if (value2.type === "lay") {
                result += value2.pl;
              } else if (value2.type === "back") {
                result -= value2.liability;
              }
            } else if (value2.odds === odds) {
              if (value2.type === "lay") {
                result -= value2.liability;
              } else if (value2.type === "back") {
                result += value2.pl;
              }
            } else if (value2.odds < odds) {
              if (value2.type === "back") {
                result += value2.pl;
              } else if (value2.type === "lay") {
                result -= value2.liability;
              }
            }
          }
        }
        let data = {
          odds: value.odds,
          result: result,
        };
        real_books.push(data);
      }
    }
    // Assuming real_books is an array of objects, similar to the PHP version.
    // The next two lines will make real_books unique based on the 'odds' property and extract only the 'result' property.
    real_books = real_books.filter(
      (book, index, self) =>
        index === self?.findIndex((b) => b.odds === book.odds)
    );
    // real_books = real_books.reduce((result, book) => {
    //   const { odds, result: bookResult } = book;
    //   if (!result.hasOwnProperty(odds)) {
    //     result[odds] = bookResult;
    //   }
    //   return result;
    // }, {});
    // console.log("real_books",real_books);
    let modifiedBooks = real_books.map((book) => book.result);
    let ex = Math.min(...modifiedBooks);
    // console.log("modifiedBooks",modifiedBooks);
    //  console.log("ex",ex);
    if (exp) {
      return ex;
    } else {
      return real_books;
    }
  }
};

export const findLineMarketBooks = (bet, exp) => {
  console.log("line book updated");
  if (bet !== undefined && bet?.length !== 0) {
    let fancyBets = undefined;
    fancyBets = bet
      ?.map((bet_data) => {
        return {
          selectionType: bet_data?.selectionType === "back" ? "lay" : "back",
          odds: Math.round(bet_data?.odds),
          stake: Math.floor(bet_data?.stake),
          liability: bet_data?.liability,
          status: bet_data?.status,
        };
      })
      ?.filter((match) => match?.status !== "6");

    if (fancyBets !== undefined && fancyBets?.length !== 0) {
      let lay = fancyBets?.filter(
        (decrement) => decrement?.selectionType === "lay"
      );
      let back = fancyBets?.filter(
        (increment) => increment?.selectionType === "back"
      );
      let min = Math.min(...fancyBets?.map((bet) => bet?.odds));
      let max = Math.max(...fancyBets?.map((bet) => bet?.odds));

      let min_odd_lay = Math.min(...lay?.map((bet) => bet?.odds));
      let max_odd_lay = Math.max(...lay?.map((bet) => bet?.odds));

      let min_odd_back = Math.min(...back?.map((bet) => bet?.odds));
      let max_odd_back = Math.max(...back?.map((bet) => bet?.odds));

      const layToBack = (data1, data2) => {
        let book = [];
        for (let i = data1 - 2; i < data2 + 4; i++) {
          const backIsExist = fancyBets?.some(
            (num) => num.odds === i && num.selectionType === "back"
          );
          if (i < min) {
            let layTotalWinGreaterThenCurrent = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.selectionType === "lay" &&
                  currentValue.odds > i
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let backTotalLossGreaterThenCurrent = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.selectionType === "back" &&
                  currentValue.odds > i
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let backTotalCurrentWin = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.selectionType === "back" &&
                  currentValue.odds <= i
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let layTotalLossLessThenCurrent = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.selectionType === "lay" &&
                  currentValue.odds < i
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let layTotalCurrentloss = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.selectionType === "lay" &&
                  currentValue.odds === i
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let totalLoss =
              backTotalLossGreaterThenCurrent +
              layTotalLossLessThenCurrent +
              layTotalCurrentloss;
            let totalProfit =
              layTotalWinGreaterThenCurrent + backTotalCurrentWin;

            let final = totalProfit - totalLoss;

            book.push({
              odds: i,
              stake: final,
              type: "back",
              result: final,
            });
          } else {
            if (backIsExist) {
              let layTotalWinGreaterThenCurrent = fancyBets?.reduce(
                (accumulator, currentValue) => {
                  if (
                    currentValue.selectionType === "lay" &&
                    currentValue.odds > i
                  ) {
                    return accumulator + currentValue.stake;
                  } else {
                    return accumulator;
                  }
                },
                0
              );

              let backTotalLossGreaterThenCurrent = fancyBets?.reduce(
                (accumulator, currentValue) => {
                  if (
                    currentValue.selectionType === "back" &&
                    currentValue.odds > i
                  ) {
                    return accumulator + currentValue.stake;
                  } else {
                    return accumulator;
                  }
                },
                0
              );

              let backTotalCurrentWin = fancyBets?.reduce(
                (accumulator, currentValue) => {
                  if (
                    currentValue.selectionType === "back" &&
                    currentValue.odds <= i
                  ) {
                    return accumulator + currentValue.stake;
                  } else {
                    return accumulator;
                  }
                },
                0
              );

              let layTotalLossLessThenCurrent = fancyBets?.reduce(
                (accumulator, currentValue) => {
                  if (
                    currentValue.selectionType === "lay" &&
                    currentValue.odds < i
                  ) {
                    return accumulator + currentValue.stake;
                  } else {
                    return accumulator;
                  }
                },
                0
              );

              let layTotalCurrentloss = fancyBets?.reduce(
                (accumulator, currentValue) => {
                  if (
                    currentValue.selectionType === "lay" &&
                    currentValue.odds === i
                  ) {
                    return accumulator + currentValue.stake;
                  } else {
                    return accumulator;
                  }
                },
                0
              );

              let totalLoss =
                backTotalLossGreaterThenCurrent +
                layTotalLossLessThenCurrent +
                layTotalCurrentloss;
              let totalProfit =
                layTotalWinGreaterThenCurrent + backTotalCurrentWin;

              let final = totalProfit - totalLoss;

              book.push({
                odds: i,
                stake: final,
                type: "back",
                result: final,
              });
            } else {
              const layIsExist = fancyBets?.some(
                (num) => num.odds === i && num.selectionType === "lay"
              );
              if (layIsExist) {
                let layTotalLosslessThenCurrent = fancyBets?.reduce(
                  (accumulator, currentValue) => {
                    if (
                      currentValue.selectionType === "lay" &&
                      currentValue.odds <= i
                    ) {
                      return accumulator + currentValue.stake;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                );

                let layTotalWinGreaterThenCurrent = fancyBets?.reduce(
                  (accumulator, currentValue) => {
                    if (
                      currentValue.selectionType === "lay" &&
                      currentValue.odds > i
                    ) {
                      return accumulator + currentValue.stake;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                );

                let backTotalWin = fancyBets?.reduce(
                  (accumulator, currentValue) => {
                    if (
                      currentValue.selectionType === "back" &&
                      currentValue.odds < i
                    ) {
                      return accumulator + currentValue.stake;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                );

                let backTotalLossGreaterThenCurrent = fancyBets?.reduce(
                  (accumulator, currentValue) => {
                    if (
                      currentValue.selectionType === "back" &&
                      currentValue.odds > i
                    ) {
                      return accumulator + currentValue.stake;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                );

                let totalLoss =
                  layTotalLosslessThenCurrent + backTotalLossGreaterThenCurrent;

                let totalProfit = layTotalWinGreaterThenCurrent + backTotalWin;
                let final = totalProfit - totalLoss;
                book.push({
                  odds: i,
                  stake: final,
                  type: "lay",
                  result: final,
                });
              }
            }
          }
        }
        return book;
      };

      const minLayToMaxLay = (data1, data2) => {
        let book = [];
        for (let i = data1 - 2; i < data2 + 3; i++) {
          if (i < min_odd_lay) {
            let layTotal = fancyBets?.reduce((accumulator, currentValue) => {
              if (currentValue.selectionType === "lay") {
                return accumulator + currentValue.stake;
              } else {
                return accumulator;
              }
            }, 0);
            book.push({
              odds: i,
              stake: layTotal,
              type: "lay",
              result: layTotal,
            });
          } else {
            let layTotalPositive = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.odds > i &&
                  currentValue.selectionType === "lay"
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let layTotalNegative = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.odds <= i &&
                  currentValue.selectionType === "lay"
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );
            let final = layTotalPositive - layTotalNegative;
            book.push({
              odds: i,
              stake: final,
              type: "lay",
              result: final,
            });
          }
        }
        return book;
      };

      const minBackToMaxBack = (data1, data2) => {
        let book = [];
        for (let i = data1 - 1; i < data2 + 2; i++) {
          if (i < min_odd_back) {
            let backTotal = fancyBets?.reduce((accumulator, currentValue) => {
              if (currentValue.selectionType === "back") {
                return accumulator + currentValue.stake;
              } else {
                return accumulator;
              }
            }, 0);
            book.push({
              odds: i,
              stake: -backTotal,
              type: "back",
              result: -backTotal,
            });
          } else {
            let backTotalPositive = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.odds <= i &&
                  currentValue.selectionType === "back"
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let backTotalNegative = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.odds > i &&
                  currentValue.selectionType === "back"
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let final = -backTotalNegative + backTotalPositive;
            book.push({
              odds: i,
              stake: final,
              type: "back",
              result: final,
            });
          }
        }
        return book;
      };

      //start
      let data = null;
      if (lay?.length > 0 && back?.length > 0) {
        data = layToBack(min, max);
      } else if (lay?.length > 0) {
        data = minLayToMaxLay(min_odd_lay, max_odd_lay);
        // return data;
      } else if (back?.length > 0) {
        data = minBackToMaxBack(min_odd_back, max_odd_back);
        // return data;
      }
      //start

      let modifiedBooks = data?.map((books) => books.stake);

      let ex = Math.min(...modifiedBooks);
      if (exp) {
        return ex;
      } else {
        return data;
      }
    }
  }
};

export const findFancyPL = (bets) => {
  let pl = 0;
  if (bets && bets.length !== 0) {
    bets.forEach((bet) => {
      const val = (bet?.oddsSize / 100) * bet?.stake;
      pl += val;
    });
    return pl.toFixed(2);
  } else {
    return 0;
  }
};

//Find the greatest value from array of objects
const findMaxFromArrayOfObjects = (array) => {
  if (array && array.length !== 0) {
    let maxObject = array?.reduce(function (previous, current) {
      return previous.price > current.price ? previous : current;
    });
    return maxObject;
  }
};

//Find the Minimum value from array of objects
const findMinFromArrayOfObjects = (array) => {
  if (array && array.length !== 0) {
    let maxObject = array?.reduce(function (previous, current) {
      return previous.price < current.price ? previous : current;
    });
    return maxObject;
  }
};

// For calling the toastify alert
export const notifySuccess = (() => {
  let hasToastBeenShown = false;
  const theme = localStorage?.getItem("theme");
  return (message) => {
    if (!hasToastBeenShown) {
      hasToastBeenShown = true;

      const toastId = toast.success(message, {
        className: theme == "true" ? "custom-toast-dark" : "custom-toast-light",
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: theme == "true" ? "dark" : "light",

        onClose: () => {
          hasToastBeenShown = false; // Reset the flag when the toast is closed.
        },
      });
      return toastId;
    }
  };
})();

export const notifyWarning = (() => {
  let hasToastBeenShown = false;
  const theme = localStorage?.getItem("theme");
  return (message) => {
    if (!hasToastBeenShown) {
      hasToastBeenShown = true;

      const toastId = toast.error(message, {
        className: theme == "true" ? "custom-toast-dark" : "custom-toast-light",
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: theme == "true" ? "dark" : "light",
        onClose: () => {
          hasToastBeenShown = false; // Reset the flag when the toast is closed.
        },
      });

      return toastId;
    }
  };
})();

//Find length of all Array inside another Arrays

export const findEventsLength = (array) => {
  if (array && array.length !== 0) {
    const totalLength = array?.reduce(
      (acc, league) => acc + league.events.length,
      0
    );
    return totalLength;
  } else {
    return 0;
  }
};

//Format numbers, to short the length

export const formatNumber = (num) => {
  if (num >= 10000) {
    return num / 1000 + "k";
  }
  return num;
};

//SlugName create

export const slugName = (name) => {
  console.log(name);
  if (name.length > 0) {
    let slugNameConcat = "";
    const slugName = name?.split(" ");
    slugName?.forEach((element) => {
      slugNameConcat =
        slugNameConcat +
        "_" +
        element?.charAt(0)?.toLowerCase() +
        element?.slice(1);
    });

    if (slugNameConcat?.startsWith("-")) {
      slugNameConcat = slugNameConcat?.slice(1);
    }
    return slugNameConcat?.replace(/^_*/, "");
  }
};

export const slugNameCreation = (name) => {
  if (name !== undefined && name.length > 0) {
    if (name.length > 0) {
      let slugNameConcat = "";
      const slugName = name?.split(" ");
      slugName?.forEach((element) => {
        slugNameConcat =
          slugNameConcat +
          "_" +
          element?.charAt(0)?.toLowerCase() +
          element?.slice(1);
      });

      if (slugNameConcat?.startsWith("-")) {
        slugNameConcat = slugNameConcat?.slice(1);
      }
      return slugNameConcat?.replace(/^_*/, "");
    }
  }
};

//groupArray Objects

// export const groupArrayasObject = (array, groupBy) => {
//   if (array && array.length > 0) {
//     const result = array?.reduce((result, obj) => {
//       let eventId = obj[groupBy];

//       if (!result[eventId]) {
//         result[eventId] = [];
//       }
//       result[eventId].push(obj);
//       return result;
//     }, {});
//     return result;
//   }
// };

export const groupArrayasObject = (array, groupBy, subGroup) => {
  if (array && array.length > 0) {
    const result = array?.reduce((result, obj) => {
      let eventId;
      if (obj?.sportBets) {
        eventId = subGroup
          ? obj.sportBets[groupBy][subGroup]
          : obj.sportBets[groupBy];
      } else {
        eventId = eventId = subGroup ? obj[groupBy][subGroup] : obj[groupBy];
      }
      if (!result[eventId]) {
        result[eventId] = [];
      }
      result[eventId].push(obj);
      return result;
    }, {});
    return result;
  }
};

// export const checkCasinoAccess = (casino, optionVal) => {
//   if (optionVal !== undefined) {
//     if (casino?.length === 0) {
//       return false;
//     } else {
//       if (casino?.includes(optionVal)) {
//         return false;
//       } else if (casino?.includes(availableCasinos.ALL)) {
//         return false;
//       } else {
//         return true;
//       }
//     }
//   } else {
//     return false;
//   }
// };

export const checkCasinoAccess = (casino, optionVal) => {
  if (optionVal !== undefined) {
    if (casino?.length === 0) {
      return true;
    } else {
      if (casino?.includes(optionVal)) {
        return true;
      } else if (casino?.includes(availableCasinos.ALL)) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    return true;
  }
};

export const oddsIncrementCheck = (selectedOdd) => {
  try {
    if (selectedOdd > 1 && selectedOdd < 2) {
      const tolerance = 1e-10;
      if ((selectedOdd * 100) % 1 < tolerance) {
        return { odd: selectedOdd };
      } else {
        const num = Math.ceil(selectedOdd * 100) / 100;
        return {
          odd: num,
          message:
            "Odds between 1 and 2 must be in increments of 0.01. Your odds have been updated accordingly.",
        };
      }
    } else if (selectedOdd > 2 && selectedOdd < 3) {
      if (
        Math.round(selectedOdd * 100) % 2 == 0 &&
        /^\d+(\.\d{1,2})?$/.test(selectedOdd.toString())
      ) {
        return { odd: selectedOdd };
      } else {
        const num = Math.ceil(selectedOdd * 50) / 50;
        return {
          odd: num,
          message:
            "Odds between 2 and 3 must be in increments of 0.02. Your odds have been updated accordingly.",
        };
      }
    } else if (selectedOdd > 3 && selectedOdd < 4) {
      if ((selectedOdd * 20) % 1 === 0) {
        return { odd: selectedOdd };
      } else {
        const num = Math.ceil(selectedOdd * 20) / 20;
        return {
          odd: num,
          message:
            "Odds between 3 and 4 must be in increments of 0.05. Your odds have been updated accordingly.",
        };
      }
    } else if (selectedOdd > 4 && selectedOdd < 6) {
      if ((selectedOdd * 10) % 1 === 0) {
        return { odd: selectedOdd };
      } else {
        const num = Math.ceil(selectedOdd * 10) / 10;
        return {
          odd: num,
          message:
            "Odds between 4 and 6 must be in increments of 0.1. Your odds have been updated accordingly.",
        };
      }
    } else if (selectedOdd > 6 && selectedOdd < 10) {
      if ((selectedOdd * 5) % 1 === 0) {
        return { odd: selectedOdd };
      } else {
        const num = Math.ceil(selectedOdd * 5) / 5;
        return {
          odd: num,
          message:
            "Odds between 6 and 10 must be in increments of 0.2. Your odds have been updated accordingly.",
        };
      }
    } else if (selectedOdd > 10 && selectedOdd < 20) {
      if ((selectedOdd * 2) % 1 === 0) {
        return { odd: selectedOdd };
      } else {
        const num = Math.ceil(selectedOdd * 2) / 2;
        return {
          odd: num,
          message:
            "Odds between 10 and 20 must be in increments of 0.5. Your odds have been updated accordingly.",
        };
      }
    } else if (selectedOdd > 20 && selectedOdd < 30) {
      if (selectedOdd % 1 === 0) {
        return { odd: selectedOdd };
      } else {
        const num = Math.ceil(selectedOdd);
        return {
          odd: num,
          message:
            "Odds between 20 and 30 must be in increments of 1. Your odds have been updated accordingly.",
        };
      }
    } else if (selectedOdd > 30 && selectedOdd < 50) {
      if (selectedOdd % 2 === 0) {
        return { odd: selectedOdd };
      } else {
        const num = Math.ceil(selectedOdd / 2) * 2;
        return {
          odd: num,
          message:
            "Odds between 30 and 50 must be in increments of 2. Your odds have been updated accordingly.",
        };
      }
    } else if (selectedOdd > 50 && selectedOdd < 100) {
      if (selectedOdd % 5 === 0) {
        return { odd: selectedOdd };
      } else {
        const num = Math.ceil(selectedOdd / 5) * 5;
        return {
          odd: num,
          message:
            "Odds between 50 and 100 must be in increments of 5. Your odds have been updated accordingly.",
        };
      }
    } else if (selectedOdd > 100 && selectedOdd < 1000) {
      if (selectedOdd % 10 === 0) {
        return { odd: selectedOdd };
      } else {
        const num = Math.ceil(selectedOdd / 10) * 10;
        return {
          odd: num,
          message:
            "Odds between 100 and 1000 must be in increments of 10. Your odds have been updated accordingly.",
        };
      }
    } else if (selectedOdd > 1000) {
      return {
        odd: 1000,
        message:
          "The maximum odds are 1000. Your odds have been updated accordingly.",
      };
    } else {
      return {
        odd: selectedOdd,
      };
    }
  } catch (error) {
    return null;
  }
};

export const oddsIncrementCheckLine = (selectedOdd) => {
  try {
    // if (selectedOdd - Math.floor(selectedOdd) == 0.5) {
    if (selectedOdd) {
      // The number after the decimal point is 0.5
      return { odd: selectedOdd };
    } else {
      // The number after the decimal point is not 0.5
      let decimalPart = selectedOdd - parseInt(selectedOdd);
      let decimalDiff = 0.5 - decimalPart;
      let pointFiveAdded = selectedOdd + decimalDiff;
      const num = pointFiveAdded;
      return {
        odd: num,
        message:
          "Runs between 0.5 and 400.5 must be in increments of 1. Your Runs have been updated accordingly.",
      };
    }
  } catch (error) {
    return null;
  }
};

export const oddsIncrementLine = (prevVal) => {
  if (prevVal) {
    return prevVal + 1.0;
  } else {
    return 0.5;
  }
};

export const oddsIncrementOdds = (prevVal) => {
  // console.log(prevVal, "prevVal");
  if (prevVal) {
    if (prevVal < 2) {
      return prevVal + 0.01;
    } else {
      if (prevVal >= 2 && prevVal < 3) {
        return prevVal + 0.02;
      } else if (prevVal >= 3 && prevVal < 4) {
        return prevVal + 0.05;
      } else if (prevVal >= 4 && prevVal < 6) {
        return prevVal + 0.1;
      } else if (prevVal >= 6 && prevVal < 10) {
        return prevVal + 0.2;
      } else if (prevVal >= 10 && prevVal < 20) {
        return prevVal + 0.5;
      } else if (prevVal >= 20 && prevVal < 30) {
        return prevVal + 1;
      } else if (prevVal >= 30 && prevVal < 50) {
        return prevVal + 2;
      } else if (prevVal >= 50 && prevVal < 100) {
        return prevVal + 5;
      } else if (prevVal >= 100 && prevVal < 1000) {
        return prevVal + 10;
      } else if (prevVal >= 1000) {
        return 1000;
      } else {
        return prevVal;
      }
    }
  } else {
    if (prevVal === 0) {
      return 0.01;
    }
  }
};
export const oddsdecrementOdds = (prevVal) => {
  // console.log(typeof prevVal, "prevVal");
  if (prevVal && prevVal > 0) {
    if (prevVal < 2) {
      return prevVal - 0.01;
    } else {
      if (prevVal >= 2 && prevVal < 3) {
        return prevVal - 0.02;
      } else if (prevVal >= 3 && prevVal < 4) {
        return prevVal - 0.05;
      }
      if (prevVal >= 4 && prevVal < 6) {
        return prevVal - 0.1;
      } else if (prevVal >= 6 && prevVal < 10) {
        return prevVal - 0.2;
      } else if (prevVal >= 10 && prevVal < 20) {
        return prevVal - 0.5;
      } else if (prevVal >= 20 && prevVal < 30) {
        return prevVal - 1;
      } else if (prevVal >= 30 && prevVal < 50) {
        return prevVal - 2;
      } else if (prevVal >= 50 && prevVal < 100) {
        return prevVal - 5;
      } else if (prevVal >= 100 && prevVal <= 1000) {
        return prevVal - 10;
      } else if (prevVal > 1000) {
        return 1000;
      } else {
        // console.log("return prev val");
        return prevVal;
      }
    }
  } else {
    return 0;
  }
};

export const oddsDecrementLine = (prevVal) => {
  if (prevVal === 1) {
    return 0.5;
  } else if (prevVal) {
    return prevVal - 1.0;
  } else {
    return 0.5;
  }
};

//FOR REMOVE MULTIPLE WHITESPACE AND SPECIAL CHARACTER

export const removeSpecialCharactersAndSpaces = (str) => {
  return str
    .replace(/[^\w\s_]/gi, "")
    .replace(/\s+/g, "")
    .replace(/\s/g, "")
    .toLowerCase();
};

export { findMaxFromArrayOfObjects, findMinFromArrayOfObjects }; // because this function used in this same file also

export const s3MediaUrl = (folder, date, filename) => {
  if (filename && filename !== "") {
    return `${process.env.AMZ_BUCKET_URL}/${folder}/${date}/${filename}`;
  }
  return "";
};

export const playNotificationSound = async () => {
  try {
    const audio = new Audio(NotificationSound);
    await audio.play();
  } catch (error) {
    console.log("error", error);
  }
};

const findFancyLeagueData = (id, objects) => {
  try {
    if (!objects) {
      return null;
    }
    if (!id) {
      return [objects.DefaultStakes, objects.DefaultStakesMatch];
    }
    function findMax(extractedData) {
      const resultArray = [];
      for (let key in extractedData) {
        if (
          extractedData.hasOwnProperty(key) &&
          extractedData[key].id === parseInt(id)
        ) {
          resultArray.push(extractedData[key].fancyLimit);
        }
      }
      //const max = resultArray[0] > resultArray[1] ? resultArray[0] : resultArray[1];
      return resultArray;
    }
    const maxLeague = findMax(objects);
    return maxLeague;
  } catch (err) {
    console.log(err);
  }
};
export const findTheFancyMarketDataStakeMarket = (id, data, type) => {
  try {
    const idArray = [10, 12, 14, 16, 18, 20, 22, 28, 34, 36, 42];
    let match;
    let legaue;
    if (idArray.includes(parseInt(id))) {
      match = findFancyLeagueData(id, data?.fancyLimitMatchData);
      legaue = findFancyLeagueData(id, data?.fancyLimitLeagueData);
    } else {
      match = findFancyLeagueData("0", data?.fancyLimitMatchData);
      legaue = findFancyLeagueData("0", data?.fancyLimitLeagueData);
    }

    if (!match && legaue) {
      if (type === "match") {
        return convertToShortForm(legaue[1]);
      } else if (type === "market") {
        return convertToShortForm(legaue[0]);
      }
    }
    let stakeMax;
    stakeMax = match[0] > legaue[0] ? match[0] : legaue[0];
    let marketMax;
    marketMax = match[1] > legaue[1] ? match[1] : legaue[1];
    if (type === "match") {
      return convertToShortForm(marketMax);
    } else if (type === "market") {
      return convertToShortForm(stakeMax);
    }
  } catch (err) {
    //console.error(err?.message);
  }
};
export function convertToShortForm(value) {
  if (value > 100000) {
    return (value / 100000).toFixed(2) + "M";
  }
  if (value > 1000) {
    return (value / 1000).toFixed(2) + "K";
  } else {
    return value.toFixed(2);
  }
}

export function oodsSizeValueFormater(value) {
  if (isNaN(value)) {
    return value;
  }
  const unitThresholds = [
    { threshold: 1000000, symbol: "m" },
    { threshold: 1000, symbol: "k" },
  ];
  const unit = unitThresholds.find((unit) => value >= unit.threshold);
  // const baseValue = value / (unit === "m" ? 1000000 : 1000);
  // const formattedBase = baseValue.toFixed(1);
  // return formattedBase + unit;
  if (!unit) {
    return value.toFixed(2);
  }
  const baseValue = value / unit.threshold;
  const formattedBase = baseValue.toFixed(2);
  return formattedBase + unit.symbol;
}

export const convertCurrencyBetFair = (
  amountInHKN = 1,
  betFairCurencyValue = 1,
  userCurrencyValue = 1,
  betFairPercentage = 1
) => {
  if (
    isNaN(userCurrencyValue) ||
    userCurrencyValue == undefined ||
    userCurrencyValue == null
  ) {
    userCurrencyValue = 1;
  }
  if (
    isNaN(betFairCurencyValue) ||
    betFairCurencyValue == undefined ||
    betFairCurencyValue == null
  ) {
    betFairCurencyValue = 1;
  }
  if (
    isNaN(betFairPercentage) ||
    betFairPercentage == undefined ||
    betFairPercentage == null
  ) {
    betFairPercentage = 1;
  }
  const convertedValue =
    (amountInHKN / betFairCurencyValue) *
    (100 / betFairPercentage) *
    userCurrencyValue;
  return convertedValue;
};
export const marketPriority = {
  "Match Odds": 1,
  BOOKMAKER: 2,
  "Tied Match": 3,
  "To Win the Toss": 4,
};

export const sortMarketsByPriority = (eventObj) => {
  return eventObj?.markets.sort((a, b) => {
    const priorityA = marketPriority[a.marketName] || 9999;
    const priorityB = marketPriority[b.marketName] || 9999;
    return priorityA - priorityB;
  });
};

export const convertINRToCurrency= (amountINR, conversionRate, number) => {
  //***pass true in number if we want response in number format

  let rate = conversionRate;
  if (
    conversionRate === undefined ||
    conversionRate === null ||
    conversionRate === 0 ||
    isNaN(conversionRate)
  ) {
    rate = 1;
  }
  const amountConverted = amountINR * rate;
  if (number) {
    //returns number
    return +parseFloat(amountConverted).toFixed(2);
  } else {
    return amountConverted.toLocaleString("en-us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
};