import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import MyErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
// import SportsPage from "../pages/Sports";
// import {
//   InPlayPage,
//   IndianCasinoPage,
//   IplClubPage,
//   LiveCasinoPage,
//   SlotsPage,
// } from "../pages";
// import { DepositPage } from "../pages/Deposit";
// import { WithdrawalPage } from "../pages/Withdrawal";
// import { MyMarketPage } from "../pages/MyMarket";
// import { ProfitLossPage } from "../pages/ProfitLoss";
// import { AccountStatementPage } from "../pages/AccountStatement";
// import CricketLeaguePage from "../pages/CricketLeaguePage";
// import PrivacyPolicy from "../pages/PrivacyPolicy";
// import TermsAndConditionsPage from "../pages/TermsAndConditions";
// import PlayResponsiblePage from "../pages/PlayResponsible";
// import AboutUsPage from "../pages/AboutUs";
// import KycAndPrivacyPage from "../pages/KycAndPrivacy";
// import St8CasinoPage from "../pages/St8Casino";

import { Paths } from "./path";
import { Layout } from "../pages/Layout";
import PrivateRoutes from "./PrivateRoutes";
import HomePage from "../pages/Home";
import NotFound from "../components/Error/NotFound";
import XLoader from "../components/Loader/XLoader";
import LazyLoadWithDelay from "../components/Hoc/LazyLoadWithDelay";
import GapCasino from "../components/GapCasino";
const SportsPage = LazyLoadWithDelay(() => import("../pages/Sports"));
const DepositPage = LazyLoadWithDelay(() => import("../pages/Deposit"));
const WithdrawalPage = LazyLoadWithDelay(() => import("../pages/Withdrawal"));
const MyMarketPage = LazyLoadWithDelay(() => import("../pages/MyMarket"));
const ProfitLossPage = LazyLoadWithDelay(() => import("../pages/ProfitLoss"));
const AccountStatementPage = LazyLoadWithDelay(() =>
  import("../pages/AccountStatement")
);
const CricketLeaguePage = LazyLoadWithDelay(() =>
  import("../pages/CricketLeaguePage")
);
const PrivacyPolicy = LazyLoadWithDelay(() => import("../pages/PrivacyPolicy"));
const PlayResponsiblePage = LazyLoadWithDelay(() =>
  import("../pages/PlayResponsible")
);
const AboutUsPage = LazyLoadWithDelay(() => import("../pages/AboutUs"));
const KycAndPrivacyPage = LazyLoadWithDelay(() =>
  import("../pages/KycAndPrivacy")
);
const St8CasinoPage = LazyLoadWithDelay(() => import("../pages/St8Casino"));
const InPlayPage = LazyLoadWithDelay(() => import("../pages/InPlay"));
const IndianCasinoPage = LazyLoadWithDelay(() =>
  import("../pages/IndianCasino")
);
const IplClubPage = LazyLoadWithDelay(() => import("../pages/IplClub"));
// const LiveCasinoPage = LazyLoadWithDelay(() => import("../pages/LiveCasino"));
const SlotsPage = LazyLoadWithDelay(() => import("../pages/Slots"));
const TermsAndConditionsPage = LazyLoadWithDelay(() =>
  import("../pages/TermsAndConditions")
);
const StocksPage= LazyLoadWithDelay(()=> import ('../pages/Stocks'))
const HorseRacingPage = LazyLoadWithDelay(() => import('../pages/HorseRacingPage'));
const HorceRacingEvents = LazyLoadWithDelay(() => import('../pages/HorseRaceEventPage'))
const MarketView = LazyLoadWithDelay(() =>
  import("../components/MarketView/MarketView")
);

export const AppRouter = () => {
  return (
    <MyErrorBoundary>
      <Suspense fallback={<XLoader />}>
        <Routes>
          <Route path={Paths.analytics} element={<MarketView />} />
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/home" replace={true} />} />
            <Route path={Paths.home} element={<HomePage />} />
            <Route path={Paths.sports} element={<SportsPage />} />
            <Route path={Paths.inplay} element={<InPlayPage />} />
            <Route path={Paths.indianCasino} element={<IndianCasinoPage />} />
            <Route path={Paths.slots} element={<SlotsPage />} />
            <Route path={Paths.gapCasino} element={<GapCasino />} />
            <Route path={Paths.iplclub} element={<IplClubPage />} />
            {/* <Route path={Paths.liveCasino} element={<LiveCasinoPage />} /> */}
            <Route path={Paths.deposit} element={<DepositPage />} />
            <Route path={Paths.withdrawal} element={<WithdrawalPage />} />
            <Route path={Paths.cricketleague} element={<CricketLeaguePage />} />
            <Route path={Paths.privacyPolicy} element={<PrivacyPolicy />} />
            <Route
              path={Paths.termsAndConditions}
              element={<TermsAndConditionsPage />}
            />
            <Route
              path={Paths.playResponsible}
              element={<PlayResponsiblePage />}
            />
            <Route path={Paths.aboutUs} element={<AboutUsPage />} />
            <Route path={Paths.kycAndPrivacy} element={<KycAndPrivacyPage />} />
            <Route path={Paths.st8Casino} element={<St8CasinoPage />} />
            <Route path={Paths.horseRacing} element={<HorseRacingPage />} />
            <Route path={Paths.horseRacingEvents} element={< HorceRacingEvents />} />
            <Route element={<PrivateRoutes />}>
              <Route path={Paths.mymarket} element={<MyMarketPage />} />
              <Route path={Paths.pl} element={<ProfitLossPage />} />
              <Route
                path={Paths.accountstatement}
                element={<AccountStatementPage />}
              />
              <Route path={Paths.stocks}  element={<StocksPage/>} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </MyErrorBoundary>
  );
};
