import {
  GET_POPULAR_EVENTS,
  GET_POPULAR_EVENTS_FAILURE,
  GET_POPULAR_EVENTS_SUCCESS,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  data: null,
};

const getPopularEventsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POPULAR_EVENTS:
      return { ...state, loading: true };
    case GET_POPULAR_EVENTS_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case GET_POPULAR_EVENTS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default getPopularEventsReducer;
