import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useForm } from "react-hook-form";
import { chipSetting } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  convertINRToCurrency,
  notifyWarning,
} from "../utils/helper";
import { defaultChipValues } from "../utils/constants";
import { getLocalStorageItem } from "../utils/helper";

const ChipSetting = ({ closeModal }) => {
  const { register, handleSubmit } = useForm();
  const userData = JSON.parse(getLocalStorageItem("userData"));
  const userCurrencyValue = userData?.currency?.value;
  const chipDatas = useSelector(
    (state) => state?.GetChipSetting?.chipData?.data
  );

  const updatedChipData = useSelector((state) => state?.ChipSetting?.newChip);

  const [chipArray, setChipArray] = useState([]);

  useEffect(() => {
   
    if (!chipDatas && !updatedChipData) {
      if (userCurrencyValue === 1) {
        setChipArray(defaultChipValues);
      } else {
        const convertedChipValues = defaultChipValues.map((chip) => {
          return {
            name: convertINRToCurrency(chip.name.replace(/,/g, ''), userCurrencyValue, true),
            value: convertINRToCurrency(chip.value, userCurrencyValue, true),
          };
        });
        setChipArray(convertedChipValues);
      }
    } else {
      setChipArray(updatedChipData?.chip || chipDatas?.chip);
    }
  }, [updatedChipData, chipDatas]);

  const chipSettingValidation = (chipArray) => {
    for (let chip of chipArray) {
      if (chip.name === "" || chip.value === null) {
        return false;
      }
    }
    return true;
  };

  // Calling the API(post)
  const dispatch = useDispatch();
  const onSubmit = (chip) => {
    // const newChips = chip?.chip?.filter((val) => {
    //   return val?.name !== "" && val?.value !== null && !isNaN(val?.value);
    // });
    let validate = chipSettingValidation(chip?.chip);

    // if (newChips?.length > 0) {
    if (validate) {
      dispatch(
        chipSetting({
          chip,
          callback: (data) => {
            if (data) {
              closeModal(false);
            }
          },
          errorCallback: (error) => {},
        })
      );
    } else {
      notifyWarning("chip setting cannot be empty");
    }
  };

  return (
    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-2">
      <div className="z-50">
        <div className="fixed inset-0 bg-gray-100 bg-opacity-25 backdrop-blur-sm flex items-center justify-center ">
          <div className="bg-skin-modalBackground rounded-xl w-96 ">
            <div className="relative p-5">
              <div className=" p-0">
                <button
                  className="absolute top-1 right-3"
                  type="button"
                  onClick={() => closeModal(false)}
                >
                  <MdOutlineClose className="text-skin-primary text-3xl font-bold" />
                </button>
                <h1 className="font-semibold text-center uppercase text-xl text-skin-primary mb-5 mt-5">
                  Chips setting
                </h1>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="max-h-96 p-5 overflow-auto"
              >
                <div className="chip-container">
                  {chipArray.map((input, index) => (
                    <div
                      className="chip-row flex items-center gap-6"
                      key={index}
                    >
                      <div className="chip-name">
                        <label
                          className="text-skin-primary text-xs font-semibold"
                          htmlFor={`nameInput-${index}`}
                        >
                          Name
                        </label>
                        <input
                          id={`nameInput-${index}`}
                          {...register(`chip[${index}].name`)}
                          type="text"
                          className="bg-skin-inputBackground w-full p-2 rounded mt-2 focus:outline-none focus:bg-[#fff] text-[#454c53]"
                          placeholder="Name"
                          defaultValue={input?.name}
                        />
                      </div>
                      <div className="chip-value">
                        <label
                          className="text-skin-primary text-xs font-semibold"
                          htmlFor={`valueInput-${index}`}
                        >
                          Value
                        </label>
                        <input
                          id={`valueInput-${index}`}
                          {...register(`chip[${index}].value`, {
                            setValueAs: (v) => parseInt(v),
                          })}
                          type="number"
                          className="bg-skin-inputBackground w-full p-2 rounded mt-2 focus:outline-none focus:bg-[#fff] text-[#454c53]"
                          placeholder="Value"
                          min={0}
                          defaultValue={input?.value}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="p-4 mt-4 border-t-2 text-right border-[#fff]">
                  <button
                    type="submit"
                    className="p-1.5 px-3 uppercase font-semibold rounded-md hover:bg-[#0069d9  ] bg-[#007bff] text-lg text-white focus:outline-none "
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChipSetting;
