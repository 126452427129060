import { all, call, put, takeEvery } from "redux-saga/effects";
import { FETCH_WHITELABEL } from "../../actions/types";
import { fetchWhiteLabelSuccess, fetchWhiteLabelFailure } from "../../actions";
import API from "../../../utils/api";

function* getHomeSliderRequest(action) {
  try {
    const { data } = yield API.get(
      `/api/v1/whiteLabel?id=${action?.payload?.domain}`
    );

    if (data.meta.code === 200) {
      yield put(fetchWhiteLabelSuccess(data?.data));
      yield call(action.payload.callback, data?.data);
    } else if (data.meta.code !== 200) {
      yield put(fetchWhiteLabelFailure());
    }
  } catch (error) {
    yield put(fetchWhiteLabelFailure());
  }
}

export function* watchgetHomeSliderAPI() {
  yield takeEvery(FETCH_WHITELABEL, getHomeSliderRequest);
}

export default function* rootSaga() {
  yield all([watchgetHomeSliderAPI()]);
}
