import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_HORSE_RACE_LIVE } from "../../actions/types";
import {
  getHorseRaceLiveSuccess,
  getHorseRaceLiveFailure,
} from "../../actions";
import horseRaceAPI from "../../../utils/horseRaceApi";

function* getLiveHorseRaceRequest(action) {
  try {
    const { data } = yield horseRaceAPI.get(`api/v1/live-race`);

    if (data.meta.code === 200) {
      yield put(getHorseRaceLiveSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code !== 200) {
      yield put(getHorseRaceLiveFailure());
    }
  } catch (error) {
    yield put(getHorseRaceLiveFailure());
  }
}

export function* watchGetHorseRaceLiveAPI() {
  yield takeEvery(GET_HORSE_RACE_LIVE, getLiveHorseRaceRequest);
}

export default function* rootSaga() {
  yield all([watchGetHorseRaceLiveAPI()]);
}
