import { all, call, put, takeEvery } from "redux-saga/effects";
import { HORSE_RACE_EVENTS } from "../../actions/types";
import {
  getHorseRaceEventsSuccess,
  getHorseRaceEventsFailure,
} from "../../actions";
import horseRaceAPI from "../../../utils/horseRaceApi";

function* getHorseRacingDataRequest(action) {
  try {
    const { data } = yield horseRaceAPI.get(`api/v1/horse-race-events`);
    if (data.meta.code === 200) {
      yield call(action.payload.callback, data.data);
      yield put(getHorseRaceEventsSuccess(data));
    } else if (data.meta.code === 401) {
      yield put(getHorseRaceEventsFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data.meta.code !== 200) {
      yield put(getHorseRaceEventsFailure());
    }
  } catch (error) {
    yield put(getHorseRaceEventsFailure());
  }
}

export function* watchGetHorseEventsDataAPI() {
  yield takeEvery(HORSE_RACE_EVENTS, getHorseRacingDataRequest);
}

export default function* rootSaga() {
  yield all([watchGetHorseEventsDataAPI()]);
}
