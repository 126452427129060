import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppRouter } from "./routes/AppRouter";
import React from "react";
import { ThemeContext } from "./context/ThemeContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import Loading from "./context/LoadingContext";
import { SocketContext, socket } from "./context/SocketContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const App = () => {
  //const navigate = useNavigate();

  useEffect(() => {
    // navigate("/home");
    clearListCookies();
  }, []);

  function clearListCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var spcook = cookies[i].split("=");
      deleteCookie(spcook[0]);
    }

    function deleteCookie(cookiename) {
      var d = new Date();
      d.setTime(d.getTime() - 1);
      var expires = "expires=" + d.toUTCString();
      var name = cookiename;
      document.cookie = name + "=; " + expires + "; path=/";
    }

    //delete cache
    caches?.keys()?.then((cacheNames) => {
      cacheNames?.forEach((value) => {
        caches?.delete(value);
      });
    });
    //delete cache
  }

  // THIS FUNCTION IS USED TO HANDLE CONTEXTMENU DISABLING
  // USING THIS FUNCTION WE HAVE TO DISABLE RIGHT CLICK ON EVERY PAGES
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const { theme } = useContext(ThemeContext);

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}
    >
      <Provider store={store}>
        <div
          className={theme ? "" : "light-theme"}
          onContextMenu={handleContextMenu}
        >
          <Loading>
            <SocketContext.Provider value={socket}>
              <AppRouter />
            </SocketContext.Provider>
          </Loading>
        </div>
      </Provider>
    </GoogleOAuthProvider>
  );
};
