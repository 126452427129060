import {
  CURRENCY_BETFAIR,
  CURRENCY_BETFAIR_FAILURE,
  CURRENCY_BETFAIR_SUCCESS,
} from "../../actions/types";

const INIT_STATE = {
  loading: false,
  currency: null,
};

const currencyBetFairReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CURRENCY_BETFAIR:
      return { ...state, loading: true };
    case CURRENCY_BETFAIR_SUCCESS:
      return { ...state, currency: action?.payload, loading: false };
    case CURRENCY_BETFAIR_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default currencyBetFairReducer;
