import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_HOME_SLIDER } from "../../actions/types";
import { getHomeSliderSuccess, getHomeSliderFailure } from "../../actions";
import API from "../../../utils/api";

function* getHomeSliderRequest(action) {
  try {
    const { data } = yield API.get(
      `/api/v1/home-slider?isMobile=${action.payload.data}`
    );
    if (data.meta.code === 200) {
      yield put(getHomeSliderSuccess(data?.data));
      yield call(action.payload.callback, data?.data);
    } else if (data.meta.code !== 200) {
      yield put(getHomeSliderFailure());
    }
  } catch (error) {
    console.log(error);
    yield put(getHomeSliderFailure());
  }
}

export function* watchgetHomeSliderAPI() {
  yield takeEvery(GET_HOME_SLIDER, getHomeSliderRequest);
}

export default function* rootSaga() {
  yield all([watchgetHomeSliderAPI()]);
}
