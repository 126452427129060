import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_BALANCE_DATA } from "../../actions/types";
import {
  getUserBalanceDataSuccess,
  getUserBalanceDataFailure,
} from "../../actions";
import API from "../../../utils/api";

function* getUserBalanceDataRequest(action) {
  try {
    const { data } = yield API.get(`api/v1/balance-data`);
    if (data.meta.code === 200) {
      yield put(getUserBalanceDataSuccess(data));
      yield call(action.payload.callback, data.data);
    } else if (data.meta.code === 401) {
      yield put(getUserBalanceDataFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(getUserBalanceDataFailure());
    }
  } catch (error) {
    yield put(getUserBalanceDataFailure());
  }
}

export function* watchUserBalanceDataAPI() {
  yield takeEvery(GET_BALANCE_DATA, getUserBalanceDataRequest);
}

export default function* rootSaga() {
  yield all([watchUserBalanceDataAPI()]);
}
