import {
    GET_EVENT_BETS,
    GET_EVENT_BETS_FAILURE,
    GET_EVENT_BETS_SUCCESS,
  } from "../../actions/types";
  
  const INIT_STATE = {
    loading: false,
    data: null,
  };
  
  const getEventBetsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_EVENT_BETS:
        return { ...state, loading: true };
      case GET_EVENT_BETS_SUCCESS:
        return { ...state, data: action.payload, loading: false };
      case GET_EVENT_BETS_FAILURE:
        return { ...state, loading: false };
      default:
        return state;
    }
  };
  
  export default getEventBetsReducer;
  