
import { SEARCH_KEYWORD_FAILURE, SEARCH_KEYWORD_REQUEST, SEARCH_KEYWORD_SUCCESS } from "../types";


  export const searchKeyword = (payload) => ({
    type: SEARCH_KEYWORD_REQUEST,
    payload,
  });

  export const searchKeywordSuccess = (payload) => ({
    type: SEARCH_KEYWORD_SUCCESS,
    payload,
  });

  export const searchKeywordFailure = () => ({
    type: SEARCH_KEYWORD_FAILURE,
  });