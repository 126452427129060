// import React from "react";

// const EmptyRunners = ({ status }) => {
//   const number = 6;
//   return (
//     <>
//       {Array.from({ length: number }, (_, index) => (
//         <div className="inline-block flex-1" key={index}>
//           <div className="flex drop-shadow-sm rounded-sm ">
//             <div
//               className={` w-[100%] py-1.5 rounded-md m-1 flex justify-center text-md font-bold cursor-pointer ${
//                 index % 2 === 0 ? "text-skin-blue bg-skin-blue" : "text-skin-pink bg-skin-pink"
//               }`}
//             >
//               <span className="m-auto brightness-125"> - </span>
//             </div>
//           </div>
//         </div>
//       ))}
//     </>
//   );
// };

// export default EmptyRunners;

import React from "react";

const EmptyRunners = (props) => {
  let number=3;
  // if(props.is=3Mobile){number = 1} else {number = 3} 
  return (
    <>
     {props.isMobile ? (
      <>
       {Array.from({ length: number }, (_, index) => (
        <div className="flex-1" key={index}>
          <div className="flex drop-shadow-sm rounded-sm">
            <div
              className={`min-w-[45%] h-10 py-1.5 rounded-md m-1 flex justify-center text-md font-bold text-skin-blue bg-skin-oddsCardBlue cursor-pointer`}
            >
              <span className="m-auto brightness-125">&nbsp; -  &nbsp;</span>
            </div>
            <div
              className={`min-w-[45%] h-10 py-1.5 rounded-md m-1 flex justify-center text-md font-bold text-skin-pink bg-skin-oddsCardPink cursor-pointer`}
            >
              <span className="m-auto brightness-125">&nbsp; -  &nbsp;</span>
            </div>
          </div>
        </div>
      ))}
      </>
     ):(
      <>
       {Array.from({ length: number }, (_, index) => (
        <div className="flex-1" key={index}>
          <div className="flex drop-shadow-sm rounded-sm">
            <div
              className={`min-w-[50px] 2xl:w-[45%] h-12 py-1.5 rounded-md m-1 flex justify-center text-md font-bold text-skin-blue bg-skin-oddsCardBlue cursor-pointer`}
            >
              <span className="m-auto brightness-125">&nbsp; -  &nbsp;</span>
            </div>
            <div
              className={`min-w-[50px] 2xl:w-[45%] h-12 py-1.5 rounded-md m-1 flex justify-center text-md font-bold text-skin-pink bg-skin-oddsCardPink cursor-pointer`}
            >
              <span className="m-auto brightness-125">&nbsp; -  &nbsp;</span>
            </div>
          </div>
        </div>
      ))}
      </>
     )}
    </>
  );
};

export default EmptyRunners;

