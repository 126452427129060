import { BET_SPORT_HORSERACING , BET_SPORT_HORSERACING_FAILURE , BET_SPORT_HORSERACING_SUCCESS } from "../types";

export const getBetHorseRace = (payload) => ({
  type: BET_SPORT_HORSERACING,
  payload,
});

export const getBetHorseRaceSuccess = (payload) => ({
  type: BET_SPORT_HORSERACING_SUCCESS,
  payload,
});

export const getBetHorseRaceFailure = () => ({
  type: BET_SPORT_HORSERACING_FAILURE,
});
