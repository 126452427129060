import {
  GET_ST8CASINO_URL,
  GET_ST8CASINO_URL_SUCCESS,
  GET_ST8CASINO_URL_FAILURE,
} from "../types";

export const getSt8CasinoUrl = (payload) => ({
  type: GET_ST8CASINO_URL,
  payload,
});

export const getSt8CasinoUrlSuccess = (payload) => ({
  type: GET_ST8CASINO_URL_SUCCESS,
  payload,
});

export const getSt8CasinoUrlFailure = () => ({
  type: GET_ST8CASINO_URL_FAILURE,
});
