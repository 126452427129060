import {
  NOTIFICATION,
  NOTIFICATION_FAILURE,
  NOTIFICATION_SUCCESS,
} from "../types";

export const notification = (payload) => ({
  type: NOTIFICATION,
  payload,
});

export const notificationSuccess = (payload) => ({
  type: NOTIFICATION_SUCCESS,
  payload,
});

export const notificationFailure = () => ({
  type: NOTIFICATION_FAILURE,
});
