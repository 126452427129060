import { all, call, put, takeEvery } from "redux-saga/effects";
import {  GET_HORSE_RACE_COUNTRIES_VENUES, } from "../../actions/types";
import {  getCountriesVenuesHorseRaceSuccess , getCountriesVenuesHorseRaceFailure } from "../../actions";
import horseRaceAPI from "../../../utils/horseRaceApi";

function* getHorseRaceCountriesVenueRequest(action) {
  try {
    const { data } = yield horseRaceAPI.get(`api/v1/horse-race-countries-venues`);
    if (data?.meta?.code === 200) {
      yield put(getCountriesVenuesHorseRaceSuccess(data));
      yield call(action?.payload?.callback, data?.data);
    } else if (data?.meta?.code === 401) {
      yield put(getCountriesVenuesHorseRaceFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    } else if (data?.meta?.code !== 200) {
      yield put(getCountriesVenuesHorseRaceFailure());
    }
  } catch (error) {
    yield put(getCountriesVenuesHorseRaceFailure());
  }
}

export function* watchGetHorseCountriesVenueDataAPI() {
  yield takeEvery(GET_HORSE_RACE_COUNTRIES_VENUES, getHorseRaceCountriesVenueRequest);
}

export default function* rootSaga() {
  yield all([watchGetHorseCountriesVenueDataAPI()]);
}