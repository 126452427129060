import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_INPLAY } from "../../actions/types";
import { getInPlaySuccess, getInPlayFailure } from "../../actions";
import API from "../../../utils/oddApi";

function* getInPlayDataRequest(action) {
  try {
    const { data } = yield API.get("/api/v2/inplay");
    if (data.meta.code === 200) {
      yield put(getInPlaySuccess(data?.data));
      yield call(action.payload.callback, data?.data);
    } else if (data.meta.code !== 200) {
      yield put(getInPlayFailure());
      yield call(action.payload.callback, data);
    }
  } catch (error) {
    yield put(getInPlayFailure());
  }
}

export function* watchGetInPlayDataAPI() {
  yield takeEvery(GET_INPLAY, getInPlayDataRequest);
}

export default function* rootSaga() {
  yield all([watchGetInPlayDataAPI()]);
}
