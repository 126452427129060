import {
  DELETE_UNMATCH_BET,
  DELETE_UNMATCH_BET_SUCCESS,
  DELETE_UNMATCH_BET_FAILURE,
} from "../types";

export const deleteUnmatchBet = (payload) => ({
  type: DELETE_UNMATCH_BET,
  payload,
});

export const deleteUnmatchBetSuccess = (payload) => ({
  type: DELETE_UNMATCH_BET_SUCCESS,
  payload,
});

export const deleteUnmatchBetFailure = () => ({
  type: DELETE_UNMATCH_BET_FAILURE,
});
