import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_DELETED_EVENT_BETS } from "../../actions/types";
import {
  getDeletedEventBetsSuccess,
  getDeletedEventBetsFailure,
} from "../../actions";
import API from "../../../utils/api";

function* getDeletedEventBetsRequest(action) {
  try {
    const { data } = yield API.get(
      `api/v1/deleted-bet-by-event?eventId=${action.payload.eventId}`
    );
    if (data.meta.code === 200) {
      yield put(getDeletedEventBetsSuccess(data?.data));
      yield call(action.payload.callback(data?.data));
    } else if (data.meta.code === 401) {
      yield put(getDeletedEventBetsFailure());
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("googleToken");
    } else if (data.meta.code !== 200) {
      yield put(getDeletedEventBetsFailure());
    }
  } catch (error) {
    yield put(getDeletedEventBetsFailure());
  }
}

export function* watchgetDeletedEventBetsAPI() {
  yield takeEvery(GET_DELETED_EVENT_BETS, getDeletedEventBetsRequest);
}

export default function* rootSaga() {
  yield all([watchgetDeletedEventBetsAPI()]);
}
