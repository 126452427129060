/** @format */

import React, { useState } from "react";
import Googlelogin from "./2FAComponents/GoogleLogin/Googlelogin";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, googleloginValidation } from "../redux/actions";
import Google_Authenticator_logo from "../assets/images/Authentication/Google_Authenticator_logo.png";
import Xxexch_logo from "../assets/images/Authentication/Xxexch_logo.jpeg";
import SelectPNG from "../assets/images/selected.png";
import QRCode from "./QRCode";
import { Link } from "react-router-dom";
import "./style.css";
import { appName } from "../utils/constants";

const ConfirmModal = (props) => {
  // console.log(props);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.GetUserData?.userData?.data);
  // console.log(userData)

  const modalClass = props.isOpen
    ? "fixed inset-0 flex items-center justify-center"
    : "hidden";
  const tempCurrentSelections =
    userData?.twoFactorMethod === "google"
      ? "Google"
      : userData?.twoFactorMethod === "app"
      ? "XXEXCH"
      : userData?.twoFactorMethod === "googleAuthenticator"
      ? "GoogleAuthenticator"
      : "null";

  const [selectedOption, setSelectedOption] = useState(tempCurrentSelections);
  const [userInput, setUserInput] = useState("");
  const [isDisable2Factor, setIsDisable2Factor] = useState(false);
  const [error, setError] = useState("");

  // const [otpSent, setOtpSent] = useState(false);

  const handleChooseOption = (option) => {
    setSelectedOption(option);
  };

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
    if (userInput.length > 5) {
      setError("Only 6 digit allowed");
      return;
    }
  };

  const handleSubmit = () => {
    // Handle submission of selected option and user input here
    console.log("Selected option:", selectedOption);
    console.log("User input:", userInput);
    // Close the modal
    props.onClose();
  };

  const handleVerifyCode = () => {
    if (userInput.length === 6) {
      setError("");
      const responseObject = {
        authType: "app",
        OTPValue: userInput,
        twoFactorMethod: "app",
        status: true,
      };
      console.log("responseObject", responseObject);
      //REUSING THE SAME CODE WITH PASSING ANOTHER DATA TO IDENTITY
      dispatch(
        googleloginValidation({
          data: responseObject,
          callback: (data) => {
            if (data) {
              props.isClose();
              dispatch(getUserData());
            }
          },
        })
      );

      return;
    } else {
      setError("Only 6 digit allowed");
      console.log("not allowed");
      return;
    }
  };

  const googleResponse = (responseData) => {
    const responseObject = {
      authType: "google",
      response: responseData,
      twoFactorMethod: "google",
      status: responseData?.email_verified,
    };
    dispatch(
      googleloginValidation({
        data: responseObject,
        callback: (data) => {
          if (data) {
            props.isClose();
            dispatch(getUserData());
          }
        },
      })
    );
  };

  return (
    <div className={`${modalClass} z-50`}>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="w-full max-w-xl p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 dark:bg-gray-800 dark:border-gray-700 z-50">
        <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
          Choose Authentication Method
        </h5>
        <div className="space-y-4">
          {/* Card 1: Google Login */}
          {/* <div
            className={`${
              selectedOption === "Google" ? "bg-blue-200" : "bg-gray-100"
            } rounded-lg p-4 flex items-center justify-between cursor-pointer`}
            onClick={() => handleChooseOption("Google")}
          >
            <span className="text-gray-900 font-semibold">Google Login</span>
            {selectedOption === "Google" && (
              <span className="text-blue-500">Chosen</span>
            )}
          </div> */}

          {/* Card 2: GOOGLE Authenticator */}
          <div
            className={`${
              selectedOption === "GoogleAuthenticator"
                ? "bg-blue-200"
                : "bg-gray-100"
            } rounded-lg p-3 flex cursor-pointer justify-between items-center`}
            onClick={() => handleChooseOption("GoogleAuthenticator")}
          >
            <div className="flex items-center">
              <div className="p-3">
                <img
                  src={Google_Authenticator_logo}
                  width="40px"
                  height="40px"
                  alt="logo"
                />
              </div>
              <div>
                <h6 className="text-gray-900 font-semibold">
                  Google Authenticator App
                </h6>
              </div>
            </div>

            {selectedOption === "GoogleAuthenticator" && (
              <img
                src={SelectPNG}
                alt="SelectPNG"
                srcset=""
                height="50px"
                width="50px"
              />
            )}
          </div>

          {/* Card 3: XXEXCH App Authentication */}
          <div
            className={`${
              selectedOption === "XXEXCH" ? "bg-white" : "bg-white"
            } rounded-lg p-3 flex cursor-pointer justify-between items-center`}
            onClick={() => handleChooseOption("XXEXCH")}
          >
            <div className="flex items-center">
              <div className="p-3">
                <img src={Xxexch_logo} width="40px" height="40px" alt="logo" />
              </div>
              <div>
                <h6 className="text-gray-900 font-semibold">
                  {appName} Authentication App
                </h6>
              </div>
            </div>

            {selectedOption === "XXEXCH" && (
              <img
                src={SelectPNG}
                alt="SelectPNG"
                srcset=""
                height="50px"
                width="50px"
              />
            )}
          </div>

          {/* onClick={() => handleChooseOption("XXEXCH")} */}

          {/* Card 3: Mobile OTP Authentication */}
          {/* <div
          className={`${
            selectedOption === "MobileOTP" ? "bg-blue-200" : "bg-gray-100"
          } rounded-lg p-4 flex items-center justify-between cursor-pointer`}
          onClick={() => handleChooseOption("MobileOTP")}
        >
          <span className="text-gray-900 font-semibold">
            Mobile OTP Verification
          </span>
          {selectedOption === "MobileOTP" && (
            <span className="text-blue-500">Chosen</span>
          )}
        </div> */}
        </div>

        {/* 
        {selectedOption === "Google" && (
          <div className="mt-4">
            <Googlelogin callbackData={googleResponse} />
          </div>
        )} */}

        {selectedOption === "XXEXCH" && (
          <div className="mt-4">
            <div className="flex justify-between text-center ">
              <input
                type="text"
                value={userInput}
                onChange={handleInputChange}
                placeholder={`Enter 2FA Code from the ${appName} App`}
                className="flex-none w-80 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
              <button class="custom-button">
                {userData?.twoFactorAuthPassword}
              </button>
            </div>

            {error !== null && (
              <p className="mt-2 text-sm text-red-500">{error}</p>
            )}

            {/* <button
              onClick={handleVerifyCode}
              className="mt-3 px-4 py-2 bg-skin-loginBtn text-white rounded hover:outline outline-1 outline-offset-2"
            >
              Verify Code
            </button> */}
          </div>
        )}
        {selectedOption === "GoogleAuthenticator" && (
          <QRCode toggled={props.isClose} />
        )}

        {/* {selectedOption === "MobileOTP" && (
        <div className="mt-4">
          <input
            type="text"
            value={userInput}
            onChange={handleInputChange}
            placeholder="Enter your mobile number"
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
          <button
            onClick={handleSendOTP}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Send OTP
          </button>
          {otpSent && (
            <>
              <p className="mt-2 text-sm text-gray-600">OTP Sent!</p>
              <input
                type="text"
                value={userInput}
                onChange={handleInputChange}
                placeholder="Enter OTP"
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
              <button
                onClick={handleSendOTP}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Verify OTP
              </button>
            </>
          )}
        </div>
      )} */}

        <div className="mt-4 flex justify-between">
          {selectedOption !== "GoogleAuthenticator" ? (
            <>
              <button
                onClick={handleVerifyCode}
                className="px-4 py-2 bg-blue-900 text-skin-textPrimaryColor rounded hover:outline-double outline-3 outline-offset-2"
              >
                Submit
              </button>

              {selectedOption === "XXEXCH" && (
                <Link to="https://myapkexchbucket247.s3.eu-west-2.amazonaws.com/app-release-31.apk">
                  <button
                    // className="mt-3 ml-2 px-4 py-2 bg-skin-loginBtn text-white rounded hover:outline outline-1 outline-offset-2">
                    className="px-4 py-2 bg-skin-loginBtn text-skin-textPrimaryColor rounded hover:outline-double outline-3 outline-offset-2"
                  >
                    Download APK
                  </button>
                </Link>
              )}

              <button
                onClick={props.isDisable2Factor}
                value="disable2Factor"
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                {isDisable2Factor ? "Disable Processing..." : "Disable 2FA"}
              </button>
            </>
          ) : (
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1"
              >
                <button className="px-4 border py-2 bg-dark-500 text-white rounded hover:bg-dark-600">
                  Android App
                </button>
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              >
                <button className="px-4 border py-2 bg-dark-500 text-white rounded hover:bg-dark-600">
                  IOS App
                </button>
              </a>

              <button
                onClick={props.isDisable2Factor}
                value="disable2Factor"
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                {isDisable2Factor ? "Disable Processing..." : "Disabled"}
              </button>
            </>
          )}
          <button
            onClick={props.isClose}
            className="px-4 py-2 bg-gray-100 text-skin-containerText rounded hover:bg-gray-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
