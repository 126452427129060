import { all, call, put, takeEvery } from "redux-saga/effects";
import { META_DEPOSIT } from "../../actions/types";
import {
  metaDepositSuccess,
  metaDepositFailure,
} from "../../actions/metaWallet/metaDepositAction";
import API from "../../../utils/api";
import { notifyWarning } from "../../../utils/helper";

function* depositMetaWallet(action) {
  try {
    const { data } = yield API.post(
      "/api/v1/moneyplant-wallet-transfer",
      action.payload
    );
    if (data.code !== 400) {
      yield put(metaDepositSuccess(true));
      yield call(action.payload.callback, data);
    } else {
      yield put(metaDepositFailure());
      yield call(action.payload.callback, data);
    }
  } catch (error) {
    console.log(error);
    notifyWarning("Couldn't initiate Wallet Deposit");
    yield put(metaDepositFailure());
  }
}

export function* watchDepositWalletAPI() {
  yield takeEvery(META_DEPOSIT, depositMetaWallet);
}

export default function* rootSaga() {
  yield all([watchDepositWalletAPI()]);
}
