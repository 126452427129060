import {
  META_WITHDRAW,
  META_WITHDRAW_SUCCESS,
  META_WITHDRAW_FAILURE,
} from "../types";

export const metaWithdraw = (payload) => ({
  type: META_WITHDRAW,
  payload
});
export const metaWithdrawSuccess = (payload) => ({
  type: META_WITHDRAW_SUCCESS,
  payload
});

export const metaWithdrawFailure = (payload) => ({
  type: META_WITHDRAW_FAILURE,
  
});
