import {
    GET_ACCOUNT_STATEMENT,
    GET_ACCOUNT_STATEMENT_FAILURE,
    GET_ACCOUNT_STATEMENT_SUCCESS,
  } from "../../actions/types";
  
  const INIT_STATE = {
    loading: false,
    data: null,
  };
  
  const getAccountStatementReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ACCOUNT_STATEMENT:
        return { ...state, loading: true };
      case GET_ACCOUNT_STATEMENT_SUCCESS:
        return { ...state, data: action.payload, loading: false };
      case GET_ACCOUNT_STATEMENT_FAILURE:
        return { ...state, loading: false };
      default:
        return state;
    }
  };
  
  export default getAccountStatementReducer;
  