/** @format */
import React, { useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { GiCardAceClubs } from "react-icons/gi";
import { FaChartBar } from "react-icons/fa";
import {
  MdOutlineScoreboard,
  MdAccessTimeFilled,
  MdCurrencyExchange,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { checkCasinoAccess } from "../utils/helper";
import { BsFillDice5Fill } from "react-icons/bs";
const MobileNavbar = () => {
  const userData = useSelector((state) => state?.GetUserData?.userData?.data);
  const storedLoggedIn = localStorage.getItem("token");
  const Menus = [
    { name: "Home", link: "/home", icon: AiFillHome, dis: "translate-x-82" },
    {
      name: "Sports",
      link: "/all-sports",
      icon: MdOutlineScoreboard,
      dis: "translate-x-32",
    },
    {
      name: "In-play",
      link: "/in-play",
      icon: MdAccessTimeFilled,
      dis: "translate-x-0",
    },
    {
      name: "Casino",
      link: "/casino",
      icon: GiCardAceClubs,
      dis: "translate-x-48",
      value: 1,
      type: "casino",
    },
  ];
  // new money Plant navmenu
  let MetaMenuItem = {
    name: "Stocks",
    link: "/stocks",
    icon: MdCurrencyExchange,
    dis: "translate-x-16",
  };
  Menus.push(MetaMenuItem);

  if (userData) {
    Menus.push({
      name: "My Markets",
      link: "/my-market",
      icon: FaChartBar,
      dis: "translate-x-16",
      value: 1,
      type: "markets",
    });
  } else {
    Menus.push({
      name: "Slots",
      link: "/slots",
      icon: BsFillDice5Fill,
      dis: "translate-x-16",
      value: 1,
      type: "casino",
    });
  }
  const [active, setActive] = useState();
  const [filteredMenu, setFilteredMenu] = useState([]);

  // this function is to add the animation
  // const [showButton, setShowButton] = useState(false);
  // useEffect(() => {
  //   // On initial render, set showButton to true
  //   if (showButton === false) {
  //     setShowButton(true);
  //   } else {
  //     // When path changes, set showButton to false first
  //     setShowButton(false);
  //     // Then set it to true after a short delay
  //     setTimeout(() => {
  //       setShowButton(true);
  //     }, 200); // You can adjust this delay if needed
  //   }
  // }, [path]);

  useEffect(() => {
    if (userData) {
      const filteredData = Menus?.filter((data) => {
        if (checkCasinoAccess(userData?.casino, data?.value)) {
          return data;
        }
      });

      setFilteredMenu(filteredData);
    } else {
      setFilteredMenu(Menus);
    }
  }, [userData]);

  return (
    <div className="bg-skin-navtop max-h-[3.5rem] px-2 pb-5 fixed inset-x-0 bottom-0 rounded-tr-md z-[100]">
      {/* text-skin-white  */}
      <ul className="flex relative justify-between">
        {filteredMenu?.map((menu, i) => {
          if (menu.name == "Stocks") {
            if (storedLoggedIn) {
              return (
                <li key={i} className="w-full">
                  <Link
                    to={menu?.link}
                    className={`flex flex-col  pt-2 pb-2 text-skin-navtext `}
                    onClick={() => setActive(i)}
                  >
                    <span
                      className={`flex justify-center text-xl cursor-pointer ${
                        i === active && " text-skin-navbarActiveIconBg"
                      }`}
                    >
                      {React.createElement(menu?.icon, { size: "25" })}
                    </span>
                    <span
                      className={`flex justify-center text-xs whitespace-nowrap text-skin-navtext  font-semibold ${
                        i === active && "text-skin-navbarActiveIconBg"
                      }`}
                    >
                      {menu.name}
                    </span>
                  </Link>
                </li>
              );
            }
          } else if (menu.name == "Home") {
            // return (
            //   <div className="w-full relative ">
            //     <li key={i} className="w-full bg-skin-navtop">
            //       <Link
            //         to={menu?.link}
            //         className={`flex flex-col  pt-2 pb-2 text-skin-navtext`}
            //         onClick={() => setActive(i)}
            //       >
            //         <span
            //           className={`flex justify-center text-xl cursor-pointer  ${
            //             i === active && "text-skin-navbarActiveIconBg"
            //           }`}
            //         >
            //           {React.createElement(menu?.icon, { size: "25" })}
            //         </span>
            //         <span
            //           className={`flex justify-center text-xs whitespace-nowrap text-skin-navtext  font-semibold  ${
            //             i === active && "text-skin-navbarActiveIconBg"
            //           }`}
            //         >
            //           {menu.name}
            //         </span>
            //       </Link>
            //     </li>
            //     {/* inplay nav Button */}
            //     <li
            //       key={i + 1}
            //       className={`w-auto absolute -top-14  left-1/2 transform -translate-x-1/2 p-3 bg-[black] shadow-md shadow-[#ffb80c] rounded-[50%]`}
            //     >
            //       <Link
            //         to={Menus[i + 1]?.link}
            //         className={`flex flex-col  `}
            //         onClick={() => setActive(i + 1)}
            //       >
            //         <span
            //           className={`flex justify-center cursor-pointer text-[#ffb80c] ${
            //             i + 1 === active && " text-skin-navbarActiveIconBg"
            //           }`}
            //         >
            //           {React.createElement(Menus[i + 1]?.icon, { size: "15" })}
            //         </span>
            //         <span
            //           className={`flex justify-center text-[0.5rem] whitespace-nowrap text-[#ffb80c] font-semibold  ${
            //             i + 1 === active && "text-skin-navbarActiveIconBg"
            //           }`}
            //         >
            //           {Menus[i + 1]?.name}
            //         </span>
            //       </Link>
            //     </li>
            //   </div>
            // );
          } else {
            return (
              <li key={i} className="w-full">
                <Link
                  to={menu?.link}
                  className={`flex flex-col  pt-2 pb-2 text-skin-navtext `}
                  onClick={() => setActive(i)}
                >
                  <span
                    className={`flex justify-center text-xl cursor-pointer ${
                      i === active && " text-skin-navbarActiveIconBg"
                    }`}
                  >
                    {React.createElement(menu?.icon, { size: "25" })}
                  </span>
                  <span
                    className={`flex justify-center text-xs whitespace-nowrap text-skin-navtext  font-semibold ${
                      i === active && "text-skin-navbarActiveIconBg"
                    }`}
                  >
                    {menu.name}
                  </span>
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default MobileNavbar;
