/** @format */

import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import { FaUser, FaAffiliatetheme } from "react-icons/fa";
import { VscChevronDown, VscChevronUp } from "react-icons/vsc";
import { BsGraphUp } from "react-icons/bs";
import { IoIosCloseCircleOutline, IoIosStats, IoMdClose } from "react-icons/io";
import { PiPokerChipFill } from "react-icons/pi";
import { SiReadthedocs } from "react-icons/si";
import { BsPersonFillLock } from "react-icons/bs";
import { FaListOl } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { useErrorBoundary } from "react-error-boundary";
import LoginModal from "./LoginModal";
import ChipSetting from "./ChipSetting";
import ChangePassword from "./ChangePassword";
import { ThemeContext } from "../context/ThemeContext";
import { IoSunnySharp } from "react-icons/io5";
import { IoMoonOutline } from "react-icons/io5";
// import LogoDark from "../assets/images/xxexch.png";
import LogoDark from "../assets/images/logo-dark.png";
import LogoLight from "../assets/images/logo-light.png";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../redux/actions/auth/logoutAction";
import {
  getSport,
  getUserData,
  globalSportData,
  getChipSetting,
  notification,
  getUserBalanceData,
  updateTwoFactorStatusAction,
  getBetHorseRace,
  getGapCasino,
} from "../redux/actions";
import { SocketContext } from "../context/SocketContext";
import {
  checkCasinoAccess,
  getLocalStorageItem,
  notifySuccess,
  notifyWarning,
} from "../utils/helper";
import { LoadingContext } from "../context/LoadingContext";
import { currencySymbols, mobileThreshold } from "../utils/constants";
import LockButton from "./2FAComponents/LockButton/LockButton";
import Notification from "./Notification/Notification";
import MobileSideNavbar from "./MobileSideNavbar";
import { GiHamburgerMenu } from "react-icons/gi";
import "./style.css";
import ConfirmModal from "./ConfirmModal";
import { SiAuth0 } from "react-icons/si";

const navigation = [
  { name: "MY MARKETS", href: "/my-market", current: false },
  { name: "SPORTS", href: "/all-sports", current: true },
  { name: "IN-PLAY", href: "/in-play", current: false },
  // { name: "IPL CLUB", href: "/indian-premier-league", current: false },
  // { name: "INDIAN CASINO", href: "/indian-casino", current: false },
  // {
  //   name: "LIVE CASINO",
  //   href: "/live-casino",
  //   current: false,
  //   value: 1,
  //   type: "casino",
  // },
  // {
  //   name: "SLOTS",
  //   href: "/slots",
  //   current: false,
  //   value: 1,
  //   type: "casino",
  // },
  {
    name: "CASINO",
    href: "/casino",
    current: false,
    value: 1,
    type: "gapcasino",
  },
  { name: "STOCKS", href: "/stocks", current: false, type: "stocks" },
  // {
  //   name: "ST8 CASINO",
  //   href: "/st8-casino",
  //   current: false,
  //   value: 1,
  //   type: "casino",
  // },
];

const loginRightMenu = [
  { name: "D", href: "/deposit", current: false },
  { name: "W", href: "/withdrawal-request", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MainNavbar = ({ Navdata }) => {
  const { showBoundary } = useErrorBoundary();
  // console.log(showBoundary , "showBoundary")
  const location = useLocation();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  const currentRoute = location.pathname;
  const userData = useSelector((state) => state?.GetUserData?.userData?.data);
  //console.log("user data ", userData);
  const newBet = useSelector((state) => state?.PlaceBet?.bets);
  const username = JSON.parse(localStorage?.getItem("userData"));
  const storedLoggedIn = localStorage.getItem("token");
  const { theme, setTheme } = useContext(ThemeContext);
  const { loading, setloading } = useContext(LoadingContext);
  const dropdownRef = useRef(null);

  const profileMenu = [
    {
      icon: "none",
      list: `Hi, ${userData?.name || username?.name}`,
      href: "/",
      current: false,
      username: true,
    },
    {
      icon: BsGraphUp,
      list: "My Market",
      href: "/my-market",
      current: false,
      username: false,
    },
    {
      icon: IoIosStats,
      list: "Profit-loss",
      href: "/reports",
      current: false,
      username: false,
    },
    {
      icon: FaListOl,
      list: "Account Statement",
      href: "/account-statement",
      current: false,
      username: false,
    },
    {
      icon: PiPokerChipFill,
      list: "Chips Setting",
      modal: true,
      username: false,
    },
    {
      icon: SiReadthedocs,
      list: "Terms & condition",
      href: "/terms-and-conditions",
      username: false,
    },
    {
      icon: FaAffiliatetheme,
      list: "Theme",
      modal: true,
      username: false,
    },
    {
      icon: SiAuth0,
      list: "2FA",
      modal: true,
      username: false,
    },
    {
      icon: BsPersonFillLock,
      list: "Change Password",
      modal: true,
      username: false,
    },
    // { icon: FaLock, list: "2FA Authentication", username: false, },
    { icon: BiLogOut, list: "Logout", username: false },
  ];

  const [profileMenuToggle, setProfileMenuToggle] = useState(profileMenu);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isChipSettingOpen, setisChipSettingOpen] = useState(false);
  const [isChangePasswordOpen, setisChangePasswordOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [balance, setBalance] = useState(0);
  const [exposure, setExposure] = useState(0);
  const [amountHold, setAmountHold] = useState(0);
  const [filteredNav, setFilteredNav] = useState([]);
  const [currency, setCurrency] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [notificationData, setNotificationData] = useState([]);
  const [newEventsFound, setNewEventsFound] = useState(null);
  const [arrowTimeout, setArrowTimeout] = useState(0);

  //MOBILE SIDEBAR
  const [mobileSideBar, setMobileSideBar] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 769);

  useEffect(() => {
    if (mobileSideBar) {
      setAnimationClass("enter");
    } else {
      setAnimationClass("exit");
    }
  }, [mobileSideBar]);

  useEffect(() => {
    dispatch(
      getGapCasino()
    );
  }, []);

  const handleMenuClick = () => {
    setIsProfileOpen(!isProfileOpen);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (href, username) => {
      if (username) {
      } else {
        navigate(href);
        setIsProfileOpen(!isProfileOpen);
        setIsDropdownOpen(isDropdownOpen);
      }
    },
    [navigate, isProfileOpen, isDropdownOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      clearTimeout(arrowTimeout);
    };
  }, []);

  useEffect(() => {
    if (windowWidth >= 1024) {
      let modifiedMenu = profileMenu?.filter(
        (menu) => menu?.list !== "My Market"
      );
      setProfileMenuToggle(modifiedMenu);
    } else {
      setProfileMenuToggle(profileMenu);
    }
    // Function to update the window dimensions
    const updateWindowDimensions = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      setIsMobileScreen(window.innerWidth < mobileThreshold);
    };

    // Add an event listener to update dimensions when the window is resized
    window.addEventListener("resize", updateWindowDimensions);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, [windowWidth, windowHeight, userData]);

  useEffect(() => {
    if (Navdata) {
      if (Object.keys(Navdata).length === 0) {
        Navdata.logo_dark = LogoDark;
        Navdata.logo_light = LogoLight;
      }
    } else {
      Navdata.logo_dark = LogoDark;
      Navdata.logo_light = LogoLight;
    }
  }, [Navdata]);

  //we dont need this one later

  useEffect(() => {
    const fetchData = async () => {
      const token = getLocalStorageItem("token");
      try {
        const sportData = await new Promise((resolve, reject) => {
          dispatch(
            getSport({ auth: token, callback: resolve, errorCallback: reject })
          );
        }).catch((error) => {
          //showBoundary(error);
        });
        setdata(sportData);
        setloading(false); //set loading true ,,,,,,,,,,,
      } catch (error) {
        // Handle error
        console.log(error);
      }
    };

    setdata([]);
    fetchData();
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loggedIn, newEventsFound]);

  useEffect(() => {
    dispatch(globalSportData({ data: data }));
  }, [data, dispatch]);

  useEffect(() => {
    // Check if the login status is stored in localStorage
    //console.log(storedLoggedIn, "storedLogin");
    if (storedLoggedIn) {
      setLoggedIn(true);
      dispatch(getUserData());
      dispatch(getChipSetting());
    } else {
      handleLogout();
    }
  }, [dispatch, loggedIn, storedLoggedIn]);

  useEffect(() => {
    socket.on("betStatusChange", (data) => {
      if (data && data?.length > 0) {
        if (data.includes(userData?.userId)) {
          dispatch(
            getUserBalanceData({
              callback: (data) => {
                //console.log(data, "userData data");
                setExposure(data?.exposure);
                const balance = data?.balance + parseFloat(data?.exposure);
                setBalance(balance);
              },
            })
          );
        }
      }
    });
  }, [userData, socket]);

  useEffect(() => {
    setExposure(userData?.exposure);
    // setAmountHold(userData?.amountOnHold);
  }, [userData, newBet]);

  useEffect(() => {
    socket.on("new-event", () => {
      //console.log("new triggered");
      setNewEventsFound(Math.random());
    });
  }, [socket]);

  useEffect(() => {
    socket.on("new-bet", (data) => {
      dispatch(getUserData());
    });
  }, [newBet]);

  useEffect(() => {
    // setBalance(Math.abs(userData?.balance) - Math.abs(exposure));
    setBalance(userData?.balance - Math.abs(exposure));
  }, [exposure, userData, newBet]);

  useEffect(() => {
    if (userData) {
      const filteredData = navigation?.filter((data) => {
        if (checkCasinoAccess(userData?.casino, data?.value)) {
          return data;
        }
      });
      //getCurencySymbol();
      setFilteredNav(filteredData);
    } else {
      const filteredData = navigation?.filter((data) => data?.href !== "/my-market");
      setFilteredNav(filteredData);
      // setFilteredNav(navigation);
    }
  }, [userData]);

  const closeModal = () => {
    setIsLoginOpen(false);
    setIsSignupOpen(false);
  };

  const handleClickOutside = (event) => {
    event.stopPropagation();
    const isProfileButtonClicked =
      event.target.classList.contains("bg-skin-cardhead");

    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !isProfileButtonClicked
    ) {
      setIsProfileOpen(false);
      setIsDropdownOpen(false);
    }
  };

  //Theme toggle function
  const handleThemeClick = (e) => {
    setTheme(!theme);
  };

  const handleModal = (id) => {
    if (id === "Change Password") {
      setisChangePasswordOpen(true);
    } else if (id === "Chips Setting") {
      setisChipSettingOpen(true);
    } else if (id === "2FA") {
      setConfirmModalToggle(true);
    } else if (id === "Theme") {
      setTheme(!theme);
    }
    handleMenuClick();
  };

  const handleLogout = () => {
    // Remove the login status from localStorage
    dispatch(
      logOut({
        callback: () => {
          //  console.log("logout user");
          navigate("/home");
          setIsProfileOpen(false);
          setLoggedIn(false);
          notifySuccess("Logged Out");
          socket.emit("logout", userData?.userId);
        },
      })
    );

    // Log the logout message
  };

  const walletBalance = [
    {
      name: "Balance",
      amount: balance ? parseFloat(balance)?.toFixed(2) : 0,
    },
    // {
    //   name: "Credit Reference",
    //   amount: userData?.creditReference ? userData?.creditReference : 0,
    // },
    {
      name: "Exposure",
      amount: exposure ? Math.abs(exposure).toFixed(2) : 0,
    },
    // {
    //   name: "On Hold",
    //   amount: amountHold ? Math.abs(amountHold).toFixed(2) : 0,
    // },
  ];

  // console.log(Object.keys(Navdata).length, "Navdata?.logo_dark");

  const getCurencySymbol = () => {
    const userCurrency = username?.currency?.code;
    setCurrency(currencySymbols[userCurrency?.toUpperCase()?.toString()]);
  };
  const fetchNotification = (userId) => {
    dispatch(
      notification({
        user: userId,
        type: userData ? "post" : "get",
        callback: (data) => {
          if (data?.meta?.code === 200) {
            setNotificationData(data.data);
          }
        },
      })
    );
  };
  useEffect(() => {
    socket.on("addedNotification", (userId) => {
      fetchNotification(userId);
    });
    fetchNotification();
  }, [socket, userData]);

  useEffect(() => {
    let timeOut;
    if (notificationData?.length > 0) {
      timeOut = setInterval(() => { }, 50000);
    }
    return () => clearInterval(timeOut);
  }, [notificationData]);

  useEffect(() => {
    let timeout;
    if (mobileSideBar) {
      setAnimationClass("enter");
    } else {
      setAnimationClass("exit");
      timeout = setTimeout(() => {
        setAnimationClass("");
      }, 300);
    }
    return () => clearTimeout(timeout);
  }, [mobileSideBar]);

  const toggleArrow = () => {
    setIsDropdownOpen(!isDropdownOpen);
    let arrow_timeout = setTimeout(() => {
      setIsDropdownOpen(false);
      clearTimeout(arrow_timeout);
    }, 3000);
    setArrowTimeout(arrow_timeout);
  };

  const [confirmModalToggle, setConfirmModalToggle] = useState(false);
  const [is2faStatus, setIs2faStatus] = useState(userData?.twoFactorEnabled);
  const [isDisabled, setIsDisabled] = useState(false);

  const confirmModalToggleFunc = () => {
    setConfirmModalToggle(!confirmModalToggle);
  };

  // const handleTwoFactor = (callback) =>{
  //   setIsTwoFactorDisabled(true)
  //   if(callback){
  //     const currectData = {
  //       status:callback.target.value
  //        };
  //        dispatch(
  //         updateTwoFactorStatusAction({
  //           data:currectData,
  //           callback: (data) => {
  //             setIs2faStatus(data?.data?.status);
  //             setTimeout(()=>{
  //               setIsTwoFactorDisabled(false)
  //             },5000)
  //           }
  //         })
  //        )
  //   }
  // }

  const handleDisable2Factor = (e) => {
    if (is2faStatus) {
      if (e.target.value) {
        setIsDisabled(true);
        const currectData = {
          status: false,
        };
        dispatch(
          updateTwoFactorStatusAction({
            data: currectData,
            callback: (data) => {
              setIs2faStatus(data?.data?.status);
              setTimeout(() => {
                setIsDisabled(false);
              }, 5000);
              confirmModalToggleFunc();
            },
          })
        );
      }
    } else {
      //console.log("2FA is already disabled");
      notifyWarning("2FA is already disabled");
      setTimeout(() => {
        confirmModalToggleFunc();
      }, 2000);
    }
  };

  useEffect(() => {
    setIs2faStatus(userData?.twoFactorEnabled);
  }, [userData]);

  return (
    <>
      {notificationData[0]?.message ? (
        <Notification msg={notificationData[0]?.message} />
      ) : null}

      <Disclosure
        as="nav"
        className={`border-b z-0 border-gray-200/10 bg-skin-navtop`}
      >
        {({ open }) => (
          <>
            <div
              className={`${isMobileScreen
                ? ""
                : "px-4 mx-auto md:px-4 lg:px-6 xl:px-6 2xl:px-6"
                }`}
            >
              <div className="relative flex items-center justify-between sm:gap-3 gap-1">
                <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start">
                  {isMobileScreen && (
                    <div>
                      {mobileSideBar ? (
                        <>
                          <div className="p-2">
                            <IoMdClose
                              size={32}
                              className="fade-in"
                              onClick={() => setMobileSideBar(!mobileSideBar)}
                            />
                          </div>
                          <MobileSideNavbar
                            visible={mobileSideBar}
                            animationClass={animationClass}
                            mobileSideBar={mobileSideBar}
                            setMobileSideBar={setMobileSideBar}
                          />
                        </>
                      ) : (
                        <div className="rotate p-2">
                          <GiHamburgerMenu
                            size={28}
                            onClick={() => setMobileSideBar(!mobileSideBar)}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {/* mainlogo */}
                  {!loading ? (
                    <div className="flex items-center ">
                      <Link to="/home">
                        <img
                          className=" h-[28px] w-[115px] sm:h-[36px] sm:w-[148px] lg:h-[40px] lg:w-[198px] "
                          src={theme ? Navdata?.logo_dark : Navdata?.logo_light}
                          alt="main-logo"
                        />
                      </Link>
                    </div>
                  ) : (
                    <div className="pr-9 pt-1">
                      <button className="bg-skin-cardhead hover:bg-skin-sidenavhover p-0.5 rounded-md w-52 ml-5 h-10 bg-white bg-opacity-5 text-white rounded-5px flex items-center  via-white to-transparent animate-loading transform  cursor-pointer"></button>
                    </div>
                  )}

                  {/* Navbar Links */}
                  <div className="hidden ml-11 lg:block">
                    {!loading ? (
                      <div className="flex h-full items-center">
                        {!storedLoggedIn && <span onClick={() => notifyWarning("Please Login.")} className="cursor-pointer px-3 py-5 text-xs font-font-family font-bold text-skin-navtext hover:bg-skin-mainNavbarHoverColor">MY MARKETS</span>}
                        {filteredNav?.map((item) => {
                          if (item.name == "STOCKS") {
                            if (storedLoggedIn) {
                              return (
                                <Link
                                  key={item.name}
                                  to={item.href}
                                  className={classNames(
                                    item.href == currentRoute &&
                                    "bg-skin-mainNavbarHoverColor text-skin-containerText ",
                                    "px-3 py-5 text-xs font-font-family font-bold text-skin-navtext hover:bg-skin-mainNavbarHoverColor "
                                  )}
                                  aria-current={
                                    item.current ? "page" : undefined
                                  }
                                >
                                  {item.name}
                                </Link>
                              );
                            }
                          } else {
                            return (
                              <Link
                                key={item.name}
                                to={item.href}
                                className={classNames(
                                  item.href == currentRoute &&
                                  "bg-skin-mainNavbarHoverColor text-skin-containerText ",
                                  "px-3 py-5 text-xs font-font-family font-bold text-skin-navtext hover:bg-skin-mainNavbarHoverColor "
                                )}
                                aria-current={item.current ? "page" : undefined}
                              >
                                {item.name}
                              </Link>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      <div className="flex  pr- h-12 ">
                        {" "}
                        <button className="bg-skin-cardhead hover:bg-skin-sidenavhover p-0.5 rounded-md w-16 ml-8 mt-4 h-3 bg-opacity-25 text-white rounded-5px flex items-center  via-white to-transparent animate-loading transform -translate-x-full cursor-pointer"></button>
                        <button className="bg-skin-cardhead hover:bg-skin-sidenavhover p-0.5 rounded-md w-16  ml-8 mt-4 h-3 bg-opacity-25 text-white rounded-5px flex items-center   via-white to-transparent animate-loading transform -translate-x-full cursor-pointer"></button>
                        <button className="bg-skin-cardhead hover:bg-skin-sidenavhover p-0.5 rounded-md w-16 ml-8 mt-4 h-3 bg-opacity-25 text-white rounded-5px flex items-center  via-white to-transparent animate-loading transform -translate-x-full cursor-pointer"></button>
                        <button className="bg-skin-cardhead hover:bg-skin-sidenavhover p-0.5 rounded-md w-16  ml-8 mt-4 h-3 bg-opacity-25 text-white rounded-5px flex items-center  via-white to-transparent animate-loading transform -translate-x-full cursor-pointer"></button>
                        <button className="bg-skin-cardhead hover:bg-skin-sidenavhover p-0.5 rounded-md w-16 ml-8 mt-4 h-3 bg-opacity-25 text-white rounded-5px flex items-center  via-white to-transparent animate-loading transform -translate-x-full cursor-pointer"></button>
                      </div>
                    )}
                  </div>
                </div>

                {/* THEME TOGGLE */}
                {/* {!loading ? (
                  <label className="relative mt-4 inline-flex items-center mb-3 cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      checked={theme}
                      className="sr-only peer"
                      onChange={handleThemeClick}
                    />
                    <IoMoonOutline className="fas fa-moon text-skin-blue absolute top-1/2 left-3 transform  -translate-x-1/2 -translate-y-1/2" />
                    <div className="p-2 py-3 w-11 h-6 bg-skin-cardhead peer-focus:outline-none pt-3 rounded-full  dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-white-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-transparent-600">
                      <IoSunnySharp className="fas fa-sun text-skin-sun absolute top-1/2 -right-1 transform -translate-y-1/2 -translate-x-1/2  " />
                    </div>
                  </label>
                ) : (
                  <button className="bg-skin-cardhead hover:bg-skin-sidenavhover p-0.5 rounded-md w-16 h-8 bg-white bg-opacity-25 text-white rounded-5px flex items-center cursor-pointer"></button>
                )} */}

                {/* AFTER LOGIN */}
                {storedLoggedIn !== null && (
                  <>
                    {!loading ? (
                      <div className="flex gap-1.5 py-2 lg:p-0  items-center relative">
                        <div className="  wallet  p-0.5 rounded-md flex items-center cursor-pointer">
                          <span className="text-skin-balance text-xs font-semibold mx-2.5">
                            <div className="">
                              {/* &#x20B9;{" "} 
                             {currency}{" "}  */}
                              {/* {balance >= 0
                                ? balance.toLocaleString("en-us", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "getting balance.."} */}
                            </div>
                          </span>
                          {/* {windowWidth <776 ?<>
                            {isDropdownOpen ? (
                            <button
                              type="button"
                              onClick={() => toggleArrow()}
                              className="bg-skin-balanceIcon rounded-md px-2.5 font-semibold p-0.5  text-xl text-white  hover:text-white  focus:outline-none h-8"
                            >
                              <VscChevronUp />
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => toggleArrow()}
                              className="bg-skin-balanceIcon rounded-md px-2.5 font-semibold p-0.5  text-xl text-white  hover:text-white  focus:outline-none h-8"
                            >
                              <VscChevronDown />
                            </button>
                          )}
                            </> : null } */}
                          <div></div>

                          <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row lg:px-3 lg:py-4 md:p-0 sm:px-0 sm:py-0 sm:text-xs">
                            {walletBalance.map((element, index) => {
                              return (
                                <div
                                  className="flex sm:flex-row justify-between font-bold sm:text-xs"
                                  key={index}
                                >
                                  <span className="text-sm text-skin-balance   ">
                                    {element.name?.slice(0, 3)} : &nbsp;
                                  </span>
                                  <span
                                    className={` text-sm mr-2   ${element?.name === "Exposure"
                                      ? "text-red-600"
                                      : "text-skin-balance"
                                      }`}
                                  >
                                    {element?.name === "Exposure"
                                      ? "- " +
                                      element?.amount.toLocaleString(
                                        "en-us",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )
                                      : element?.amount.toLocaleString(
                                        "en-us",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* {windowWidth <776 ?   <div>
                          {isDropdownOpen && (
                            <div
                              ref={dropdownRef}
                              className="w-64 absolute text-skin-primary rounded-md top-12 -mt-[1px] right-1 bg-skin-navBackground z-50 backdrop-blur-sm divide-y"
                            >
                              <div className="wallet-balance px-3 py-4 rounded">
                                {walletBalance.map((element, index) => {
                                  return (
                                    <div
                                      className="flex justify-between font-bold border-b border-[#85858542]"
                                      key={index}
                                    >
                                      <span className="py-2 text-sm ">
                                        {element.name}
                                      </span>
                                      <span className="py-2 text-sm ">
                                        {element?.amount.toLocaleString(
                                          "en-us",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div> : null} */}

                        {/* Render your 2FA component here */}
                        {/* <LockButton /> */}
                        <>
                          <input
                            type="checkbox"
                            disabled={isDisabled}
                            checked={!is2faStatus}
                            value={!is2faStatus}
                            readOnly
                            onClick={confirmModalToggleFunc}
                            id="lock"
                          />

                          {confirmModalToggle && (
                            <ConfirmModal
                              isOpen={confirmModalToggle}
                              isClose={confirmModalToggleFunc}
                              isDisable2Factor={handleDisable2Factor}
                            />
                          )}
                        </>

                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsProfileOpen(!isProfileOpen);
                          }}
                          type="button"
                          className="bg-skin-cardhead hover:bg-skin-sidenavhoversecondary rounded-md px-3 py-2.5 font-semibold p-2 text-md text-skin-white  hover:text-skin-white  focus:outline-none mr-2"
                        >
                          <FaUser
                            className="bg-skin-cardhead"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setIsProfileOpen(!isProfileOpen);
                            }}
                          />
                        </button>
                        {isProfileOpen && (
                          <div
                            ref={dropdownRef}
                            className="w-64 absolute top-12 -mt-[1px] right-1 flex flex-col px-1.5 py-2 bg-skin-navBackground z-50 backdrop-blur-sm rounded "
                          >
                            {profileMenuToggle?.map((element, index) => (
                              <div
                                onClick={
                                  element.list === "Logout"
                                    ? handleLogout
                                    : element?.modal
                                      ? () => handleModal(element?.list)
                                      : () =>
                                        handleNavigate(
                                          element.href,
                                          element.username
                                        )
                                }
                                className="flex items-center gap-2.5 py-2 px-3 rounded text-skin-primary hover:duration-200 hover:text-skin-white cursor-pointer active:bg-skin-nav hover:bg-skin-nav"
                                key={index}
                              >
                                <div className={`icon group `}>
                                  {!element.username &&
                                    React.createElement(element.icon)}
                                </div>
                                <div
                                  className={`group menu-list cursor-pointer ${element.username
                                    ? "font-bold text-center"
                                    : ""
                                    }`}
                                >
                                  {element.list === "2FA Authentication" ? (
                                    <div>{"element.list"}</div>
                                  ) : (
                                    element.list
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <button className="bg-skin-cardhead hover:bg-skin-sidenavhover p-0.5  rounded-md w-16 h-8 bg-white bg-opacity-25 text-white rounded-5px flex items-center cursor-pointer"></button>
                    )}
                  </>
                )}
                {/* BEFORE LOGIN */}
                {storedLoggedIn === null && (
                  <>
                    {!loading ? (
                      <button
                        onClick={() => {
                          setIsLoginOpen(true);
                        }}
                        type="button"
                        className="font-semibold border border-black rounded-md text-skin-loginButtonFontBg bg-skin-loginButtonBg focus:outline-none p-2 px-4 py-3 text-xs focus:ring-2  focus:ring-offset-2 focus:ring-offset-gray-800 mr-2"
                      >
                        LOG IN
                      </button>
                    ) : (
                      <button className="text-skin-loginButtonFontBg bg-skin-loginButtonBg hover:bg-skin-sidenavhover p-0.5  rounded-md w-16 h-8 bg-opacity-25 rounded-5px flex items-center cursor-pointer mr-2"></button>
                    )}
                  </>
                )}
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-skin-sideBarHover "
                        : "text-gray-300 hover:bg-skin- hover:text-skin-white ",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {isLoginOpen && (
        <LoginModal
          className="z-50"
          closeModal={closeModal}
          onLogin={setLoggedIn}
        />
      )}
      {isChipSettingOpen && (
        <ChipSetting className="z-50" closeModal={setisChipSettingOpen} />
      )}
      {isChangePasswordOpen && (
        <ChangePassword className="z-50" closeModal={setisChangePasswordOpen} />
      )}
    </>
  );
};
export default MainNavbar;
