import {
  DELETE_UNMATCH_STX_BET,
  DELETE_UNMATCH_STX_BET_SUCCESS,
  DELETE_UNMATCH_STX_BET_FAILURE,
} from "../types";

export const deleteUnmatchStxBet = (payload) => ({
  type: DELETE_UNMATCH_STX_BET,
  payload,
});

export const deleteUnmatchStxBetSuccess = (payload) => ({
  type: DELETE_UNMATCH_STX_BET_SUCCESS,
  payload,
});

export const deleteUnmatchStxBetFailure = () => ({
  type: DELETE_UNMATCH_STX_BET_FAILURE,
});
